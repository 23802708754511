import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import SubmissionsIcon from '@images/icons/MyDashboarIcons/submissionIcon.svg';
import { translate } from '@app/components/IntlGlobalProvider';

const SubmissionCardContainer = styled.div`
  background: ${colors.white};
  gap: 0.5rem;
  padding: 2.5rem 1.87rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 1.25rem ${colors.myDashboardColors.cardShadow};
  margin-top: 3rem;
  cursor: pointer;
`;

const SubmissionCardSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: flex-start;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SubmissionTitile = styled.p`
  ${fonts.size.big()}
  ${fonts.weights.fw800()}
  color:${colors.myDashboardColors.subHeading};
  line-height: 1.75rem;
`;

const SubmissionText = styled.p`
  ${fonts.size.xRegular()}
  ${fonts.weights.fw400()}
  color:${colors.cardListItem};
  line-height: 1.5rem;
`;
const SubmissionCard = () => {
  return (
    <SubmissionCardContainer>
      <SubmissionCardSubContainer>
        <img src={SubmissionsIcon} />
        <ContentContainer>
          <SubmissionTitile>{translate('start_submission')}</SubmissionTitile>
          <SubmissionText>{translate('tips')}</SubmissionText>
        </ContentContainer>
      </SubmissionCardSubContainer>
    </SubmissionCardContainer>
  );
};

export default SubmissionCard;
