/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';

import languageProvider from '@containers/LanguageProvider/reducer';
import home from '@containers/HomeContainer/reducer';
import signUp from '@containers/SignUpContainer/reducer';
import packageDetails from '@containers/PackageDetailScreen/reducer';

import login from '@containers/LoginContainer/reducer';
import profileCreate from '@containers/ProfileContainer/reducer';
import jwtToken from '@containers/TokenProvider/reducer';
import myDashboard from '@containers/DashboardContainerPage/MyDashboard/reducer';
import submission from '@containers/DashboardContainerPage/NewSubmissionPage/reducer';
import copyrightContainerReducer from '@containers/CopyrightContainer/reducer';
import mySubmission from '@containers/DashboardContainerPage/MySubmissions/reducer';
import submittedStory from '@containers/DashboardContainerPage/SubmittedStoryPage/reducer';
import pendingApproval from '@containers/DashboardContainerPage/PendingApproval/reducer';
import offersReceivedReducer from '@containers/DashboardContainerPage/OfferView/reducer';
import offerHistoryReducer from '@containers/DashboardContainerPage/OfferHistory/reducer';
import offerStoryReducer from '@containers/DashboardContainerPage/OffersRecieved/reducer';
import offerDetailsReducer from '@containers/DashboardContainerPage/ManageOffer/reducer';
import mySpotTickets from '@containers/DashboardContainerPage/MySpotTickets/reducer';
import addSpotTickets from '@containers/DashboardContainerPage/AddSpotTickets/reducer';
import myNotificationsReducer from '@containers/DashboardContainerPage/MyNotifications/reducer';
import globalReducer from '@containers/App/reducer';
import dashboardContainerReducer from '@containers/DashboardContainerPage/reducer';
import acceptedOfferReducer from '@containers/DashboardContainerPage/AcceptedOffer/reducer';
import screenplayRequestsReducer from '@containers/DashboardContainerPage/RequestView/reducer';

import { AnyAction, createAction } from '@reduxjs/toolkit';

export const logoutAction = createAction('USER_LOGOUT');

const requestBlacklist = [
  'profileCreate/requestCreateProfile',
  'copyrightContainer/requestGeneratePaymentLink',
  'copyrightContainer/requestCopyrightWithTickets',
  'copyrightContainer/requestConfirmStorySubmission',
  'copyrightContainer/requestStateChange',
  'submission/requestSubmitStory',
  'addSpotTickets/requestGenerateSpotTicketsLink',
  'myNotifications/requestReadNotification',
  'myNotifications/requestGetInboxNotifications',
  'myNotifications/requestGetNotifications',
  'home/requestAccountStatus',
  'pendingApproval/requestGetFileUrl',
  'copyrightContainer/requestGenerateStoryPdf'
];

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducer = {}) {
  const appReducer = combineReducers({
    ...injectedReducer,
    languageProvider,
    home,
    signUp,
    login,
    profileCreate,
    jwtToken,
    packageDetails,
    myDashboard,
    submission,
    copyrightContainerReducer,
    mySubmission,
    submittedStory,
    pendingApproval,
    offersReceivedReducer,
    offerHistoryReducer,
    offerStoryReducer,
    offerDetailsReducer,
    mySpotTickets,
    addSpotTickets,
    myNotificationsReducer,
    globalReducer,
    dashboardContainerReducer,
    acceptedOfferReducer,
    screenplayRequestsReducer
  });

  const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    if (action.type === logoutAction.type) {
      return appReducer(undefined, { type: undefined });
    }

    const newState = Object.assign({}, state);

    if (action.type.includes('request') && !requestBlacklist.includes(action.type)) {
      if (newState) {
        newState.globalReducer = {
          loading: true
        };
      }
    } else {
      if (action.type.includes('failure') || action.type.includes('success')) {
        if (newState) {
          newState.globalReducer = {
            loading: false
          };
        }
      }
    }

    return appReducer(newState, action);
  };

  return rootReducer;
}
