import { SORT_BY_TYPES, SORT_ORDER_TYPES } from '@app/utils/constants';
import { getQueryResponse } from '@app/utils/graphqlUtils';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get } from 'lodash-es';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_OFFER_DETAILS } from './queries';
import { failureGetOfferDetails, requestGetOfferDetails, successGetOfferDetails } from './reducer';

export function* getOfferDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId,
        sellerId: action.payload.sellerId,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder
      },
      pagination: {
        limit: 0,
        page: 1
      }
    };
    const { ok, data, error } = yield call(getQueryResponse, GET_OFFER_DETAILS, payload);

    if (ok) {
      const offerDetails = data.offerDetails.offerDetails;

      yield put(successGetOfferDetails(offerDetails));
    } else {
      yield put(failureGetOfferDetails(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureGetOfferDetails(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export default function* offersReceivedSaga() {
  yield all([takeLatest(requestGetOfferDetails.toString(), getOfferDetails)]);
}

export const offersReceivedSagaArr = [takeLatest(requestGetOfferDetails.toString(), getOfferDetails)];
