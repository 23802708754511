import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

export interface PortalProps {
  children: ReactNode;
}

export const Portal = ({ children }: PortalProps) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;
};
