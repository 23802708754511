import { SELLER } from '@app/utils/constants';
import { getMutationResponse, getQueryResponse } from '@app/utils/graphqlUtils';
import { SellerAccountStatus } from '@app/utils/queries';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get } from 'lodash-es';

import { put, takeLatest, call, all } from 'redux-saga/effects';
import { failureAccountStatus, successAccountStatus } from '../HomeContainer/reducer';
import { RENEW_SELLER_SUBSCRIPTION } from './queries';
import { requestRenewSubscription, successRenewSubscription, failureRenewSubscription } from './reducer';
import history from '@app/utils/history';
import routeConstants from '@app/utils/routeConstants';
import { translate } from '@app/components/IntlGlobalProvider';

export function* renewSubscription(): Generator<any, any, any> {
  try {
    const response = yield call(getMutationResponse, RENEW_SELLER_SUBSCRIPTION);

    if (response.ok) {
      yield put(successRenewSubscription());
      const { ok, data, error } = yield call(getQueryResponse, SellerAccountStatus);

      if (ok) {
        const sellerAccountStatus = get(data, 'sellerAccountStatus', {});

        yield put(successAccountStatus(sellerAccountStatus));
        history.push(`${routeConstants.dashboard.route}?activeTab=subscription-renewed`);
      } else {
        yield put(failureAccountStatus(error));
      }
      message.success(translate('renew_success'));
    } else {
      yield put(failureRenewSubscription(response.error));
    }
  } catch (err) {
    yield put(failureRenewSubscription(err));
  }
}

// Individual exports for testing
export default function* dashboardContainerSaga() {
  yield all([takeLatest(requestRenewSubscription.toString(), renewSubscription)]);
}

export const dashboardContainerSagaArr = [takeLatest(requestRenewSubscription.toString(), renewSubscription)];
