import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Divider } from 'antd';
import React from 'react';
import styled from 'styled-components';
import SubmissionDraftItem from '../SubmissionDraftItem';
import ArrowRight from '@images/icons/ArrowRightIcon.svg';
import { Link } from 'react-router-dom';
import { If } from 'tsw-sdk';

export interface SubmissionDraftsProps {
  submissionDraft: any;
}

const Container = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;
`;

const CustomDivider = styled(Divider)`
  && {
    margin: 0;
    border-top: 0.8px solid ${colors.myDashboardColors.dividerColor};
  }
`;

const Heading = styled.h1`
  ${fonts.style.mulishHeading}
  color:${colors.myDashboardColors.subHeading};
  margin-bottom: 0;
`;

const ViewDiv = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.37rem 0;
  gap: 0.5rem;
  margin-top: auto;
  margin-bottom: 0px;
  color: ${colors.myDashboardColors.viewText};
`;

const ViewText = styled.div`
  ${fonts.size.small()};
  ${fonts.weights.fw800()};
  color: ${colors.myDashboardColors.viewText};
`;

const SubHeading = styled.p`
  ${fonts.size.regular()}
  ${fonts.weights.fw700()}
  color: ${colors.darkBorder};
  margin-top: 0.6rem;
  line-height: 1.25rem;
`;

const SubmissionDrafts = ({ submissionDraft }: SubmissionDraftsProps) => {
  return (
    <Container>
      <Heading>{translate('my_submissions')}</Heading>
      <If condition={submissionDraft.length === 0}>
        <SubHeading>{translate('no_submission')}</SubHeading>
      </If>
      {submissionDraft.length > 0 &&
        submissionDraft.map((item: any) => {
          return (
            <div key={item.id}>
              <SubmissionDraftItem
                date={item.date}
                title={item.title}
                percent={Math.floor((item.progress / 7) * 100)}
                progress={`0${item.progress}/07`}
                id={item.id}
                isCopyrightPending={item.isCopyrightPending}
              />
              <CustomDivider />
            </div>
          );
        })}

      <If condition={submissionDraft.length > 0}>
        <ViewDiv to="dashboard?activeTab=submissions">
          <ViewText>{translate('view_all')}</ViewText>
          <img src={ArrowRight} />
        </ViewDiv>
      </If>
    </Container>
  );
};

export default SubmissionDrafts;
