import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  requestGetOpenOffers,
  successGetOpenOffers,
  failureGetOpenOffers,
  requestGetClosedOffers,
  successGetClosedOffers,
  failureGetClosedOffers,
  requestGetDeclinedOffers,
  successGetDeclinedOffers,
  failureGetDeclinedOffers,
  successGetScreenplayRequests,
  failureGetScreenplayRequests,
  requestsGetScreenplayRequests
} from './reducer';
import { getQueryResponse } from '@app/utils/graphqlUtils';
import { get } from 'lodash-es';
import { AnyAction } from '@reduxjs/toolkit';
import { GET_OFFERS, GET_REQUESTS } from './queries';

export function* getOpenOffers(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      filters: {
        asSeller: {
          withID: action.payload.sellerId,
          areOpen: true
        }
      },
      page: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_OFFERS, payload);

    if (ok) {
      const sellerOffers = data.sellerOffers;
      const total = sellerOffers.total;

      yield put(successGetOpenOffers({ data: get(sellerOffers, 'offers', []), total: total }));
    } else {
      yield put(failureGetOpenOffers(error));
    }
  } catch (err) {
    yield put(failureGetOpenOffers(err));
  }
}

export function* getClosedOffers(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      filters: {
        asSeller: {
          withID: action.payload.sellerId,
          areClosed: true
        }
      },
      page: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_OFFERS, payload);

    if (ok) {
      const sellerOffers = data.sellerOffers;
      const total = sellerOffers.total;

      yield put(successGetClosedOffers({ data: get(sellerOffers, 'offers', []), total: total }));
    } else {
      yield put(failureGetClosedOffers(error));
    }
  } catch (err) {
    yield put(failureGetClosedOffers(err));
  }
}

export function* getDeclinedOffers(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      filters: {
        asSeller: {
          withID: action.payload.sellerId,
          areDeclined: true
        }
      },
      page: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_OFFERS, payload);

    if (ok) {
      const sellerOffers = data.sellerOffers;
      const total = sellerOffers.total;

      yield put(successGetDeclinedOffers({ data: get(sellerOffers, 'offers', []), total: total }));
    } else {
      yield put(failureGetDeclinedOffers(error));
    }
  } catch (err) {
    yield put(failureGetDeclinedOffers(err));
  }
}

export function* getScreenplayRequests(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_REQUESTS, payload);

    if (ok) {
      const sellerConversations = data.sellerConversations?.conversations?.filter(
        (conversation: any) => conversation?.isRequestPending
      );

      const total = sellerConversations.length;

      yield put(successGetScreenplayRequests({ data: sellerConversations, total: total }));
    } else {
      yield put(failureGetScreenplayRequests(error));
    }
  } catch (err) {
    yield put(failureGetScreenplayRequests(err));
  }
}

export default function* offersReceivedPerStorySaga() {
  yield all([
    takeLatest(requestGetOpenOffers.toString(), getOpenOffers),
    takeLatest(requestGetClosedOffers.toString(), getClosedOffers),
    takeLatest(requestGetDeclinedOffers.toString(), getDeclinedOffers),
    takeLatest(requestsGetScreenplayRequests.toString(), getScreenplayRequests)
  ]);
}

export const offersReceivedPerStorySagaArr = [
  takeLatest(requestGetOpenOffers.toString(), getOpenOffers),
  takeLatest(requestGetClosedOffers.toString(), getClosedOffers),
  takeLatest(requestGetDeclinedOffers.toString(), getDeclinedOffers),
  takeLatest(requestsGetScreenplayRequests.toString(), getScreenplayRequests)
];
