import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Step3Props } from './types';
import { CustomButton, CustomInput } from 'tsw-sdk';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { COPYRIGHT_PROTECTION_STEPS } from '@app/utils/constants';

const StepContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const StepTitle = styled.h2`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  margin-bottom: 0px;
`;

const StepDescription = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;
  margin-top: 1.5rem;
`;

const ProceedBtn = styled(CustomButton)`
  margin-top: 3rem;
  margin-bottom: 2rem;

  && {
    width: 100%;
    border: 1px solid ${colors.primary};
    justify-content: center;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const Step3Form = styled(Form)`
  margin-top: 1rem;
`;

const CustomFormItem = styled(Form.Item)`
  &:not(:first-of-type) {
    margin-top: 1rem;
  }

  &.ant-form-item {
    width: 25.93rem;
    margin-bottom: 0;
    row-gap: 0.5rem;

    & input {
      padding: 1rem 0.5rem;
    }
  }
  .ant-form-item-label {
    padding: 0 !important;
    row-gap: 0.5rem;
    & label {
      ${fonts.family.SpoofRegular()};
      ${fonts.size.regular()};
      ${fonts.weights.fw400()};
      line-height: 1.31rem;
    }
  }
`;
const Step3 = ({
  storyId,
  copyrightProtectionDetails,
  selfAttestationDetails,
  userProfile,
  storyTitle,
  setStepFormValues,
  dispatchUpdateCopyrightSelfAttestationDetails
}: Step3Props) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<any>({});
  const [proceedDisabled, setProceedDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (selfAttestationDetails) {
      form.setFieldsValue({
        residentialAddress: selfAttestationDetails.address,
        designation: selfAttestationDetails.designation
      });
    }
  }, [selfAttestationDetails]);

  useEffect(() => {
    if (userProfile) {
      form.setFieldsValue({
        fullName: `${userProfile?.firstName} ${userProfile?.lastName}`,
        nationalityLabel: userProfile?.nationality,
        storyTitle: storyTitle
      });
    }
  }, [userProfile, storyTitle]);

  useEffect(() => {
    const isDisabled = getIsDisabled();
    setProceedDisabled(isDisabled);
    setFormValues(formValues);

    const values = form.getFieldsValue();
    setStepFormValues(values);
  }, [formValues]);

  const handleChange = (values: any) => {
    setFormValues(values);
  };

  const getIsDisabled = () => {
    let isDisabled = false;
    const values = form.getFieldsValue();

    if (
      !values.fullName ||
      !values.nationalityLabel ||
      !values.residentialAddress ||
      !values.storyTitle ||
      !values.designation
    ) {
      isDisabled = true;
    }

    return isDisabled;
  };

  const handleProceed = () => {
    const values = form.getFieldsValue();
    dispatchUpdateCopyrightSelfAttestationDetails({
      data: {
        storyId: storyId,
        copyrightProtectionID: copyrightProtectionDetails?.copyrightProtectionId,
        mode: 'PROCEED',
        powerOfAttorney: {
          name: values.fullName,
          nationality: values.nationalityLabel,
          address: values.residentialAddress,
          storyTitle: values.storyTitle,
          designation: values.designation
        },
        currentStep: COPYRIGHT_PROTECTION_STEPS.STEP3
      },
      nextStep: COPYRIGHT_PROTECTION_STEPS.STEP4
    });
  };

  return (
    <StepContainer>
      <StepTitle>{translate('step3_title')}</StepTitle>
      <StepDescription>{translate('step3_description')}</StepDescription>
      <Step3Form layout="vertical" form={form} onValuesChange={handleChange}>
        <CustomFormItem label={translate('full_name_label')} name="fullName">
          <CustomInput placeholder={translate('first_name_place')} data-testid="first-name-input" disabled={true} />
        </CustomFormItem>
        <CustomFormItem label={translate('your_nationality_label')} name="nationalityLabel">
          <CustomInput placeholder={translate('first_name_place')} data-testid="nationality-input" disabled={true} />
        </CustomFormItem>
        <CustomFormItem label={translate('your_residential_address')} name="residentialAddress">
          <CustomInput placeholder={translate('first_name_place')} data-testid="residential-address-input" />
        </CustomFormItem>
        <CustomFormItem label={translate('your_story_title')} name="storyTitle">
          <CustomInput placeholder={translate('first_name_place')} data-testid="story-title-input" disabled={true} />
        </CustomFormItem>
        <CustomFormItem label={translate('your_designation')} name="designation">
          <CustomInput placeholder={translate('first_name_place')} data-testid="designation-input" />
        </CustomFormItem>
      </Step3Form>
      <ProceedBtn
        onClick={handleProceed}
        text_color={`${colors.darkBorder}`}
        data-testid="step3-proceed"
        disabled={proceedDisabled}
      >
        {translate('proceed')} <ArrowRightOutlined />
      </ProceedBtn>
    </StepContainer>
  );
};

export default Step3;
