import { T } from '@app/components';
import React, { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { translate } from '@components/IntlGlobalProvider';
import { Form, Divider, Checkbox, notification, Input } from 'antd';
import { CustomButton, CustomInput, If } from 'tsw-sdk';
import { PlusOutlined, ArrowRightOutlined } from '@ant-design/icons';
import AddIcon from '@images/icons/AddIcon.svg';
import { colors, fonts } from '@app/themes';
import Infotag from '@app/components/InfoTag';
import moment from 'moment';

import { AnyAction, compose } from '@reduxjs/toolkit';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { EditProfileProps } from './types';
import { requestCreateProfile, requestGetProfile, resetState } from '../ProfileContainer/reducer';
import { selectUserProfile } from '../ProfileContainer/selectors';
import _, { cloneDeep } from 'lodash';

interface InlineItemsProps {
  marginTop?: boolean;
}

const CustomInputCapital = styled(CustomInput)`
  text-transform: uppercase;

  &&& {
    &:placeholder-shown {
      text-transform: unset;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5625rem;
  align-items: flex-start;

  position: absolute;
  top: 6.2%;
  left: 12%;
`;

const ContainerTitle = styled(T)`
  && {
    line-height: 3.975rem;

    text-align: center;
  }
`;

const FormContainer = styled(Form)`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    gap: 2rem;
  }
`;

const InlineItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 1.5rem;
`;

const InlineExpItems = styled.div<InlineItemsProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 1.5rem;
  ${(props) => props.marginTop && 'margin-bottom: 1rem'}
`;

const ButtonInlineItems = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  padding: 0px;
  gap: 1.5rem;
`;

const CustomFormItem = styled(Form.Item)`
  &.ant-form-item {
    width: 16rem;
    margin-bottom: 0;
    row-gap: 0.5rem;
  }
  .ant-form-item-label {
    padding: 0 !important;
    font-size: 1rem;
    row-gap: 0.5rem;
  }
`;

const ExpContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
`;

const CustomDivider = styled(Divider)`
  && {
    margin: 0;
    border-top: 1px solid ${colors.lightBorder};
  }
`;

const SubText = styled.p`
  opacity: 0.5;
  ${fonts.style.smallText()}
`;

const LabelInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;
const LablelCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const CustomInputDatePicker = styled.input`
  border: 0.5px solid ${colors.lightBorder};
  border-radius: 5px;
  padding: 0.5rem;
  height: 2rem;
  width: 100%;
  font-size: 0.75rem;
  outline: none;
`;

const CustomTextArea = styled(Input.TextArea)`
  &&& {
    width: 100%;
    background-color: ${colors.white};
    margin-top: 1rem;
    border-radius: 0.31rem;
    border 1px solid ${colors.lightBorder};
    font-size: 0.75rem;
  }
`;

const DefaultInputText = styled.input`
  border: 0.5px solid ${colors.lightBorder};
  border-radius: 5px;
  padding: 0.5rem;
  height: 2rem;
  width: 16rem;
  font-size: 0.75rem;
  outline: none;
  margin-top: 0.31rem;
  flex: auto;
  margin-bottom: 0.18rem;
`;

type ExpObjType = {
  id: string;
  startDate: string;
  endDate: string;
  companyName: string;
  designation: string;
};

type ExpObjectSubmitType = {
  id?: string;
  startDate: number;
  endDate: number | null;
  company: string;
  designation: string;
};

const CustomAddButton = styled(CustomButton)`
  && {
    border: 1px solid ${colors.primary};
    padding: 0.5rem 1.5rem;
    font-size: 0.875rem;
    height: auto;
    margin-top: 2rem;
  }
`;

const CustomProceedButton = styled(CustomButton)`
  && {
    justify-content: center;
    width: 50%;
  }
`;

const PlusButton = styled.img`
  cursor: pointer;
  margin-bottom: 0.62rem;
`;

const PersonalDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelText = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.small()};
  line-height: 1.09rem;
  color: ${colors.tagBorder};
  margin-top: 1.93rem;
  margin-bottom: 0.93rem;
`;

const CustomCheckBox = styled(Checkbox)`
  && {
    .ant-checkbox-inner {
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid ${colors.checkboxborder};
      border-radius: 4px;
    }
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  padding: 0px;
`;

const CheckBoxText = styled.p`
  line-height: 15px;
  ${fonts.style.checkBoxText()}
`;

type StoriesObjType = {
  id: string;
  storyName: string;
  dateOfRelease: string;
};

type PublishedStoriesType = {
  id?: string;
  name: string;
  publishDate: number;
};

export const EditProfileContainer = ({
  dispatchCreateUserProfile,
  dispatchGetUserProfile,
  dispatchResetState,
  userProfile
}: EditProfileProps) => {
  const [profileForm] = Form.useForm();
  const [expArr, setExpArr] = useState<ExpObjType[]>([]);
  const [experience, setExperience] = useState<ExpObjectSubmitType[]>([]);
  const [expTag, setExpTag] = useState<boolean>(true);

  const [storiesArr, setStoriesArr] = useState<StoriesObjType[]>([]);
  const [publishedStories, setPublishedStories] = useState<PublishedStoriesType[]>([]);
  const [storyTag, setStoryTag] = useState<boolean>(true);
  const [currentlyWorking, setCurrentlyWorking] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>('fromdate');
  const [currentlyWorkingExperience, setCurrentlyWorkingExperience] = useState<string>('');
  const [releaseDate, setReleaseDate] = useState<string>('');
  const [provideGST, setProvideGST] = useState<boolean>(false);

  useEffect(() => {
    dispatchGetUserProfile();

    return () => {
      dispatchResetState();
    };
  }, []);

  useEffect(() => {
    profileForm.setFieldsValue({
      shortBio: userProfile?.bioDescription,
      favGen1: userProfile?.favGenres && userProfile?.favGenres[0] ? userProfile?.favGenres[0]?.name : '',
      favGen2: userProfile?.favGenres && userProfile?.favGenres[1] ? userProfile?.favGenres[1]?.name : '',
      favGen3: userProfile?.favGenres && userProfile?.favGenres[2] ? userProfile?.favGenres[2]?.name : '',
      favMovie1: userProfile?.favMovies && userProfile?.favMovies[0] ? userProfile?.favMovies[0]?.name : '',
      favMovie2: userProfile?.favMovies && userProfile?.favMovies[1] ? userProfile?.favMovies[1]?.name : '',
      favMovie3: userProfile?.favMovies && userProfile?.favMovies[2] ? userProfile?.favMovies[2]?.name : ''
    });

    if (userProfile?.experiences) {
      let expArrCopy: any[] = [];
      let experienceListCopy: any[] = [];
      userProfile?.experiences.map((item: any) => {
        const expObj = {
          id: item.id,
          startDate: moment(item.startDate * 1000).format('YYYY'),
          endDate: item.endDate !== null ? moment(item.endDate * 1000).format('YYYY') : 'present',
          companyName: item.company,
          designation: item.designation,
          description: item.description
        };

        const experienceObj = {
          id: item.id,
          startDate: item.startDate,
          endDate: item.endDate,
          company: item.company,
          designation: item.designation,
          description: item.description
        };

        expArrCopy = [...expArrCopy, expObj];
        experienceListCopy = [...experienceListCopy, experienceObj];
      });

      if (userProfile?.experiences?.length >= 3) {
        setExpTag(false);
      }

      setExperience(experienceListCopy);
      setExpArr(expArrCopy);
    } else {
      setExperience([]);
      setExpArr([]);
    }

    if (userProfile?.publishedStories) {
      let storiesArrCopy: any[] = [];
      let publishedStoriesCopy: any[] = [];

      userProfile?.publishedStories.map((item: any) => {
        const storyObj = {
          id: item.id,
          storyName: item.name,
          dateOfRelease: moment(item.publishDate * 1000).format('YYYY-MM-DD')
        };
        const publishedStoryObj = {
          id: item.id,
          name: item.name,
          publishDate: item.publishDate
        };
        storiesArrCopy = [...storiesArrCopy, storyObj];
        publishedStoriesCopy = [...publishedStoriesCopy, publishedStoryObj];
      });

      if (userProfile?.publishedStories?.length >= 3) {
        setStoryTag(false);
      }

      setStoriesArr(storiesArrCopy);
      setPublishedStories(publishedStoriesCopy);
    } else {
      setStoriesArr([]);
      setPublishedStories([]);
    }
  }, [userProfile]);

  const openNotification = (err: string) => {
    notification.open({
      message: 'Error',
      description: err
    });
  };

  const handleAddExperience = () => {
    const startDate = profileForm.getFieldValue('startDate');
    const endDate = profileForm.getFieldValue('endDate');
    const companyName = profileForm.getFieldValue('companyName');
    const designation = profileForm.getFieldValue('designation');
    const description = profileForm.getFieldValue('description');

    if (!currentlyWorking) {
      if (moment(endDate).isBefore(moment(startDate))) {
        notification.open({
          message: 'Error',
          description: translate('end_date_before_start_date')
        });
        return;
      }
    }

    let dataValid = false;

    if (currentlyWorking) {
      dataValid = startDate !== undefined && companyName !== undefined && designation !== undefined;
    } else {
      dataValid =
        startDate !== undefined && endDate !== undefined && companyName !== undefined && designation !== undefined;
    }

    if (!description) {
      dataValid = false;
    }

    if (dataValid) {
      const id = Date.now().toString();
      const expObj = {
        id: id,
        startDate: moment(startDate).format('YYYY'),
        endDate: currentlyWorking ? moment().format('YYYY') : moment(endDate).format('YYYY'),
        companyName: profileForm.getFieldValue('companyName'),
        designation: profileForm.getFieldValue('designation'),
        description: profileForm.getFieldValue('description')
      };

      const experienceObj = {
        id: id,
        startDate: Math.floor(new Date(startDate).getTime() / 1000),
        endDate: currentlyWorking ? null : Math.floor(new Date(endDate).getTime() / 1000),
        company: profileForm.getFieldValue('companyName'),
        designation: profileForm.getFieldValue('designation'),
        description: profileForm.getFieldValue('description')
      };

      setExperience([...experience, experienceObj]);
      setExpArr([...expArr, expObj]);
      setExpTag(false);

      profileForm.resetFields(['startDate', 'endDate', 'companyName', 'designation', 'description']);

      if (currentlyWorking) {
        setCurrentlyWorkingExperience(id);
      }

      setCurrentlyWorking(false);
    } else {
      notification.open({
        message: 'Error',
        description: translate('enter_all_fields_error')
      });
    }
  };

  const handleDeleteExperience = (id: string) => {
    setExpArr(expArr.filter((item) => item.id !== id));
    setExperience(experience.filter((item) => item.id !== id));
    if (currentlyWorkingExperience === id) {
      setCurrentlyWorkingExperience('');
    }
  };

  const handleAddStory = () => {
    const storyName = profileForm.getFieldValue('storyName');

    if (storyName && releaseDate) {
      const storyObj = {
        id: Date.now().toString(),
        storyName: storyName,
        dateOfRelease: releaseDate
      };
      const publishedStoryObj = {
        id: Date.now().toString(),
        name: storyName,
        publishDate: Math.floor(new Date(releaseDate).getTime() / 1000)
      };
      setStoriesArr([...storiesArr, storyObj]);
      setPublishedStories([...publishedStories, publishedStoryObj]);
      setStoryTag(false);

      profileForm.resetFields(['storyName', 'releaseDate']);
    } else {
      notification.open({
        message: 'Error',
        description: translate('enter_all_fields_error')
      });
    }
  };

  const handleDeleteStory = (id: string) => {
    setStoriesArr(storiesArr.filter((item) => item.id !== id));
    setPublishedStories(publishedStories.filter((item) => item.id !== id));
    setStoryTag(true);
  };

  const validateFormData = (formData: any) => {
    let formDataValid = true;
    let errorMessage = '';

    if (!formData?.bioDescription?.trim()) {
      formDataValid = false;
      errorMessage = translate('bio_description_error');
    }

    return { formDataValid, errorMessage };
  };

  const handleUpdate = () => {
    const ProceedForm = constructSubmitForm();
    const requireFieldsFilled = validateFormData(ProceedForm.sellerProfileInput);

    if (requireFieldsFilled.formDataValid) {
      try {
        dispatchCreateUserProfile({ data: ProceedForm, goBack: true });
      } catch (err: any) {
        openNotification(err);
      }
    } else {
      notification.open({
        message: translate('error'),
        description: requireFieldsFilled.errorMessage
      });
    }
  };

  const constructFavouriteGenres = (formData: any) => {
    const favouriteGenres = [
      {
        name: formData.favGen1
      },
      {
        name: formData.favGen2
      },
      {
        name: formData.favGen3
      }
    ];

    const newfavouriteGenres = [];

    for (let i = 0; i < favouriteGenres.length; i++) {
      if (favouriteGenres[i].name !== '' && favouriteGenres[i].name !== undefined && favouriteGenres[i].name !== null) {
        newfavouriteGenres[i] = favouriteGenres[i];
      }
    }

    return newfavouriteGenres;
  };

  const constructFavouriteMovies = (formData: any) => {
    const favouriteMovies = [
      {
        name: formData.favMovie1
      },
      {
        name: formData.favMovie2
      },
      {
        name: formData.favMovie3
      }
    ];

    const newFavouriteMovies = [];

    for (let i = 0; i < favouriteMovies.length; i++) {
      if (favouriteMovies[i].name !== '' && favouriteMovies[i].name !== undefined && favouriteMovies[i].name !== null) {
        newFavouriteMovies[i] = favouriteMovies[i];
      }
    }

    return newFavouriteMovies;
  };

  const contructStories = () => {
    const newStories = publishedStories.map((story) => ({
      name: story.name,
      publishDate: story.publishDate
    }));

    return newStories;
  };

  const constructExperiences = () => {
    const newExperiences: any = [];

    experience.forEach((experienceObj: any) => {
      const newExperienceObj = cloneDeep(experienceObj);

      delete newExperienceObj.id;

      newExperiences.push(newExperienceObj);
    });

    return newExperiences;
  };

  const constructSubmitForm = () => {
    const newProfileInput = _.cloneDeep(userProfile);

    const { shortBio, favGen1, favGen2, favGen3, favMovie1, favMovie2, favMovie3 } = profileForm.getFieldsValue([
      'shortBio',
      'favGen1',
      'favGen2',
      'favGen3',
      'favMovie1',
      'favMovie2',
      'favMovie3'
    ]);

    const genreList = constructFavouriteGenres({
      favGen1,
      favGen2,
      favGen3
    });

    const movieList = constructFavouriteMovies({ favMovie1, favMovie2, favMovie3 });

    const stories = contructStories();

    const experiences = constructExperiences();

    delete newProfileInput?.typename;
    delete newProfileInput?.id;
    delete newProfileInput?.email;
    delete newProfileInput?.billingAddress?.typename;

    const submitForm = {
      sellerProfileInput: {
        ...newProfileInput,
        bioDescription: shortBio,
        favGenres: genreList,
        favMovies: movieList,
        experiences: experiences,
        publishedStories: stories,
        isSave: true
      }
    };

    return submitForm;
  };

  return (
    <>
      <Container>
        <ContainerTitle id="edit_profile" type="spoofHeading" />
        <FormContainer layout="vertical" form={profileForm}>
          <ExpContainer>
            <LablelCont>
              <LabelInput>
                <p>{translate('add_exp')}</p>
                <SubText>{translate('experience_sub')}</SubText>
              </LabelInput>
              <If condition={!expTag && expArr.length < 3}>
                <PlusButton src={AddIcon} onClick={() => setExpTag(true)} data-testid="add-icon" />
              </If>
            </LablelCont>
            <If condition={expTag}>
              <InlineExpItems marginTop={true} data-testid="add-exp-form">
                <CustomFormItem name="startDate">
                  <CustomInputDatePicker
                    type="text"
                    placeholder="From"
                    data-testid="start-date"
                    onFocus={(e) => (e.target.type = 'date')}
                    onBlur={(e) => (e.target.type = 'text')}
                    onChange={(e) => {
                      setFromDate(e.target.value);
                    }}
                    max={new Date().toISOString().split('T')[0]}
                  />
                </CustomFormItem>
                <LabelInput>
                  <If
                    condition={!currentlyWorking}
                    otherwise={<DefaultInputText type="text" placeholder="Present" disabled={true} />}
                  >
                    <CustomFormItem name="endDate">
                      <CustomInputDatePicker
                        type="text"
                        placeholder="To"
                        data-testid="end-date"
                        min={fromDate}
                        onFocus={(e) => (e.target.type = 'date')}
                        onBlur={(e) => (e.target.type = 'text')}
                        max={new Date().toISOString().split('T')[0]}
                      />
                    </CustomFormItem>
                  </If>

                  <Checkbox
                    onChange={(e: any) => setCurrentlyWorking(e.target.checked)}
                    checked={currentlyWorking}
                    data-testid="currently-working-checkbox"
                    disabled={expArr.find((exp) => exp.id === currentlyWorkingExperience) !== undefined}
                  >
                    {translate('current_work')}
                  </Checkbox>
                </LabelInput>
              </InlineExpItems>
              <InlineItems>
                <CustomFormItem name="companyName">
                  <CustomInput placeholder={translate('company_name_place')} data-testid="company-name" />
                </CustomFormItem>
                <CustomFormItem name="designation">
                  <CustomInput placeholder={translate('designation_place')} data-testid="designation" />
                </CustomFormItem>
              </InlineItems>
              <InlineItems>
                <CustomFormItem name="description">
                  <CustomTextArea
                    placeholder={translate('description_placeholder')}
                    data-testid="experience-description"
                  />
                </CustomFormItem>
              </InlineItems>

              <CustomAddButton
                bg_color={`${colors.secondaryText}`}
                text_color={`${colors.darkBorder}`}
                onClick={handleAddExperience}
                data-testid="add-exp-btn"
              >
                <PlusOutlined /> {translate('add_experience')}
              </CustomAddButton>
            </If>
            <TagsContainer>
              {expArr.map((items, index) => {
                return (
                  <React.Fragment key={index}>
                    <Infotag
                      {...items}
                      onDelete={handleDeleteExperience}
                      isExp={true}
                      currentlyWorkingId={currentlyWorkingExperience}
                      data-testid="info-tag"
                    />
                  </React.Fragment>
                );
              })}
            </TagsContainer>
          </ExpContainer>
          <CustomDivider />
          <CustomFormItem label={translate('short_bio')} name="shortBio">
            <CustomInput
              placeholder={translate('bio_placeholder')}
              style={{ height: '2.875rem' }}
              data-testid="bio-input"
            />
          </CustomFormItem>
          <CustomDivider />
          <ExpContainer>
            <LablelCont>
              <LabelInput>
                <p>{translate('story_label')}</p>
                <SubText>{translate('stories_sub')}</SubText>
              </LabelInput>
              <If condition={!storyTag && storiesArr.length < 3}>
                <PlusButton src={AddIcon} onClick={() => setStoryTag(true)} data-testid="add-icon" />
              </If>
            </LablelCont>
            <If condition={storyTag}>
              <InlineItems data-testid="add-story-form">
                <CustomFormItem name="storyName">
                  <CustomInput placeholder="Story Name" data-testid="story-name" />
                </CustomFormItem>
                <CustomFormItem name="releaseDate">
                  <CustomInputDatePicker
                    type="text"
                    placeholder="Date of Release"
                    data-testid="release-date"
                    onFocus={(e) => (e.target.type = 'date')}
                    onBlur={(e) => (e.target.type = 'text')}
                    onChange={(e) => setReleaseDate(e.target.value)}
                    max={new Date().toISOString().split('T')[0]}
                  />
                </CustomFormItem>
              </InlineItems>
              <CustomAddButton
                bg_color={`${colors.secondaryText}`}
                text_color={`${colors.darkBorder}`}
                onClick={handleAddStory}
                data-testid="addstory-btn"
              >
                <PlusOutlined /> {translate('add_story')}
              </CustomAddButton>
            </If>
            <TagsContainer>
              {storiesArr.map((items, index) => {
                return (
                  <React.Fragment key={index}>
                    <Infotag {...items} onDelete={handleDeleteStory} isExp={false} data-testid="info-tag" />
                  </React.Fragment>
                );
              })}
            </TagsContainer>
          </ExpContainer>
          <CustomDivider />
          <InlineItems>
            <CustomFormItem label={translate('genres_label')} name="favGen1">
              <CustomInput placeholder={translate('fav_genre_1')} />
            </CustomFormItem>
            <CustomFormItem name="favGen2">
              <CustomInput placeholder={translate('fav_genre_2')} />
            </CustomFormItem>
            <CustomFormItem name="favGen3">
              <CustomInput placeholder={translate('fav_genre_3')} />
            </CustomFormItem>
          </InlineItems>
          <InlineItems>
            <CustomFormItem label={translate('movies_label')} name="favMovie1">
              <CustomInput placeholder={translate('fav_movie_1')} />
            </CustomFormItem>
            <CustomFormItem name="favMovie2">
              <CustomInput placeholder={translate('fav_movie_2')} />
            </CustomFormItem>
            <CustomFormItem name="favMovie3">
              <CustomInput placeholder={translate('fav_movie_3')} />
            </CustomFormItem>
          </InlineItems>
          <ButtonInlineItems>
            <CustomProceedButton onClick={handleUpdate} data-testid="update-btn">
              {translate('update')} <ArrowRightOutlined />
            </CustomProceedButton>
          </ButtonInlineItems>
        </FormContainer>
      </Container>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchCreateUserProfile: (profileData: any) => dispatch(requestCreateProfile(profileData)),
    dispatchGetUserProfile: () => dispatch(requestGetProfile({})),
    dispatchResetState: () => dispatch(resetState())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo, injectIntl)(EditProfileContainer) as React.FC;

export const EditProfileContainerTest = EditProfileContainer;
