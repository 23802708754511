import React, { useState, useEffect } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Progress } from 'antd';
import styled from 'styled-components';

const CounteringContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: ${colors.white};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomProgressBar = styled(Progress)`
  width: 15rem;
  margin-left: auto;
  margin-right: auto;

  && {
    .ant-progress-success-bg,
    .ant-progress-bg {
      background-color: ${colors.myDashboardColors.viewText};
    }

    .ant-progress-inner {
      background-color: ${colors.cardBg};
    }
  }
`;

const CounteringContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const CounteringText = styled.h1`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.large()};
  line-height: 2.62rem;
  text-align: center;
`;

const CounteringLoader = () => {
  const [progressPercent, setprogressPercent] = useState<number>(0);

  useEffect(() => {
    if (progressPercent === 100) {
      setprogressPercent(0);
    } else {
      setTimeout(() => setprogressPercent((prev) => prev + 1), 500);
    }
  }, [progressPercent]);

  return (
    <CounteringContainer>
      <CounteringContentContainer>
        <CustomProgressBar percent={progressPercent} showInfo={false} />
        <CounteringText id="countering-text">{translate('countering_offer')}</CounteringText>
      </CounteringContentContainer>
    </CounteringContainer>
  );
};

export default CounteringLoader;
