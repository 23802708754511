import { colors, fonts } from '@app/themes';
import React, { memo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import { Button, Collapse, Divider, Form, Input, message, notification, Progress, UploadProps } from 'antd';
import AddIcon from '@images/icons/MySubmissionIcons/add-icon.svg';
import { CustomInput, If } from 'tsw-sdk';
import TextArea from 'antd/lib/input/TextArea';
import Dragger from 'antd/lib/upload/Dragger';
import PageIcon from '@images/icons/MySubmissionIcons/page-icon.svg';
import MicIcon from '@images/icons/MySubmissionIcons/mic-icon.svg';
import ImgIcon from '@images/icons/MySubmissionIcons/img-icon.svg';
import ActiveCrownIcon from '@images/icons/MySubmissionIcons/active-crown-icon.svg';
import ToolTipIcon from '@images/icons/MySubmissionIcons/tooltip-icon.svg';
import Tippy from '@tippyjs/react';
import InfoIcon from '@images/icons/MySubmissionIcons/info-icon.svg';
import Bin from '@images/icons/MySubmissionIcons/trash.svg';

import PlusIcon from '@images/icons/MySubmissionIcons/plus-icon.svg';
import TagsPage from './components/TagsPage';
import { Portal } from '@app/components/Portal';
import Select from 'react-select';
import Tags from './components/Tags';
import {
  requestGetStoryDetails,
  requestGetStoryTypes,
  requestGetTags,
  requestLangGenre,
  requestStateChange,
  requestSubmitStory,
  resetState
} from './reducer';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import {
  selectLanguageList,
  selectGenreList,
  selectTagsList,
  selectDraftedStory,
  selectSubmitStoryLoading,
  selectScreenplayFileId,
  selectStoryTypeList
} from './selectors';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { DraftedFilesType, NewSubmissionPageProps, ProgressStateType, SubmitFileType } from './types';
import { FAQ_URL, STORIES_STATES, STORY_SUBMITTED_MODE, SUPPORTING_MATERIAL_TYPE } from '@app/utils/constants';
import { bytesToMegaByte, isListOfObjectsNotEmpty, isNotNullOrUndefined } from '@app/utils';
import { UploadFile } from 'antd/lib/upload/interface';
import CompilingLoader from './components/CompilingLoader';
import 'tippy.js/dist/tippy.css';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import MessageTip from './components/MessageTip';
import { selectCurrentStoryId, selectHasSelfAttestedStory } from '@app/containers/CopyrightContainer/selectors';
import { requestGetHasSelfAttestedStory, setCurrentStoryId } from '@app/containers/CopyrightContainer/reducer';
import TipsAndTricksModal from '@app/components/TipsAndTricksModal';
import SubmissionGuidelinesModal from '../../../components/SubmissionGuidelinesModal';
import { debounce } from 'lodash-es';
import { isEmpty } from 'lodash';
import { allowOnlyChars, preventInvalidCharsNumberInput, wordsCount } from '@app/utils/uiUtils';
import DeleteDraftModal from '@app/components/CustomModal';

const { Panel } = Collapse;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 4.5rem 4.5rem 2rem 2rem;
  justify-content: space-between;
  background: ${colors.dashboardBg};
  gap: 7.18rem;
`;

const NewSubmissionContainer = styled.div`
  width: 34.125rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  width: 4.25rem;
`;

const FormContainer = styled(Form)`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.37rem;

    .ant-form-item {
      margin-bottom: 0px;
    }
  }
`;

const AddImg = styled.img`
  cursor: pointer;
`;

const FormListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LabelAddContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-item: center;
  gap: 0.5rem;
`;

const FormItemLabel = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  color: ${colors.mySubmissions.textClr};
`;

const ListWrapContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-top: 0.5rem;
`;

const InlineItems = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const CustomFormItem = styled(Form.Item)<any>`
  &.ant-form-item {
    width: 16.18rem;
    margin-bottom: 0;
    row-gap: 0.5rem;
  }
  .ant-form-item-label {
    padding: 0 !important;
    ${fonts.weights.fw600()};
    ${fonts.size.regular()};
    row-gap: 0.5rem;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 34.12rem;
    background-color: ${colors.white};
    border: 0.5px solid ${colors.lightBorder};
    border-radius: 5px;
    margin-top: 0.5rem;

    &.ant-input {
      max-width: 34.12rem;
      line-height: 0.94rem;
    }

    &.ant-input:placeholder-shown {
      color: ${colors.lightBorder};
      font-size: 0.75rem;
      ${fonts.weights.fw400};
      line-height: 0.94rem;
    }
  }
`;

const CustomDivider = styled(Divider)`
  && {
    margin: 0;
    border-top: 1px solid ${colors.lightBorder};
  }
`;

const UploadText = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.xsmall()};
  line-height: 0.94rem;
  text-align: center;
  color: ${colors.primary};
  margin-top: 0.5rem;
`;

const UploadSubText = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.extraSmall()};
  line-height: 0.62rem;
  text-align: center;
  color: ${colors.mySubmissions.textClr};
  margin-top: 0.5rem;
`;

const StyledDragger = styled(Dragger)`
  && {
    padding: 1.31rem 3.81rem;
    background: ${colors.white} !important;
    border: 0.5px dashed ${colors.lightBorder};
    border-radius: 0.31rem;
    margin-top: 0.5rem;

    &.ant-upload.ant-upload-drag .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.25rem 0px 0px;
`;

const StyledButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.62rem 2rem;
    background-color: ${colors.dashboardBg};
    color: ${colors.darkBorder};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()}
    border: 1px solid ${colors.primary};
  }
`;

const StyledContinueDisableButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: ${colors.tagBg};
    color: ${colors.navbarText};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()}
    border:none;
    padding: 0.62rem 2rem;
  }
`;

const StyledContinueButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: ${colors.mySubmissions.headingClr};
    color: ${colors.white};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()}
    border:none;
    padding: 0.62rem 2rem;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
`;

const ProgressText = styled.p`
  ${fonts.size.regular()}
  ${fonts.weights.fw700()}
  color: ${colors.darkBorder};
  line-height: 2.06rem;
  white-space: nowrap;
`;

const ProgressSideText = styled.p`
  width: 16.56rem;
  ${fonts.size.regular()};
  ${fonts.weights.fw800()};
  color: ${colors.mySubmissions.progressText};
  line-height: 1.5rem;
  white-space: wrap;
  text-align: center;
  letter-spacing: 0.02em;
`;

const ProgressSubContainer = styled.div`
  width: 23.43rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.06rem;
`;

const CustomProgressBar = styled(Progress)`
  && {
    .ant-progress-success-bg,
    .ant-progress-bg {
      background-color: ${colors.myDashboardColors.viewText};
      height: 1.12rem !important;
    }

    .ant-progress-inner {
      height: 1.12rem;
      background-color: ${colors.cardBg};
    }
  }
`;

const CustomTagsInput = styled(CustomInput)`
  && {
    &.ant-input-affix-wrapper {
      cursor: default;
    }
    &.ant-input-affix-wrapper > input.ant-input {
      background: ${colors.white};
      cursor: default;
    }
  }
`;

const CustomNumInput = styled(CustomInput)`
  && {
    &.ant-input-affix-wrapper > input.ant-input {
      background: ${colors.white};
    }
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const StyledSelect = styled(Select)`
  width: 16.18rem;
  height: 2rem;
  background: ${colors.white};
  border: 0.5px solid ${colors.lightBorder};
  border-radius: 5px;

  &&& {
    & > div:nth-of-type(1) {
      min-height: 100%;
      max-height: 100%;
      border: 0px solid ${colors.lightBorder};
      display: flex;
      box-shadow: none;
      align-items: flex-start;
      padding: 0;
      & > div:nth-of-type(1) {
        display: flex;
        align-items: center;
        max-height: 100%;
        min-height: 100%;
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
      }

      & > div:nth-of-type(2) {
        min-height: 100%;
        max-height: 100%;
        display: flex;
        align-items: center;
        & > span {
          display: none;
        }
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
        }
      }
    }
  }
`;

const TagsContainer = styled.div`
  diplay: flex;
  flex-direction: column;
`;
const TagSubContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const StyledImg = styled.img``;

const StyledEditHeader = styled(Input)`
  && {
    ${fonts.family.SpoofFamily};
    ${fonts.weights.fw700()};
    ${fonts.size.extraLarge()};
    line-height: 3.93rem;
    color: ${colors.myDashboardColors.heading};
    background-color: ${colors.dashboardBg};
    border: none;
    margin-top: 1rem;
  }
`;

const SubText = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.extraSmall()};
  line-height: 0.62rem;
  color: ${colors.lightBorder};
  margin-top: 0.25rem;
`;

const FormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelTooltipContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

const TitleCharacterCount = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.extraSmall()};
  line-height: 0.625rem;
  color: ${colors.lightBorder};
  margin-bottom: 1.31rem;
  margin-top: -2rem;
`;

const TipsContainer = styled.div`
  margin-top: 3.5rem;
`;

const TipContainer = styled.div`
  width: 22rem;
  height: 6.25rem;
  border-radius: 1.25rem;
  background-color: ${colors.white};
  box-shadow: 0 0 1.25rem 0 ${colors.mySubmissions.tipShadow};
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 1.56rem;
  z-index: 12;

  &:after {
    content: ' ';
    position: absolute;
    right: -3rem;
    bottom: -0.2rem;
    border-top: none;
    border-right: 4rem solid transparent;
    border-left: 2rem solid transparent;
    border-bottom: 3rem solid #ffff;
    transform: rotate(20deg);
    z-index: 10;
  }
`;

const TipTitle = styled.p`
  text-transform: uppercase;
  color: ${colors.mySubmissions.lightText};
  ${fonts.weights.fw700()};
  ${fonts.size.small()};
  line-height: 0.81rem;
`;

export const TipInfo = styled.p`
  color: ${colors.mySubmissions.headingClr};
  ${fonts.weights.fw600()};
  ${fonts.size.xsmall()};
  line-height: 1rem;
  margin-top: 0.28rem;
`;

export const UnderlineText = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const UnderlineTextLink = styled.a`
  text-decoration: underline !important;
  cursor: pointer;
  color: ${colors.primary} !important;
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ReminderContainer = styled.div`
  display: flex;
  gap: 0.6rem;
`;

const ReminderText = styled.p`
  ${fonts.size.small()}
  ${fonts.weights.fw700()}
  color: ${colors.mySubmissions.progressText};
  line-height: 2.06rem;
  white-space: nowrap;
`;

export const Accordion = styled(Collapse)`
  &&& {
    border: none;
    background: none;
    width: 24rem;
    margin-top: 3.62rem;

    & .ant-collapse-expand-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .ant-collapse-header {
      display: flex;
      align-items: center;
      padding: 12px 0px;
      border-bottom: 1px solid ${colors.myDashboardColors.accordionBorder};

      & * {
        ${fonts.family.Inter()};
        ${fonts.weights.fw200()}
      }
    }

    & .ant-collapse-header-text {
      text-transform: uppercase;
    }

    & .ant-collapse-item {
      border: none;
      box-shadow: none;
    }

    & .ant-collapse-content {
      background-color: transparent;
      border: none;
    }
  }
`;

export const AccordionPanel = styled(Panel)`
  &&& {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0.62rem 0px 0.5rem 0px;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  img {
    height: 1.2rem;
  }
`;

const Icon = styled.img``;

const NewSubmissionPage = ({
  langList,
  genList,
  tagsDataList,
  draftedStory,
  submitLoading,
  userProfile,
  hasSelfAttestedStory,
  dispatchGetTags,
  dispatchGetLangAndGenre,
  dispatchGetDraftedStory,
  dispatchResetState,
  currentStoryId,
  storyTypeList,
  dispatchGetHasSelfAttestedStory,
  dispatchSubmitStory,
  dispatchCurrentStoryId,
  dispatchGetStoryTypes,
  dispatchChangeStoryState
}: NewSubmissionPageProps) => {
  const [showLanguage, setShowLanguage] = useState<boolean>(true);
  const [showGenres, setShowGenres] = useState<boolean>(true);
  const [showTags, setShowTags] = useState<boolean>(false);
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [progressState, setProgressState] = useState<ProgressStateType>({
    storyline: 0,
    logline: 0,
    genres: 0,
    languages: 0,
    storyType: 0,
    tags: 0,
    storyTitle: 0
  });
  const [progressValue, setProgressValue] = useState(0);
  const [progressPercent, setProgressPercent] = useState(0);
  const [pdfFile, setPdfFile] = useState({});
  const [mp3File, setMp3File] = useState({});
  const [imgFileList, setImgFileList] = useState<UploadFile<any>[]>([]);
  const [storyId, setStoryId] = useState<string>('');
  const [duplicateStoryId, setDuplicateStoryId] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [draftedScreenplay, setDraftedScreenPlay] = useState<DraftedFilesType>({});
  const [draftedNarration, setDraftedNarration] = useState<DraftedFilesType>({});
  const [draftedImage, setDraftedImage] = useState('');
  const [storyTitle, setStoryTitle] = useState<string>('');
  const [logline, setLogline] = useState<string>('');
  const [storyLine, setStoryLine] = useState<string>('');
  const [isNotAutoSave, setIsNotAutoSave] = useState<boolean>(true);
  const [countImagesUpload, setCountImagesupload] = useState<number>(0);

  const [availableLanguages, setAvailableLanguages] = useState<any>(langList);

  const [availableGenres, setAvailableGenres] = useState<any>(genList);

  const [submissionGuidelinesModalVisible, setSubmissionGuidelinesModalVisible] = useState<boolean>(false);
  const [tipsAndTricksModalVisible, setTipsAndTricksModalVisible] = useState<boolean>(false);
  const [deleteDraftModalVisible, setDeleteDraftModalVisible] = useState<boolean>(false);

  const [submissionForm] = Form.useForm();

  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location?.search);
  const draftId: string | null = params.get('draftId');
  const duplicateStoryIdData = params.get('duplicateStoryId');

  useEffect(() => {
    setAvailableLanguages(langList);
  }, [langList]);

  useEffect(() => {
    setAvailableGenres(genList);
  }, [genList]);

  useEffect(() => {
    if (draftId && userProfile) {
      setStoryId(draftId);
      dispatchGetDraftedStory(0, 1, draftId, userProfile.id);
    } else if (duplicateStoryIdData && userProfile) {
      setDuplicateStoryId(duplicateStoryIdData);
      dispatchGetDraftedStory(0, 1, duplicateStoryIdData, userProfile.id);
    }
  }, [userProfile]);

  useEffect(() => {
    debounceSave.cancel();

    dispatchGetLangAndGenre(21, 1);
    dispatchGetTags(57, 1);
    dispatchGetStoryTypes(0, 1);
    dispatchGetHasSelfAttestedStory();

    return () => {
      dispatchResetState();
      debounceSave.cancel();
    };
  }, []);

  useEffect(() => {
    if (currentStoryId) {
      /* istanbul ignore else */
      if (storyId !== currentStoryId) {
        setStoryId(currentStoryId);
      }
    }
  }, [currentStoryId]);

  useEffect(() => {
    if (draftedStory) {
      submissionForm.setFieldsValue({
        storyTitle: draftedStory.title,
        logline: draftedStory.logline,
        storyline: draftedStory.storyline,
        coWriter1: draftedStory.coAuthor1,
        coWriter2: draftedStory.coAuthor2
      });

      if (draftedStory.id) {
        setStoryId(draftedStory.id);
        if (duplicateStoryId === '') {
          dispatchCurrentStoryId(draftedStory.id);
        }
      }

      if (draftedStory.expectedOffer) {
        submissionForm.setFieldValue('expectedPrice', draftedStory.expectedOffer);
      }

      if (draftedStory.title) {
        setProgressState((currState) => ({ ...currState, storyTitle: 1 }));
      }

      if (draftedStory.logline) {
        setProgressState((currState) => ({ ...currState, logline: 1 }));
      }

      if (draftedStory.storyline) {
        setProgressState((currState) => ({ ...currState, storyline: 1 }));
      }
      if (draftedStory.storyType) {
        setProgressState((currState) => ({ ...currState, storyType: 1 }));
      }
      if (draftedStory.languages) {
        setProgressState((currState) => ({ ...currState, languages: 1 }));
      }
      if (draftedStory.genres) {
        setProgressState((currState) => ({ ...currState, genres: 1 }));
      }
      const fields = submissionForm.getFieldsValue();

      let { languages, genres, storyType } = fields;
      if (draftedStory.languages) {
        draftedStory.languages.map((el: any, index: number) => {
          languages[index] = { language: { label: el.name, value: Number(el.id) } };
        });
      }

      if (draftedStory.genres) {
        draftedStory.genres.map((el: any, index: number) => {
          genres[index] = { genre: { label: el.name, value: Number(el.id) } };
        });
      }

      if (draftedStory?.storyType) {
        storyType = { label: draftedStory?.storyType?.name, value: Number(draftedStory?.storyType?.id) };
      }

      submissionForm.setFieldsValue({ languages, genres, storyType });

      if (draftedStory.tags) {
        setTagsList(draftedStory.tags.map((el: any) => ({ id: Number(el.id), name: el.name })));
      }

      if (draftedStory.supportingMaterials?.length > 0) {
        let imagescount = 0;
        draftedStory.supportingMaterials.map((currMaterial: any) => {
          switch (currMaterial.attachmentType) {
            case SUPPORTING_MATERIAL_TYPE.SCREENPLAY: {
              const draftScreenPlayObj: DraftedFilesType = {};
              draftScreenPlayObj.id = currMaterial?.id;
              draftScreenPlayObj.name = currMaterial?.fileName;

              setDraftedScreenPlay(draftScreenPlayObj);
              break;
            }
            case SUPPORTING_MATERIAL_TYPE.AUDIO_NARRATION: {
              const draftNarrationObj: DraftedFilesType = {};
              draftNarrationObj.id = currMaterial?.id;
              draftNarrationObj.name = currMaterial?.fileName;
              setDraftedNarration(draftNarrationObj);
              break;
            }

            case SUPPORTING_MATERIAL_TYPE.IMAGE:
              setDraftedImage(currMaterial.fileName);
              imagescount++;
              break;
          }
        });
        setCountImagesupload(imagescount);
      }
    }
  }, [draftedStory]);

  const handleApplyTags = (tags: any[]) => {
    if (tags.length > 0) {
      setTagsList(tags);
    }
    setShowTags(false);
  };

  const handleTagDelete = (tag: any) => {
    setTagsList((current) => [...current.filter((item) => item.name !== tag.name)]);
  };

  useEffect(() => {
    setProgressValue(Object.values(progressState).reduce((curr, sum) => curr + sum));
  }, [progressState]);

  useEffect(() => {
    setProgressPercent(Math.floor((progressValue / 7) * 100));
  }, [progressValue]);

  useEffect(() => {
    if (tagsList.length > 0) {
      setProgressState((currState) => ({ ...currState, tags: 1 }));
      debounceSave();
    } else {
      setProgressState((currState) => ({ ...currState, tags: 0 }));
    }
  }, [tagsList]);

  useEffect(() => {
    if (!isEmpty(pdfFile) || !isEmpty(mp3File) || imgFileList.length > 0) {
      debounceSave();
    }
  }, [pdfFile, mp3File, imgFileList]);

  const handleChangeForm = (values: any) => {
    if (values.storyTitle?.trim()) {
      setProgressState((currState) => ({ ...currState, storyTitle: 1 }));
    } else {
      setProgressState((currState) => ({ ...currState, storyTitle: 0 }));
    }
    if (values.logline?.trim()) {
      setProgressState((currState) => ({ ...currState, logline: 1 }));
    } else {
      setProgressState((currState) => ({ ...currState, logline: 0 }));
    }
    if (values.storyline?.trim()) {
      setProgressState((currState) => ({ ...currState, storyline: 1 }));
    } else {
      setProgressState((currState) => ({ ...currState, storyline: 0 }));
    }

    if (isListOfObjectsNotEmpty(values.genres)) {
      setProgressState((currState) => ({ ...currState, genres: 1 }));
    } else {
      setProgressState((currState) => ({ ...currState, genres: 0 }));
    }
    if (isListOfObjectsNotEmpty(values.languages)) {
      setProgressState((currState) => ({ ...currState, languages: 1 }));
    } else {
      setProgressState((currState) => ({ ...currState, languages: 0 }));
    }
    if (values.storyType) {
      setProgressState((currState) => ({ ...currState, storyType: 1 }));
    } else {
      setProgressState((currState) => ({ ...currState, storyType: 0 }));
    }
  };

  const constructSubmitForm = (submitMode: string) => {
    const { storyTitle, languages, genres, storyType, expectedPrice, coWriter1, coWriter2 } =
      submissionForm.getFieldsValue([
        'storyTitle',
        'languages',
        'genres',
        'storyType',
        'expectedPrice',
        'coWriter1',
        'coWriter2'
      ]);

    if (!storyTitle?.trim()) {
      message.error(translate('enter_title'));
      return false;
    }

    let genreList = [];
    let languageList = [];
    genreList = genres
      .filter((el: any) => {
        return el ? isNotNullOrUndefined(el.genre) : false;
      })
      .map((el: any) => {
        if (el) {
          return el.genre.value;
        }
      });
    languageList = languages
      .filter((el: any) => (el ? isNotNullOrUndefined(el.language) : false))
      .map((el: any) => {
        if (el) {
          return el.language.value;
        }
      });

    let storyTypeValue;

    if (storyType) {
      if (storyType.value) {
        storyTypeValue = storyType.value;
      }
    }

    let tagsIdList = [];
    tagsIdList = tagsList.map((current) => current.id);

    let submitScreenplayObj: SubmitFileType = {};
    let submitNarrationyObj: SubmitFileType = {};
    let submitImagesList: UploadFile<any>[] = [];

    if (!isEmpty(pdfFile)) {
      submitScreenplayObj = {
        id: !isEmpty(draftedScreenplay) ? draftedScreenplay?.id : 0,
        file: pdfFile,
        isUploaded: true
      };
      setPdfFile({});
    } else if (!isEmpty(draftedScreenplay)) {
      submitScreenplayObj = draftedScreenplay;
      submitScreenplayObj.isUploaded = false;
    }

    if (!isEmpty(mp3File)) {
      submitNarrationyObj = {
        id: !isEmpty(draftedNarration) ? draftedNarration?.id : 0,
        file: mp3File,
        isUploaded: true
      };
      setMp3File({});
    } else if (!isEmpty(draftedNarration)) {
      submitNarrationyObj = draftedNarration;
      submitNarrationyObj.isUploaded = false;
    }

    if (imgFileList.length > 0) {
      submitImagesList = imgFileList
        ?.map((file: any) => {
          if (bytesToMegaByte(file?.size) <= 1) {
            return file;
          }
        })
        .filter(Boolean);

      if (submitImagesList.length > 0) {
        setCountImagesupload(countImagesUpload + submitImagesList.length);
        setDraftedImage(submitImagesList[0].name);
      }

      setImgFileList([]);
    }

    const submitForm = {
      storySubmissionInput: {
        title: storyTitle?.trim(),
        mode: submitMode,
        storyId: currentStoryId === null ? '' : currentStoryId,
        storyline: submissionForm.getFieldValue('storyline')?.trim() || '',
        storyType: storyTypeValue,
        logline: submissionForm.getFieldValue('logline')?.trim() || '',
        genres: genreList,
        languages: languageList,
        tags: tagsIdList,
        screenPlay: submitScreenplayObj,
        audioNarration: submitNarrationyObj,
        images: submitImagesList,
        expectedOffer: Number(expectedPrice),
        coAuthor1: coWriter1,
        coAuthor2: coWriter2
      }
    };

    return submitForm;
  };

  const handleSubmitDraft = () => {
    const draftForm = constructSubmitForm(STORY_SUBMITTED_MODE.DRAFT);
    setIsNotAutoSave(false);
    if (draftForm) {
      dispatchSubmitStory(draftForm, false);
    }
  };

  const handleSubmitStory = () => {
    const values = submissionForm.getFieldsValue();
    const formDataValid = validateStoryForm(values);

    if (formDataValid.formDataValid) {
      const continueForm = constructSubmitForm(STORY_SUBMITTED_MODE.CONTINUE);
      setIsNotAutoSave(false);
      setIsSubmitted(true);
      dispatchSubmitStory(continueForm, false);
    } else {
      notification.open({
        message: translate('error'),
        description: formDataValid.errorMessage
      });
    }
  };

  const dummyRequest = async ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const pdfFileProp: UploadProps = {
    name: 'pdfFile',
    multiple: false,
    customRequest: dummyRequest,
    maxCount: 1,
    accept: 'application/pdf',
    beforeUpload: (file) => {
      if (bytesToMegaByte(file.size) > 5) {
        message.error(translate('file_size_exceeds_limit'));
        return false;
      }
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === 'done') {
        setPdfFile(info.file.originFileObj);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const mp3FileProp: UploadProps = {
    name: 'mp3file',
    multiple: false,
    customRequest: dummyRequest,
    maxCount: 1,
    accept: '.mp3',
    beforeUpload: (file) => {
      if (bytesToMegaByte(file.size) > 15) {
        message.error(translate('file_size_exceeds_limit'));
        return false;
      }
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === 'done') {
        setMp3File(info.file.originFileObj);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const imgFileProp: UploadProps = {
    onRemove: (fileToRemove) => {
      const newFileList = imgFileList.filter((file) => {
        return fileToRemove.uid !== file.uid;
      });
      setImgFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (bytesToMegaByte(file.size) > 1) {
        message.error(translate('file_size_exceeds_limit_images'));
      } else {
        setImgFileList([...imgFileList, file]);

        return false;
      }
    },
    onChange: ({ fileList }) => {
      setImgFileList([...fileList]);
    },
    onDrop(e) {
      const filesDroppedCount = e.dataTransfer.files.length;

      if (filesDroppedCount > 10) {
        message.error(translate('max_10_files_allowed_error'));
      }
    },
    multiple: true,
    fileList: imgFileList,
    listType: 'picture',
    accept: '.jpg',
    maxCount: 10 - countImagesUpload
  };

  const handleLanguageChange = () => {
    const selectedLanguages = submissionForm
      .getFieldsValue()
      .languages.map((selectedLanguage: any) => selectedLanguage?.language?.value)
      .filter(Boolean);

    const newLanguages = langList.filter(
      (availableLanguage: any) => !selectedLanguages.includes(availableLanguage.value)
    );

    setAvailableLanguages(newLanguages);
  };

  const handleGenreChange = () => {
    const selectedGenres = submissionForm
      .getFieldsValue()
      .genres.map((selectedgenre: any) => selectedgenre?.genre?.value)
      .filter(Boolean);

    const newGenres = genList.filter((availableGenre: any) => !selectedGenres.includes(availableGenre.value));

    setAvailableGenres(newGenres);
  };

  const debounceSave = useCallback(
    debounce(
      () => {
        if (!window.location.href.includes('dashboard')) {
          return;
        }
        const formData = constructSubmitForm(STORY_SUBMITTED_MODE.DRAFT);
        if (formData) {
          dispatchSubmitStory(formData, true);
        }
      },
      5000,
      { maxWait: 3000 }
    ),
    [currentStoryId, storyId, tagsList, pdfFile, mp3File, imgFileList]
  );

  const validateStoryForm = (formData: any) => {
    let formDataValid = true;
    let errorMessage = '';

    if (!formData?.storyline?.trim()) {
      formDataValid = false;
      errorMessage = translate('storyline_error');
    }

    if (!formData?.logline?.trim()) {
      formDataValid = false;
      errorMessage = translate('logline_error');
    }

    if (tagsList.length === 0) {
      formDataValid = false;
      errorMessage = translate('tags_error');
    }

    if (!formData.storyType) {
      formDataValid = false;
      errorMessage = translate('storyType_error');
    }
    if (!isListOfObjectsNotEmpty(formData.genres)) {
      formDataValid = false;
      errorMessage = translate('genre_error');
    }

    if (!isListOfObjectsNotEmpty(formData.languages)) {
      formDataValid = false;
      errorMessage = translate('language_error');
    }

    if (!formData?.storyTitle?.trim()) {
      formDataValid = false;
      errorMessage = translate('storyTitle_error');
    }

    return { formDataValid, errorMessage };
  };

  const confirmHandler = () => {
    dispatchChangeStoryState(STORIES_STATES.DELETED, draftId as string);
    setDeleteDraftModalVisible(false);
  };

  return (
    <PageContainer>
      <TipsAndTricksModal visible={tipsAndTricksModalVisible} handleClose={() => setTipsAndTricksModalVisible(false)} />
      <DeleteDraftModal
        title="delete_draft_modal_title"
        desc="delete_draft_modal_desc"
        width={'70rem'}
        height={'38.25rem'}
        visible={deleteDraftModalVisible}
        handleConfirm={confirmHandler}
        handleCancel={() => setDeleteDraftModalVisible(false)}
      />
      <SubmissionGuidelinesModal
        visible={submissionGuidelinesModalVisible}
        handleClose={() => setSubmissionGuidelinesModalVisible(false)}
      />
      <Container>
        <NewSubmissionContainer>
          <Wrapper>
            <BackButton onClick={() => history.goBack()} data-testid="back-button">
              <StyledImg src={ArrowLeft} />
              {translate('back')}
            </BackButton>
            <If condition={draftId as string}>
              <Icon
                data-testid="delete-icon"
                src={Bin}
                alt="delete icon"
                onClick={() => {
                  debounceSave.cancel();
                  setDeleteDraftModalVisible((prev) => !prev);
                }}
              />
            </If>
          </Wrapper>
          <FormContainer
            layout="vertical"
            form={submissionForm}
            initialValues={{
              languages: [{ language: undefined }, { language: undefined }],
              genres: [{ genre: undefined }, { genre: undefined }]
            }}
            onValuesChange={(_, values: any) => {
              handleChangeForm(values);

              if (values?.storyTitle?.trim().length === 1 && !currentStoryId) {
                const formData = constructSubmitForm(STORY_SUBMITTED_MODE.DRAFT);
                formData && dispatchSubmitStory(formData, true);
              } else {
                if (currentStoryId) {
                  debounceSave();
                }
              }
            }}
          >
            <Form.Item name="storyTitle">
              <StyledEditHeader
                placeholder={translate('edit_title')}
                data-testid="header-input"
                onChange={(e) => setStoryTitle(e.target.value)}
                value={storyTitle}
                maxLength={32}
              />
            </Form.Item>
            <TitleCharacterCount>{translate('character_count', { amount: storyTitle.length })}</TitleCharacterCount>
            <Form.List name="languages">
              {(fields, { add }) => (
                <>
                  <FormListContainer>
                    <LabelAddContainer>
                      <FormItemLabel>{translate('languages')}</FormItemLabel>
                      <If condition={showLanguage}>
                        <AddImg
                          src={AddIcon}
                          onClick={() => {
                            if (fields.length === 4) {
                              setShowLanguage(false);
                            }
                            add();
                          }}
                          data-testid="add-icon-language"
                        />
                      </If>
                    </LabelAddContainer>
                    <ListWrapContainer>
                      {fields.map(({ key, name }) => (
                        <Form.Item key={key} name={[name, 'language']} data-testid="language-select">
                          <StyledSelect
                            options={availableLanguages}
                            placeholder={translate('language_placeholder')}
                            onChange={handleLanguageChange}
                            isClearable
                          />
                        </Form.Item>
                      ))}
                    </ListWrapContainer>
                    <SubText>{translate('min_max_language')}</SubText>
                  </FormListContainer>
                </>
              )}
            </Form.List>
            <Form.List name="genres">
              {(fields, { add }) => (
                <>
                  <FormListContainer>
                    <LabelAddContainer>
                      <FormItemLabel>{translate('genres')}</FormItemLabel>
                      <If condition={showGenres}>
                        <AddImg
                          src={AddIcon}
                          onClick={() => {
                            setShowGenres(false);
                            add();
                          }}
                          data-testid="add-icon-genre"
                        />
                      </If>
                    </LabelAddContainer>
                    <ListWrapContainer>
                      {fields.map(({ key, name }) => (
                        <Form.Item key={key} name={[name, 'genre']} data-testid="genre-select">
                          <StyledSelect
                            options={availableGenres}
                            placeholder={translate('genre_placeholder')}
                            onChange={handleGenreChange}
                            isClearable
                          />
                        </Form.Item>
                      ))}
                    </ListWrapContainer>
                    <SubText>{translate('min_max_genre')}</SubText>
                  </FormListContainer>
                </>
              )}
            </Form.List>
            <InlineItems>
              <CustomFormItem label={translate('story_type')} name="storyType" data-testid="story-type-select">
                <StyledSelect options={storyTypeList} placeholder={translate('story_type_placeholder')} isClearable />
              </CustomFormItem>
              <TagsContainer>
                <CustomFormItem label={translate('add_tags')} name="addTags" onClick={() => setShowTags(true)}>
                  <CustomTagsInput
                    placeholder={translate('select_tags_placeholder')}
                    suffix={<AddImg src={PlusIcon} data-testid="tags-add" />}
                    disabled={true}
                  />
                </CustomFormItem>
                <SubText>{translate('select_tags')}</SubText>
                <TagSubContainer>
                  {tagsList.map((tag) => (
                    <Tags
                      isSelected={false}
                      tagName={tag.name}
                      isDeletable={true}
                      handleDelete={() => handleTagDelete(tag)}
                      key={tag.id}
                    />
                  ))}
                </TagSubContainer>
              </TagsContainer>
            </InlineItems>
            <FormItemContainer>
              <LabelTooltipContainer>
                <FormItemLabel>{translate('logline')}</FormItemLabel>
                <Tippy content={translate('logline_tooltip')}>
                  <StyledImg src={ToolTipIcon} />
                </Tippy>
              </LabelTooltipContainer>
              <CustomFormItem name="logline">
                <StyledTextArea
                  placeholder={translate('logline_placeholder')}
                  autoSize
                  data-testid="logline"
                  onPaste={(e) => {
                    const clipboardData = e.clipboardData;
                    const pastedData = clipboardData.getData('Text');

                    const words = wordsCount(pastedData, {});
                    const currentWords = wordsCount(logline, {});

                    if (words > 50) {
                      e.preventDefault();
                      message.error(translate('50_words_limit_error'));
                    } else {
                      if (words + currentWords > 50) {
                        e.preventDefault();
                        message.error(translate('50_words_limit_error'));
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (wordsCount(e.target.value, {}) <= 50) {
                      setLogline(e.target.value);
                    }
                  }}
                  onKeyDown={(e) => {
                    const loglineWords = wordsCount(logline, {});
                    if (loglineWords >= 50) {
                      if (e.key !== 'Backspace' && e.key !== 'Delete') {
                        e.preventDefault();
                      }
                    }
                  }}
                  value={logline}
                />
              </CustomFormItem>
              <SubText>{translate('logline_limit', { amount: logline === '' ? 0 : wordsCount(logline, {}) })}</SubText>
            </FormItemContainer>
            <FormItemContainer>
              <LabelTooltipContainer>
                <FormItemLabel>{translate('synopsis')}</FormItemLabel>
                <Tippy content={translate('storyline_tooltip')}>
                  <StyledImg src={ToolTipIcon} />
                </Tippy>
              </LabelTooltipContainer>
              <CustomFormItem name="storyline">
                <StyledTextArea
                  placeholder={translate('storyline_placeholder')}
                  autoSize={{ minRows: 7 }}
                  data-testid="storyline"
                  onPaste={(e) => {
                    const clipboardData = e.clipboardData;
                    const pastedData = clipboardData.getData('Text');
                    const pastedDataWordCount = wordsCount(pastedData, {});
                    const currentWordCount = wordsCount(storyLine, {});

                    if (pastedDataWordCount > 500) {
                      e.preventDefault();
                      message.error(translate('500_words_limit_error'));
                    } else {
                      if (pastedDataWordCount + currentWordCount > 500) {
                        e.preventDefault();
                        message.error(translate('500_words_limit_error'));
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (wordsCount(e.target.value) <= 500) {
                      setStoryLine(e.target.value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (wordsCount(storyLine) >= 500) {
                      if (e.key !== 'Backspace' && e.key !== 'Delete') {
                        e.preventDefault();
                      }
                    }
                  }}
                  value={storyLine}
                />
              </CustomFormItem>
              <SubText>
                {translate('storyline_limit', { amount: storyLine === '' ? 0 : wordsCount(storyLine) })}
              </SubText>
            </FormItemContainer>
            <CustomDivider />
            <FormItemLabel>{translate('supporting_material')}</FormItemLabel>
            <InlineItems>
              <FormItemContainer>
                <LabelTooltipContainer>
                  <FormItemLabel>{translate('upload_screenplay')}</FormItemLabel>
                  <Tippy content={translate('screenplay_tooltip')}>
                    <StyledImg src={ToolTipIcon} />
                  </Tippy>
                </LabelTooltipContainer>
                <CustomFormItem name="screenPlay">
                  <StyledDragger {...pdfFileProp} data-testid="screenplay-test">
                    <StyledImg src={PageIcon} />
                    <UploadText>{draftedScreenplay?.name || translate('upload_file')}</UploadText>
                    <UploadSubText>{translate('drag_drop')}</UploadSubText>
                    <UploadSubText>{translate('upto_5mb')}</UploadSubText>
                  </StyledDragger>
                </CustomFormItem>
                <SubText>{translate('files_limit')}</SubText>
              </FormItemContainer>

              <CustomFormItem label={translate('expected_price')} name="expectedPrice">
                <CustomNumInput
                  type="number"
                  placeholder={translate('expected_price_placeholder')}
                  onKeyDown={preventInvalidCharsNumberInput}
                  min={1}
                />
              </CustomFormItem>
            </InlineItems>

            <InlineItems>
              <FormItemContainer>
                <LabelTooltipContainer>
                  <FormItemLabel>{translate('upload_narration')}</FormItemLabel>
                  <Tippy content={translate('narration_tooltip')}>
                    <StyledImg src={ToolTipIcon} />
                  </Tippy>
                </LabelTooltipContainer>
                <CustomFormItem name="narration">
                  <StyledDragger {...mp3FileProp}>
                    <StyledImg src={MicIcon} />
                    <UploadText>{draftedNarration?.name || translate('select_audio')}</UploadText>
                    <UploadSubText>{translate('drag_drop')}</UploadSubText>
                    <UploadSubText>{translate('upto_15mb')}</UploadSubText>
                  </StyledDragger>
                </CustomFormItem>
                <SubText>{translate('files_limit')}</SubText>
              </FormItemContainer>
              <FormItemContainer>
                <LabelTooltipContainer>
                  <FormItemLabel>{translate('upload_image')}</FormItemLabel>
                  <Tippy content={translate('supporting_images_tooltip')}>
                    <StyledImg src={ToolTipIcon} />
                  </Tippy>
                </LabelTooltipContainer>
                <CustomFormItem name="image">
                  <StyledDragger {...imgFileProp}>
                    <StyledImg src={ImgIcon} />
                    <UploadText>{draftedImage || translate('select_image')}</UploadText>
                    <UploadSubText>{translate('drag_drop')}</UploadSubText>
                    <UploadSubText>{translate('upload_images_limit', { count: 10 - countImagesUpload })}</UploadSubText>
                  </StyledDragger>
                </CustomFormItem>
                <SubText>{translate('files_limit_images')}</SubText>
              </FormItemContainer>
            </InlineItems>
            <InlineItems>
              <CustomFormItem label={translate('add_co_writer_1')} name="coWriter1">
                <CustomTagsInput placeholder={translate('add_co_writer_placeholder')} onKeyDown={allowOnlyChars} />
              </CustomFormItem>

              <CustomFormItem label={translate('add_co_writer_2')} name="coWriter2">
                <CustomTagsInput placeholder={translate('add_co_writer_placeholder')} onKeyDown={allowOnlyChars} />
              </CustomFormItem>
            </InlineItems>
            <ButtonContainer>
              <InlineItems>
                <StyledButton onClick={handleSubmitDraft} data-testid="draft-btn">
                  {translate('save_draft')}
                </StyledButton>

                <StyledContinueButton onClick={handleSubmitStory} data-testid="continue-btn">
                  <StyledImg src={ActiveCrownIcon} />
                  {translate('continue')}
                </StyledContinueButton>
              </InlineItems>
            </ButtonContainer>
          </FormContainer>
        </NewSubmissionContainer>
        <ProgressContainer>
          <ProgressSubContainer>
            <ProgressText>{`${progressPercent}%`}</ProgressText>
            <CustomProgressBar percent={progressPercent} showInfo={false} />
            <ProgressText>{`0${progressValue}/07`}</ProgressText>
          </ProgressSubContainer>

          <If condition={progressValue === 0}>
            <ProgressSideText>{translate('fresh_start')}</ProgressSideText>
          </If>
          <If condition={progressValue === 7}>
            <ProgressSideText>{translate('all_set')}</ProgressSideText>
          </If>
          <ReminderContainer>
            <StyledImg src={InfoIcon} />
            <ReminderText>{translate('story_autosave_reminder')}</ReminderText>
          </ReminderContainer>
          <Accordion accordion={true} expandIconPosition={'right'}>
            <AccordionPanel header={translate('submission_guidelines_title')} key={1}>
              <TipInfo>
                {translate('submission_guidelines_info_before_underline')}
                <UnderlineText data-testid="sub-text" onClick={() => setSubmissionGuidelinesModalVisible(true)}>
                  {translate('submission_guidelines_info_underline')}
                </UnderlineText>
                {translate('submission_guidelines_info_after_underline')}
              </TipInfo>
            </AccordionPanel>
            <AccordionPanel header={translate('tips_and_tricks_title')} key={2}>
              <TipInfo>
                {translate('tips_and_tricks_info_before_underline')}
                <UnderlineText data-testid="tips-text" onClick={() => setTipsAndTricksModalVisible(true)}>
                  {translate('tips_and_tricks_info_underline')}
                </UnderlineText>
                {translate('tips_and_tricks_info_after_underline')}
              </TipInfo>
            </AccordionPanel>
            <AccordionPanel header={translate('copyright_and_hosting_fees')} key={3}>
              <TipInfo>{translate('copyright_hosting_fees')}</TipInfo>
            </AccordionPanel>
            <AccordionPanel header={translate('commission')} key={4}>
              <TipInfo>{translate('commission_message')}</TipInfo>
            </AccordionPanel>
            <AccordionPanel header={translate('faqs')} key={5}>
              <TipInfo>
                {translate('faq_before_underline')}
                <UnderlineTextLink href={FAQ_URL} target="_blank">
                  {translate('faq_underline')}
                </UnderlineTextLink>
                {translate('faq_after_underline')}
              </TipInfo>
            </AccordionPanel>
          </Accordion>
          {/* <TipsContainer>
            <TipContainer>
              <TipTitle>{translate('submission_guidelines')}</TipTitle>
              <TipInfo>
                {translate('submission_guidelines_info_before_underline')}
                <UnderlineText onClick={() => setSubmissionGuidelinesModalVisible(true)}>
                  {translate('submission_guidelines_info_underline')}
                </UnderlineText>
                {translate('submission_guidelines_info_after_underline')}
              </TipInfo>
            </TipContainer>
            <TipContainer>
              <TipTitle>{translate('tips_and_tricks_title')}</TipTitle>
              <TipInfo>
                {translate('tips_and_tricks_info_before_underline')}
                <UnderlineText onClick={() => setTipsAndTricksModalVisible(true)}>
                  {translate('tips_and_tricks_info_underline')}
                </UnderlineText>
                {translate('tips_and_tricks_info_after_underline')}
              </TipInfo>
            </TipContainer>
            <If condition={hasSelfAttestedStory}>
              <MessageTip title={translate('copyright_and_hosting_fees')} info={translate('copyright_fees_info')} />
            </If>
          </TipsContainer> */}
        </ProgressContainer>
      </Container>
      <If condition={showTags}>
        <Portal>
          <TagsPage onApply={handleApplyTags} initialList={tagsList} tagsData={tagsDataList || []} />
        </Portal>
      </If>
      <If condition={submitLoading && !isNotAutoSave}>
        <Portal>
          <CompilingLoader isSubmitted={isSubmitted} />
        </Portal>
      </If>
    </PageContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  langList: selectLanguageList(),
  genList: selectGenreList(),
  tagsDataList: selectTagsList(),
  draftedStory: selectDraftedStory(),
  submitLoading: selectSubmitStoryLoading(),
  userProfile: selectUserProfile(),
  hasSelfAttestedStory: selectHasSelfAttestedStory(),
  currentStoryId: selectCurrentStoryId(),
  currentScreenplayFileId: selectScreenplayFileId(),
  storyTypeList: selectStoryTypeList()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetLangAndGenre: (limit: number, page: number) => dispatch(requestLangGenre({ limit, page })),
    dispatchGetTags: (limit: number, page: number) => dispatch(requestGetTags({ limit, page })),
    dispatchSubmitStory: (storyData: any, isAutoSave: boolean) =>
      dispatch(requestSubmitStory({ storyData, isAutoSave })),
    dispatchGetDraftedStory: (limit: number, page: number, draftId: string, sellerId: string) =>
      dispatch(requestGetStoryDetails({ limit, page, draftId, sellerId })),
    dispatchGetHasSelfAttestedStory: () => dispatch(requestGetHasSelfAttestedStory({})),
    dispatchResetState: () => dispatch(resetState()),
    dispatchCurrentStoryId: (id: string) => dispatch(setCurrentStoryId(id)),
    dispatchGetStoryTypes: (limit: number, page: number) => dispatch(requestGetStoryTypes({ limit, page })),
    dispatchChangeStoryState: (state: string, id: string) => dispatch(requestStateChange({ id, state }))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo, injectIntl)(NewSubmissionPage) as React.FC;

export const NewSubmisstionPageTest = NewSubmissionPage;
