import { gql } from 'apollo-boost';

export const RENEW_SELLER_SUBSCRIPTION = gql`
  mutation RenewSellerSubscription {
    renewSellerSubscription {
      previousSubscriptionExpiredAt
      previousTicketsBalance
      ticketsDeducted
      currentBalance
      currentSubscriptionExpiresAt
    }
  }
`;
