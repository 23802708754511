import { getQueryResponse } from '@app/utils/graphqlUtils';

import { AnyAction } from '@reduxjs/toolkit';
import { get } from 'lodash-es';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_MY_SPOT_TICKETS } from './queries';
import { failureGetSpotTickets, requestGetSpotTickets, successGetSpotTickets } from './reducer';

export function* getMySpotTickets(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      sellerId: action.payload.sellerId,
      page: {
        limit: 0,
        page: 1
      }
    };
    const { ok, data, error } = yield call(getQueryResponse, GET_MY_SPOT_TICKETS, payload);

    if (ok) {
      const spotTickets = get(data, 'sellerSpotTickets', {});

      yield put(successGetSpotTickets(spotTickets));
    } else {
      yield put(failureGetSpotTickets(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureGetSpotTickets(get(e, 'message', 'something_went_wrong')));
  }
}

export default function* mySpotTicketsContainerSaga() {
  yield all([takeLatest(requestGetSpotTickets.toString(), getMySpotTickets)]);
}

export const mySpotTicketsSagaArr = [takeLatest(requestGetSpotTickets.toString(), getMySpotTickets)];
