import { gql } from 'apollo-boost';

export const GET_REQUESTS_DETAILS = gql`
  query StoryConversations($input: StoryConversationsInput!, $pagination: ConversationPagination!) {
    storyConversations(input: $input, pagination: $pagination) {
      conversations {
        conversationId
        buyer {
          id
          firstName
          lastName
          profileImage
          designation
          companyName
        }
        isRequestPending
      }
      total
    }
  }
`;
