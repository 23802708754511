import { gql } from 'apollo-boost';

export const GET_MY_SPOT_TICKETS = gql`
  query SpotTickets($sellerId: ID, $filters: SpotTicketsWhere, $page: SpotTicketsPagination!) {
    sellerSpotTickets(sellerId: $sellerId, filters: $filters, pagination: $page) {
      currentBalance
      transactionsHistory {
        id
        amount
        action
        receiptLink
        actionVia
        createdAt
        updatedAt
        deletedAt
      }
      total
    }
  }
`;
