import CustomEmpty from '@app/components/CustomEmpty';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { If } from 'tsw-sdk';
import { StoriesProps } from '../../types';
import StoryItem from '../StoryItem';
import { StoryItemType } from '../StoryItem/types';

const PublishedStoriesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  padding-left: 4.93rem;
`;

const PublishedStoriesTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.profilePageColors.titleColor};
  margin-bottom: 1rem;
`;

const PublishedStoriesListContainer = styled.div`
  width: 100%;
  padding-right: 9rem;
  display: flex;
  align-items: center;
  margin-top: 2.75rem;
`;

const PublishedStories = ({ publishedStories }: StoriesProps) => {
  return (
    <PublishedStoriesContainer>
      <PublishedStoriesTitle>{translate('favourite_stories')}</PublishedStoriesTitle>
      <If condition={publishedStories?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
        <PublishedStoriesListContainer>
          {publishedStories?.map((storyItem: StoryItemType) => (
            <StoryItem key={storyItem.id} story={storyItem} />
          ))}
        </PublishedStoriesListContainer>
      </If>
    </PublishedStoriesContainer>
  );
};

export default PublishedStories;
