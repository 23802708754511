import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CustomButton, If } from 'tsw-sdk';
import renewSubscriptionIcon from '@images/icons/MyTickets/subscription-renew.svg';
import ArrowLeft from '@images/icons/MyDashboarIcons/arrow-left-white.svg';

import _ from 'lodash';
import { CardSectionProps, SubscriptionExpiredPageProps } from '../types';
import { Input, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';

const SubscriptionInActiveScreen = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 10000;
  position: absolute;
  background-color: ${colors.myDashboardColors.subscriptionInActiveColor};
`;

const SubscriptionReminderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2.56rem;
  padding-top: 9.5rem;
`;

const SubscriptionTextParagraph = styled.p`
  max-width: 53rem;
  ${fonts.size.large()};
  ${fonts.weights.fw700()};
  line-height: 2.43rem;
  color: ${colors.white};
  &:not(:first-of-type) {
    margin-top: 1.25rem;
  }
`;

const CtaButton = styled(CustomButton)`
  &&& {
    min-width: 16rem;
    background-color: ${colors.tagBg};
    color: ${colors.myDashboardColors.buttonTextColor};
    margin-top: 2.18rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SubscriptionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2rem;
  padding-top: 4.5rem;
`;

const SubscriptionCard = styled.div`
  width: 47.56rem;
  height: 21.62rem;
  border-radius: 1rem;
  background-color: ${colors.white};
  box-shadow: 0px 0px 1.25rem ${colors.myTicketsColors.cardShadow};
  padding: 4.18rem 2.37rem 4.81rem 2.18rem;
  display: flex;
  cursor: pointer;
`;

const SubscriptionCardSection = styled.div<CardSectionProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 1.25rem;
  ${(props) => !props.canRenew && 'padding-top:1.37rem'}
`;

const SubscriptionTitle = styled.p`
  margin-top: 1.93rem;
  ${fonts.weights.fw700()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  color: ${colors.primary};
  text-align: center;
  white-space: nowrap;
`;

const SubscriptionInstructList = styled.ul`
  padding-top: 1rem;
`;

const SubscriptionIntructItems = styled.li`
  ${fonts.weights.fw400()};
  ${fonts.size.size15px()};
  line-height: 1.5rem;
  color: ${colors.cardListItem};
  margin-bottom: 0.25rem;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.white};
  margin-bottom: 3.5rem;
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const AddSpotTicketModal = styled(Modal)`
  width: 35rem !important;

  && {
    & .ant-modal-footer {
      display: none;
    }

    & .ant-modal-content {
      border-radius: 1.5rem;
    }

    & .ant-modal-body {
      border-radius: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
`;

const AddTicketModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.p`
  width: 24.43rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.large()};
  ${fonts.weights.fw700()};
  line-height: 2.62rem;
  text-align: center;
  color: ${colors.primary};
  margin-left: auto;
  margin-right: auto;
`;

const TicketAmountInput = styled(Input)`
  width: 16rem;
  margin-top: 1.5rem;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${colors.white};
`;

const ContinueButton = styled(CustomButton)`
  width: 8.5rem;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
`;

const RenewButton = styled(CustomButton)`
  width: 10rem;
  margin-top: 1.81rem;
`;

const SubscriptionExpiredPage = ({
  userProfile,
  spotTicketsBalance,
  handleAddingTickets,
  dispatchRenewSubscription,
  handleCardClick
}: SubscriptionExpiredPageProps) => {
  const [isSpotTickets, setIsSpotTickets] = useState<boolean>(false);
  const [addSpotTicketModalVisible, setAddSpotTicketModalVisible] = useState<boolean>(false);
  const [tickets, setTickets] = useState<number>(0);

  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    setIsSpotTickets(params.get('activeTab') === 'spot_tickets');
  }, []);

  const handleSubCardClicked = () => {
    handleCardClick();
    setAddSpotTicketModalVisible(true);
  };

  const handleAddSpotTickets = () => {
    if (tickets > 0) {
      handleAddingTickets(tickets);
    }
  };

  const handleRenewSubscription = (event: any) => {
    event.stopPropagation();
    dispatchRenewSubscription();
  };

  const getSubscriptionCard = () => {
    return (
      <SubscriptionCardContainer>
        <BackButton onClick={() => history.goBack()} data-testid="back-btn">
          <StyledImg src={ArrowLeft} />
          {translate('back')}
        </BackButton>
        <SubscriptionCard onClick={() => handleSubCardClicked()} data-testid="subscription-card">
          <SubscriptionCardSection canRenew={spotTicketsBalance >= 500}>
            <StyledImg src={renewSubscriptionIcon} />
            <If
              condition={spotTicketsBalance >= 500}
              otherwise={<SubscriptionTitle>{translate('add_spot_tickets_now')}</SubscriptionTitle>}
            >
              <SubscriptionTitle>{translate('spot_balance', { balance: spotTicketsBalance })}</SubscriptionTitle>
              <RenewButton onClick={(event: any) => handleRenewSubscription(event)} data-testid="renew-btn">
                {translate('renew_now')}
              </RenewButton>
            </If>
          </SubscriptionCardSection>
          <SubscriptionInstructList>
            {_.range(5).map((number) => (
              <SubscriptionIntructItems key={number}>
                {translate(`sub_expire_list_${number + 1}`)}
              </SubscriptionIntructItems>
            ))}
          </SubscriptionInstructList>
        </SubscriptionCard>
      </SubscriptionCardContainer>
    );
  };

  return (
    <SubscriptionInActiveScreen>
      <AddSpotTicketModal
        centered={true}
        visible={addSpotTicketModalVisible}
        destroyOnClose={true}
        footer={<></>}
        onCancel={() => setAddSpotTicketModalVisible(false)}
        zIndex={10001}
      >
        <AddTicketModalContainer>
          <ModalTitle>{translate('add_tickets_modal_title')}</ModalTitle>
          <TicketAmountInput
            type="number"
            min={500 - spotTicketsBalance}
            placeholder={translate('ticket_input_placeholder')}
            onChange={(e: any) => setTickets(e.target.value)}
            data-testid="add-ticket-input"
          ></TicketAmountInput>
          <ContinueButton loading={false} onClick={handleAddSpotTickets} data-testid="add-ticket-continue">
            {translate('continue')}
          </ContinueButton>
        </AddTicketModalContainer>
      </AddSpotTicketModal>
      <If condition={!isSpotTickets} otherwise={getSubscriptionCard()}>
        <SubscriptionReminderContainer>
          <SubscriptionTextParagraph>
            {translate('user_greeting', { firstName: userProfile?.firstName, lastName: userProfile?.lastName })}
          </SubscriptionTextParagraph>
          <SubscriptionTextParagraph>{translate('subscription_inactive_message')}</SubscriptionTextParagraph>
          <SubscriptionTextParagraph>{translate('renew_request_message')}</SubscriptionTextParagraph>
          <CtaButton
            onClick={() => history.push(`${routeConstants.dashboard.route}?activeTab=spot_tickets`)}
            data-testid="redirect-spot-btn"
          >
            {translate('spot_tickets')}
          </CtaButton>
        </SubscriptionReminderContainer>
      </If>
    </SubscriptionInActiveScreen>
  );
};

export default SubscriptionExpiredPage;
