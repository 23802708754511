import React, { useEffect, useMemo, useState } from 'react';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import OfferCard from './components/OfferCard';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { requestGetOfferDetails } from './reducer';
import { selectOfferDetails } from './selectors';
import { createStructuredSelector } from 'reselect';
import { OfferViewProps } from './types';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import cardViewIcon from '@images/icons/OffersReceived/card-view-icon.svg';
import listViewIcon from '@images/icons/OffersReceived/list-view-icon.svg';
import { If } from 'tsw-sdk';
import { Table } from 'antd';
import acceptOfferIcon from '@images/icons/ViewOffers/accept-offer.svg';
import routeConstants from '@app/utils/routeConstants';

const OfferViewContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  background-color: ${colors.dashboardBg};
  padding-left: 2rem;
  padding-bottom: 2rem;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.93rem;
  padding-right: 4rem;
`;

const StoryNameTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  color: ${colors.offersReceivedColors.titleColor};
`;

const ViewToggleBtn = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ViewText = styled.p`
  ${fonts.size.small()};
  ${fonts.weights.fw800()};
  color: ${colors.offersReceivedColors.counterOfferFormLabel};
  line-height: 1rem;
`;

const ViewIcon = styled.img`
  margin-left: 1rem;
`;

const CardsContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 2.56rem;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
`;

const ViewOfferBtn = styled.div`
  width: 9.56rem;
  height: 1.75rem;
  background: ${colors.offersReceivedColors.storyName};
  border-radius: 0.5rem;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  ${fonts.weights.fw800()};
  ${fonts.size.small()}
  line-height: 1rem;
  cursor: pointer;
`;

const StyledImage = styled.img`
  margin-right: 0.56rem;
`;

const OfferAmountContainer = styled.div`
  min-width: 7.5rem;
  height: 1.75rem;
  width: auto;
  background-color: ${colors.offersReceivedColors.scriptIncludedPillBg};
  color: ${colors.offersReceivedColors.offerCardPillText};
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.43rem 0.75rem 0.43rem 0.75rem;
  ${fonts.size.regular()};
  ${fonts.weights.fw800()};
  line-height: 1rem;
  border-radius: 0.75rem;
`;

const TableContainer = styled.div`
  max-width: 65rem;
  height: 36rem;
  background-color: ${colors.white};
  border-radius: 1rem;
  margin-top: 2rem;
  box-shadow: 0px 0px 5px 0px ${colors.offersReceivedColors.offerCardShadowColor};
  overflow: hidden;
  padding: 1rem;
`;

const OffersTable = styled(Table)`
  &&& {
    height: 100%;

    & .ant-table-thead tr > th {
      background-color: transparent;
      color: ${colors.offersReceivedColors.storyName};
      ${fonts.family.SpoofRegular()};
      ${fonts.size.regular()};
      ${fonts.weights.fw400()};
      line-height: 1.87rem;
      border-bottom: 0.31rem solid ${colors.offersReceivedColors.tableHeadingBorder};

      &:first-of-type {
        width: 3.25rem;
      }

      &::before {
        display: none;
      }
    }

    & .ant-table-row td {
      color: ${colors.offersReceivedColors.storyName};
      ${fonts.family.SpoofRegular()};
      ${fonts.size.regular()};
      ${fonts.weights.fw400()};
      border-bottom: none;
    }
  }
`;

const OfferView = ({ userProfile, offerDetails, dispatchGetOfferDetails }: OfferViewProps) => {
  const location = useLocation();
  const [currentView, setCurrentView] = useState<'card' | 'list'>('card');
  const [sortBy, setSortBy] = useState<'ID' | 'DAYS_LEFT' | 'OFFER_AMOUNT'>('ID');
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('ASC');

  const params = new URLSearchParams(location.search);

  const storyTitle = params.get('title');

  const storyId = params.get('story');

  const getColumns = () => [
    {
      title: translate('number'),
      dataIndex: 'number',
      key: 'number',
      width: '8%'
    },
    {
      title: translate('production_house'),
      dataIndex: 'offerEntity',
      key: 'offerEntity',
      render: (_: any, record: any) => record?.offerEntity?.companyName
    },
    {
      title: translate('offer_made_by'),
      dataIndex: 'offerEntity',
      key: 'offerEntity',
      render: (_: any, record: any) => `${record?.offerEntity?.firstName} ${record?.offerEntity?.lastName}`
    },
    {
      title: translate('days_to_close_table'),
      dataIndex: 'daysToClose',
      key: 'daysToClose',
      sorter: true,
      align: 'center'
    },
    {
      title: translate('last_offer_amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (_: any, record: any) => (
        <OfferAmountContainer>
          {translate('offer_amount', { offerAmount: record?.amount?.toLocaleString('en-IN') })}
        </OfferAmountContainer>
      ),
      align: 'center',
      sorter: true
    },
    {
      title: '',
      dataIndex: 'viewOffer',
      key: 'viewOffer',
      render: (_: any, record: any) => (
        <Link
          to={`${routeConstants.dashboard.route}?activeTab=offer-history&offerId=${record?.offerId}&storyId=${storyId}&buyerId=${record?.offerEntity?.id}`}
        >
          <ViewOfferBtn>
            <StyledImage src={acceptOfferIcon} /> {translate('view_offer')}
          </ViewOfferBtn>
        </Link>
      ),
      align: 'center'
    }
  ];

  const columns: any = useMemo(() => getColumns(), []);

  useEffect(() => {
    if (userProfile?.id && storyId) {
      dispatchGetOfferDetails(storyId, userProfile?.id, sortBy, sortOrder);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile?.id && storyId) {
      dispatchGetOfferDetails(storyId, userProfile?.id, sortBy, sortOrder);
    }
  }, [sortBy, sortOrder]);

  const handleGoBack = () => {
    history.back();
  };

  const toggleView = () => {
    setCurrentView(currentView === 'card' ? 'list' : 'card');
  };

  const handleTableChange = (pagination: any, tableFilters: any, tableSorter: any) => {
    let sortKey = 'ID';
    let sortOrder = 'ASC';

    if (tableSorter) {
      if (tableSorter?.order) {
        if (tableSorter?.field === 'amount') {
          sortKey = 'OFFER_AMOUNT';
        } else if (tableSorter?.field === 'daysToClose') {
          sortKey = 'DAYS_LEFT';
        }

        sortOrder = tableSorter?.order === 'ascend' ? 'ASC' : 'DESC';
      }
    }

    setSortBy(sortKey as any);
    setSortOrder(sortOrder as any);
  };

  return (
    <OfferViewContainer data-testid="offer-view-container">
      <BackButton onClick={handleGoBack} data-testid="back-btn">
        <StyledImg src={ArrowLeft} />
        {translate('back')}
      </BackButton>
      <TitleContainer>
        <StoryNameTitle>{storyTitle}</StoryNameTitle>
        <ViewToggleBtn onClick={toggleView} data-testid="toggle-view-btn">
          <ViewText>{translate('view')}</ViewText>
          <If condition={currentView === 'card'} otherwise={<ViewIcon src={listViewIcon} />}>
            <ViewIcon src={cardViewIcon} />
          </If>
        </ViewToggleBtn>
      </TitleContainer>
      <If
        condition={currentView === 'list'}
        otherwise={
          <CardsContainer>
            {offerDetails.map((offer: any) => (
              <OfferCard offer={offer} storyId={storyId} key={offer.id} />
            ))}
          </CardsContainer>
        }
      >
        <TableContainer>
          <OffersTable
            dataSource={offerDetails.map((offer: any, index: number) => ({
              number: index + 1,
              ...offer
            }))}
            columns={columns}
            pagination={false}
            bordered={false}
            scroll={{ y: 400 }}
            onChange={handleTableChange}
          />
        </TableContainer>
      </If>
    </OfferViewContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile(),
  offerDetails: selectOfferDetails()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetOfferDetails: (storyId: string, sellerId: string, sortBy: string, sortOrder: string) =>
      dispatch(
        requestGetOfferDetails({
          storyId,
          sellerId,
          sortBy,
          sortOrder
        })
      )
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferView) as React.FC;

export const OfferViewTest = OfferView;
