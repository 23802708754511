import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PaymentCompleteModalProps } from './types';
import paymentDoneCheckmark from '@images/icons/circle-checked-icon.svg';
import routeConstants from '@app/utils/routeConstants';
import { useHistory } from 'react-router-dom';

const PaymentCompleteModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
`;

const PageTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.primary};
`;

const PaymentConfirmedCheckmark = styled.img`
  width: 4.5rem;
  height: 4.5rem;
  margin-top: 1.87rem;
`;

const SpotTicketBalanceContainer = styled.div`
  width: 31.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.87rem;
`;

const ContainerText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  line-height: 1.62rem;
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
`;

const CenterText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  line-height: 1.62rem;
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  text-align: center;
`;

const PaymentCompleteModal = ({ mode, spotTickets, storyId }: PaymentCompleteModalProps) => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push(`${routeConstants.copyrightContainer.route}?page=submission&storyId=${storyId}`);
    }, 2000);
  }, []);

  return (
    <PaymentCompleteModalContainer>
      <PageTitle>{translate('payment_confirmed')}</PageTitle>
      <PaymentConfirmedCheckmark src={paymentDoneCheckmark} />
      {mode === 'spot_ticket' ? (
        <SpotTicketBalanceContainer>
          <ContainerText>{translate('new_spot_balance')}</ContainerText>
          {/* <ContainerText>{translate('tickets_amount', { amount: spotTickets })}</ContainerText> */}
          <ContainerText>{translate('tickets_amount', { amount: 'Unlimited' })}</ContainerText>
        </SpotTicketBalanceContainer>
      ) : (
        <SpotTicketBalanceContainer>
          <CenterText>{translate('razorpay_copyright_payment_done')}</CenterText>
        </SpotTicketBalanceContainer>
      )}
    </PaymentCompleteModalContainer>
  );
};

export default PaymentCompleteModal;
