import { getMutationResponse, getQueryResponse } from '@app/utils/graphqlUtils';
import { AnyAction } from '@reduxjs/toolkit';
import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  failureGetDeletedDraft,
  failureGetDraftsData,
  failureGetPendingApprovalStories,
  failureGetPublishedStories,
  failureGetUnpublishedStories,
  requestGetDeletedDraft,
  requestGetDrafts,
  requestGetPendingApprovalStories,
  requestGetPublishedStories,
  requestGetUnpublishedStories,
  successGetDeletedDraft,
  successGetDraftsData,
  successGetPendingApprovalStories,
  successGetPublishedStories,
  successGetUnpublishedStories
} from './reducer';
import {
  GET_DELETED_DRAFTS,
  GET_DRAFT_STORIES,
  GET_PENDING_APPROVAL,
  GET_PUBLISHED_STORIES,
  GET_UNPUBLISHED_STORIES
} from './queries';
import moment from 'moment';
import { STORIES_STATES } from '@app/utils/constants';

export function* getDrafts(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      },
      filters: {
        where: {
          state: {
            in: action.payload.state
          }
        },
        asSeller: {
          withID: action.payload.sellerId
        }
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_DRAFT_STORIES, payload);

    if (ok) {
      const storiesData = data.stories.stories;
      const total = data.stories.total;

      let storiesDataList = [];
      if (storiesData) {
        storiesDataList = storiesData.map((story: any) => {
          let fieldsFilled = 0;
          if (story.languages) {
            fieldsFilled++;
          }
          if (story.genres) {
            fieldsFilled++;
          }
          if (story.storyType) {
            fieldsFilled++;
          }
          if (story.tags) {
            fieldsFilled++;
          }
          if (story.logline) {
            fieldsFilled++;
          }
          if (story.storyline) {
            fieldsFilled++;
          }
          if (story.title) {
            fieldsFilled++;
          }

          const date = moment(story.updatedAt).zone('GMT+0530').format('DD/MM/YYYY, HH:mm');

          return {
            id: story.id,
            title: story.title,
            date: date,
            progress: fieldsFilled,
            isCopyrightPending: story.state === STORIES_STATES.PENDING_APPROVAL
          };
        });
      }
      yield put(successGetDraftsData({ data: storiesDataList, total: total }));
    } else {
      yield put(failureGetDraftsData(error));
    }
  } catch (err) {
    yield put(failureGetDraftsData(err));
  }
}

export function* getPublishedStories(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      },
      filters: {
        where: {
          state: {
            in: action.payload.state
          }
        },
        asSeller: {
          withID: action.payload.sellerId
        }
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_PUBLISHED_STORIES, payload);

    if (ok) {
      const storiesData = data.stories.stories;
      const total = data.stories.total;

      yield put(successGetPublishedStories({ data: storiesData, total: total }));
    } else {
      yield put(failureGetPublishedStories(error));
    }
  } catch (err) {
    yield put(failureGetPublishedStories(err));
  }
}

export function* getUnpublishedStories(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      },
      filters: {
        where: {
          state: {
            in: action.payload.state
          }
        },
        asSeller: {
          withID: action.payload.sellerId
        }
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_UNPUBLISHED_STORIES, payload);

    if (ok) {
      const storiesData = data.stories.stories;
      const total = data.stories.total;

      yield put(successGetUnpublishedStories({ data: storiesData, total: total }));
    } else {
      yield put(failureGetUnpublishedStories(error));
    }
  } catch (err) {
    yield put(failureGetUnpublishedStories(err));
  }
}

export function* getPendingApprovals(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      },
      filters: {
        where: {
          state: {
            in: action.payload.state
          }
        },
        asSeller: {
          withID: action.payload.sellerId
        }
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_PENDING_APPROVAL, payload);

    if (ok) {
      const storiesData = data.stories.stories;
      const total = data.stories.total;

      let storiesDataList = [];
      if (storiesData) {
        storiesDataList = storiesData.map((story: any) => {
          const date = moment(story.createdAt).zone('GMT+0530').format('DD/MM/YYYY, HH:mm');

          return {
            id: story.id,
            title: story.title,
            date: date
          };
        });
      }
      yield put(successGetPendingApprovalStories({ data: storiesDataList, total: total }));
    } else {
      yield put(failureGetPendingApprovalStories(error));
    }
  } catch (err) {
    yield put(failureGetPendingApprovalStories(err));
  }
}

export function* getDeletedDrafts(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      },
      filters: {
        where: {
          state: {
            in: action.payload.state
          }
        },
        asSeller: {
          withID: action.payload.sellerId
        }
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_DELETED_DRAFTS, payload);

    if (ok) {
      const storiesData = data.stories.stories;
      const total = data.stories.total;

      let storiesDataList = [];
      if (storiesData) {
        storiesDataList = storiesData.map((story: any) => {
          let fieldsFilled = 0;
          story.languages && fieldsFilled++;
          story.genres && fieldsFilled++;
          story.storyType && fieldsFilled++;
          story.tags && fieldsFilled++;
          story.logline && fieldsFilled++;
          story.storyline && fieldsFilled++;
          story.title && fieldsFilled++;

          const date = moment(story.updatedAt).zone('GMT+0530').format('DD/MM/YYYY, HH:mm');

          return {
            id: story.id,
            title: story.title,
            progress: fieldsFilled,
            date: date,
            isCopyrightPending: story.state === STORIES_STATES.PENDING_APPROVAL
          };
        });
      }
      yield put(successGetDeletedDraft({ data: storiesDataList, total: total }));
    } else {
      yield put(failureGetDeletedDraft(error));
    }
  } catch (err) {
    yield put(failureGetDeletedDraft(err));
  }
}

export default function* mySubmissionSaga() {
  yield all([
    takeLatest(requestGetDrafts.toString(), getDrafts),
    takeLatest(requestGetPublishedStories.toString(), getPublishedStories),
    takeLatest(requestGetPendingApprovalStories.toString(), getPendingApprovals),
    takeLatest(requestGetUnpublishedStories.toString(), getUnpublishedStories),
    takeLatest(requestGetDeletedDraft.toString(), getDeletedDrafts)
  ]);
}

export const mySubmissionSagaArr = [
  takeLatest(requestGetDrafts.toString(), getDrafts),
  takeLatest(requestGetPublishedStories.toString(), getPublishedStories),
  takeLatest(requestGetPendingApprovalStories.toString(), getPendingApprovals),
  takeLatest(requestGetUnpublishedStories.toString(), getUnpublishedStories),
  takeLatest(requestGetDeletedDraft.toString(), getDeletedDrafts)
];
