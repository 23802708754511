import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';

export const initialState = {
  loading: false,
  accountStatusData: {},
  accountStatusError: null
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    requestAccountStatus(state) {
      state.loading = true;
    },
    successAccountStatus(state, action) {
      state.accountStatusError = null;
      state.accountStatusData = action.payload;
      state.loading = false;
    },
    failureAccountStatus(state, action) {
      state.accountStatusError = get(action.payload, 'message', 'something_went_wrong');
      state.loading = false;
    },
    resetState(state) {
      state.loading = false;
      state.accountStatusError = null;
      state.accountStatusData = {};
    }
  }
});

export const { requestAccountStatus, successAccountStatus, failureAccountStatus, resetState } = homeSlice.actions;

export default homeSlice.reducer;
