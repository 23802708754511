import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectPendingApprovalContainerDomain = (state: any) => state.pendingApproval || initialState;

export const selectStoryData = () =>
  createSelector(selectPendingApprovalContainerDomain, (substate) => get(substate, 'storyData'));

export const selectSignedUrl = () =>
  createSelector(selectPendingApprovalContainerDomain, (substate) => get(substate, 'currentFileUrl'));

export const selectGetFileUrlLoading = () =>
  createSelector(selectPendingApprovalContainerDomain, (substate) => get(substate, 'getFileUrlLoading'));
