import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Progress } from 'antd';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { If } from 'tsw-sdk';
import { CompilingLoaderProps } from '../../types';

const CompilingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: ${colors.white};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomProgressBar = styled(Progress)`
  width: 15rem;
  && {
    .ant-progress-success-bg,
    .ant-progress-bg {
      background-color: ${colors.myDashboardColors.viewText};
    }

    .ant-progress-inner {
      background-color: ${colors.cardBg};
    }
  }
`;

const CompilingContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const CompilingText = styled.h1`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.large()};
  line-height: 2.62rem;
  text-align: center;
`;

const CompilingLoader = ({ isSubmitted }: CompilingLoaderProps) => {
  const [progressPercent, setprogressPercent] = useState<number>(0);

  useEffect(() => {
    if (progressPercent === 100) {
      setprogressPercent(0);
    } else {
      setTimeout(() => setprogressPercent((prev) => prev + 1), 500);
    }
  }, [progressPercent]);

  return (
    <CompilingContainer>
      <CompilingContentContainer>
        <CustomProgressBar percent={progressPercent} showInfo={false} />
        <If condition={isSubmitted}>
          <CompilingText>{translate('compiling')}</CompilingText>
        </If>
      </CompilingContentContainer>
    </CompilingContainer>
  );
};

export default CompilingLoader;
