import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import downloadOfferIcon from '@images/icons/OffersReceived/download-offer.svg';

import { translate } from '@app/components/IntlGlobalProvider';
import _ from 'lodash';
import { ClosedOfferCardProps } from './types';
import moment from 'moment';
import { OFFER_STATES } from '@app/utils/constants';
import { useHistory } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';
import { If } from 'tsw-sdk';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import supportIcon from '@images/icons/OffersReceived/support-white.svg';

type ClosedOfferCardContainerProps = {
  clickable: boolean;
  isStoryLegal: boolean;
};

const ClosedOfferCardContainer = styled.div<ClosedOfferCardContainerProps>`
  width: 19.12rem;
  height: 15.87rem;
  display: flex;
  flex-direction: column;
  ${(props) => (props.isStoryLegal ? `background-color: ${colors.offersReceivedColors.scriptIncludedPillBg}` : '')};
  border-radius: 1rem;
  box-shadow: 0px 0px 20px ${colors.offersReceivedColors.offerCardShadowColor};
  overflow: hidden;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

const ClosedOfferCardHeader = styled.div`
  width: 100%;
  height: 5.25rem;
  background-color: ${colors.offersReceivedColors.offerCardHeaderBg};
  display: flex;
  padding-top: 1.12rem;
  padding-left: 1.2rem;
  padding-right: 1.06rem;
  position: relative;
  overflow: hidden;
`;

const ClosedOfferCardHeaderBg = styled.div`
  position: absolute;
  top: -1rem;
  left: -1rem;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
  z-index: 2;
`;

const ClosedOfferCardHeaderBgText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.xlarge()};
  color: ${colors.offersReceivedColors.cardHeaderBgTextColor};
  white-space: nowrap;
`;

const ClosedOfferCardBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const ClosedOfferCardBodyTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StoryNameTitle = styled.h3`
  width: 15rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw900()};
  color: ${colors.offersReceivedColors.storyName};
  margin-right: 0.68rem;
  line-height: 1.87rem;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

const DownloadOfferIcon = styled.img`
  width: 0.75rem;
  height: 0.75rem;
  cursor: pointer;
`;

const ClosedOfferCardDate = styled.p`
  width: 100%;
  margin-top: 0.5rem;
  ${fonts.weights.fw700()};
  ${fonts.size.xsmall()};
  line-height: 1rem;
  color: ${colors.offersReceivedColors.offerDateColor};
  text-align: center;
`;

const OfferAmountText = styled.p`
  ${fonts.size.extraSmall()};
  ${fonts.weights.fw800()};
  line-height: 1rem;
  color: ${colors.offersReceivedColors.offerDateColor};
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  text-align: center;
`;

const ClosedPill = styled.div`
  width: 6.87rem;
  height: 1.5rem;
  background-color: ${colors.offersReceivedColors.closedPillBg};
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${colors.offersReceivedColors.offerDateColor};
  ${fonts.size.regular()};
  ${fonts.weights.fw800()};
  line-height: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

const RejectedStateText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.big()};
  line-height: 1.875rem;
  text-align: center;
  color: ${colors.offersReceivedColors.rejectedStateColor};
  margin-top: 5px;
`;

const SupportButton = styled(Button)`
  && {
    padding: 0.75rem 2rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: ${colors.offersReceivedColors.supportButtonBg};
    ${fonts.weights.fw800()};
    ${fonts.size.small()};
    color: ${colors.offersReceivedColors.supportText};
    line-height: 1.5rem;
    letter-spacing: 2%;
    gap: 0.5rem;
    margin-top: 0.75rem;
  }
`;

const SupportIconImage = styled.img``;

const ClosedOfferCard = ({
  offer,
  currentLoadingStoryId,
  isStoryLegal,
  handleDownloadStory,
  openSupportModal
}: ClosedOfferCardProps) => {
  const history = useHistory();

  const openOffersPage = (offerId: string) => {
    history.push(
      `${routeConstants.dashboard.route}?activeTab=accepted-offer&storyId=${offer?.storyId}&title=${offer?.title}&offerId=${offerId}`
    );
  };

  return offer?.offers?.map((storyOffer: any) =>
    storyOffer?.offerState === OFFER_STATES.SELLER_ACCEPTED ||
    storyOffer?.offerState === OFFER_STATES.BUYER_ACCEPTED ? (
      <ClosedOfferCardContainer
        key={storyOffer.offerId}
        onClick={() => openOffersPage(storyOffer.offerId)}
        clickable={true}
        isStoryLegal={isStoryLegal}
      >
        <ClosedOfferCardHeader>
          <ClosedOfferCardHeaderBg>
            {_.range(10).map((number) => (
              <ClosedOfferCardHeaderBgText key={number}>{offer?.title}</ClosedOfferCardHeaderBgText>
            ))}
          </ClosedOfferCardHeaderBg>
        </ClosedOfferCardHeader>
        <ClosedOfferCardBody>
          <ClosedOfferCardBodyTitle>
            <StoryNameTitle>{offer?.title}</StoryNameTitle>
            <If
              condition={currentLoadingStoryId !== offer?.storyId}
              otherwise={<Spin indicator={<LoadingOutlined spin={true} />} />}
            >
              <DownloadOfferIcon
                src={downloadOfferIcon}
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleDownloadStory(offer?.storyId);
                }}
                data-testid="download-icon"
              />
            </If>
          </ClosedOfferCardBodyTitle>

          <ClosedOfferCardDate>
            {moment(storyOffer.createdAt).zone('GMT+0530').format('DD.MM.YYYY | HH:mm')}
          </ClosedOfferCardDate>

          <OfferAmountText>
            {translate('offer_amount', { offerAmount: storyOffer?.amount?.toLocaleString('en-IN') })}
          </OfferAmountText>
          <ClosedPill>{translate('closed')}</ClosedPill>
        </ClosedOfferCardBody>
      </ClosedOfferCardContainer>
    ) : (
      <ClosedOfferCardContainer key={storyOffer.id} clickable={false} isStoryLegal={isStoryLegal}>
        <ClosedOfferCardHeader>
          <ClosedOfferCardHeaderBg>
            {_.range(10).map((number) => (
              <ClosedOfferCardHeaderBgText key={number}>{offer?.title}</ClosedOfferCardHeaderBgText>
            ))}
          </ClosedOfferCardHeaderBg>
        </ClosedOfferCardHeader>
        <ClosedOfferCardBody>
          <ClosedOfferCardBodyTitle>
            <StoryNameTitle>{offer?.title}</StoryNameTitle>
            <DownloadOfferIcon
              src={downloadOfferIcon}
              onClick={(e: any) => {
                e.stopPropagation();
                handleDownloadStory(offer?.storyId);
              }}
              data-testid="download-icon"
            />
          </ClosedOfferCardBodyTitle>

          <ClosedOfferCardDate>
            {moment(storyOffer.createdAt).zone('GMT+0530').format('DD.MM.YYYY | HH:mm')}
          </ClosedOfferCardDate>

          <If condition={isStoryLegal}>
            <OfferAmountText>
              {translate('offer_amount', { offerAmount: storyOffer?.amount?.toLocaleString('en-IN') })}
            </OfferAmountText>
            <ClosedPill>{translate('closed')}</ClosedPill>
          </If>

          <If condition={!isStoryLegal}>
            <RejectedStateText>{storyOffer?.notes}</RejectedStateText>
            <SupportButton onClick={openSupportModal} data-testid="closed-support-btn">
              <SupportIconImage src={supportIcon} />
              {translate('support')}
            </SupportButton>
          </If>
        </ClosedOfferCardBody>
      </ClosedOfferCardContainer>
    )
  );
};

export default ClosedOfferCard;
