import { gql } from 'apollo-boost';

export const GET_LANGUAGE_GENRE = gql`
  query LanguagesAndGenres($pagination: LinguisticsPagination!) {
    languages(pagination: $pagination) {
      languageData {
        id
        name
      }
      total
    }
    genres(pagination: $pagination) {
      genreData {
        id
        name
      }
      total
    }
  }
`;

export const GET_TAGS = gql`
  query Tags($pagination: LinguisticsPagination!) {
    tags(pagination: $pagination) {
      tagsData {
        id
        name
      }
      total
    }
  }
`;

export const GET_STORY_TYPES = gql`
  query StoryTypes($pagination: LinguisticsPagination!) {
    storyTypes(pagination: $pagination) {
      storyTypeData {
        id
        name
      }
      total
    }
  }
`;

export const SUBMIT_STORY = gql`
  mutation SubmitStory($storySubmissionInput: StorySubmissionInput!) {
    updateStorySubmission(input: $storySubmissionInput) {
      response
      id
      supportingMaterials {
        id
        fileName
        attachmentType
      }
    }
  }
`;

export const GET_SCREENPLAY_FILE_NAME = gql`
  mutation UploadStoryScreenPlay {
    uploadStoryScreenPlay {
      filepath
      fileName
      extensions
    }
  }
`;

export const GET_NARRATION_FILE_NAME = gql`
  mutation UploadStoryAudioNarration {
    uploadStoryAudioNarration {
      filepath
      fileName
      extensions
    }
  }
`;

export const GET_SUPPORT_IMAGES_FILE_NAME = gql`
  mutation uploadStorySupportingImage {
    uploadStorySupportingImage {
      filepath
      fileName
      extensions
    }
  }
`;

export const GET_DRAFTED_STORY = gql`
  query Stories($filters: NewStoryFilters, $pagination: StoryPagination!) {
    stories(filters: $filters, pagination: $pagination) {
      stories {
        id
        storyline
        logline
        title
        storyType {
          id
          name
        }
        tags {
          id
          name
        }
        languages {
          id
          name
        }
        genres {
          id
          name
        }
        supportingMaterials {
          id
          fileName
          attachmentType
        }
        expectedOffer
        coAuthor1
        coAuthor2
      }
      total
    }
  }
`;

export const GET_SELF_ATTESTED_STORY = gql`
  query GetSelfAttestedStory {
    hasSelfAttestedStory
  }
`;
