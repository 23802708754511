import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  requestLoginUser,
  successLoginUser,
  failureLoginUser,
  successReCaptcha,
  failureRecaptcha,
  requestVerifyRecaptcha,
  requestSendEmail,
  successSendMail,
  failureSendMail,
  requestResetPassword,
  successResetPassword,
  failureResetPassword,
  requestSendOtp,
  failureSendOtp,
  requestVerifyOtp,
  successRefreshToken,
  requestRefreshToken
} from './reducer';
import { cognito } from 'tsw-sdk';
import { getMutationResponse, getQueryResponse, setClient } from '@utils/graphqlUtils';
import { VERIFY_RECAPTCHA, SellerAccountStatus } from '@utils/queries';
import { successSetJWTToken } from '../TokenProvider/reducer';
import history from '@app/utils/history';
import routeConstants from '@app/utils/routeConstants';
import get from 'lodash-es/get';
import { translate } from '@app/components/IntlGlobalProvider';
import { notification } from 'antd';
import jwtDecode from 'jwt-decode';

const {
  createCognitoUserPool,
  login,
  forgotPassword,
  confirmPassword,
  resendConfirmationCode,
  confirmSignUp,
  refreshToken
} = cognito;
const userPoolId = process.env.USER_POOL_ID as string;
const userPoolClientId = process.env.USER_POOL_CLIENT_ID as string;

export function* loginUser(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);
    const data = yield call(login, action.payload.email, action.payload.password);
    yield put(successLoginUser(data));
    yield put(successSetJWTToken(data.payload));
    setClient(data.payload.accessToken);

    const response = yield call(getQueryResponse, SellerAccountStatus, {});

    if (response.ok) {
      const paymentStatus = response.data;

      const sellerAccountStatus = get(paymentStatus, 'sellerAccountStatus', {});
      if (!sellerAccountStatus.isRegistrationComplete) {
        history.push(routeConstants.profile.route);
      } else {
        history.push(routeConstants.dashboard.route);
      }
    }
  } catch (err) {
    const error = err;

    yield put(failureLoginUser(error));
  }
}
export function* getRecaptchaVerify(action: any): Generator<any, any, any> {
  const payload = {
    input: {
      token: action.payload.value
    }
  };
  try {
    const data = yield call(getMutationResponse, VERIFY_RECAPTCHA, payload);
    yield put(successReCaptcha(data));
  } catch (err) {
    yield put(failureRecaptcha(err));
  }
}
export function* sendEmail(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);
    const data = yield call(forgotPassword, action.payload.email);
    yield put(successSendMail(data));
  } catch (err) {
    const error = err;
    yield put(failureSendMail(error));
  }
}
export function* resetPassword(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);

    yield call(login, action.payload.email, action.payload.password);

    notification.open({
      message: translate('error'),
      description: translate('new_password_conflict_error')
    });

    yield put(failureResetPassword({ message: '' }));
  } catch (err: any) {
    if (err?.code === 'NotAuthorizedException') {
      try {
        const data = yield call(confirmPassword, action.payload.email, action.payload.otp, action.payload.password);
        yield put(successResetPassword(data));
        notification.open({
          message: 'Password changed successfully!',
          description: 'Login with new password.'
        });
        history.push(routeConstants.login.route);
      } catch (err) {
        yield put(failureResetPassword(err));
      }
    } else {
      yield put(failureResetPassword(err));
    }
  }
}

export function* resendConfirmation(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);

    const data = yield call(resendConfirmationCode, action.payload);

    yield put(successSendMail(data));
  } catch (err) {
    const error = err;

    yield put(failureSendMail(error));
  }
}

export function* getVerifyOTPDetails(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);
    const data = yield call(confirmSignUp, action.payload.email, action.payload.otp);
    yield put(successLoginUser(data));
    history.push(routeConstants.profile.route);
  } catch (err) {
    yield put(failureSendOtp(err));
  }
}

export function* renewToken(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);

    const response = yield call(refreshToken, action.payload.email, action.payload.refreshToken);

    const decoded: any = jwtDecode(get(response.payload, 'accessToken', null));

    if (decoded['cognito:groups']) {
      yield put(successLoginUser(response));
      yield put(successSetJWTToken(response.payload));
      yield put(successRefreshToken());

      setClient(response.payload.accessToken);
    }
  } catch (err) {
    yield put(failureLoginUser(err));
  }
}

// Individual exports for testing
export default function* loginContainerSaga() {
  yield all([
    takeLatest(requestLoginUser.toString(), loginUser),
    takeLatest(requestVerifyRecaptcha.toString(), getRecaptchaVerify),
    takeLatest(requestSendEmail.toString(), sendEmail),
    takeLatest(requestResetPassword.toString(), resetPassword),
    takeLatest(requestSendOtp.toString(), resendConfirmation),
    takeLatest(requestVerifyOtp.toString(), getVerifyOTPDetails),
    takeLatest(requestRefreshToken.toString(), renewToken)
  ]);
}

export const loginContainerSagaArr = [
  takeLatest(requestLoginUser.toString(), loginUser),
  takeLatest(requestVerifyRecaptcha.toString(), getRecaptchaVerify),
  takeLatest(requestSendEmail.toString(), sendEmail),
  takeLatest(requestResetPassword.toString(), resetPassword),
  takeLatest(requestSendOtp.toString(), resendConfirmation),
  takeLatest(requestVerifyOtp.toString(), getVerifyOTPDetails),
  takeLatest(requestRefreshToken.toString(), renewToken)
];
