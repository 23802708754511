import { Empty } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { translate } from '../IntlGlobalProvider';
import { CustomEmptyProps } from './types';

const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem;
`;

const StyledEmpty = styled(Empty)`
  &&&& {
    margin: auto;
  }
`;

const CustomEmpty = ({ text }: CustomEmptyProps) => {
  return (
    <EmptyWrapper>
      <StyledEmpty description={<span>{translate(text)}</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </EmptyWrapper>
  );
};

export default CustomEmpty;
