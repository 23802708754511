import { getQueryResponse } from '@app/utils/graphqlUtils';
import { SellerAccountStatus } from '@app/utils/queries';
import { get } from 'lodash-es';
import { put, takeLatest, all, call } from 'redux-saga/effects';

import { requestAccountStatus, failureAccountStatus, successAccountStatus } from './reducer';

export function* getAccountStatus(): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getQueryResponse, SellerAccountStatus);

    if (ok) {
      const sellerAccountStatus = get(data, 'sellerAccountStatus', {});

      yield put(successAccountStatus(sellerAccountStatus));
    } else {
      yield put(failureAccountStatus(error));
    }
  } catch (err) {
    yield put(failureAccountStatus(err));
  }
}
// Individual exports for testing
export default function* homeContainerSaga() {
  yield all([takeLatest(requestAccountStatus.toString(), getAccountStatus)]);
}

export const homeContainerSagaArr = [takeLatest(requestAccountStatus.toString(), getAccountStatus)];
