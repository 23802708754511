import { OFFER_ACTIONS, SELLER } from '@app/utils/constants';
import { getMutationResponse, getQueryResponse } from '@app/utils/graphqlUtils';
import history from '@app/utils/history';
import routeConstants from '@app/utils/routeConstants';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get } from 'lodash-es';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  CONCLUDE_OFFER,
  COUNTER_OFFER,
  GET_CONVERSATION_HISTORY,
  GET_OFFER_HISTORY,
  SEND_REQUEST_MESSAGE
} from './queries';
import {
  failureConcludeOffer,
  failureCounterOffer,
  failureGetConversationData,
  failureGetTransactionData,
  failureSendMessage,
  requestConcludeOffer,
  requestCounterOffer,
  requestGetConversationData,
  requestGetTransactionData,
  requestSendMessage,
  successConcludeOffer,
  successCounterOffer,
  successGetConversationData,
  successGetTransactionData,
  successSendMessage
} from './reducer';

export function* getTransactionData(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId,
        sellerId: action.payload.sellerId,
        buyerId: action.payload.buyerId,
        isBuyer: false
      },
      pagination: {
        limit: 0,
        page: 1
      }
    };
    const { ok, data, error } = yield call(getQueryResponse, GET_OFFER_HISTORY, payload);

    if (ok) {
      const offerDetailsHistory = get(data, 'offerDetailsHistory', {});

      yield put(successGetTransactionData(offerDetailsHistory));
    } else {
      yield put(failureGetTransactionData(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureGetTransactionData(get(e, 'message', 'something_went_wrong')));
  }
}

export function* counterOffer(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId,
        offerId: action.payload.offerId,
        offerEntity: {
          buyerId: action.payload.buyerId,
          sellerId: action.payload.sellerId,
          placedBy: SELLER
        },
        amount: action.payload.amount,
        notes: action.payload.notes
      }
    };

    const response = yield call(getMutationResponse, COUNTER_OFFER, payload);

    if (response.ok) {
      yield put(successCounterOffer());

      const payload2 = {
        input: {
          storyId: action.payload.storyId,
          sellerId: action.payload.sellerId,
          buyerId: action.payload.buyerId,
          isBuyer: false
        },
        pagination: {
          limit: 0,
          page: 1
        }
      };

      const { ok, data, error } = yield call(getQueryResponse, GET_OFFER_HISTORY, payload2);

      if (ok) {
        const offerDetailsHistory = get(data, 'offerDetailsHistory', {});

        yield put(successGetTransactionData(offerDetailsHistory));
        history.push(
          `${routeConstants.dashboard.route}?activeTab=offer-history&offerId=${action.payload.offerId}&storyId=${action.payload.storyId}&buyerId=${action.payload.buyerId}`
        );
      } else {
        yield put(failureGetTransactionData(get(error, 'message', 'something_went_wrong')));
        message.error(get(error, 'message', 'something_went_wrong'));
      }
    } else {
      yield put(failureCounterOffer(get(response.error, 'message', 'something_went_wrong')));
      message.error(get(response.error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureCounterOffer(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export function* concludeOffer(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId,
        offerId: action.payload.offerId,
        offerEntity: {
          buyerId: action.payload.buyerId,
          sellerId: action.payload.sellerId,
          concludedBy: SELLER
        },
        action: action.payload.action,
        reason: action.payload.reason
      }
    };

    const response = yield call(getMutationResponse, CONCLUDE_OFFER, payload);

    if (response.ok) {
      yield put(successConcludeOffer());

      const payload2 = {
        input: {
          storyId: action.payload.storyId,
          sellerId: action.payload.sellerId,
          buyerId: action.payload.buyerId,
          isBuyer: false
        },
        pagination: {
          limit: 0,
          page: 1
        }
      };

      const { ok, data, error } = yield call(getQueryResponse, GET_OFFER_HISTORY, payload2);

      if (ok) {
        const offerDetailsHistory = get(data, 'offerDetailsHistory', {});

        yield put(successGetTransactionData(offerDetailsHistory));
        if (action.payload.action === OFFER_ACTIONS.ACCEPT_OFFER) {
          history.push(
            `${routeConstants.dashboard.route}?activeTab=celebration-time&offerId=${action.payload.offerId}&storyId=${action.payload.storyId}`
          );
        } else {
          history.push(
            `${routeConstants.dashboard.route}?activeTab=offer-history&offerId=${action.payload.offerId}&storyId=${action.payload.storyId}&buyerId=${action.payload.buyerId}`
          );
        }
      } else {
        yield put(failureGetTransactionData(get(error, 'message', 'something_went_wrong')));
        message.error(get(error, 'message', 'something_went_wrong'));
      }
    } else {
      yield put(failureConcludeOffer(get(response.error, 'message', 'something_went_wrong')));
      message.error(get(response.error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureConcludeOffer(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export function* getConversationData(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyID: action.payload.storyId,
        sellerID: action.payload.sellerId,
        buyerID: action.payload.buyerId,
        isBuyer: false
      },
      pagination: {
        limit: 0,
        page: 1
      }
    };
    const { ok, data, error } = yield call(getQueryResponse, GET_CONVERSATION_HISTORY, payload);

    if (ok) {
      const conversationHistory = get(data, 'conversationHistory', {});

      yield put(successGetConversationData(conversationHistory));
    } else {
      yield put(failureGetConversationData(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureGetConversationData(get(e, 'message', 'something_went_wrong')));
  }
}

export function* mutateSendMessage(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        conversationEntity: {
          isBuyer: false,
          buyerId: action.payload.buyerId,
          sellerId: action.payload.sellerId
        },
        storyId: action.payload.storyId,
        message: action.payload.message,
        type: action.payload.type,
        state: action.payload.state
      }
    };

    const { ok, error } = yield call(getMutationResponse, SEND_REQUEST_MESSAGE, payload);
    if (ok) {
      message.success('success');
      yield put(successSendMessage());

      const ConversationPayload = {
        input: {
          storyID: action.payload.storyId,
          sellerID: action.payload.sellerId,
          buyerID: action.payload.buyerId,
          isBuyer: false
        },
        pagination: {
          limit: 0,
          page: 1
        }
      };

      const { ok, data, error } = yield call(getQueryResponse, GET_CONVERSATION_HISTORY, ConversationPayload);

      if (ok) {
        const conversationHistory = get(data, 'conversationHistory', {});

        yield put(successGetConversationData(conversationHistory));
      } else {
        yield put(failureGetConversationData(get(error, 'message', 'something_went_wrong')));
      }
    } else {
      yield put(failureSendMessage(error));
    }
  } catch (e: any) {
    yield put(failureSendMessage(e));
  }
}

export default function* offerHistoryContainerSaga() {
  yield all([
    takeLatest(requestGetTransactionData.toString(), getTransactionData),
    takeLatest(requestCounterOffer.toString(), counterOffer),
    takeLatest(requestConcludeOffer.toString(), concludeOffer),
    takeLatest(requestGetConversationData.toString(), getConversationData),
    takeLatest(requestSendMessage.toString(), mutateSendMessage)
  ]);
}

export const offerHistoryContainerSagaArr = [
  takeLatest(requestGetTransactionData.toString(), getTransactionData),
  takeLatest(requestCounterOffer.toString(), counterOffer),
  takeLatest(requestConcludeOffer.toString(), concludeOffer),
  takeLatest(requestGetConversationData.toString(), getConversationData),
  takeLatest(requestSendMessage.toString(), mutateSendMessage)
];
