import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ScreenplayAccessModalProps } from '../../types';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { REQUEST_STATE } from '@app/utils/constants';

const RequestAccessModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 6.5rem 26.4rem 8.81rem 26.4rem;
  background: ${colors.white};
`;

const RequestAccessContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h1`
  ${fonts.family.SpoofFamily};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  text-align: center;
  color: ${colors.primary};
  margin-bottom: 0px;
`;

const StyledLabel = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  color: ${colors.tagBorder};
`;

const FormActionsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotesFieldContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    min-height: 1.93rem;
    > * {
      background-color: ${colors.white} !important;
      border: 0.5px solid ${colors.lightBorder};
      border-radius: 0.31rem;
      margin-top: 0.5rem;

      &.ant-input {
        line-height: 0.94rem;
        min-height: 1.93rem;
      }

      &.ant-input:placeholder-shown {
        color: ${colors.lightBorder};
        font-size: 0.75rem;
        ${fonts.weights.fw400};
        line-height: 0.94rem;
      }

      &.textarea.ant-input {
        min-height: 1.93rem;
        background-color: ${colors.white};
      }
    }
    &.ant-input-textarea-show-count::after {
      margin-top: 0.25rem;
      float: left;
      ${fonts.size.extraSmall()};
      line-height: 0.62rem;
      ${fonts.weights.fw400};
      content: ' ' attr(data-count) ' characters ';
      color: ${colors.lightBorder};
    }
  }
`;

const DeclineBtn = styled(Button)`
  width: 7.81rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.offersReceivedColors.grayBtnBg};
  color: ${colors.offersReceivedColors.grayBtnColor};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.offersReceivedColors.grayBtnBg};
    color: ${colors.offersReceivedColors.grayBtnColor};
    border: none;
  }

  &:focus {
    background-color: ${colors.offersReceivedColors.grayBtnBg};
    color: ${colors.offersReceivedColors.grayBtnColor};
    border: none;
  }
`;

const RetractOfferBtn = styled(Button)`
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  width: 4.25rem;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
`;

const StyledImg = styled.img``;

const ScreenplayAccessModal = ({ handleBack, handleSendResponse }: ScreenplayAccessModalProps) => {
  const [responseMessage, setResponseMessage] = useState<string>('');

  const accessResponseHandler = () => {
    if (responseMessage.trim()) {
      handleSendResponse(responseMessage.trim(), REQUEST_STATE.APPROVED);
    } else {
      message.error(translate('cannot_be_empty'));
    }
  };

  const declineResponseHandler = () => {
    if (responseMessage.trim()) {
      handleSendResponse(responseMessage.trim(), REQUEST_STATE.DECLINED);
    } else {
      message.error(translate('cannot_be_empty'));
    }
  };

  return (
    <RequestAccessModalContainer>
      <BackButton onClick={handleBack}>
        <StyledImg src={ArrowLeft} />
        {translate('back')}
      </BackButton>
      <RequestAccessContentContainer>
        <Heading>{translate('request_access_heading', { storyTitle: 'storyTitle' })}</Heading>
        <NotesFieldContainer>
          <StyledLabel>{translate('notes_for_producer')}</StyledLabel>
          <StyledTextArea
            rows={1}
            autoSize
            showCount
            maxLength={250}
            value={responseMessage}
            onChange={(e) => setResponseMessage(e.target.value)}
            onPaste={(e) => {
              const clipboardData = e.clipboardData;
              const pastedData = clipboardData.getData('Text');

              if (pastedData.length > 250) {
                message.error(translate('250_chars_limit_error'));
              }
            }}
            data-testid="response-access-notes-input"
          />
        </NotesFieldContainer>
        <FormActionsContainer>
          <DeclineBtn onClick={declineResponseHandler} data-testid="response-access-decline">
            {translate('decline')}
          </DeclineBtn>
          <RetractOfferBtn onClick={accessResponseHandler} data-testid="response-access-accept">
            {translate('accept')}
          </RetractOfferBtn>
        </FormActionsContainer>
      </RequestAccessContentContainer>
    </RequestAccessModalContainer>
  );
};

export default ScreenplayAccessModal;
