import React from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import { CustomButton } from 'tsw-sdk';
import { ConfirmProceedSubmissionProps } from '../../containers/CopyrightContainer/CopyrightProtection/CopyrightStepsFlow/components/Step5/types';
import { ArrowRightOutlined } from '@ant-design/icons';

const ConfirmProceedSubmissionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  z-index: 1000;
`;

const Title = styled.p`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  text-align: center;
  color: ${colors.primary};
`;

const ConfirmText = styled.p`
  width: 75%;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.big()};
  ${fonts.weights.fw700()};
  line-height: 1.62rem;
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-top: 3.31rem;
`;

const ButtonsContainer = styled.div`
  margin-top: 4.62rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GoBackBtn = styled(CustomButton)`
  &&& {
    width: 15rem;
    height: 2.75rem;
    background-color: ${colors.copyrightColors.goBackBtnColor};
    color: ${colors.white};
    ${fonts.size.regular()};
    ${fonts.weights.fw800()};
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ProceedBtn = styled(CustomButton)`
  &&& {
    width: 15rem;
    height: 2.75rem;
    background-color: ${colors.primary};
    color: ${colors.white};
    ${fonts.size.regular()};
    ${fonts.weights.fw800()};
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3.12rem;
  }
`;

const ConfirmProceedSubmission = ({ handleProceed, handleGoBack }: ConfirmProceedSubmissionProps) => {
  return (
    <ConfirmProceedSubmissionContainer
      data-testid="confirm-proceed-submission-container"
      id="confirm-submission-modal-container"
    >
      <Title>{translate('proceed_to_submission')}</Title>
      <ConfirmText>{translate('confirm_to_submission')}</ConfirmText>
      <ButtonsContainer>
        <GoBackBtn onClick={handleGoBack} data-testid="confirm-submission-go-back-btn">
          {translate('go_back')}
        </GoBackBtn>
        <ProceedBtn onClick={handleProceed} data-testid="confirm-submission-btn">
          {translate('proceed')} <ArrowRightOutlined />
        </ProceedBtn>
      </ButtonsContainer>
    </ConfirmProceedSubmissionContainer>
  );
};

export default ConfirmProceedSubmission;
