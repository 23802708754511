import React, { useEffect } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import celebrationCheckmarkIcon from '@images/icons/OffersReceived/celebration-checkmark.svg';
import { useHistory, useLocation } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';

const CelebrationTimeContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: ${colors.white};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CelebrationTimeContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const CelebrationTimeText = styled.h1`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.large()};
  line-height: 2.62rem;
  text-align: center;
  margin-top: 0px;
`;

const StyledImg = styled.img``;

const CelebrationTime = () => {
  const history = useHistory();

  const location: any = useLocation();

  const params = new URLSearchParams(location.search);

  const offerId = params.get('offerId');
  const storyId = params.get('storyId');

  useEffect(() => {
    setTimeout(() => {
      history.push(`${routeConstants.dashboard.route}?activeTab=accepted-offer&offerId=${offerId}&storyId=${storyId}`);
    }, 2000);
  }, []);

  return (
    <CelebrationTimeContainer>
      <CelebrationTimeContentContainer>
        <StyledImg src={celebrationCheckmarkIcon} />
        <CelebrationTimeText>{translate('celebration_time')}</CelebrationTimeText>
      </CelebrationTimeContentContainer>
    </CelebrationTimeContainer>
  );
};

export default CelebrationTime;
