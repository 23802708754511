import { prepare } from '@app/utils';
import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';

export const initialState = {
  offerDetails: [],
  getOfferDetailsError: null,
  getOfferDetailsLoading: false
};

const offersReceivedSlice = createSlice({
  name: 'offersReceived',
  initialState,
  reducers: {
    requestGetOfferDetails: {
      reducer: (state) => {
        state.getOfferDetailsLoading = true;
      },
      prepare
    },
    successGetOfferDetails(state, action) {
      state.getOfferDetailsLoading = false;
      state.getOfferDetailsError = null;
      state.offerDetails = action.payload;
    },
    failureGetOfferDetails(state, action) {
      state.getOfferDetailsLoading = false;
      state.getOfferDetailsError = get(action.payload, 'message', 'failure');
    },
    resetState(state) {
      state.offerDetails = [];
      state.getOfferDetailsError = null;
      state.getOfferDetailsLoading = false;
    }
  }
});

export const { requestGetOfferDetails, successGetOfferDetails, failureGetOfferDetails, resetState } =
  offersReceivedSlice.actions;

export default offersReceivedSlice.reducer;
