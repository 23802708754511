import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isProd } from '@utils/index';

export function initSentry() {
  if (isProd()) {
    Sentry.init({
      release: process.env.SENTRY_RELEASE,
      environment: process.env.ENVIRONMENT_NAME,
      dsn: process.env.SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0
    });
  }
}

export function reportError(error: any, errorInfo: any) {
  Sentry.captureException(error, { extra: errorInfo });
}
