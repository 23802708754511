import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import jwtDecode from 'jwt-decode';

export interface TokenReducerStateProps {
  jwtToken: object | null;
  accessToken: string | null;
  refreshToken: string | null;
  credential: string | null;
}
export const initialState = {
  idToken: null,
  accessToken: null,
  refreshToken: null,
  credential: null
};
const tokenProviderSlice = createSlice({
  name: 'jwtToken',
  initialState,
  reducers: {
    successSetJWTToken(state, action) {
      state.accessToken = jwtDecode(get(action.payload, 'accessToken', null));
      state.idToken = get(action.payload, 'idToken', null);
      state.refreshToken = get(action.payload, 'refreshToken', null);
      state.credential = get(action.payload, 'accessToken', null);
    },
    successSetCredential(state, action) {
      state.credential = action.payload;
    },
    failureGetJWTToken(state) {
      state.accessToken = null;
      state.idToken = null;
      state.refreshToken = null;
      state.credential = null;
    },
    resetAuthState: () => initialState
  }
});
export const { successSetJWTToken, failureGetJWTToken, resetAuthState, successSetCredential } =
  tokenProviderSlice.actions;
export default tokenProviderSlice.reducer;
