import routeConstants from './routeConstants';

const DEFAULT_PUNCTUATION = [
  ',',
  '，',
  '.',
  '。',
  ':',
  '：',
  ';',
  '；',
  '[',
  ']',
  '【',
  ']',
  '】',
  '{',
  '｛',
  '}',
  '｝',
  '(',
  '（',
  ')',
  '）',
  '<',
  '《',
  '>',
  '》',
  '$',
  '￥',
  '!',
  '！',
  '?',
  '？',
  '~',
  '～',
  "'",
  '’',
  '"',
  '“',
  '”',
  '*',
  '/',
  '\\',
  '&',
  '%',
  '@',
  '#',
  '^',
  '、',
  '、',
  '、',
  '、'
];

const EMPTY_RESULT = {
  words: [],
  count: 0
};

type showHeadertype = boolean | undefined;

export const shouldShowHeader = (path: string) => {
  let showHeader: showHeadertype = true;

  Object.keys(routeConstants).forEach((route) => {
    if (routeConstants[route].route === path) {
      showHeader = routeConstants[route].showHeader;
    }
  });

  return showHeader;
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr: any = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const validateFileSize = (file: any, expectedSizeInMb: number) => {
  const expectedSizeInBytes = expectedSizeInMb * 1000000;
  return file.size < expectedSizeInBytes;
};

export const cleanUpRoute = (route: string) => {
  let newRoute: any = route.split('/');

  if (newRoute[newRoute.length - 1] === '/') {
    newRoute.pop();
  }

  newRoute = newRoute.join('/');

  return newRoute;
};

export const allowOnlyChars = (e: any) => {
  const regex = /[A-Za-z ]/;

  if (!regex.test(e.key)) {
    e.preventDefault();
  }
};

export const preventInvalidCharsNumberInput = (e: any) => {
  if (e.key === '-' || e.key.toLowerCase() === 'e' || e.key === '.') {
    e.preventDefault();
  }
};

const wordsDetect = (text: string, config: any) => {
  if (!text) {
    return EMPTY_RESULT;
  }
  let words: any = String(text);
  if (words.trim() === '') {
    return EMPTY_RESULT;
  }
  const punctuationReplacer = config.punctuationAsBreaker ? ' ' : '';
  const defaultPunctuations = config.disableDefaultPunctuation ? [] : DEFAULT_PUNCTUATION;
  const customizedPunctuations = config.punctuation || [];
  const combinedPunctionations = defaultPunctuations.concat(customizedPunctuations);
  // Remove punctuations or change to empty space
  combinedPunctionations.forEach(function (punctuation) {
    const punctuationReg = new RegExp('\\' + punctuation, 'g');
    words = words.replace(punctuationReg, punctuationReplacer);
  });
  // Remove all kind of symbols
  words = words.replace(/[\uFF00-\uFFEF\u2000-\u206F]/g, '');
  // Format white space character
  words = words.replace(/\s+/, ' ');
  // Split words by white space (For European languages)
  words = words.split(' ');
  words = words.filter((word: string) => word.trim());
  // Match latin, cyrillic, Malayalam letters and numbers
  const common =
    '(\\d+)|[a-zA-Z\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u0250-\u02AF\u1E00-\u1EFF\u0400-\u04FF\u0500-\u052F\u0D00-\u0D7F]+|';
  // Match Chinese Hànzì, the Japanese Kanji and the Korean Hanja
  const cjk =
    '\u2E80-\u2EFF\u2F00-\u2FDF\u3000-\u303F\u31C0-\u31EF\u3200-\u32FF\u3300-\u33FF\u3400-\u3FFF\u4000-\u4DBF\u4E00-\u4FFF\u5000-\u5FFF\u6000-\u6FFF\u7000-\u7FFF\u8000-\u8FFF\u9000-\u9FFF\uF900-\uFAFF';
  // Match Japanese Hiragana, Katakana, Rōmaji
  const jp = '\u3040-\u309F\u30A0-\u30FF\u31F0-\u31FF\u3190-\u319F';
  // Match Korean Hangul
  const kr = '\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uAFFF\uB000-\uBFFF\uC000-\uCFFF\uD000-\uD7AF\uD7B0-\uD7FF';

  // eslint-disable-next-line no-misleading-character-class
  const reg = new RegExp(common + '[' + cjk + jp + kr + ']', 'g');

  let detectedWords: string[] = [];
  words.forEach(function (word: string) {
    const carry: string[] = [];
    let matched;
    do {
      matched = reg.exec(word);
      if (matched) {
        carry.push(matched[0]);
      }
    } while (matched);
    if (carry.length === 0) {
      detectedWords.push(word);
    } else {
      detectedWords = detectedWords.concat(carry);
    }
  });
  return {
    words: detectedWords,
    count: detectedWords.length
  };
};

export const wordsCount = (text: string, config = {}) => {
  const { count } = wordsDetect(text, config);
  return count;
};

export const handleScreenScaling = () => {
  const MOUNT_NODE: any = document.getElementById('app');

  const width = window.innerWidth;

  const scaleFactor = getScaleFactor(width);

  MOUNT_NODE.style.transform = `scale(${scaleFactor})`;
  MOUNT_NODE.style.transformOrigin = '0 0';

  const scaleFactorToPercentage = ((10 - scaleFactor * 10) / 10) * 100;

  MOUNT_NODE.style.minWidth = `${
    100 + (scaleFactor !== 1 ? scaleFactorToPercentage + getAdditionalPercentage(scaleFactor) : 0)
  }%`;
  MOUNT_NODE.style.minHeight = `${
    100 + (scaleFactor !== 1 ? scaleFactorToPercentage + getAdditionalPercentage(scaleFactor) : 0)
  }%`;
};

const getAdditionalPercentage = (scaleFactor: number) => {
  let additionalPercentage = 0;

  if (scaleFactor === 1) {
    additionalPercentage = 0;
  } else if (scaleFactor === 0.8) {
    additionalPercentage = 5;
  } else if (scaleFactor === 0.7) {
    additionalPercentage = 12.9;
  } else if (scaleFactor === 0.5) {
    additionalPercentage = 50;
  } else if (scaleFactor === 0.4) {
    additionalPercentage = 100;
  }

  return additionalPercentage;
};

export const getHeightOfContainer = () => {
  const width = window.innerWidth;

  let height = 100;

  if (width >= 1390) {
    height = 100;
  } else if (width >= 992) {
    height = 125;
  } else if (width >= 768) {
    height = 143.3;
  } else if (width >= 600) {
    height = 200;
  } else if (width <= 600) {
    height = 250;
  }

  return height;
};

export const getScaleFactor = (width: number) => {
  let scaleFactor = 1;

  if (width >= 1390) {
    scaleFactor = 1;
  } else if (width >= 992) {
    scaleFactor = 0.8;
  } else if (width >= 768) {
    scaleFactor = 0.7;
  } else if (width >= 600) {
    scaleFactor = 0.5;
  } else if (width <= 600) {
    scaleFactor = 0.4;
  }

  return scaleFactor;
};
