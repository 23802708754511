import { colors, fonts } from '@app/themes';
import React, { useEffect, useState } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import styled from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CustomButton } from 'tsw-sdk';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import CopyrightStepProgressBar from './components/CopyrightStepProgressBar';
import _ from 'lodash-es';
import { CopyrightStepsFlowProps } from './types';
import { COPYRIGHT_PROTECTION_STEPS, COPYRIGHT_PROTECTION_STEPS_MAPPING } from '@app/utils/constants';

const CopyrightProtectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
`;

const CopyrightProtectionContentContainer = styled.div`
  width: 100%;
  max-width: 38rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
`;

const ProceedBtn = styled(CustomButton)`
  margin-top: 3rem;
  && {
    width: 100%;
    border: 1px solid ${colors.primary};
    justify-content: center;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const CopyrightFlowContainer = styled.div`
  width: 100%;
`;

const CopyrightFlowInstructionsContainer = styled.div`
  width: 100%;
`;

const CopyrightProtectionTitle = styled.h2`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xlarge()};
  color: ${colors.copyrightColors.copyrightDeclarationTitle};
  margin-bottom: 0;
`;

const GetCopyrightedTitle = styled.h3`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-top: 3rem;
`;

const StoryNotProtectedMessage = styled.p`
  margin-top: 3rem;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;
`;

const CopyrightRequirementListTitle = styled.p`
  margin-top: 3rem;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;
`;

const CopyrightRequirementList = styled.ul`
  margin-top: 0.5rem;
  padding-left: 1.6rem;
`;

const CopyrightRequirementListItem = styled.li`
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;
`;

const StepContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
`;

const CopyrightStepsFlow = ({
  storyId,
  sellerId,
  copyrightProcessCurrentStep,
  copyrightProtectionDetails,
  signedPoaDocumentPath,
  signedStoryDocumentPath,
  signedStoryDocumentFileName,
  signedStoryPoaFileName,
  swaRegistrationDetails,
  storyPdfUrl,
  selfAttestationDetails,
  userProfile,
  storyTitle,
  poaSignedDocumentPath,
  setStepFormValues,
  dispatchUpdateCopyrightSelfAttestationDetails,
  dispatchSetSignedPoaDocumentPath,
  dispatchSetSignedStoryPath,
  dispatchUploadPoaDocumentDocument,
  dispatchUploadSignedStoryDocument,
  dispatchGenerateStoryPdf,
  dispatchResetStoryPdfUrl,
  dispatchPreviewFile,
  dispatchGetPoaDocument
}: CopyrightStepsFlowProps) => {
  const [showFlow, setShowFlow] = useState<boolean>(false);

  const getStep = () => {
    switch (copyrightProcessCurrentStep) {
      case COPYRIGHT_PROTECTION_STEPS.STEP1:
        return (
          <Step1
            storyId={storyId}
            sellerId={sellerId}
            copyrightProtectionDetails={copyrightProtectionDetails}
            storyPdfUrl={storyPdfUrl}
            dispatchUpdateCopyrightSelfAttestationDetails={dispatchUpdateCopyrightSelfAttestationDetails}
            dispatchGenerateStoryPdf={dispatchGenerateStoryPdf}
            dispatchResetStoryPdfUrl={dispatchResetStoryPdfUrl}
          />
        );
      case COPYRIGHT_PROTECTION_STEPS.STEP2:
        return (
          <Step2
            storyId={storyId}
            copyrightProtectionDetails={copyrightProtectionDetails}
            signedStoryDocumentFileName={signedStoryDocumentFileName}
            signedStoryDocumentPath={signedStoryDocumentPath}
            selfAttestationDetails={selfAttestationDetails}
            dispatchUpdateCopyrightSelfAttestationDetails={dispatchUpdateCopyrightSelfAttestationDetails}
            dispatchUploadSignedStoryDocument={dispatchUploadSignedStoryDocument}
            dispatchSetSignedStoryPath={dispatchSetSignedStoryPath}
            dispatchPreviewFile={dispatchPreviewFile}
          />
        );
      case COPYRIGHT_PROTECTION_STEPS.STEP3:
        return (
          <Step3
            storyId={storyId}
            copyrightProtectionDetails={copyrightProtectionDetails}
            selfAttestationDetails={selfAttestationDetails}
            userProfile={userProfile}
            storyTitle={storyTitle}
            setStepFormValues={setStepFormValues}
            dispatchUpdateCopyrightSelfAttestationDetails={dispatchUpdateCopyrightSelfAttestationDetails}
          />
        );
      case COPYRIGHT_PROTECTION_STEPS.STEP4:
        return (
          <Step4
            storyId={storyId}
            sellerId={userProfile?.id}
            copyrightProtectionDetails={copyrightProtectionDetails}
            poaSignedDocumentPath={poaSignedDocumentPath}
            dispatchUpdateCopyrightSelfAttestationDetails={dispatchUpdateCopyrightSelfAttestationDetails}
            dispatchGetPoaDocument={dispatchGetPoaDocument}
            dispatchSetSignedPoaDocumentPath={dispatchSetSignedPoaDocumentPath}
          />
        );
      case COPYRIGHT_PROTECTION_STEPS.STEP5:
        return (
          <Step5
            storyId={storyId}
            signedPoaDocumentPath={signedPoaDocumentPath}
            signedStoryPoaFileName={signedStoryPoaFileName}
            copyrightProtectionDetails={copyrightProtectionDetails}
            selfAttestationDetails={selfAttestationDetails}
            swaRegistrationDetails={swaRegistrationDetails}
            dispatchUpdateCopyrightSelfAttestationDetails={dispatchUpdateCopyrightSelfAttestationDetails}
            dispatchUploadPoaDocumentDocument={dispatchUploadPoaDocumentDocument}
            dispatchSetSignedPoaDocumentPath={dispatchSetSignedPoaDocumentPath}
            dispatchPreviewFile={dispatchPreviewFile}
          />
        );
      default:
        return (
          <Step1
            storyId={storyId}
            sellerId={sellerId}
            copyrightProtectionDetails={copyrightProtectionDetails}
            storyPdfUrl={storyPdfUrl}
            dispatchUpdateCopyrightSelfAttestationDetails={dispatchUpdateCopyrightSelfAttestationDetails}
            dispatchGenerateStoryPdf={dispatchGenerateStoryPdf}
            dispatchResetStoryPdfUrl={dispatchResetStoryPdfUrl}
          />
        );
    }
  };

  const getContent = () => {
    if (showFlow) {
      return (
        <CopyrightFlowContainer data-testid="copyright-flow-container">
          <CopyrightStepProgressBar currentStep={COPYRIGHT_PROTECTION_STEPS_MAPPING[copyrightProcessCurrentStep]} />
          <StepContainer>{getStep()}</StepContainer>
        </CopyrightFlowContainer>
      );
    } else {
      return (
        <CopyrightFlowInstructionsContainer data-testid="copyright-flow-instructions-container">
          <GetCopyrightedTitle>{translate('lets_get_copyright')}</GetCopyrightedTitle>
          <StoryNotProtectedMessage>{translate('story_not_registered')}</StoryNotProtectedMessage>
          <CopyrightRequirementListTitle>{translate('copyright_requirement_list_title')}</CopyrightRequirementListTitle>
          <CopyrightRequirementList>
            {_.range(2).map((value) => (
              <CopyrightRequirementListItem key={value}>
                {translate(`copyright_requirement_list_item_${value + 1}`)}
              </CopyrightRequirementListItem>
            ))}
          </CopyrightRequirementList>
          <ProceedBtn
            onClick={() => setShowFlow(true)}
            text_color={`${colors.darkBorder}`}
            data-testid="proceed-btn-copyrightflow"
          >
            {translate('proceed')} <ArrowRightOutlined />
          </ProceedBtn>
        </CopyrightFlowInstructionsContainer>
      );
    }
  };

  return (
    <CopyrightProtectionContainer data-testid="copyright-steps-flow-container">
      <CopyrightProtectionContentContainer>
        <CopyrightProtectionTitle>{translate('copyright_protection')}</CopyrightProtectionTitle>
        {getContent()}
      </CopyrightProtectionContentContainer>
    </CopyrightProtectionContainer>
  );
};

export default CopyrightStepsFlow;

export const CopyrightStepsFlowTest = CopyrightStepsFlow;
