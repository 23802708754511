import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  storyData: null,
  getStoryDataLoading: false,
  getStoryDataError: null,
  currentFileUrl: '',
  getFileUrlLoading: false,
  getFileUrlError: null
};

const pendingApprovalSlice = createSlice({
  name: 'pendingApproval',
  initialState,
  reducers: {
    requestGetStoryData: {
      reducer: (state) => {
        state.getStoryDataLoading = true;
        state.storyData = null;
      },
      prepare
    },
    requestGetFileUrl: {
      reducer: (state) => {
        state.getFileUrlLoading = true;
        state.currentFileUrl = '';
      },
      prepare
    },
    successGetFileUrl(state, action) {
      state.getFileUrlLoading = false;
      state.getFileUrlError = null;
      state.currentFileUrl = action.payload;
    },
    failureGetFileUrl(state, action) {
      state.getFileUrlError = get(action.payload, 'message', 'something_went_wrong');
      state.getFileUrlLoading = false;
    },
    successGetStoryData(state, action) {
      state.getStoryDataLoading = false;
      state.getStoryDataError = null;
      state.storyData = action.payload;
    },
    failureGetStoryData(state, action) {
      state.getStoryDataError = get(action.payload, 'message', 'something_went_wrong');
      state.getStoryDataLoading = false;
    },
    resetFileUrl(state) {
      state.currentFileUrl = '';
    },
    resetState(state) {
      state.storyData = null;
      state.getStoryDataLoading = false;
      state.getStoryDataError = null;
      state.currentFileUrl = '';
      state.getFileUrlLoading = false;
      state.getFileUrlError = null;
    }
  }
});

export const {
  requestGetStoryData,
  successGetStoryData,
  failureGetStoryData,
  requestGetFileUrl,
  successGetFileUrl,
  failureGetFileUrl,
  resetFileUrl,
  resetState
} = pendingApprovalSlice.actions;

export default pendingApprovalSlice.reducer;
