import React, { useState } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button, Form, Input, message } from 'antd';
import styled from 'styled-components';
import { CounterOfferModalProps } from './types';
import { ArrowRightOutlined } from '@ant-design/icons';
import { preventInvalidCharsNumberInput } from '@app/utils/uiUtils';

const CounterOfferModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6.5rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.white};
`;

const CounterOfferTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  color: ${colors.primary};
  text-align: center;
`;

const CounterOfferForm = styled(Form)`
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;

  && {
    width: 16rem;

    & > div:nth-of-type(3) {
      margin-top: 3rem;
    }
  }
`;

const CounterOfferFormItem = styled(Form.Item)`
  && {
    margin-bottom: 0px;

    label {
      ${fonts.weights.fw600()};
      ${fonts.size.regular()};
      line-height: 1.25rem;
      color: ${colors.offersReceivedColors.counterOfferFormLabel};
    }

    input {
      background-color: ${colors.white};
      border-radius: 0.31rem;
    }
  }
`;

const CounterOfferAmountNote = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.extraSmall()};
  line-height: 0.62rem;
  margin-top: 0.25rem;
  color: ${colors.offersReceivedColors.counterOfferNoteColor};
`;

const CurrentOfferPill = styled.div`
  width: fit-content;
  height: 1.5rem;
  border-radius: 6.25rem;
  background-color: ${colors.offersReceivedColors.scriptIncludedPillBg};
  color: ${colors.offersReceivedColors.scriptIncludedPillColor};
  margin-top: 1rem;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fonts.weights.fw800()};
  ${fonts.size.extraSmall()};
  line-height: 1rem;
  white-space: nowrap;
`;

const FormActionsContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 5.18rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CancelBtn = styled(Button)`
  width: 7.81rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.offersReceivedColors.grayBtnBg};
  color: ${colors.offersReceivedColors.grayBtnColor};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.offersReceivedColors.grayBtnBg};
    color: ${colors.offersReceivedColors.grayBtnColor};
    border: none;
  }
`;

const CounterOfferBtn = styled(Button)`
  width: 12.62rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;

const CustomNumberInput = styled(Input)`
  &&& {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const CounterOfferModal = ({
  currentOffer,
  counteringOffer,
  handleCancel,
  handleCounterOffer
}: CounterOfferModalProps) => {
  const [offerAmount, setOfferAmount] = useState<string>('');
  const [notesFromSeller, setNotesFromSeller] = useState<string>('');

  const handleCounterOfferLocal = () => {
    if (offerAmount) {
      handleCounterOffer(Number(offerAmount), notesFromSeller);
    } else {
      message.error(translate('no_zero'));
    }
  };

  return (
    <CounterOfferModalContent>
      <CounterOfferTitle>{translate('counter_offer')}</CounterOfferTitle>
      <CounterOfferForm layout="vertical">
        <CounterOfferFormItem name="counterOfferAmount" label={translate('new_offered_amount')}>
          <CustomNumberInput
            type="number"
            placeholder={translate('counter_offer_placeholder')}
            onChange={(e) => setOfferAmount(e.target.value)}
            data-testid="offer-amount-input"
            onKeyDown={preventInvalidCharsNumberInput}
          />
          {/* <CounterOfferAmountNote>{translate('counter_offer_note')}</CounterOfferAmountNote> */}
        </CounterOfferFormItem>
        <CurrentOfferPill>
          {translate('current_offer_offered', { amount: currentOffer?.toLocaleString('en-IN') })}
        </CurrentOfferPill>
        <CounterOfferFormItem name="noteFromSeller" label={translate('note_from_seller')}>
          <Input
            type="text"
            value={notesFromSeller}
            onChange={(e) => e.target.value.length < 251 && setNotesFromSeller(e.target.value)}
            data-testid="note-from-seller-input"
            onPaste={(e) => {
              const clipboardData = e.clipboardData;
              const pastedData = clipboardData.getData('Text');

              if (pastedData.length > 250) {
                e.preventDefault();
                message.error(translate('250_chars_limit'));
              } else {
                if (pastedData.length + notesFromSeller.length > 250) {
                  e.preventDefault();
                  message.error(translate('250_chars_limit'));
                }
              }
            }}
          />
          <CounterOfferAmountNote>
            {translate('note_from_seller_character_limit', { characterCount: notesFromSeller?.length })}
          </CounterOfferAmountNote>
        </CounterOfferFormItem>
      </CounterOfferForm>
      <FormActionsContainer>
        <CancelBtn onClick={handleCancel} data-testid="counter-offer-cancel">
          {translate('cancel')}
        </CancelBtn>
        <CounterOfferBtn
          onClick={handleCounterOfferLocal}
          data-testid="counter-offer-counter-btn"
          loading={counteringOffer}
        >
          {translate('counter_offer')} <ArrowRightOutlined />
        </CounterOfferBtn>
      </FormActionsContainer>
    </CounterOfferModalContent>
  );
};

export default CounterOfferModal;
