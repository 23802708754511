import { createGlobalStyle } from 'styled-components';

// fonts

// spoof
import SpoofBlack from './Spoof-Black.ttf';
import SpoofBold from './Spoof-Bold.ttf';
import SpoofMedium from './Spoof-Medium.ttf';
import SpoofRegular from './Spoof-Regular.ttf';

// spotwriter
import SpotwriterBody from './Spotwriter-Body.ttf';
import SpotwriterMono from './Spotwriter-Mono.ttf';
import SpotwriterHeadline from './Spotwriter-Headline.ttf';

import InterBold from './Inter-Bold.ttf';
import InterLight from './Inter-Light.ttf';
import InterMedium from './Inter-Medium.ttf';
import InterRegular from './Inter-Regular.ttf';

export default createGlobalStyle`
   @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      src: url(${InterBold}) format('truetype');
   }

   @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 100;
      src: url(${InterLight}) format('truetype');
   }

   @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 300;
      src: url(${InterMedium}) format('truetype');
   }

   @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 200;
      src: url(${InterRegular}) format('truetype');
   }

   @font-face {
     font-family: 'Spotwriter-Body';
     src: url(${SpotwriterBody}) format('truetype');
  }

  @font-face {
     font-family: 'Spotwriter-Mono';
     src: url(${SpotwriterMono}) format('truetype');
  }

  @font-face {
     font-family: 'Spotwriter-Headline';
     src: url(${SpotwriterHeadline}) format('truetype');
  }

  @font-face {
     font-family: 'Spoof-Black';
     font-style: normal;
     font-weight: 400;
     src: url(${SpoofBlack}) format('truetype');
  }

  @font-face {
     font-family: 'Spoof-Regular';
     font-style: normal;
     font-weight: 400;
     src: url(${SpoofRegular}) format('truetype');
  }

  @font-face {
     font-family: 'Spoof-Bold';
     font-style: normal;
     font-weight: 400;
     src: url(${SpoofBold}) format('truetype');
  }

  @font-face {
     font-family: 'Spoof-Medium';
     src: url(${SpoofMedium}) format('truetype');
  }
`;
