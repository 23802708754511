import { gql } from 'apollo-boost';

export const GET_OFFERS = gql`
  query SellerOffers($filters: OfferFilters, $page: OffersPagination!) {
    sellerOffers(filters: $filters, pagination: $page) {
      offers {
        storyId
        title
        languages {
          id
          name
        }
        genres {
          id
          name
        }
        storyType {
          id
          name
        }
        tags {
          id
          name
        }
        ratings
        views
        state
        offers {
          id
          offerId
          amount
          currency
          offerState
          notes
          offerEntity {
            id
            firstName
            lastName
            email
            profilePic
            profileBanner
            designation
            companyName
          }
          createdAt
          updatedAt
          deletedAt
        }
      }
      total
    }
  }
`;

export const GET_REQUESTS = gql`
  query sellerConversations($pagination: ConversationPagination!) {
    sellerConversations(pagination: $pagination) {
      conversations {
        storyId
        title
        ratings
        views
        isRequestPending
      }
      total
    }
  }
`;
