import React, { useEffect, useRef, useState } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import _ from 'lodash-es';
import { CustomButton, If } from 'tsw-sdk';
import { ProtectStoryProps } from './types';
import uploadIcon from '@images/icons/upload-icon.svg';
import { ArrowRightOutlined } from '@ant-design/icons';
import { validateFileSize } from '@app/utils/uiUtils';
import { message } from 'antd';
import crossIcon from '@images/icons/MySubmissionIcons/cross-icon.svg';

const ProtectStoryContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProtectStoryContentContainer = styled.div`
  width: 100%;
  max-width: 38rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
`;

const ProtectStoryTitle = styled.h2`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xlarge()};
  color: ${colors.copyrightColors.copyrightDeclarationTitle};
  margin-bottom: 0;
`;

const ProtectStoryDescription = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  margin-top: 3rem;
  line-height: 1.31rem;
`;

const StoryProtectionMethodsTitle = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  margin-top: 1rem;
  line-height: 1.31rem;
`;

const StoryProtectionMethodsList = styled.ul`
  width: 100%;
  padding-left: 1.5rem;
  margin-bottom: 0px;
  margin-top: 0.5rem;
`;

const StoryProtectionMethodsListItem = styled.li`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  line-height: 1.31rem;
`;

const ScanAndUploadTitle = styled.p`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const UploadIdBtn = styled(CustomButton)`
  margin-right: auto;
  margin-left: auto;

  && {
    border: 1px solid ${colors.primary};
    background-color: transparent;
    justify-content: center;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const UploadIcon = styled.img`
  margin-left: 0.5rem;
`;

const ScanningToolMessage = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  line-height: 1.31rem;
  text-align: center;
  margin-bottom: 0.5rem;
`;

// const DontWorryMessage = styled.p`
//   width: 100%;
//   ${fonts.family.SpoofRegular()};
//   ${fonts.weights.fw400()};
//   ${fonts.size.regular()};
//   color: ${colors.copyrightColors.timelineItemTextActive};
//   line-height: 1.31rem;
//   margin-top: 1rem;
// `;

const ContinueBtn = styled(CustomButton)`
  margin-top: 1.37rem;
  && {
    width: 100%;
    border: 1px solid ${colors.primary};
    background-color: transparent;
    justify-content: center;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const GovtIdFileInput = styled.input`
  display: none;
`;

const FileLimitText = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  margin-top: 0.4rem;
  line-height: 1.31rem;
  text-align: center;
`;

const GovtIdImg = styled.img`
  height: 7rem;
  width: 8rem;
  block-size: auto;
  object-fit: contain;

  border: 1px solid ${colors.primary};
  border-radius: 1rem;
`;

const GovtIdImgContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;
`;

const CutIcon = styled.img`
  margin-left: 0.5rem;
  margin-top: 0.3rem;
  height: 0.62rem;

  cursor: pointer;
`;

const FileUploadedText = styled.p`
  margin-top: 0.5rem;
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-right: auto;
  line-height: 1.31rem;
`;

const PreviewFileBtn = styled.span`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-left: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
`;

const DeleteFileBtn = styled.span`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-left: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
`;

const ProtectStory = ({
  beginSubmissionProcessLoading,
  sellerId,
  storyId,
  govtIdFilePath,
  govtIdFileName,
  signedGovtIdFilePath,
  dispatchBeginSubmissionProcess,
  dispatchUploadGovtIdFile,
  dispatchResetGovtIdFilePath,
  dispatchGetGovtDetails,
  dispatchPreviewFile
}: ProtectStoryProps) => {
  const [govtIdFile, setGovtIdFile] = useState<string>('');
  const [isImage, setIsImage] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const govtIdFileRef = useRef<any>(null);

  useEffect(() => {
    const isDisabled = getIsDisabled();

    setIsDisabled(isDisabled);
  }, [govtIdFilePath]);

  useEffect(() => {
    if (sellerId && storyId) {
      dispatchGetGovtDetails(sellerId, storyId);
    }
  }, [sellerId, storyId]);

  useEffect(() => {
    if (govtIdFileName) {
      let extension: any = govtIdFileName.split('.');
      extension = extension[extension.length - 1];

      if (extension === 'jpg') {
        setIsImage(true);
      } else {
        setIsImage(false);
      }
    }
  }, [govtIdFileName]);

  const handleGovtIdUpload = (e: any) => {
    const file = e.target.files[0];

    const validImageTypes = ['image/jpeg', 'image/png'];

    if (validateFileSize(file, 5)) {
      setGovtIdFile(URL.createObjectURL(file));
      dispatchUploadGovtIdFile({ storyId, file });

      if (validImageTypes.includes(file.type)) {
        setIsImage(true);
      }
    } else {
      message.error(translate('file_size_exceed', { fileSize: '5mb' }));
    }

    e.target.value = null;
  };

  const handleClearGovtId = () => {
    setGovtIdFile('');
    dispatchResetGovtIdFilePath();
    setIsImage(false);
  };

  const handleUploadGovtIdClick = () => {
    govtIdFileRef.current?.click();
  };

  const getIsDisabled = () => {
    let isDisabled = false;

    if (govtIdFilePath === '') {
      isDisabled = true;
    }

    return isDisabled;
  };

  const handleProceed = () => {
    const payload = {
      sellerId: sellerId,
      storyId: storyId,
      proceedToNextStep: true,
      govDoc: {
        isFileUploaded: true,
        filepath: govtIdFilePath
      }
    };

    dispatchBeginSubmissionProcess(payload);
  };

  const handleFilePreview = () => {
    let rootFolder: any = govtIdFilePath.split('.')[0];
    rootFolder = rootFolder.split('/');
    rootFolder.pop();

    rootFolder = rootFolder.join('/');

    let fileName: any = govtIdFilePath.split('/');

    fileName = fileName[fileName.length - 1];

    dispatchPreviewFile({
      rootFolder,
      fileName
    });
  };

  return (
    <ProtectStoryContainer data-testid="protect-story-container">
      <GovtIdFileInput
        onChange={handleGovtIdUpload}
        type={'file'}
        accept="image/png, image/jpeg, application/pdf"
        ref={govtIdFileRef}
        data-testid="govt-id-file-input"
      />
      <ProtectStoryContentContainer>
        <ProtectStoryTitle>{translate('lets_protect_story')}</ProtectStoryTitle>
        <ProtectStoryDescription>{translate('lets_protect_description')}</ProtectStoryDescription>
        <StoryProtectionMethodsList>
          {_.range(2).map((index) => (
            <StoryProtectionMethodsListItem key={index}>
              {translate(`lets_protect_id_${index + 1}`)}
            </StoryProtectionMethodsListItem>
          ))}
        </StoryProtectionMethodsList>
        <ScanAndUploadTitle>{translate('scan_and_upload')}</ScanAndUploadTitle>
        <UploadIdBtn text_color={`${colors.darkBorder}`} onClick={handleUploadGovtIdClick} data-testid="upload-id-btn">
          {translate('upload_id')} <UploadIcon src={uploadIcon} />
        </UploadIdBtn>
        <FileLimitText>{translate('5mb_size_limit')}</FileLimitText>
        <If condition={govtIdFilePath !== '' && isImage}>
          <GovtIdImgContainer>
            <GovtIdImg src={govtIdFile !== '' ? govtIdFile : signedGovtIdFilePath} />
            <CutIcon src={crossIcon} onClick={handleClearGovtId} data-testid="clear-govt-id-image" />
          </GovtIdImgContainer>
        </If>
        <If condition={govtIdFilePath !== '' && !isImage}>
          <FileUploadedText>
            {translate('noc_uploaded_text', { fileName: govtIdFileName })}
            <PreviewFileBtn data-testid="govt-id-preview-btn" onClick={handleFilePreview}>
              {translate('preview')}
            </PreviewFileBtn>
            <DeleteFileBtn onClick={() => dispatchResetGovtIdFilePath()} data-testid="govt-id-delete-file-btn">
              {translate('delete')}
            </DeleteFileBtn>
          </FileUploadedText>
        </If>
        {/* <StoryProtectionMethodsTitle>{translate('story_protection_methods')}</StoryProtectionMethodsTitle> */}

        {/* <DontWorryMessage>{translate('dont_worry_message')}</DontWorryMessage> */}
        {/* <ScanningToolMessage>{translate('incase_scan_tool')}</ScanningToolMessage>
        <ScanningToolMessage>{translate('incase_pdf_tool')}</ScanningToolMessage> */}
        <ContinueBtn
          text_color={`${colors.darkBorder}`}
          loading={beginSubmissionProcessLoading}
          onClick={handleProceed}
          data-testid="proceed-btn-protect-story"
          disabled={isDisabled}
        >
          {translate('proceed')}
          <ArrowRightOutlined />
        </ContinueBtn>
      </ProtectStoryContentContainer>
    </ProtectStoryContainer>
  );
};

export default ProtectStory;
