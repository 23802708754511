import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';
import { string } from 'prop-types';

export const initialState = {
  transactions: [],
  offerState: null,
  daysLeft: null,
  offerPlacedOn: null,
  offerEndsOn: null,
  getTransactionDataLoading: false,
  getTransactionDataError: null,
  counterOfferLoading: false,
  counterOfferError: null,
  concludeOfferSuccess: false,
  concludeOfferLoading: false,
  concludeOfferError: null,
  getConversationDataLoading: false,
  conversations: [],
  conversationError: null,
  buyerData: null,
  sendMessageLoading: false,
  sendMessageError: null,
  offerId: null
};

const offerHistorySlice = createSlice({
  name: 'offerHistory',
  initialState,
  reducers: {
    requestGetTransactionData: {
      reducer: (state) => {
        state.getTransactionDataLoading = true;
      },
      prepare
    },
    requestCounterOffer: {
      reducer: (state) => {
        state.counterOfferLoading = true;
      },
      prepare
    },
    requestConcludeOffer: {
      reducer: (state) => {
        state.concludeOfferLoading = true;
        state.concludeOfferSuccess = false;
      },
      prepare
    },
    requestGetConversationData: {
      reducer: (state) => {
        state.getConversationDataLoading = true;
      },
      prepare
    },
    requestSendMessage: {
      reducer: (state) => {
        state.sendMessageLoading = true;
      },
      prepare
    },
    successConcludeOffer(state) {
      state.concludeOfferLoading = false;
      state.concludeOfferSuccess = true;
      state.concludeOfferError = null;
    },
    failureConcludeOffer(state, action) {
      state.concludeOfferLoading = false;
      state.concludeOfferError = get(action.payload, 'message', 'something_went_wrong');
    },
    successCounterOffer(state) {
      state.counterOfferLoading = false;
      state.counterOfferError = null;
    },
    failureCounterOffer(state, action) {
      state.counterOfferLoading = false;
      state.counterOfferError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetTransactionData(state, action) {
      state.getTransactionDataLoading = false;
      state.getTransactionDataError = null;
      state.transactions = action.payload.transactions;
      state.offerState = action.payload.offerState;
      state.daysLeft = action.payload.daysLeft;
      state.offerPlacedOn = action.payload.placedOn;
      state.offerEndsOn = action.payload.expiresOn;
      state.offerId = action.payload.offerId;
    },
    failureGetTransactionData(state, action) {
      state.getTransactionDataError = get(action.payload, 'message', 'something_went_wrong');
      state.getTransactionDataLoading = false;
    },
    successGetConversationData(state, action) {
      state.getConversationDataLoading = false;
      state.conversations = action.payload.conversationDetails;
      state.buyerData = action.payload.buyer;
    },
    failureGetConversationData(state, action) {
      state.getConversationDataLoading = false;
      state.conversationError = get(action.payload, 'message', 'something_went_wrong');
    },
    successSendMessage(state) {
      state.sendMessageLoading = false;
    },
    failureSendMessage(state, action) {
      state.sendMessageLoading = false;
      state.sendMessageError = get(action.payload, 'message', 'something went wrong');
    },

    resetState(state) {
      state.transactions = [];
      state.offerState = null;
      state.daysLeft = null;
      state.getTransactionDataLoading = false;
      state.getTransactionDataError = null;
      state.getConversationDataLoading = false;
      state.conversationError = null;
      state.conversations = [];
      state.buyerData = null;
      state.sendMessageLoading = false;
      state.sendMessageError = null;
      state.offerId = null;
    }
  }
});

export const {
  requestGetTransactionData,
  requestGetConversationData,
  successGetTransactionData,
  failureGetTransactionData,
  requestCounterOffer,
  successCounterOffer,
  failureCounterOffer,
  requestConcludeOffer,
  successConcludeOffer,
  failureConcludeOffer,
  successGetConversationData,
  failureGetConversationData,
  requestSendMessage,
  successSendMessage,
  failureSendMessage,
  resetState
} = offerHistorySlice.actions;

export default offerHistorySlice.reducer;
