import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import DownloadIcon from '@images/icons/MySubmissionIcons/download-icon.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import ViewedIcon from '@images/icons/MySubmissionIcons/viewed-icon.svg';
import StarIcon from '@images/icons/MySubmissionIcons/star-icon.svg';
import OfferIcon from '@images/icons/MySubmissionIcons/offer-icon.svg';
import { SubmittedCardProps } from '../../types';
import _ from 'lodash';
import history from '@app/utils/history';
import routeConstants from '@app/utils/routeConstants';
import moment from 'moment';
import { If } from 'tsw-sdk';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { STORIES_STATES } from '@app/utils/constants';

const CardContainer = styled.div`
  width: 19.12rem;
  height: 15.87rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.white};
  box-shadow: 0px 0px 1.25rem 0px ${colors.myDashboardColors.cardShadow};
  border-radius: 1rem;
  padding: 0px 0px 2rem;
  cursor: pointer;
`;

const StyledHeader = styled.div`
  width: 100%;
  height: 5.25rem;
  background: ${colors.mySubmissions.cardHeaderBg};
  border-radius: 1rem 1rem 0px 0px;
  position: relative;
  overflow: hidden;
`;

const BannerContainer = styled.div`
  width: 200%;
  height: 100%;
  top: -1rem;
  left: -1rem;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
`;

const StoryTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin-top: 1rem;
`;

const StoryTitle = styled.h1`
  max-width: 15rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw900()};
  line-height: 1.87rem;
  text-align: center;
  color: ${colors.mySubmissions.headingClr};
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DateText = styled.h1`
  width: 100%;
  ${fonts.weights.fw700()};
  ${fonts.size.xsmall()};
  line-height: 1rem;
  text-align: center;
  color: ${colors.mySubmissions.lightText};
  margin-bottom: 0px;
  margin-top: 0.5rem;
`;

const ScriptsTag = styled.div`
  width: 6.87rem;
  height: 1.5rem;
  padding: 0.25rem 0.75rem;
  background: ${colors.mySubmissions.tagBg};
  border-radius: 0.75rem;
  margin-top: 0.5rem;
`;

const ScriptTagText = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.extraSmall()};
  line-height: 1rem;
  text-align: center;
  color: ${colors.mySubmissions.lightText};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 1rem;
  margin-top: 1rem;
`;

const InfoSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 0.25rem;
`;

const InfoText = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.xsmall()};
  line-height: 1.25rem;
  text-align: center;
  color: ${colors.mySubmissions.headingClr};
`;

const BackgroundText = styled.h1`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.xlarge()};
  ${fonts.weights.fw900()};
  line-height: 2.625rem;
  color: ${colors.mySubmissions.backgroundfTextClr};
  margin-bottom: 0px;
`;

const SubmittedStoriesCard = ({
  storyDetails,
  sellerId,
  storyState,
  currentLoadingStoryId,
  dispatchGenerateStoryPdf
}: SubmittedCardProps) => {
  const { id, title, views, offers, ratings } = storyDetails;

  const handleStoryDownload = (e: any) => {
    e.stopPropagation();
    dispatchGenerateStoryPdf(sellerId, id);
  };

  const handleCardClick = () => {
    history.push(`${routeConstants.dashboard.route}?activeTab=submitted_story&storyId=${id}`);
  };

  return (
    <CardContainer onClick={handleCardClick} data-testid="card-container">
      <StyledHeader>
        <BannerContainer>
          {_.range(30).map((number: number) => (
            <BackgroundText key={number}>{title}</BackgroundText>
          ))}
        </BannerContainer>
      </StyledHeader>
      <StoryTitleContainer>
        <StoryTitle>{title}</StoryTitle>
        <If condition={currentLoadingStoryId !== id} otherwise={<Spin indicator={<LoadingOutlined spin={true} />} />}>
          <img src={DownloadIcon} onClick={handleStoryDownload} data-testid="download-btn" />
        </If>
      </StoryTitleContainer>
      <DateText>{moment(storyDetails?.createdAt).zone('GMT+0530').format('DD/MM/YYYY, HH:mm')}</DateText>
      <ScriptsTag>
        <If condition={storyState === STORIES_STATES.PUBLISHED}>
          <ScriptTagText>{translate('stories_included')}</ScriptTagText>
        </If>
        <If condition={storyState === STORIES_STATES.UNPUBLISHED}>
          <ScriptTagText>{STORIES_STATES.UNPUBLISHED}</ScriptTagText>
        </If>
        <If condition={storyState === STORIES_STATES.EXPIRED}>
          <ScriptTagText>{STORIES_STATES.EXPIRED}</ScriptTagText>
        </If>
      </ScriptsTag>
      <InfoContainer>
        <InfoSubContainer>
          <img src={ViewedIcon} />
          <InfoText>{views}</InfoText>
        </InfoSubContainer>
        <InfoSubContainer>
          <img src={StarIcon} />
          <InfoText>{ratings}</InfoText>
        </InfoSubContainer>
        <InfoSubContainer>
          <img src={OfferIcon} />
          <InfoText>{offers}</InfoText>
        </InfoSubContainer>
      </InfoContainer>
    </CardContainer>
  );
};

export default SubmittedStoriesCard;
