import React from 'react';
import { colors, fonts } from '@app/themes';
import { Modal } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import { translate } from '../IntlGlobalProvider';
import { SubmissionGuidelinesModalProps } from './types';

const CustomModal = styled(Modal)`
  margin: 2rem;

  &&& {
    & .ant-modal-content {
      border-radius: 1.25rem;
    }

    & .ant-modal-footer {
      display: none;
    }
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.p`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  text-align: center;
  color: ${colors.primary};
`;

const FollowStepsTitle = styled.p`
  margin-top: 1.5rem;
  color: ${colors.primary};
  line-height: 1rem;
  ${fonts.size.size15px()};
`;

const SubmissionGuidelinesTipsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const SubmissionGuidelinesList = styled.ul`
  width: 100%;
  padding-left: 1rem;
`;

const SubmissionGuidelinesListItem = styled.li``;

const SubmissionGuidelinesModal = ({ visible, handleClose }: SubmissionGuidelinesModalProps) => {
  return (
    <CustomModal
      centered={true}
      visible={visible}
      destroyOnClose={true}
      footer={<></>}
      onCancel={handleClose}
      width={700}
    >
      <ContentContainer>
        <ModalTitle>{translate('submission_guidelines_modal_title')}</ModalTitle>
        <FollowStepsTitle>{translate('submission_guidelines_modal_body')}</FollowStepsTitle>
        <FollowStepsTitle>{translate('follow_steps_title')}</FollowStepsTitle>
        <SubmissionGuidelinesTipsContainer>
          <SubmissionGuidelinesList>
            {_.range(10).map((number) => (
              <SubmissionGuidelinesListItem key={number}>
                {translate(`submission_guidelins_${number + 1}`)}
              </SubmissionGuidelinesListItem>
            ))}
          </SubmissionGuidelinesList>
        </SubmissionGuidelinesTipsContainer>
      </ContentContainer>
    </CustomModal>
  );
};

export default SubmissionGuidelinesModal;
