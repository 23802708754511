import { gql } from 'apollo-boost';

export const GET_BANNER_IMAGE_FILE_NAME = gql`
  mutation UploadSellerProfileBanner {
    uploadSellerProfileBanner {
      filepath
      fileName
      extensions
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query GetSellerProfile {
    getSellerProfile {
      id
      firstName
      lastName
      email
      contactNumber
      bioDescription
      nationality
      profilePic
      profileBanner
      isLegalAge
      experiences {
        id
        company
        designation
        description
        startDate
        endDate
      }
      publishedStories {
        id
        name
        publishDate
      }
      favGenres {
        id
        name
      }
      favMovies {
        id
        name
      }
      billingAddress {
        id
        panNumber
        gstNumber
        streetAddress
        localeAddress
        city
        state
        pinCode
      }
    }
  }
`;
