import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { colors, fonts } from '@app/themes';
import { translate } from '@app/components/IntlGlobalProvider';
import defaultProfilePic from '@images/avatar.png';
import supportIcon from '@images/icons/OffersReceived/support.svg';
import bulletPointsIcon from '@images/icons/OffersReceived/bullet-points.svg';
import { If } from 'tsw-sdk';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { requestGetOfferDetails } from '../ManageOffer/reducer';
import { createStructuredSelector } from 'reselect';
import { AcceptedOfferProps } from './types';
import { selectOfferDetails } from '../ManageOffer/selectors';
import {
  requestGetImages,
  requestGetNarration,
  requestGetScreenPlay,
  requestGetStoryDetails,
  resetState
} from '../SubmittedStoryPage/reducer';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import {
  selectImagesUrlList,
  selectNarrationUrl,
  selectScreenPlayUrl,
  selectStoryDetails
} from '../SubmittedStoryPage/selectors';
import SupportModal from './components/SupportModal';
import LegalModal from './components/LegalModal';
import {
  NARRATION_PATH,
  PROFILE_IMAGE_PROD_URL,
  PROFILE_IMAGE_QA_URL,
  PROFILE_IMAGE_URL,
  SCREENPLAY_PATH,
  SUPPORTING_MATERIAL_TYPE
} from '@app/utils/constants';
import { requestSendMailToLegalTeam } from './reducer';
import { selectSendMailToLegalTeamLoading } from './selectors';
import routeConstants from '@app/utils/routeConstants';
import downloadIcon from '@images/icons/OffersReceived/download-icon.svg';
import { message } from 'antd';

const AcceptedOfferContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
  padding-top: 5rem;
  padding-left: 2.25rem;
  padding-bottom: 2rem;
  background-color: ${colors.dashboardBg};
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const OfferInfoContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
`;

const ProfilePicImage = styled.img`
  width: 9rem;
  height: 9rem;
  border-radius: 100%;
`;

const UserName = styled.p`
  margin-top: 2rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.large()};
  line-height: 2.68rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const UserOrg = styled.p`
  margin-top: 0.72rem;
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  line-height: 2.18rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const UserDesignation = styled.p`
  margin-top: 0.72rem;
  ${fonts.weights.fw400()};
  ${fonts.size.xBig()};
  line-height: 2.18rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const OfferAmount = styled.p`
  margin-top: 2rem;
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.75rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const OfferAcceptedPill = styled.div`
  width: 9.87rem;
  height: 2.25rem;
  margin-top: 0.72rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.37rem 1.11rem;
  text-transform: uppercase;
  border-radius: 6.25rem;
  background-color: ${colors.offersReceivedColors.scriptIncludedPillBg};
  color: ${colors.primary};
  ${fonts.weights.fw800()};
  ${fonts.size.xsmall()};
  line-height: 1.5rem;
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
`;

const BtnIcon = styled.img`
  margin-right: 0.62rem;
`;

const CounterOfferBtn = styled.div`
  width: 10.625rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.offersReceivedColors.grayBtnBg};
  color: ${colors.offersReceivedColors.grayBtnColor};
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  cursor: pointer;
  line-height: 1.5rem;
`;

const AcceptOfferBtn = styled.div`
  width: 16.43rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
`;

const StoryTitle = styled.p`
  ${fonts.family.Inter()};
  ${fonts.weights.fw200()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.offersReceivedColors.storyTitleColor};
`;

const StoryInfoContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 6.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StoryDetailsContainer = styled.div`
  width: 100%;
  margin-top: 3.1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const StoryDetailsInfoItem = styled.div`
  width: 33%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2.43rem;

  &:last-of-type {
    margin-right: auto;
  }
`;

const StoryDetailsInfoItemTitle = styled.p`
  ${fonts.size.big()};
  ${fonts.family.Inter()};
  text-transform: uppercase;
`;

const StoryDetailsInfoItemDetail = styled.p`
  ${fonts.family.Inter()};
  ${fonts.size.regular()};
  ${fonts.weights.fw200()};
  margin-top: 1rem;
`;

const StoryDetailsInfoItemTitle2 = styled.p`
  ${fonts.size.big()};
  ${fonts.family.Inter()};
  text-transform: uppercase;
  margin-right: auto;

  &:not(:first-of-type) {
    margin-top: 2.18rem;
  }
`;

const StoryDetailsInfoItemDetail2 = styled.p`
  ${fonts.family.Inter()};
  ${fonts.size.regular()};
  ${fonts.weights.fw200()};
  margin-top: 1rem;
  margin-right: auto;
  line-height: 1.25rem;
`;

const SupportingMaterialsContainer = styled.div`
  width: 100%;
  margin-top: 7rem;
  display: flex;
  align-items: center;
`;

const SupportingMaterialsItem = styled.div`
  width: auto;
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    margin-left: 4.6rem;
  }
`;

const SupportingMaterialsItemTitle = styled.p`
  ${fonts.size.big()};
  ${fonts.family.Inter()};
`;

const SupportingMaterialDownloadIcon = styled.img`
  margin-left: 0.62rem;
  cursor: pointer;
`;

const AcceptedOffer = ({
  offerDetails,
  userProfile,
  publishedStory,
  screenplayUrl,
  imagesUrl,
  narrationUrl,
  sendMailToLegalTeamLoading,
  dispatchGetOfferDetails,
  dispatchGetPublishedStory,
  dispatchSendMailToLegalTeam,
  dispatchGetImagesUrl,
  dispatchGetNarrationUrl,
  dispatchGetScreenplaySignedUrl,
  dispatchResetState
}: AcceptedOfferProps) => {
  const [formVisible, setFormVisible] = useState<boolean>(true);
  const [chartVisible, setChartVisible] = useState<boolean>(false);

  const [supportModalVisible, setSupportModalVisible] = useState<boolean>(false);
  const [legalModalVisible, setLegalModalVisible] = useState<boolean>(false);

  const [screenPlayName, setScreenPlayName] = useState<string>('');
  const [narrationName, setNarrationName] = useState<string>('');
  const [imagesName, setimagesName] = useState<string[]>([]);
  const [currOfferId, setCurrOfferId] = useState<string>('');

  const history = useHistory();

  const location: any = useLocation();

  const params = new URLSearchParams(location.search);

  const offerId = params.get('offerId');

  useEffect(() => {
    return () => {
      dispatchResetState();
    };
  }, []);

  useEffect(() => {
    if (!sendMailToLegalTeamLoading && (legalModalVisible || supportModalVisible)) {
      history.push(`${routeConstants.dashboard.route}?activeTab=offers_recieved_nav`);
    }
  }, [sendMailToLegalTeamLoading]);

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const storyId = params.get('storyId');

    if (storyId !== null && storyId !== undefined) {
      if (userProfile?.id) {
        dispatchGetPublishedStory(0, 1, storyId, userProfile?.id);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (offerId) {
      dispatchGetOfferDetails(offerId);
      setCurrOfferId(offerId);
    }
  }, [offerId]);

  useEffect(() => {
    if (publishedStory?.supportingMaterials) {
      publishedStory?.supportingMaterials?.forEach((el: any) => {
        if (el.attachmentType === SUPPORTING_MATERIAL_TYPE.SCREENPLAY) {
          const newScreenPlayName = el.fileName.replace('storyMaterial/screenplays', '');
          setScreenPlayName(newScreenPlayName);
        } else if (el.attachmentType === SUPPORTING_MATERIAL_TYPE.AUDIO_NARRATION) {
          const newAudioNarration = el.fileName.replace('storyMaterial/audioNarrations', '');
          setNarrationName(newAudioNarration);
        } else {
          setimagesName((curr) => [...curr, el.fileName.replace('storyMaterial/images', '')]);
        }
      });
    }
  }, [publishedStory]);

  useEffect(() => {
    if (narrationUrl) {
      const anchorTag = document.createElement('a');
      anchorTag.href = narrationUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();
    }
  }, [narrationUrl]);

  useEffect(() => {
    if (screenplayUrl) {
      const anchorTag = document.createElement('a');
      anchorTag.href = screenplayUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();
    }
  }, [screenplayUrl]);

  useEffect(() => {
    if (imagesUrl?.length > 0) {
      imagesUrl.forEach((curr: string) => {
        const anchorTag = document.createElement('a');
        anchorTag.href = curr;
        anchorTag.setAttribute('target', '_blank');
        anchorTag.click();
      });
    }
  }, [imagesUrl]);

  const toggleForm = () => {
    setFormVisible(!formVisible);
  };

  const toggleChart = () => {
    setChartVisible(!chartVisible);
  };

  const handleGoBack = () => {
    history.push(`${routeConstants.dashboard.route}?activeTab=offers_recieved_nav`);
  };

  const handleScreenplayDownload = () => {
    if (screenPlayName) {
      dispatchGetScreenplaySignedUrl(SCREENPLAY_PATH, screenPlayName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  const handleImagesDownload = () => {
    if (imagesName.length > 0) {
      dispatchGetImagesUrl(imagesName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  const handleNarrationDownload = () => {
    if (narrationName) {
      dispatchGetNarrationUrl(NARRATION_PATH, narrationName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  return (
    <AcceptedOfferContainer data-testid="accepted-offer-container">
      <SupportModal visible={supportModalVisible} onClose={() => setSupportModalVisible(false)} />
      <LegalModal
        visible={legalModalVisible}
        offerId={currOfferId}
        onClose={() => setLegalModalVisible(false)}
        dispatchSendMailToLegalTeam={dispatchSendMailToLegalTeam}
      />
      <BackButton onClick={handleGoBack} data-testid="back-btn">
        <StyledImg src={ArrowLeft} />
        {translate('back')}
      </BackButton>
      <OfferInfoContainer>
        <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
          <ProfilePicImage
            src={
              offerDetails?.profileImage !== ''
                ? `${PROFILE_IMAGE_QA_URL}/${offerDetails?.profileImage}`
                : defaultProfilePic
            }
          />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
          <ProfilePicImage
            src={
              offerDetails?.profileImage !== ''
                ? `${PROFILE_IMAGE_PROD_URL}/${offerDetails?.profileImage}`
                : defaultProfilePic
            }
          />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME !== 'qa' && process.env.ENVIRONMENT_NAME !== 'production'}>
          <ProfilePicImage
            src={
              offerDetails?.profileImage !== ''
                ? `${PROFILE_IMAGE_URL}/${offerDetails?.profileImage}`
                : defaultProfilePic
            }
          />
        </If>
        <UserName>{offerDetails?.buyerName}</UserName>
        <UserOrg>{offerDetails?.companyName}</UserOrg>
        <UserDesignation>{offerDetails?.designation}</UserDesignation>
        <OfferAcceptedPill>{translate('offer_accepted')}</OfferAcceptedPill>
        <OfferAmount>
          {translate('transaction_item_amount', { offerAmount: offerDetails?.amount?.toLocaleString('en-IN') })}
        </OfferAmount>
        <ActionsContainer>
          <CounterOfferBtn onClick={() => setSupportModalVisible(true)}>
            <BtnIcon src={supportIcon} />
            {translate('support')}
          </CounterOfferBtn>
          <AcceptOfferBtn onClick={() => setLegalModalVisible(true)}>
            <BtnIcon src={bulletPointsIcon} />
            {translate('legal_next_steps')}
          </AcceptOfferBtn>
        </ActionsContainer>
      </OfferInfoContainer>
      {/* <FormContainer>
        <StoryTitleContainer>
          <StoryTitle>{publishedStory?.title}</StoryTitle>
          <CollapseBtn onClick={toggleForm} data-testid="toggle-form-btn" />
        </StoryTitleContainer>
        <If condition={formVisible}>
          <AcceptedStoryForm layout="vertical">
            <FormRow>
              <CustomFormItem name="language">
                <FormItemLabel>{translate('select_language')}</FormItemLabel>
                <MultipleContainer>
                  {publishedStory?.languages?.map((language: any) => (
                    <CustomDisableInput
                      key={language?.id}
                      placeholder={translate('language_placeholder')}
                      value={language?.name}
                      suffix={<StyledImg src={ArrowDown} />}
                      disabled={true}
                    />
                  ))}
                </MultipleContainer>
              </CustomFormItem>
              <CustomFormItem name="genre">
                <FormItemLabel>{translate('genre')}</FormItemLabel>
                <MultipleContainer>
                  {publishedStory?.genres?.map((genre: any) => (
                    <CustomDisableInput
                      key={genre?.id}
                      placeholder={translate('language_placeholder')}
                      value={genre?.name}
                      suffix={<StyledImg src={ArrowDown} />}
                      disabled={true}
                    />
                  ))}
                </MultipleContainer>
              </CustomFormItem>
            </FormRow>
            <FormRow>
              <CustomFormItem name="storyType">
                <FormItemLabel>{translate('story_type_label')}</FormItemLabel>
                <CustomDisableInput
                  placeholder={translate('language_placeholder')}
                  value={publishedStory?.storyType}
                  suffix={<StyledImg src={ArrowDown} />}
                  disabled={true}
                />
              </CustomFormItem>
              <CustomFormItem name="tags">
                <FormItemLabel>{translate('tags_label')}</FormItemLabel>
                <TagItemList>
                  {publishedStory?.tags?.map((tag: any) => (
                    <TagItem key={tag.id}>{tag?.name}</TagItem>
                  ))}
                </TagItemList>
              </CustomFormItem>
            </FormRow>
            <FormRow>
              <CustomFormItem name="logline">
                <FormItemLabel>{translate('logline_label')}</FormItemLabel>
                <Input.TextArea disabled={true} defaultValue={publishedStory?.logline} />
              </CustomFormItem>
              <CustomFormItem name="synopsis">
                <FormItemLabel>{translate('synopsis_label')}</FormItemLabel>
                <Input.TextArea disabled={true} defaultValue={publishedStory?.storyline} />
              </CustomFormItem>
            </FormRow>
            <If condition={screenPlayName !== ''}>
              <FormRow>
                <CustomFormItem name="screenplay">
                  <FormItemLabel>{translate('screenplay_label')}</FormItemLabel>
                  <StyledDragger disabled={true}>
                    <StyledImg src={disabledPageIcon} />
                    <UploadFileName>{screenPlayName}</UploadFileName>
                    {/* <UploadFileSize>850 kb</UploadFileSize> 
                  </StyledDragger>
                </CustomFormItem>
              </FormRow>
            </If>
            <FormRow>
              <If condition={narrationName !== ''}>
                <CustomFormItem name="audio_narration">
                  <FormItemLabel>{translate('audio_narration_label')}</FormItemLabel>
                  <StyledDragger disabled={true}>
                    <StyledImg src={disabledPageIcon} />
                    <UploadFileName>{narrationName}</UploadFileName>
                  </StyledDragger>
                </CustomFormItem>
              </If>
              <If condition={imagesName.length}>
                <CustomFormItem name="supporting_images">
                  <FormItemLabel>{translate('supporting_images_label')}</FormItemLabel>
                  <StyledDragger disabled={true}>
                    <StyledImg src={disabledPageIcon} />
                    <UploadFileName>{imagesName[0]}</UploadFileName>
                  </StyledDragger>
                </CustomFormItem>
              </If>
            </FormRow>
          </AcceptedStoryForm>
        </If>

        <StoryTitleContainer>
          <StoryTitle>{translate('insights_overview')}</StoryTitle>
          <CollapseBtn onClick={toggleChart} data-testid="toggle-chart-btn" />
        </StoryTitleContainer>
      </FormContainer> */}
      {/* <If condition={chartVisible}>
        <ChartContainer />
      </If> */}
      <StoryInfoContainer>
        <StoryTitle>{publishedStory?.title}</StoryTitle>
        <StoryDetailsContainer>
          <StoryDetailsInfoItem>
            <StoryDetailsInfoItemTitle>{translate('language')}</StoryDetailsInfoItemTitle>
            {publishedStory?.languages?.map((language: any, index: number) => (
              <StoryDetailsInfoItemDetail key={index}>{language?.name}</StoryDetailsInfoItemDetail>
            ))}
          </StoryDetailsInfoItem>
          <StoryDetailsInfoItem>
            <StoryDetailsInfoItemTitle>{translate('genre')}</StoryDetailsInfoItemTitle>
            {publishedStory?.genres?.map((genre: any, index: number) => (
              <StoryDetailsInfoItemDetail key={index}>{genre?.name}</StoryDetailsInfoItemDetail>
            ))}
          </StoryDetailsInfoItem>
          <StoryDetailsInfoItem>
            <StoryDetailsInfoItemTitle>{translate('story_type_label')}</StoryDetailsInfoItemTitle>
            <StoryDetailsInfoItemDetail>{publishedStory?.storyType?.name}</StoryDetailsInfoItemDetail>
          </StoryDetailsInfoItem>
          <StoryDetailsInfoItem>
            <StoryDetailsInfoItemTitle>{translate('co_writer')}</StoryDetailsInfoItemTitle>
            <StoryDetailsInfoItemDetail>{publishedStory?.coAuthor1}</StoryDetailsInfoItemDetail>
            <StoryDetailsInfoItemDetail>{publishedStory?.coAuthor2}</StoryDetailsInfoItemDetail>
          </StoryDetailsInfoItem>
          <StoryDetailsInfoItem>
            <StoryDetailsInfoItemTitle>{translate('tags')}</StoryDetailsInfoItemTitle>
            {publishedStory?.tags?.map((tag: any, index: number) => (
              <StoryDetailsInfoItemDetail key={index}>{tag?.name}</StoryDetailsInfoItemDetail>
            ))}
          </StoryDetailsInfoItem>
        </StoryDetailsContainer>
        <StoryDetailsInfoItemTitle2>{translate('logline_title')}</StoryDetailsInfoItemTitle2>
        <StoryDetailsInfoItemDetail2>{publishedStory?.logline}</StoryDetailsInfoItemDetail2>
        <StoryDetailsInfoItemTitle2>{translate('synopsis_title')}</StoryDetailsInfoItemTitle2>
        <StoryDetailsInfoItemDetail2>{publishedStory?.storyline}</StoryDetailsInfoItemDetail2>
        <SupportingMaterialsContainer>
          <SupportingMaterialsItem>
            <SupportingMaterialsItemTitle>{translate('screenplay_title')}</SupportingMaterialsItemTitle>
            <SupportingMaterialDownloadIcon
              src={downloadIcon}
              onClick={handleScreenplayDownload}
              data-testid="download-screenplay-btn"
            />
          </SupportingMaterialsItem>
          <SupportingMaterialsItem>
            <SupportingMaterialsItemTitle>{translate('audio_narration_title')}</SupportingMaterialsItemTitle>
            <SupportingMaterialDownloadIcon
              src={downloadIcon}
              onClick={handleNarrationDownload}
              data-testid="download-narration-btn"
            />
          </SupportingMaterialsItem>
          <SupportingMaterialsItem>
            <SupportingMaterialsItemTitle>{translate('supporting_images_title')}</SupportingMaterialsItemTitle>
            <SupportingMaterialDownloadIcon
              src={downloadIcon}
              onClick={handleImagesDownload}
              data-testid="download-images-btn"
            />
          </SupportingMaterialsItem>
        </SupportingMaterialsContainer>
      </StoryInfoContainer>
    </AcceptedOfferContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile(),
  offerDetails: selectOfferDetails(),
  publishedStory: selectStoryDetails(),
  sendMailToLegalTeamLoading: selectSendMailToLegalTeamLoading(),
  screenplayUrl: selectScreenPlayUrl(),
  narrationUrl: selectNarrationUrl(),
  imagesUrl: selectImagesUrlList()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetOfferDetails: (offerId: string) =>
      dispatch(
        requestGetOfferDetails({
          offerId
        })
      ),
    dispatchGetPublishedStory: (limit: number, page: number, storyId: string, sellerId: string) =>
      dispatch(requestGetStoryDetails({ limit, page, storyId, sellerId })),
    dispatchSendMailToLegalTeam: (supportNeeded: boolean, offerId: string) =>
      dispatch(
        requestSendMailToLegalTeam({
          supportNeeded,
          offerId
        })
      ),
    dispatchGetImagesUrl: (fileNames: string[]) => dispatch(requestGetImages({ fileNames })),
    dispatchGetNarrationUrl: (folderPath: string, fileName: string) =>
      dispatch(requestGetNarration({ folderPath, fileName })),
    dispatchGetScreenplaySignedUrl: (folderPath: string, fileName: string) =>
      dispatch(requestGetScreenPlay({ folderPath, fileName })),
    dispatchResetState: () => dispatch(resetState())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AcceptedOffer) as React.FC;

export const AcceptedOfferTest = AcceptedOffer;
