import React, { useEffect, useState } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import ClosedOfferCard from './components/ClosedOfferCard';
import OfferCard from './components/OfferCard';
import DeclineOfferCard from './components/DeclinedOfferCard';
import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { OffersReceivedProps } from './types';
import {
  requestGetClosedOffers,
  requestGetDeclinedOffers,
  requestGetOpenOffers,
  requestsGetScreenplayRequests
} from './reducer';
import {
  selectClosedOffers,
  selectClosedOffersLoading,
  selectClosedOffersTotal,
  selectDeclinedOffers,
  selectDeclinedOffersTotal,
  selectOpenOffers,
  selectOpenOffersLoading,
  selectOpenOffersTotal,
  selectScreenplayRequests,
  selectScreenplayRequestsTotal
} from './selectors';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { If, Pagination } from 'tsw-sdk';
import { requestGenerateStoryPdf, resetStoryPdfUrl } from '@app/containers/CopyrightContainer/reducer';
import { selectStoryPdfUrl } from '@app/containers/CopyrightContainer/selectors';
import CustomEmpty from '@app/components/CustomEmpty';
import RequestCard from './components/RequestCard';
import StyledSpin from '@app/components/StyledSpin';
import LegalDeclinedSupportModal from './components/LegalDeclinedSupportModal';

const OffersRecievedContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 3.25rem;
  padding-bottom: 2rem;
  background-color: ${colors.dashboardBg};
  padding-left: 2rem;
`;

const OffersRecievedTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.offersReceivedColors.titleColor};
`;

const OffersContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2.56rem;
`;

const ClosedOffersTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.offersReceivedColors.titleColor};
  margin-top: 2.56rem;
  margin-bottom: 2.56rem;
`;

const HeadingContainer = styled.div`
  width: 100%;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 3rem;
  margin-top: 2rem;
`;

const OffersRecieved = ({
  userProfile,
  openOffers,
  closedOffers,
  declinedOffers,
  openOffersTotal,
  closedOffersTotal,
  declinedOffersTotal,
  storyPdfUrl,
  screenplayRequests,
  screenplayRequestsTotal,
  loadingOpenOffers,
  loadingClosedOffers,
  dispatchGetOpenOffers,
  dispatchGetClosedOffers,
  dispatchGetDeclinedOffers,
  dispatchGenerateStoryPdf,
  dispatchResetStoryPdfUrl,
  dispatchGetScreenplayRequests
}: OffersReceivedProps) => {
  const perPage = 5;

  const [openOffersCurrentPage, setOpenOffersCurrentPage] = useState<number>(1);
  const [closedOffersCurrentPage, setClosedOffersCurrentPage] = useState<number>(1);
  const [declinedOffersCurrentPage, setDeclinedOffersCurrentPage] = useState<number>(1);
  const [currentLoadingStoryId, setCurrentLoadingStoryId] = useState<string>('');
  const [sellerRequestsCurrentPage, setSellerRequestsCurrentPage] = useState<number>(1);
  const [supportModalVisible, setSupportModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (userProfile) {
      if (Object.keys(userProfile).length > 2) {
        dispatchGetOpenOffers(userProfile?.id, perPage, openOffersCurrentPage);
        dispatchGetClosedOffers(userProfile?.id, perPage, closedOffersCurrentPage);
        dispatchGetDeclinedOffers(userProfile?.id, perPage, declinedOffersCurrentPage);
        dispatchGetScreenplayRequests(perPage, sellerRequestsCurrentPage);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile) {
      if (Object.keys(userProfile).length > 2) {
        dispatchGetOpenOffers(userProfile?.id, perPage, openOffersCurrentPage);
      }
    }
  }, [openOffersCurrentPage]);

  useEffect(() => {
    if (userProfile) {
      if (Object.keys(userProfile).length > 2) {
        dispatchGetClosedOffers(userProfile?.id, perPage, closedOffersCurrentPage);
      }
    }
  }, [closedOffersCurrentPage]);

  useEffect(() => {
    if (userProfile) {
      if (Object.keys(userProfile).length > 2) {
        dispatchGetDeclinedOffers(userProfile?.id, perPage, declinedOffersCurrentPage);
      }
    }
  }, [declinedOffersCurrentPage]);

  useEffect(() => {
    dispatchGetScreenplayRequests(perPage, sellerRequestsCurrentPage);
  }, [sellerRequestsCurrentPage]);

  useEffect(() => {
    if (storyPdfUrl) {
      const anchorTag = document.createElement('a');

      anchorTag.href = storyPdfUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();

      dispatchResetStoryPdfUrl();
      setCurrentLoadingStoryId('');
    }
  }, [storyPdfUrl]);

  const handleStoryDownload = (storyId: string) => {
    dispatchGenerateStoryPdf(userProfile?.id, storyId);
    setCurrentLoadingStoryId(storyId);
  };

  return (
    <OffersRecievedContainer>
      <LegalDeclinedSupportModal visible={supportModalVisible} onClose={() => setSupportModalVisible(false)} />

      <HeadingContainer>
        <OffersRecievedTitle>{translate('offers_recieved')}</OffersRecievedTitle>
        <Pagination
          total={openOffersTotal}
          currentPage={openOffersCurrentPage}
          perPage={perPage}
          setPage={(page: number) => setOpenOffersCurrentPage(page)}
          testKey="open-offers"
        />
      </HeadingContainer>
      <If condition={!loadingOpenOffers} otherwise={<StyledSpin />}>
        <If condition={openOffers && openOffers?.length > 0} otherwise={<CustomEmpty text={'no_offers'} />}>
          <OffersContainer>
            {openOffers?.map((offer: any) => (
              <OfferCard
                key={offer.id}
                offer={offer}
                handleDownloadStory={handleStoryDownload}
                currentLoadingStoryId={currentLoadingStoryId}
              />
            ))}
          </OffersContainer>
        </If>
      </If>
      <If condition={screenplayRequests && screenplayRequests?.length > 0}>
        <HeadingContainer>
          <ClosedOffersTitle>{translate('screenplay_requests_heading')}</ClosedOffersTitle>
          <Pagination
            total={screenplayRequestsTotal}
            currentPage={sellerRequestsCurrentPage}
            perPage={perPage}
            setPage={(page: number) => setSellerRequestsCurrentPage(page)}
            testKey="seller-requests"
          />
        </HeadingContainer>

        <OffersContainer>
          {screenplayRequests?.map((request: any) => (
            <RequestCard
              key={request.id}
              request={request}
              handleDownloadStory={handleStoryDownload}
              currentLoadingStoryId={currentLoadingStoryId}
            />
          ))}
        </OffersContainer>
      </If>
      <HeadingContainer>
        <ClosedOffersTitle>{translate('closed_offers')}</ClosedOffersTitle>
        <Pagination
          total={closedOffersTotal}
          currentPage={closedOffersCurrentPage}
          perPage={perPage}
          setPage={(page: number) => setClosedOffersCurrentPage(page)}
          testKey="closed-offers"
        />
      </HeadingContainer>
      <If condition={!loadingClosedOffers} otherwise={<StyledSpin />}>
        <If condition={closedOffers && closedOffers?.length > 0} otherwise={<CustomEmpty text={'no_offers'} />}>
          <OffersContainer>
            {closedOffers?.map((offer: any) => (
              <ClosedOfferCard
                key={offer.id}
                offer={offer}
                handleDownloadStory={handleStoryDownload}
                currentLoadingStoryId={currentLoadingStoryId}
                isStoryLegal={offer?.state !== 'DRAFTED'}
                openSupportModal={() => setSupportModalVisible(true)}
              />
            ))}
          </OffersContainer>
        </If>
      </If>
      <HeadingContainer>
        <ClosedOffersTitle>{translate('declined_offers')}</ClosedOffersTitle>
        <Pagination
          total={declinedOffersTotal}
          currentPage={declinedOffersCurrentPage}
          perPage={perPage}
          setPage={(page: number) => setDeclinedOffersCurrentPage(page)}
          testKey="declined-offers"
        />
      </HeadingContainer>
      <If condition={declinedOffers && declinedOffers?.length > 0} otherwise={<CustomEmpty text={'no_offers'} />}>
        <OffersContainer>
          {declinedOffers?.map((offer: any) => (
            <DeclineOfferCard
              key={offer.id}
              offer={offer}
              handleDownloadStory={handleStoryDownload}
              currentLoadingStoryId={currentLoadingStoryId}
            />
          ))}
        </OffersContainer>
      </If>
    </OffersRecievedContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  openOffers: selectOpenOffers(),
  closedOffers: selectClosedOffers(),
  declinedOffers: selectDeclinedOffers(),
  openOffersTotal: selectOpenOffersTotal(),
  closedOffersTotal: selectClosedOffersTotal(),
  declinedOffersTotal: selectDeclinedOffersTotal(),
  userProfile: selectUserProfile(),
  storyPdfUrl: selectStoryPdfUrl(),
  screenplayRequests: selectScreenplayRequests(),
  screenplayRequestsTotal: selectScreenplayRequestsTotal(),
  loadingOpenOffers: selectOpenOffersLoading(),
  loadingClosedOffers: selectClosedOffersLoading()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetOpenOffers: (sellerId: string, limit: number, page: number) =>
      dispatch(requestGetOpenOffers({ sellerId, limit, page })),
    dispatchGetClosedOffers: (sellerId: string, limit: number, page: number) =>
      dispatch(requestGetClosedOffers({ sellerId, limit, page })),
    dispatchGetDeclinedOffers: (sellerId: string, limit: number, page: number) =>
      dispatch(requestGetDeclinedOffers({ sellerId, limit, page })),
    dispatchGenerateStoryPdf: (sellerId: string, storyId: string) =>
      dispatch(
        requestGenerateStoryPdf({
          sellerId,
          storyId
        })
      ),
    dispatchResetStoryPdfUrl: () => dispatch(resetStoryPdfUrl()),
    dispatchGetScreenplayRequests: (limit: number, page: number) =>
      dispatch(requestsGetScreenplayRequests({ limit, page }))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OffersRecieved) as React.FC;

export const OffersRecievedTest = OffersRecieved;
