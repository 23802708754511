import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { GenreItemProps } from './types';

const GenreItemContainer = styled.div`
  max-width: 57rem;
  min-width: 20rem;
  height: 5rem;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  border-radius: 1rem;
  ${fonts.family.SpotwriterFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw400()};
  color: ${colors.profilePageColors.cardText};
  padding-left: 1rem;
`;

const GenreItem = ({ genre }: GenreItemProps) => {
  return <GenreItemContainer>{genre.name}</GenreItemContainer>;
};

export default GenreItem;
