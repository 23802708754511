import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  offerDetails: null,
  getOfferDetailsLoading: false,
  getOfferDetailsError: null
};

const offerDetailsSlice = createSlice({
  name: 'offerDetails',
  initialState,
  reducers: {
    requestGetOfferDetails: {
      reducer: (state) => {
        state.getOfferDetailsLoading = true;
      },
      prepare
    },
    successGetOfferDetails(state, action) {
      state.getOfferDetailsLoading = false;
      state.getOfferDetailsError = null;
      state.offerDetails = action.payload;
    },
    failureGetOfferDetails(state, action) {
      state.getOfferDetailsLoading = false;
      state.getOfferDetailsError = get(action.payload, 'message', 'something_went_wrong');
    },
    resetState(state) {
      state.offerDetails = null;
      state.getOfferDetailsLoading = false;
      state.getOfferDetailsError = null;
    }
  }
});

export const { requestGetOfferDetails, successGetOfferDetails, failureGetOfferDetails, resetState } =
  offerDetailsSlice.actions;

export default offerDetailsSlice.reducer;
