import React, { useEffect } from 'react';
import styled from 'styled-components';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { colors, fonts } from '@app/themes';
import { translate } from '@app/components/IntlGlobalProvider';
import { useHistory, useLocation } from 'react-router-dom';
import { RECIEPTS_CLOUD_FOLDER, TICKET_ACTION_MAP } from '@app/utils/constants';
import { LocationStatetypes, TicketTransactionDetailsProps } from './types';
import downloadIcon from '@images/icons/MyTickets/download-icon.svg';
import { connect } from 'react-redux';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { requestGetFileUrl, resetFileUrl } from '../PendingApproval/reducer';
import { selectSignedUrl } from '../PendingApproval/selectors';
import { createStructuredSelector } from 'reselect';

const TicketTransactionDetailsContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  padding-top: 4.5rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  background-color: ${colors.dashboardBg};
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  margin-top: 2rem;
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const DownloadImg = styled.img`
  cursor: pointer;
  margin-top: 0.5rem;
`;

const PageTitle = styled.p`
  margin-top: 3.5rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.myTicketsColors.pageTitle};
`;

const TransactionDetailsCardContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
`;

const TransactionDetailsCard = styled.div`
  min-width: 14.75rem;
  height: 8rem;
  background-color: ${colors.white};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &:not(:first-of-type) {
    margin-left: 1.5rem;
  }
`;

const TransactionDetailsCardTitle = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.big()};
  line-height: 1.75rem;
  color: ${colors.myTicketsColors.currentBalanceText};
`;

const TransactionDetailsCardInfo = styled.p`
  margin-top: 0.5rem;
  ${fonts.weights.fw800()};
  ${fonts.size.xBig()};
  line-height: 1.75rem;
  color: ${colors.myTicketsColors.myTicketTitle};
  padding: 0 0.5rem;
`;

const TicketTransactionDetails = ({
  dispatchGetFileUrl,
  currentFileUrl,
  dispatchResetState
}: TicketTransactionDetailsProps) => {
  const ticketDataLoc = useLocation<LocationStatetypes>();
  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatchResetState();
    };
  }, []);

  useEffect(() => {
    if (currentFileUrl) {
      const anchorTag = document.createElement('a');

      anchorTag.href = currentFileUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();
    }
  }, [currentFileUrl]);

  const handleDownload = () => {
    const filePathList = ticketDataLoc?.state?.receiptLink?.split('/');
    const fileName = filePathList[filePathList.length - 1];
    dispatchGetFileUrl(RECIEPTS_CLOUD_FOLDER, fileName);
  };

  return (
    <TicketTransactionDetailsContainer>
      <BackButton onClick={() => history.goBack()} data-testid="go-back-btn">
        <StyledImg src={ArrowLeft} />
        {translate('back')}
      </BackButton>
      <PageTitle>{translate('spot_ticket_amount', { ticketAmount: ticketDataLoc?.state?.amount })}</PageTitle>
      <TransactionDetailsCardContainer>
        <TransactionDetailsCard>
          <TransactionDetailsCardTitle>{translate('action_performed')}</TransactionDetailsCardTitle>
          <TransactionDetailsCardInfo>{TICKET_ACTION_MAP[ticketDataLoc?.state?.action]}</TransactionDetailsCardInfo>
        </TransactionDetailsCard>
        <TransactionDetailsCard>
          <TransactionDetailsCardTitle>{translate('mode_of_action')}</TransactionDetailsCardTitle>
          <TransactionDetailsCardInfo>{ticketDataLoc?.state?.method}</TransactionDetailsCardInfo>
        </TransactionDetailsCard>
        <TransactionDetailsCard>
          <TransactionDetailsCardTitle>{translate('payment_reciept')}</TransactionDetailsCardTitle>
          <DownloadImg src={downloadIcon} onClick={handleDownload} />
        </TransactionDetailsCard>
      </TransactionDetailsCardContainer>
    </TicketTransactionDetailsContainer>
  );
};

export const mapStateToProps = createStructuredSelector({
  currentFileUrl: selectSignedUrl()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetFileUrl: (path: string, fileName: string) => dispatch(requestGetFileUrl({ path, fileName })),
    dispatchResetState: () => dispatch(resetFileUrl())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TicketTransactionDetails);

export const TicketTransactionDetailsTest = TicketTransactionDetails;
