import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectMyDashboardDomain = (state: any) => state.myDashboard || initialState;

export const selectSubmissionDraftData = () =>
  createSelector(selectMyDashboardDomain, (substate) => get(substate, 'submissionDraftData'));

export const selectSubmissionDraftError = () =>
  createSelector(selectMyDashboardDomain, (substate) => get(substate, 'submissionDraftError'));

export const selectSubmissionDraftLoadingLoading = () =>
  createSelector(selectMyDashboardDomain, (substate) => get(substate, 'submissionDraftLoading'));

export const selectOffersReceivedData = () =>
  createSelector(selectMyDashboardDomain, (substate) => get(substate, 'offersReceivedData'));

export const selectoffersReceivedError = () =>
  createSelector(selectMyDashboardDomain, (substate) => get(substate, 'offersReceivedError'));

export const selectOffersReceivedLoading = () =>
  createSelector(selectMyDashboardDomain, (substate) => get(substate, 'offersReceivedLoading'));
