import { gql } from 'apollo-boost';

export const GET_DRAFT_STORIES = gql`
  query Stories($filters: NewStoryFilters, $pagination: StoryPagination!) {
    stories(filters: $filters, pagination: $pagination) {
      stories {
        id
        title
        languages {
          id
        }
        genres {
          id
        }
        storyType {
          id
        }
        tags {
          id
        }
        logline
        storyline
        createdAt
        updatedAt
        state
      }
      total
    }
  }
`;

export const GET_PUBLISHED_STORIES = gql`
  query Stories($filters: NewStoryFilters, $pagination: StoryPagination!) {
    stories(filters: $filters, pagination: $pagination) {
      stories {
        id
        title
        createdAt
        views
        offers
        ratings
        state
      }
      total
    }
  }
`;

export const GET_UNPUBLISHED_STORIES = gql`
  query Stories($filters: NewStoryFilters, $pagination: StoryPagination!) {
    stories(filters: $filters, pagination: $pagination) {
      stories {
        id
        title
        createdAt
        views
        offers
        ratings
        state
      }
      total
    }
  }
`;

export const GET_PENDING_APPROVAL = gql`
  query Stories($filters: NewStoryFilters, $pagination: StoryPagination!) {
    stories(filters: $filters, pagination: $pagination) {
      stories {
        id
        title
        createdAt
      }
      total
    }
  }
`;

export const GET_DELETED_DRAFTS = gql`
  query Stories($filters: NewStoryFilters, $pagination: StoryPagination!) {
    stories(filters: $filters, pagination: $pagination) {
      stories {
        id
        title
        languages {
          id
        }
        genres {
          id
        }
        storyType {
          id
        }
        tags {
          id
        }
        logline
        storyline
        createdAt
        updatedAt
        state
      }
      total
    }
  }
`;
