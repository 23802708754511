import { colors, fonts } from '@app/themes';
import React, { useState, useEffect } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import styled from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CustomButton, CustomInput } from 'tsw-sdk';
import { Form } from 'antd';
import { CopyrightProtectionFormProps } from './types';
import moment from 'moment';

const CopyrightProtectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
`;

const CopyrightProtectionContentContainer = styled.div`
  width: 100%;
  max-width: 38rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
`;

const CopyrightProtectionTitle = styled.h2`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xlarge()};
  color: ${colors.copyrightColors.copyrightDeclarationTitle};
  margin-bottom: 0;
`;

const ProceedBtn = styled(CustomButton)`
  margin-top: 3rem;
  && {
    width: 100%;
    border: 1px solid ${colors.primary};
    background-color: transparent;
    justify-content: center;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const CopyrightProtectionFormContainer = styled.div`
  margin-top: 3rem;
  margin-right: auto;
`;

const CopyrightProtectionFormMessage = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;
`;

const CopyrightForm = styled(Form)`
  margin-top: 1rem;
`;

const CustomFormItem = styled(Form.Item)`
  margin-top: 1rem;

  &.ant-form-item {
    width: 20rem;
    margin-bottom: 0;
    row-gap: 0.5rem;
  }

  & input {
    width: 16rem;
  }

  .ant-form-item-label {
    padding: 0 !important;
    row-gap: 0.5rem;
    & label {
      ${fonts.family.SpoofRegular()};
      ${fonts.size.regular()};
      ${fonts.weights.fw400()};
      line-height: 1.31rem;
      white-space: nowrap;
    }
  }
`;

const ApplyingOnBehalfText = styled.p`
  width: 100%;
  margin-top: 0.5rem;
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  ${fonts.family.SpoofRegular()};
  ${fonts.size.xsmall()};
  ${fonts.weights.fw400()};
  line-height: 1rem;
`;

const PleaseNoteText = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  margin-top: 1rem;
  line-height: 1.31rem;
`;

const PleaseNote = styled.span`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.regular()};
  ${fonts.weights.fw700()};
`;

const CopyrightProtectionForm = ({
  sellerId,
  storyId,
  copyrightProtectionDetails,
  copyrightRegistrationDetails,
  dispatchUpdateCopyrightRegistrationDetails,
  dispatchGetCopyrightRegistrationDetails,
  setCopyrightRegistrationFormValues
}: CopyrightProtectionFormProps) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<any>({});
  const [proceedDisabled, setProceedDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (copyrightRegistrationDetails) {
      form.setFieldsValue({
        applicantName: copyrightRegistrationDetails?.applicantName,
        diaryNumber: copyrightRegistrationDetails?.diaryNumber,
        category: copyrightRegistrationDetails?.submissionCategory,
        submissionDate: moment(copyrightRegistrationDetails?.submittedAt).format('YYYY-MM-DD')
      });

      setFormValues({
        applicantName: copyrightRegistrationDetails?.applicantName,
        diaryNumber: copyrightRegistrationDetails?.diaryNumber,
        category: copyrightRegistrationDetails?.submissionCategory,
        submissionDate: moment(copyrightRegistrationDetails?.submittedAt).format('YYYY-MM-DD')
      });

      setCopyrightRegistrationFormValues({
        applicantName: copyrightRegistrationDetails?.applicantName,
        diaryNumber: copyrightRegistrationDetails?.diaryNumber,
        category: copyrightRegistrationDetails?.submissionCategory,
        submissionDate: moment(copyrightRegistrationDetails?.submittedAt).format('YYYY-MM-DD')
      });
    }
  }, [copyrightRegistrationDetails]);

  useEffect(() => {
    if (copyrightProtectionDetails?.copyrightProtectionId) {
      dispatchGetCopyrightRegistrationDetails(sellerId, storyId, copyrightProtectionDetails?.copyrightProtectionId);
    }
  }, [copyrightProtectionDetails]);

  useEffect(() => {
    const isDisabled = getIsDisabled();
    setProceedDisabled(isDisabled);
    setFormValues(formValues);
  }, [formValues]);

  const getIsDisabled = () => {
    let isDisabled = false;
    const values = form.getFieldsValue();

    if (!values.applicantName || !values.diaryNumber || !values.submissionDate || !values.category) {
      isDisabled = true;
    }

    if (!copyrightProtectionDetails?.copyrightProtectionId) {
      isDisabled = true;
    }

    return isDisabled;
  };

  const handleProceed = () => {
    const values = form.getFieldsValue();

    const payload = {
      storyId: storyId,
      copyrightProtectionID: copyrightProtectionDetails?.copyrightProtectionId,
      applicantName: values.applicantName,
      diaryNumber: values.diaryNumber,
      submittedAt: new Date(values.submissionDate).valueOf(),
      submissionCategory: values.category,
      proceedToPreSubmission: true
    };

    dispatchUpdateCopyrightRegistrationDetails(payload);
  };

  const handleFormChange = (values: any) => {
    setFormValues(values);
    const formData = form.getFieldsValue();
    setCopyrightRegistrationFormValues(formData);
  };

  return (
    <CopyrightProtectionContainer data-testid="copyright-protection-form-container">
      <CopyrightProtectionContentContainer>
        <CopyrightProtectionTitle>{translate('copyright_protection')}</CopyrightProtectionTitle>
        <CopyrightProtectionFormContainer>
          <CopyrightProtectionFormMessage>
            {translate('copyright_protection_form_message')}
          </CopyrightProtectionFormMessage>
          <CopyrightForm layout="vertical" form={form} onValuesChange={handleFormChange}>
            <CustomFormItem label={translate('applicant_name_label')} name="applicantName">
              <CustomInput placeholder={translate('applicant_name_placeholder')} data-testid="applicant-name" />
            </CustomFormItem>
            <ApplyingOnBehalfText>{translate('applying_on_someones_behalf_message')}</ApplyingOnBehalfText>
            <CustomFormItem label={translate('diary_number_label')} name="diaryNumber">
              <CustomInput placeholder={translate('swa_membership_placeholder')} data-testid="diarynumber" />
            </CustomFormItem>
            <CustomFormItem label={translate('year_and_month_submission_label')} name="submissionDate">
              <CustomInput
                placeholder={translate('year_and_month_placeholder')}
                type="date"
                data-testid="submission-date"
                max={new Date().toISOString().split('T')[0]}
              />
            </CustomFormItem>
            <CustomFormItem label={translate('which_category_label')} name="category">
              <CustomInput placeholder={translate('swa_membership_placeholder')} data-testid="category" />
            </CustomFormItem>
          </CopyrightForm>
        </CopyrightProtectionFormContainer>
        <PleaseNoteText>
          <PleaseNote>{translate('please_note_text_2')}</PleaseNote>
          {translate('please_note_message_2')}
        </PleaseNoteText>
        <ProceedBtn
          text_color={`${colors.darkBorder}`}
          disabled={proceedDisabled}
          onClick={handleProceed}
          data-testid="proceed-btn"
        >
          {translate('proceed')} <ArrowRightOutlined />
        </ProceedBtn>
      </CopyrightProtectionContentContainer>
    </CopyrightProtectionContainer>
  );
};

export default CopyrightProtectionForm;
