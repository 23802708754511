import { colors, fonts } from '@app/themes';
import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { NotificationPopupProps } from '../../types';

const NotificationModal = styled(Modal)`
  margin-left: 20rem;

  &&& {
    .ant-modal-content {
      border-radius: 0.5rem;
    }

    .ant-modal-footer {
      display: none;
    }
  }
`;

const NotificationBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NotificationTitle = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  line-height: 1.62rem;
  color: ${colors.notificationColors.notificationTitle};
`;

const NotificationContent = styled.p`
  margin-top: 0.875rem;
  ${fonts.weights.fw400()};
  ${fonts.size.big()};
  line-height: 1.62rem;
  color: ${colors.notificationColors.notificationTitle};
`;

const NotificationPopup = ({ isVisible, notification, handleClose }: NotificationPopupProps) => {
  return (
    <NotificationModal visible={isVisible} centered={true} onCancel={handleClose} footer={<></>} destroyOnClose={true}>
      <NotificationBody>
        <NotificationTitle>{notification?.title}</NotificationTitle>
        <NotificationContent>{notification?.desc}</NotificationContent>
      </NotificationBody>
    </NotificationModal>
  );
};

export default NotificationPopup;
