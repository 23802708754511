import React from 'react';
import styled from 'styled-components';
import { MyDraftsProps } from '../../types';
import SubmissionDraftItem from '../SubmissionDraftItem';

const DraftContainer = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0;
  margin-bottom: ${(props) => props.marginBottom};
`;

const MyDrafts = ({ drafts, type, marginBottom, handleContinue }: MyDraftsProps) => {
  return (
    <DraftContainer marginBottom={marginBottom}>
      {drafts?.map(({ id, title, date, progress, isCopyrightPending }) => (
        <SubmissionDraftItem
          date={date}
          title={title}
          type={type}
          percent={Math.floor((progress / 7) * 100)}
          progress={`0${progress}/7`}
          key={id}
          id={id}
          isCopyrightPending={isCopyrightPending}
          handleContinue={handleContinue}
        />
      ))}
    </DraftContainer>
  );
};

export default MyDrafts;
