import ApolloClient, { DocumentNode, FetchResult, InMemoryCache } from 'apollo-boost';
import camelCase from 'lodash-es/camelCase';
import { mapKeysDeep } from '.';

export const createClient = (token: string) =>
  new ApolloClient({
    uri: process.env.BACKENED_API,
    request: (operation) => {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
          x_tsw_env: process.env.ENVIRONMENT_NAME
        }
      });
    },
    cache: new InMemoryCache()
  });

export let client = createClient('');

export const setClient = (token: string) => {
  client = createClient(token);
};

export interface GqlQueryReponse<Data> {
  data?: Data;
  error?: any;
  ok: boolean;
}

export const getQueryResponse = <Data, Variables>(
  query: DocumentNode,
  variables?: Variables
): Promise<GqlQueryReponse<Data>> => {
  return client
    .query<Data, Variables>({ query, variables, fetchPolicy: 'network-only' })
    .then((res) => {
      if (res.errors) {
        return { error: res.errors, ok: false };
      } else {
        return { data: mapKeysDeep(res.data, camelCase), ok: true };
      }
    })
    .catch((err) => {
      return { error: err, ok: false };
    });
};

export const getMutationResponse = <Data, Variables>(
  mutation: DocumentNode,
  variables?: Variables
): Promise<FetchResult<Data, Record<string, any>, Record<string, any>>> => {
  return client.mutate<Data, Variables>({ mutation, variables }).then((res) => {
    if (res.errors) {
      return { error: res.errors, ok: false };
    } else {
      return { data: mapKeysDeep(res.data, camelCase), ok: true };
    }
  });
};
