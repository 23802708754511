import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { CustomButton, If } from 'tsw-sdk';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { StoryRenewPageProps } from '../../types';
import { message } from 'antd';

const SuccessPageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  padding: 2.25rem;
`;

const SuccessPageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const PageTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.primary};
  margin-bottom: 2rem;
`;

const SpotTicketBalanceContainer = styled.div`
  width: 31.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :nth-child(3) {
    margin-top: 1rem;
  }
`;

const ContainerText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  line-height: 1.62rem;
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
`;

const Divider = styled.div`
  height: 0.12rem;
  width: 31rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin-top: 1.75rem;
  margin-bottom: 0.75rem;
`;

const RenewButton = styled(CustomButton)`
  width: 8.5rem;
  margin-top: 2.37rem;
  margin-left: auto;
  margin-right: auto;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  margin-top: 2rem;
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const StoryRenewPage = ({
  spotTicketsBalance,
  storyId,
  generatePaymentLinkLoading,
  handleBack,
  handleRenewStory,
  dispatchGenerateAddSpotTicketLink
}: StoryRenewPageProps) => {
  const handleRenewStoryValidate = () => {
    if (spotTicketsBalance < 250) {
      // dispatchGenerateAddSpotTicketLink(
      //   0,
      //   250 - spotTicketsBalance,
      //   storyId,
      //   'RENEW_STORY_ADD_TICKETS',
      //   'adding missing tickets amount to seller balance'
      // );
      message.error(translate('low_balance'));
    } else {
      handleRenewStory();
    }
  };
  return (
    <SuccessPageContainer>
      <BackButton onClick={handleBack} data-testid="back-transaction-btn">
        <StyledImg src={ArrowLeft} />
        {translate('back')}
      </BackButton>
      <SuccessPageContentContainer>
        <PageTitle>{translate('renew_spot_tickets')}</PageTitle>

        <SpotTicketBalanceContainer>
          <ContainerText>{translate('spot_tickets_balance')}</ContainerText>
          <ContainerText>{translate('tickets_amount', { amount: spotTicketsBalance })}</ContainerText>
        </SpotTicketBalanceContainer>
        <SpotTicketBalanceContainer>
          <ContainerText>{translate('renewal_fee')}</ContainerText>
          <ContainerText>{translate('tickets_amount', { amount: 250 })}</ContainerText>
        </SpotTicketBalanceContainer>
        <Divider />
        <If condition={spotTicketsBalance >= 250}>
          <SpotTicketBalanceContainer>
            <ContainerText>{translate('new_balance')}</ContainerText>
            <ContainerText>{translate('tickets_amount', { amount: spotTicketsBalance - 250 })}</ContainerText>
          </SpotTicketBalanceContainer>
        </If>
        <If condition={spotTicketsBalance < 250}>
          <SpotTicketBalanceContainer>
            <ContainerText>{translate('min_required')}</ContainerText>
            <ContainerText>{translate('tickets_amount', { amount: 250 })}</ContainerText>
          </SpotTicketBalanceContainer>
        </If>

        <RenewButton
          onClick={handleRenewStoryValidate}
          data-testid="renew-now-btn"
          loading={generatePaymentLinkLoading}
        >
          <If condition={spotTicketsBalance >= 250} otherwise={translate('add_spot_ticket')}>
            {translate('renew_now')}
          </If>
        </RenewButton>
      </SuccessPageContentContainer>
    </SuccessPageContainer>
  );
};

export default StoryRenewPage;
