import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { FilmItemProps } from './types';

const FilmItemContainer = styled.div`
  width: 18rem;
  height: 14.5rem;
  background-color: ${colors.profilePageColors.filmCardBg};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  border-radius: 1rem;
  ${fonts.family.SpotwriterFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw400()};
  color: ${colors.profilePageColors.cardText};

  &:not(:first-of-type) {
    margin-left: 2rem;
  }
`;

const FilmItem = ({ film }: FilmItemProps) => {
  return <FilmItemContainer>{film.name}</FilmItemContainer>;
};

export default FilmItem;
