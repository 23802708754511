import { colors, fonts } from '@app/themes';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CopyrightSidebar from './components/CopyrightSidebar';
import ScreenWritersAssociation from './ScreenwritersAssociation';
import ProtectStory from './ProtectStory';
import CopyrightDeclaration from './CopyrightDeclaration';
import CopyrightProtection from './CopyrightProtection';
import SubmissionPage from './SubmissionContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  selectBackPressed,
  selectBeginSubmissionProcess,
  selectConfirmStorySubmissionLoading,
  selectCopyrightAuthorVerificationDetails,
  selectCopyrightPaymentStatusData,
  selectCopyrightPreSubmissionDetails,
  selectCopyrightProtectionCurrentStep,
  selectCopyrightProtectionDetails,
  selectCopyrightRegistrationDetails,
  selectCopyrightWithTicketsLoading,
  selectCurrentStoryId,
  selectGeneratePaymentLinkLoading,
  selectGovtIdFileName,
  selectGovtIdFilePath,
  selectHasSelfAttestedStory,
  selectIsAuthor,
  selectIsStoryCopyrighted,
  selectIsStoryRegistered,
  selectIsStoryRegisteredSWA,
  selectNocActive,
  selectNocFileName,
  selectNocFilePath,
  selectNocSignedDocument,
  selectPoaSignedDocument,
  selectSelfAttestationDetails,
  selectShouldCopyright,
  selectSignedGovtidFilePath,
  selectSignedPOADocumentPath,
  selectSignedPoaFileName,
  selectSignedStoryDocumentPath,
  selectSignedStoryFileName,
  selectSignedSwaQrCodeFilePath,
  selectStoryPdfUrl,
  selectStoryTitle,
  selectSubmissionStatus,
  selectSwaQrCodeFilePath,
  selectSwaRegistrationDetails
} from './selectors';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import {
  requestBeginSubmissionProcess,
  requestConfirmStorySubmission,
  requestCopyrightWithTickets,
  requestGeneratePaymentLink,
  requestGenerateStoryPdf,
  requestGetCopyrightAuthorVerificationDetails,
  requestGetCopyrightPaymentStatus,
  requestGetCopyrightPreSubmissionDetails,
  requestGetCopyrightProtectionDetails,
  requestGetCopyrightRegistrationDetails,
  requestGetGovtDetails,
  requestGetHasSelfAttestedStory,
  requestGetNocSignedDocument,
  requestGetPoaSignedDocument,
  requestGetSelfAttestationDetails,
  requestGetSubmissionStatus,
  requestGetSwaRegistrationDetails,
  requestPreviewFile,
  requestStateChange,
  requestUpdateAuhtorVerificationDetails,
  requestUpdateCopyrightProtectionStrategy,
  requestUpdateCopyrightRegistrationDetails,
  requestUpdateCopyrightSelfAttestationDetails,
  requestUpdateSwaRegistrationDetails,
  requestUploadGovtId,
  requestUploadNocFile,
  requestUploadSignedPoaDocument,
  requestUploadSignedStoryDocument,
  requestUploadSwaQrCode,
  resetCopyrightDeclarationState,
  resetCopyrightProtection,
  resetGovtIdFilePath,
  resetState,
  resetStoryPdfUrl,
  resetSwaQrCodeFilePath,
  resetSwaState,
  setBackPressed,
  setCopyrightCurrentStep,
  setCurrentStoryId,
  setIsAuthor,
  setIsStoryCopyrighted,
  setIsStoryRegistered,
  setIsStoryRegisteredSWA,
  setNocActive,
  setNocFilePath,
  setShouldCopyright,
  setSignedPoaDocumentPath,
  setSignedStoryDocumentPath
} from './reducer';
import { CopyrightContainerProps, CopyrightStep } from './types';
import { translate } from '@app/components/IntlGlobalProvider';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import routeConstants from '@app/utils/routeConstants';
import { AUTHOR_STATES, COPYRIGHT_PROTECTION_STEPS } from '@app/utils/constants';
import { selectUserProfile } from '../ProfileContainer/selectors';
import { selectSpotTicketsBalance } from '../DashboardContainerPage/MySpotTickets/selectors';
import { requestGetSpotTickets } from '../DashboardContainerPage/MySpotTickets/reducer';
import { requestGetProfile } from '../ProfileContainer/reducer';
import { selectGlobalLoading } from '../App/selectors';
import { PageSpinner } from 'tsw-sdk';

const CopyrightFlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: hidden;
`;

const CopyrightContentContainer = styled.div`
  width: calc(100% - 19.5rem);
  height: auto;
  min-height: 100vh;
  background: ${colors.copyrightColors.backgroundColor};
  overflow-y: auto;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  margin-top: 2rem;
  position: absolute;
  top: 10px;
  left: 22rem;
`;

const StyledImg = styled.img``;

const CopyrightContainer = ({
  isStoryCopyrighted,
  isStoryRegistered,
  shouldCopyright,
  isAuthor,
  isStoryRegisteredSWA,
  beginSubmissionProcessLoading,
  currentStoryId,
  submissionStatus,
  copyrightAuthorVerificationDetails,
  nocActive,
  nocFilePath,
  nocFileName,
  swaQrCodeFilePath,
  swaRegistrationDetails,
  copyrightProcessCurrentStep,
  backPressed,
  copyrightProtectionDetails,
  copyrightRegistrationDetails,
  copyrightPreSubmissionDetails,
  signedPoaDocumentPath,
  signedStoryDocumentPath,
  signedStoryDocumentFileName,
  signedStoryPoaFileName,
  storyPdfUrl,
  selfAttestationDetails,
  userProfile,
  storyTitle,
  confirmStorySubmissionLoading,
  hasSelfAttestedStory,
  spotTicketsBalance,
  copyrightPaymentStatusData,
  generatePaymentLinkLoading,
  copyrightWithTicketsLoading,
  globalLoading,
  govtIdFilePath,
  govtIdFileName,
  signedGovtIdFilePath,
  signedSwaFilePath,
  poaSignedDocumentPath,
  nocSignedDocumentPath,
  dispatchResetState,
  dispatchSetIsStoryCopyrighted,
  dispatchSetIsStoryRegistered,
  dispatchSetShouldCopyright,
  dispatchSetIsAuthor,
  dispatchSetIsStoryRegisteredSWA,
  dispatchGetCopyrightSubmissionStatus,
  dispatchBeginSubmissionProcess,
  dispatchGetCopyrightAuthorVerificationDetails,
  dispatchSetNocActive,
  dispatchUploadNocFile,
  dispatchSetNocFilePath,
  dispatchUpdateAuthorVerificationDetails,
  dispatchGetSwaRegistrationDetails,
  dispatchUpdateSwaRegistrationDetails,
  dispatchUpdateCopyrightRegistrationDetails,
  dispatchUploadSWAQrCodeFile,
  dispatchUpdateCopyrightSelfAttestationDetails,
  dispatchSetBackPressed,
  dispatchGetCopyrightProtectionDetails,
  dispatchUpdateCopyrightProtectionStrategy,
  dispatchGetCopyrightRegistrationDetails,
  dispatchGetCopyrightPreSubmissionDetails,
  dispatchConfirmStorySubmission,
  dispatchSetSignedPoaDocumentPath,
  dispatchUploadPoaDocumentDocument,
  dispatchUploadSignedStoryDocument,
  dispatchSetSignedStoryPath,
  dispatchChangeStoryState,
  dispatchGenerateStoryPdf,
  dispatchGetSelfAttestationDetails,
  dispatchResetCopyrightDeclarationState,
  dispatchResetSwaState,
  dispatchResetCopyrightProtection,
  dispatchResetStoryPdfUrl,
  dispatchGetHasSelfAttestedStory,
  dispatchGetMySpotTickets,
  dispatchResetSwaQrCodeFilePath,
  dispatchGetCopyrightPaymentStatusData,
  dispatchSetCurrentStoryId,
  dispatchGetUserProfile,
  dispatchCopyrightWithTickets,
  dispatchGenerateCopyrightPaymentLink,
  dispatchUploadGovtIdFile,
  dispatchResetGovtIdFilePath,
  dispatchGetGovtDetails,
  dispatchPreviewFile,
  dispatchGetNocDocument,
  dispatchGetPoaDocument,
  dispatchSetCopyrightCurrentStep
}: CopyrightContainerProps) => {
  const location = useLocation();
  const history = useHistory();
  const [currentStage, setCurrentStage] = useState<number>(1);
  const [descriptionText, setDescriptionText] = useState<string[]>([]);
  const [swaFormValues, setSwaFormValues] = useState<any>({});
  const [stepFormValues, setStepFormValues] = useState<any>({});
  const [copyrightRegistrationFormValues, setCopyrightRegistrationFormValues] = useState<any>({});

  const params = new URLSearchParams(location?.search);

  const activePage = params.get('page');
  const mode = params.get('mode');

  useEffect(() => {
    const storyId = params.get('storyId');
    if (storyId) {
      dispatchSetCurrentStoryId(storyId);
    }

    if (userProfile) {
      if (Object.keys(userProfile).length <= 2) {
        dispatchGetUserProfile();
      }
    }
  }, []);

  useEffect(() => {
    if (submissionStatus) {
      let currentStep = 0;

      const firstStepStatus = submissionStatus?.stepOneStatus;
      currentStep = firstStepStatus.status === 'COMPLETE' ? 2 : 1;

      const secondStepStatus = submissionStatus?.stepTwoStatus;
      currentStep =
        secondStepStatus.status === 'COMPLETE' ? 3 : secondStepStatus.status === 'PENDING' ? 2 : currentStep;

      const thirdStepStatus = submissionStatus?.stepThreeStatus;
      currentStep = thirdStepStatus.status === 'COMPLETE' ? 4 : thirdStepStatus.status === 'PENDING' ? 3 : currentStep;

      const fourthStepStatus = submissionStatus?.stepFourStatus;

      currentStep =
        fourthStepStatus.status === 'COMPLETE' ? 5 : fourthStepStatus.status === 'PENDING' ? 4 : currentStep;

      const page = getPageFromStep(currentStep);

      if (page !== activePage) {
        if (!backPressed) {
          history.push(
            mode !== null
              ? `${routeConstants.copyrightContainer.route}?page=${page}&storyId=${currentStoryId}&mode=${mode}`
              : `${routeConstants.copyrightContainer.route}?page=${page}&storyId=${currentStoryId}`
          );
        }
      }
    }
  }, [submissionStatus]);

  const getPageFromStep = (currentStep: number) => {
    let page = 'protect_story';
    if (currentStep === 1) {
      page = 'protect_story';
    } else if (currentStep === 2) {
      page = 'declaration';
    } else if (currentStep === 3) {
      page = 'swa';
    } else if (currentStep === 4) {
      page = 'copyright_protection';
    } else if (currentStep === 5) {
      page = 'submission';
    } else {
      page = 'submission';
    }

    return page;
  };

  useEffect(() => {
    if (
      (activePage === 'protect_story' ||
        activePage === 'declaration' ||
        activePage === 'swa' ||
        activePage === 'copyright_protection' ||
        activePage === 'submission') &&
      userProfile
    ) {
      dispatchGetCopyrightSubmissionStatus(userProfile?.id, currentStoryId);
    } else {
      if (activePage === null) {
        dispatchResetState();
        history.push(routeConstants.dashboard.route);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (activePage === 'protect_story') {
      setCurrentStage(1);

      setDescriptionText([]);
    } else if (activePage === 'declaration') {
      if (nocActive) {
        setDescriptionText([translate('sidebar_declaration'), translate('sidebar_noc')]);
      } else {
        setDescriptionText([translate('sidebar_declaration')]);
      }
      setCurrentStage(2);
    } else if (activePage === 'swa') {
      setCurrentStage(3);
      setDescriptionText([translate('sidebar_swa')]);
    } else if (activePage === 'copyright_protection') {
      setCurrentStage(4);

      if (isStoryCopyrighted === 'yes') {
        setDescriptionText([translate('sidebar_copyright_protection_form')]);
      } else {
        setDescriptionText([translate('sidebar_copyright_protection_steps_intro')]);
      }
    } else if (activePage === 'submission') {
      setCurrentStage(5);
      setDescriptionText([]);
    } else {
      setDescriptionText([]);
    }
  }, [activePage, nocActive]);

  const handleGoBack = () => {
    let previousPage = '';

    if (activePage === 'copyright_protection') {
      if (copyrightProcessCurrentStep === COPYRIGHT_PROTECTION_STEPS.STEP1) {
        dispatchResetCopyrightProtection();
        previousPage = 'swa';
      } else {
        if (copyrightProcessCurrentStep !== COPYRIGHT_PROTECTION_STEPS.DONE) {
          const newStep = `STEP${Number(copyrightProcessCurrentStep.split('STEP')[1]) - 1}`;
          dispatchSetCopyrightCurrentStep(COPYRIGHT_PROTECTION_STEPS[newStep]);
        }
      }
    } else if (activePage === 'swa') {
      previousPage = 'declaration';
      dispatchResetSwaState();
    } else if (activePage === 'declaration') {
      previousPage = 'protect_story';
      dispatchResetCopyrightDeclarationState();
    }

    if (previousPage !== '') {
      dispatchSetBackPressed(true);

      history.push(
        mode !== null
          ? `${routeConstants.copyrightContainer.route}?page=${previousPage}&storyId=${currentStoryId}&mode=${mode}`
          : `${routeConstants.copyrightContainer.route}?page=${previousPage}&storyId=${currentStoryId}`
      );
    }
  };

  const saveProcessAndExit = () => {
    if (activePage === 'protect_story') {
      const payload = {
        sellerId: userProfile?.id,
        storyId: currentStoryId,
        proceedToNextStep: false,
        govDoc: {
          isFileUploaded: !!govtIdFilePath,
          filepath: govtIdFilePath
        }
      };

      dispatchBeginSubmissionProcess(payload);
    } else if (activePage === 'declaration') {
      let payload = {};
      let shouldSave = false;
      if (isAuthor === 'yes') {
        shouldSave = true;
        payload = {
          storyId: currentStoryId,
          copyrightId: copyrightAuthorVerificationDetails?.copyrightId,
          hasClaimedAuthor: AUTHOR_STATES.CLAIMED_AUTHOR,
          proceedToNextStep: false
        };
      } else if (isAuthor === 'no') {
        if (nocFileName) {
          shouldSave = true;
          payload = {
            storyId: currentStoryId,
            copyrightId: copyrightAuthorVerificationDetails?.copyrightId,
            hasClaimedAuthor: AUTHOR_STATES.DISCLAIMED_AUTHOR,
            noc: {
              isFileUploaded: true,
              filepath: nocFileName
            },
            proceedToNextStep: false
          };
        } else {
          shouldSave = true;
          payload = {
            storyId: currentStoryId,
            copyrightId: copyrightAuthorVerificationDetails?.copyrightId,
            hasClaimedAuthor: AUTHOR_STATES.DISCLAIMED_AUTHOR,
            noc: {
              isFileUploaded: false,
              filepath: nocFileName
            },
            proceedToNextStep: false
          };
        }
      }

      if (shouldSave) {
        dispatchUpdateAuthorVerificationDetails(payload);
      } else {
        history.push(routeConstants.dashboard.route);
      }
    } else if (activePage === 'swa') {
      let payload = {};

      const values = swaFormValues;

      if (isStoryRegisteredSWA === 'yes') {
        payload = {
          storyId: currentStoryId,
          isStorySWARegistered: true,
          copyrightId: swaRegistrationDetails?.copyrightId,
          isCopyrightActProtected: isStoryCopyrighted === 'yes' ? true : null,
          registrationDetails: {
            membershipNo: values?.membershipNumber,
            registrationNo: values?.registrationNumber,
            registrationQRCodePath: swaQrCodeFilePath
          },
          proceedToNextStep: false
        };
      } else if (isStoryRegisteredSWA === 'no') {
        payload = {
          storyId: currentStoryId,
          isStorySWARegistered: false,
          copyrightId: swaRegistrationDetails?.copyrightId,
          isCopyrightActProtected: isStoryCopyrighted === 'yes' ? true : null,
          proceedToNextStep: false
        };
      }

      if (Object.keys(payload).length > 0) {
        dispatchUpdateSwaRegistrationDetails({ swaDetails: payload });
      } else {
        history.push(routeConstants.dashboard.route);
      }
    } else if (activePage === 'copyright_protection') {
      if (isStoryCopyrighted === 'yes') {
        dispatchUpdateCopyrightRegistrationDetails({
          storyId: currentStoryId,
          copyrightProtectionID: copyrightProtectionDetails?.copyrightProtectionId,
          applicantName: copyrightRegistrationFormValues.applicantName,
          diaryNumber: copyrightRegistrationFormValues.diaryNumber,
          submittedAt: new Date(copyrightRegistrationFormValues.submissionDate).valueOf(),
          submissionCategory: copyrightRegistrationFormValues.category,
          proceedToPreSubmission: false
        });
      } else {
        if (copyrightProtectionDetails?.copyrightProtectionId) {
          switch (copyrightProcessCurrentStep) {
            case COPYRIGHT_PROTECTION_STEPS.STEP1:
              history.push(`${routeConstants.dashboard.route}?activePage=submissions`);
              break;
            case COPYRIGHT_PROTECTION_STEPS.STEP2:
              if (signedStoryDocumentFileName !== '' || signedStoryDocumentPath !== '') {
                dispatchUpdateCopyrightSelfAttestationDetails({
                  data: {
                    storyId: currentStoryId,
                    copyrightProtectionID: copyrightProtectionDetails?.copyrightProtectionId,
                    mode: 'SAVE',
                    signedStoryDocumentPath:
                      signedStoryDocumentFileName !== '' ? signedStoryDocumentFileName : signedStoryDocumentPath,
                    currentStep: COPYRIGHT_PROTECTION_STEPS.STEP2
                  },
                  nextStep: COPYRIGHT_PROTECTION_STEPS.STEP3
                });
              } else {
                history.push(`${routeConstants.dashboard.route}?activePage=submissions`);
              }

              break;
            case COPYRIGHT_PROTECTION_STEPS.STEP3:
              dispatchUpdateCopyrightSelfAttestationDetails({
                data: {
                  storyId: currentStoryId,
                  copyrightProtectionID: copyrightProtectionDetails?.copyrightProtectionId,
                  mode: 'SAVE',
                  powerOfAttorney: {
                    name: stepFormValues.fullName,
                    nationality: stepFormValues.nationalityLabel,
                    address: stepFormValues.residentialAddress,
                    storyTitle: stepFormValues.storyTitle,
                    designation: stepFormValues.designation
                  },
                  currentStep: COPYRIGHT_PROTECTION_STEPS.STEP3
                },
                nextStep: COPYRIGHT_PROTECTION_STEPS.STEP4
              });
              break;
            case COPYRIGHT_PROTECTION_STEPS.STEP4:
              history.push(`${routeConstants.dashboard.route}?activePage=submissions`);
              break;
            case COPYRIGHT_PROTECTION_STEPS.STEP5:
              if (signedStoryPoaFileName !== '' || signedPoaDocumentPath !== '') {
                dispatchUpdateCopyrightSelfAttestationDetails({
                  data: {
                    storyId: currentStoryId,
                    copyrightProtectionID: copyrightProtectionDetails?.copyrightProtectionId,
                    mode: 'SAVE',
                    signedPOADocumentPath:
                      signedStoryPoaFileName !== '' ? signedStoryPoaFileName : signedPoaDocumentPath,
                    currentStep: COPYRIGHT_PROTECTION_STEPS.STEP5
                  },
                  nextStep: COPYRIGHT_PROTECTION_STEPS.DONE
                });
              } else {
                history.push(`${routeConstants.dashboard.route}?activePage=submissions`);
              }

              break;
          }
        } else {
          history.push(`${routeConstants.dashboard.route}?activePage=submissions`);
        }
      }
    } else {
      history.push(`${routeConstants.dashboard.route}?activePage=submissions`);
    }
  };

  const getPage = () => {
    switch (activePage) {
      case 'protect_story':
        return (
          <ProtectStory
            beginSubmissionProcessLoading={beginSubmissionProcessLoading}
            sellerId={userProfile?.id}
            storyId={currentStoryId}
            govtIdFilePath={govtIdFilePath}
            govtIdFileName={govtIdFileName}
            signedGovtIdFilePath={signedGovtIdFilePath}
            dispatchBeginSubmissionProcess={dispatchBeginSubmissionProcess}
            dispatchUploadGovtIdFile={dispatchUploadGovtIdFile}
            dispatchResetGovtIdFilePath={dispatchResetGovtIdFilePath}
            dispatchGetGovtDetails={dispatchGetGovtDetails}
            dispatchPreviewFile={dispatchPreviewFile}
          />
        );
      case 'declaration':
        return (
          <CopyrightDeclaration
            isAuthor={isAuthor}
            copyrightAuthorVerificationDetails={copyrightAuthorVerificationDetails}
            sellerId={userProfile?.id}
            storyId={currentStoryId}
            nocFilePath={nocFilePath}
            nocFileName={nocFileName}
            nocActive={nocActive}
            nocSignedDocumentPath={nocSignedDocumentPath}
            setNocActive={dispatchSetNocActive}
            dispatchUploadNocFile={dispatchUploadNocFile}
            dispatchSetNocFilePath={dispatchSetNocFilePath}
            dispatchUpdateAuthorVerificationDetails={dispatchUpdateAuthorVerificationDetails}
            setIsAuthor={dispatchSetIsAuthor}
            dispatchGetCopyrightAuthorVerificationDetails={dispatchGetCopyrightAuthorVerificationDetails}
            dispatchPreviewFile={dispatchPreviewFile}
            dispatchGetNocDocument={dispatchGetNocDocument}
          />
        );
      case 'swa':
        return (
          <ScreenWritersAssociation
            isStoryCopyrighted={isStoryCopyrighted}
            isStoryRegisteredSWA={isStoryRegisteredSWA}
            sellerId={userProfile?.id}
            storyId={currentStoryId}
            swaQrCodeFilePath={swaQrCodeFilePath}
            swaRegistrationDetails={swaRegistrationDetails}
            signedSwaFilePath={signedSwaFilePath}
            dispatchUpdateSwaRegistrationDetails={dispatchUpdateSwaRegistrationDetails}
            dispatchUploadSWAQrCodeFile={dispatchUploadSWAQrCodeFile}
            setSwaFormValues={setSwaFormValues}
            dispatchResetSwaQrCodeFilePath={dispatchResetSwaQrCodeFilePath}
            setIsStoryRegisteredSWA={dispatchSetIsStoryRegisteredSWA}
            dispatchGetSwaRegistrationDetails={dispatchGetSwaRegistrationDetails}
            setIsStoryRegistered={dispatchSetIsStoryRegistered}
            setShouldCopyright={dispatchSetShouldCopyright}
            setIsStoryCopyrighted={dispatchSetIsStoryCopyrighted}
            dispatchPreviewFile={dispatchPreviewFile}
            dispatchSetCopyrightCurrentStep={dispatchSetCopyrightCurrentStep}
          />
        );

      case 'copyright_protection':
        return (
          <CopyrightProtection
            storyRegistered={isStoryRegistered}
            isStoryCopyrighted={isStoryCopyrighted}
            shouldCopyright={shouldCopyright}
            sellerId={userProfile?.id}
            storyId={currentStoryId}
            copyrightProtectionDetails={copyrightProtectionDetails}
            copyrightProcessCurrentStep={copyrightProcessCurrentStep}
            swaRegistrationDetails={swaRegistrationDetails}
            copyrightRegistrationDetails={copyrightRegistrationDetails}
            signedPoaDocumentPath={signedPoaDocumentPath}
            signedStoryDocumentPath={signedStoryDocumentPath}
            signedStoryDocumentFileName={signedStoryDocumentFileName}
            signedStoryPoaFileName={signedStoryPoaFileName}
            storyPdfUrl={storyPdfUrl}
            selfAttestationDetails={selfAttestationDetails}
            userProfile={userProfile}
            storyTitle={storyTitle}
            poaSignedDocumentPath={poaSignedDocumentPath}
            setStepFormValues={setStepFormValues}
            setShouldCopyright={dispatchSetShouldCopyright}
            dispatchGetSwaRegistrationDetails={dispatchGetSwaRegistrationDetails}
            setIsStoryCopyrighted={dispatchSetIsStoryCopyrighted}
            dispatchUpdateCopyrightRegistrationDetails={dispatchUpdateCopyrightRegistrationDetails}
            dispatchUpdateCopyrightSelfAttestationDetails={dispatchUpdateCopyrightSelfAttestationDetails}
            dispatchGetCopyrightProtectionDetails={dispatchGetCopyrightProtectionDetails}
            dispatchUpdateCopyrightProtectionStrategy={dispatchUpdateCopyrightProtectionStrategy}
            dispatchGetCopyrightRegistrationDetails={dispatchGetCopyrightRegistrationDetails}
            dispatchSetSignedPoaDocumentPath={dispatchSetSignedPoaDocumentPath}
            dispatchUploadPoaDocumentDocument={dispatchUploadPoaDocumentDocument}
            dispatchUploadSignedStoryDocument={dispatchUploadSignedStoryDocument}
            dispatchSetSignedStoryPath={dispatchSetSignedStoryPath}
            dispatchGenerateStoryPdf={dispatchGenerateStoryPdf}
            dispatchGetSelfAttestationDetails={dispatchGetSelfAttestationDetails}
            setCopyrightRegistrationFormValues={setCopyrightRegistrationFormValues}
            dispatchResetStoryPdfUrl={dispatchResetStoryPdfUrl}
            dispatchPreviewFile={dispatchPreviewFile}
            dispatchGetPoaDocument={dispatchGetPoaDocument}
          />
        );
      case 'submission':
        return (
          <SubmissionPage
            storyId={currentStoryId}
            sellerId={userProfile?.id}
            copyrightPreSubmissionDetails={copyrightPreSubmissionDetails}
            swaRegistrationDetails={swaRegistrationDetails}
            copyrightProtectionDetails={copyrightProtectionDetails}
            confirmStorySubmissionLoading={confirmStorySubmissionLoading}
            hasSelfAttestedStory={hasSelfAttestedStory}
            spotTicketsBalance={spotTicketsBalance}
            copyrightPaymentStatusData={copyrightPaymentStatusData}
            generatePaymentLinkLoading={generatePaymentLinkLoading}
            copyrightWithTicketsLoading={copyrightWithTicketsLoading}
            dispatchGetCopyrightPreSubmissionDetails={dispatchGetCopyrightPreSubmissionDetails}
            dispatchConfirmStorySubmission={dispatchConfirmStorySubmission}
            dispatchChangeStoryState={dispatchChangeStoryState}
            dispatchGetSwaRegistrationDetails={dispatchGetSwaRegistrationDetails}
            dispatchGetCopyrightProtectionDetails={dispatchGetCopyrightProtectionDetails}
            dispatchResetStoryPdfUrl={dispatchResetStoryPdfUrl}
            dispatchGetHasSelfAttestedStory={dispatchGetHasSelfAttestedStory}
            dispatchGetMySpotTickets={dispatchGetMySpotTickets}
            dispatchGetCopyrightPaymentStatusData={dispatchGetCopyrightPaymentStatusData}
            dispatchCopyrightWithTickets={dispatchCopyrightWithTickets}
            dispatchGenerateCopyrightPaymentLink={dispatchGenerateCopyrightPaymentLink}
          />
        );
      default:
        return (
          <ProtectStory
            beginSubmissionProcessLoading={beginSubmissionProcessLoading}
            sellerId={userProfile?.id}
            storyId={currentStoryId}
            govtIdFilePath={govtIdFilePath}
            govtIdFileName={govtIdFileName}
            signedGovtIdFilePath={signedGovtIdFilePath}
            dispatchBeginSubmissionProcess={dispatchBeginSubmissionProcess}
            dispatchUploadGovtIdFile={dispatchUploadGovtIdFile}
            dispatchResetGovtIdFilePath={dispatchResetGovtIdFilePath}
            dispatchGetGovtDetails={dispatchGetGovtDetails}
            dispatchPreviewFile={dispatchPreviewFile}
          />
        );
    }
  };

  return (
    <CopyrightFlexContainer>
      <PageSpinner loading={globalLoading} />
      <CopyrightSidebar
        currentState={currentStage}
        descriptionText={descriptionText}
        saveProcessAndExit={saveProcessAndExit}
      />
      {activePage !== 'protect_story' && activePage !== 'submission' && activePage !== null && (
        <BackButton onClick={handleGoBack} data-testid="back-btn">
          <StyledImg src={ArrowLeft} />
          {translate('back')}
        </BackButton>
      )}
      <CopyrightContentContainer>{getPage()}</CopyrightContentContainer>
    </CopyrightFlexContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  isStoryCopyrighted: selectIsStoryCopyrighted(),
  shouldCopyright: selectShouldCopyright(),
  isStoryRegistered: selectIsStoryRegistered(),
  isAuthor: selectIsAuthor(),
  isStoryRegisteredSWA: selectIsStoryRegisteredSWA(),
  currentStoryId: selectCurrentStoryId(),
  beginSubmissionProcessLoading: selectBeginSubmissionProcess(),
  submissionStatus: selectSubmissionStatus(),
  copyrightAuthorVerificationDetails: selectCopyrightAuthorVerificationDetails(),
  nocActive: selectNocActive(),
  nocFilePath: selectNocFilePath(),
  nocFileName: selectNocFileName(),
  swaRegistrationDetails: selectSwaRegistrationDetails(),
  swaQrCodeFilePath: selectSwaQrCodeFilePath(),
  copyrightProcessCurrentStep: selectCopyrightProtectionCurrentStep(),
  copyrightProtectionDetails: selectCopyrightProtectionDetails(),
  copyrightRegistrationDetails: selectCopyrightRegistrationDetails(),
  copyrightPreSubmissionDetails: selectCopyrightPreSubmissionDetails(),
  backPressed: selectBackPressed(),
  signedStoryDocumentPath: selectSignedStoryDocumentPath(),
  signedPoaDocumentPath: selectSignedPOADocumentPath(),
  signedStoryDocumentFileName: selectSignedStoryFileName(),
  signedStoryPoaFileName: selectSignedPoaFileName(),
  storyPdfUrl: selectStoryPdfUrl(),
  selfAttestationDetails: selectSelfAttestationDetails(),
  userProfile: selectUserProfile(),
  storyTitle: selectStoryTitle(),
  confirmStorySubmissionLoading: selectConfirmStorySubmissionLoading(),
  hasSelfAttestedStory: selectHasSelfAttestedStory(),
  spotTicketsBalance: selectSpotTicketsBalance(),
  copyrightPaymentStatusData: selectCopyrightPaymentStatusData(),
  generatePaymentLinkLoading: selectGeneratePaymentLinkLoading(),
  copyrightWithTicketsLoading: selectCopyrightWithTicketsLoading(),
  globalLoading: selectGlobalLoading(),
  govtIdFilePath: selectGovtIdFilePath(),
  govtIdFileName: selectGovtIdFileName(),
  signedGovtIdFilePath: selectSignedGovtidFilePath(),
  signedSwaFilePath: selectSignedSwaQrCodeFilePath(),
  poaSignedDocumentPath: selectPoaSignedDocument(),
  nocSignedDocumentPath: selectNocSignedDocument()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchSetIsStoryCopyrighted: (option: string) => dispatch(setIsStoryCopyrighted(option)),
    dispatchSetShouldCopyright: (option: boolean) => dispatch(setShouldCopyright(option)),
    dispatchSetIsStoryRegistered: (option: boolean) => dispatch(setIsStoryRegistered(option)),
    dispatchSetIsAuthor: (option: string) => dispatch(setIsAuthor(option)),
    dispatchSetIsStoryRegisteredSWA: (option: string) => dispatch(setIsStoryRegisteredSWA(option)),
    dispatchGetCopyrightSubmissionStatus: (sellerId: string, storyId: string) =>
      dispatch(
        requestGetSubmissionStatus({
          sellerId,
          storyId
        })
      ),
    dispatchBeginSubmissionProcess: (payload: any) => dispatch(requestBeginSubmissionProcess(payload)),
    dispatchGetCopyrightAuthorVerificationDetails: (sellerId: string, storyId: string) =>
      dispatch(
        requestGetCopyrightAuthorVerificationDetails({
          sellerId,
          storyId
        })
      ),
    dispatchUpdateAuthorVerificationDetails: (payload: any) =>
      dispatch(requestUpdateAuhtorVerificationDetails(payload)),
    dispatchUploadNocFile: (fileData: any) => dispatch(requestUploadNocFile(fileData)),
    dispatchSetNocActive: (nocActive: boolean) => dispatch(setNocActive(nocActive)),
    dispatchSetNocFilePath: (filePath: string) => dispatch(setNocFilePath(filePath)),
    dispatchGetSwaRegistrationDetails: (sellerId: string, storyId: string) =>
      dispatch(
        requestGetSwaRegistrationDetails({
          sellerId,
          storyId
        })
      ),
    dispatchUpdateSwaRegistrationDetails: (payload: any) => dispatch(requestUpdateSwaRegistrationDetails(payload)),
    dispatchUploadSWAQrCodeFile: (fileData: any) => dispatch(requestUploadSwaQrCode(fileData)),
    dispatchUpdateCopyrightRegistrationDetails: (payload: any) =>
      dispatch(requestUpdateCopyrightRegistrationDetails(payload)),
    dispatchUpdateCopyrightSelfAttestationDetails: (payload: any) =>
      dispatch(requestUpdateCopyrightSelfAttestationDetails(payload)),
    dispatchSetBackPressed: (backPressed: boolean) => dispatch(setBackPressed(backPressed)),
    dispatchGetCopyrightProtectionDetails: (sellerId: string, storyId: string) =>
      dispatch(
        requestGetCopyrightProtectionDetails({
          sellerId,
          storyId
        })
      ),
    dispatchUpdateCopyrightProtectionStrategy: (payload: any) =>
      dispatch(requestUpdateCopyrightProtectionStrategy(payload)),
    dispatchGetCopyrightRegistrationDetails: (sellerId: string, storyId: string, copyrightProtectionID: number) =>
      dispatch(
        requestGetCopyrightRegistrationDetails({
          sellerId,
          storyId,
          copyrightProtectionID
        })
      ),
    dispatchGetCopyrightPreSubmissionDetails: (sellerId: string, storyId: string) =>
      dispatch(
        requestGetCopyrightPreSubmissionDetails({
          sellerId,
          storyId
        })
      ),
    dispatchConfirmStorySubmission: (sellerId: string, storyId: string, type: string) =>
      dispatch(
        requestConfirmStorySubmission({
          sellerId,
          storyId,
          type
        })
      ),
    dispatchUploadSignedStoryDocument: (fileData: any) => dispatch(requestUploadSignedStoryDocument(fileData)),
    dispatchUploadPoaDocumentDocument: (fileData: any) => dispatch(requestUploadSignedPoaDocument(fileData)),
    dispatchSetSignedStoryPath: (filePath: string) => dispatch(setSignedStoryDocumentPath(filePath)),
    dispatchSetSignedPoaDocumentPath: (filePath: string) => dispatch(setSignedPoaDocumentPath(filePath)),
    dispatchChangeStoryState: (id: string) => dispatch(requestStateChange({ id })),
    dispatchGenerateStoryPdf: (sellerId: string, storyId: string) =>
      dispatch(
        requestGenerateStoryPdf({
          sellerId,
          storyId
        })
      ),
    dispatchGetSelfAttestationDetails: (sellerId: string, storyId: string, copyrightProtectionID: number) =>
      dispatch(requestGetSelfAttestationDetails({ sellerId, storyId, copyrightProtectionID })),
    dispatchGetHasSelfAttestedStory: () => dispatch(requestGetHasSelfAttestedStory({})),
    dispatchResetState: () => dispatch(resetState()),
    dispatchResetCopyrightDeclarationState: () => dispatch(resetCopyrightDeclarationState()),
    dispatchResetSwaState: () => dispatch(resetSwaState()),
    dispatchResetCopyrightProtection: () => dispatch(resetCopyrightProtection()),
    dispatchResetStoryPdfUrl: () => dispatch(resetStoryPdfUrl()),
    dispatchGetMySpotTickets: (sellerId: string) =>
      dispatch(
        requestGetSpotTickets({
          sellerId
        })
      ),
    dispatchResetSwaQrCodeFilePath: () => dispatch(resetSwaQrCodeFilePath()),
    dispatchGetCopyrightPaymentStatusData: (sellerId: string, storyId: string, copyrightID: number) =>
      dispatch(requestGetCopyrightPaymentStatus({ sellerId, storyId, copyrightID })),
    dispatchSetCurrentStoryId: (storyId: string) => dispatch(setCurrentStoryId(storyId)),
    dispatchGetUserProfile: () => dispatch(requestGetProfile({})),
    dispatchCopyrightWithTickets: (storyId: string, copyrightId: number) =>
      dispatch(
        requestCopyrightWithTickets({
          storyId,
          copyrightId
        })
      ),
    dispatchGenerateCopyrightPaymentLink: (
      amount: number,
      tickets: number,
      storyId: string,
      reason: string,
      notes: string
    ) =>
      dispatch(
        requestGeneratePaymentLink({
          amount,
          tickets,
          storyId,
          reason,
          notes
        })
      ),
    dispatchResetGovtIdFilePath: () => dispatch(resetGovtIdFilePath()),
    dispatchUploadGovtIdFile: (fileData: any) => dispatch(requestUploadGovtId(fileData)),
    dispatchGetGovtDetails: (sellerId: string, storyId: string) =>
      dispatch(
        requestGetGovtDetails({
          sellerId,
          storyId
        })
      ),
    dispatchPreviewFile: (fileData: any) => dispatch(requestPreviewFile(fileData)),
    dispatchGetNocDocument: (sellerId: string) =>
      dispatch(
        requestGetNocSignedDocument({
          sellerId
        })
      ),
    dispatchGetPoaDocument: (sellerId: string, storyId: string) =>
      dispatch(
        requestGetPoaSignedDocument({
          sellerId,
          storyId
        })
      ),
    dispatchSetCopyrightCurrentStep: (step: string) => dispatch(setCopyrightCurrentStep(step))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CopyrightContainer) as React.FC;

export const CopyrightContainerTest = CopyrightContainer;
