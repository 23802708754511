import React, { useEffect, useState } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Input, notification } from 'antd';
import styled from 'styled-components';
import { BillingDetailsContainerProps } from '../../types';
import editIcon from '@images/icons/myprofile/edit-icon.svg';
import { CustomButton, If } from 'tsw-sdk';
import { cloneDeep } from 'lodash-es';

const BillingDetailsContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  padding-left: 4.93rem;
`;

const BillingDetailsTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const BillingDetailsTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.profilePageColors.titleColor};
`;

const InputGrid = styled.div`
  width: 33rem;
  height: auto;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 1rem;
  margin-top: 2rem;
`;

const BillingDetailsInput = styled(Input)`
  width: 100%;
  height: 1.93rem;
  background-color: ${colors.white};
  border-radius: 5px;
`;

const BillingDetailsInputCapital = styled(Input)`
  width: 100%;
  height: 1.93rem;
  background-color: ${colors.white};
  border-radius: 5px;
  text-transform: uppercase;

  &&& {
    &:placeholder-shown {
      text-transform: unset;
    }
  }
`;

const SmallInputsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 47% 47%;
  grid-gap: 1rem;
`;

const EditIcon = styled.img`
  margin-left: 1.93rem;
  cursor: pointer;
  user-select: none;
`;

const SaveBtn = styled(CustomButton)`
  margin-left: 1.93rem;
`;

const BillingDetailsContainer = ({ billingInfo, profileError, handleSave }: BillingDetailsContainerProps) => {
  const [newBillingInfo, setNewBillingInfo] = useState<any>(billingInfo);
  const [inputsDisabled, setInputsDisabled] = useState<boolean>(true);

  const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

  useEffect(() => {
    setNewBillingInfo(billingInfo);
  }, [billingInfo]);

  useEffect(() => {
    setNewBillingInfo(billingInfo);
  }, [profileError]);

  const handleSaveClick = () => {
    const billingInfoSanitized = cloneDeep(newBillingInfo);
    delete billingInfoSanitized.typename;

    const { formDataValid, errorMessage } = validateBillingDetails(billingInfoSanitized);

    if (formDataValid) {
      billingInfoSanitized.panNumber = billingInfoSanitized.panNumber?.toUpperCase();
      billingInfoSanitized.gstNumber = billingInfoSanitized.gstNumber?.toUpperCase();
      setInputsDisabled(true);
      handleSave(billingInfoSanitized);
    } else {
      notification.open({
        message: 'Error',
        description: errorMessage
      });
    }
  };

  const validateBillingDetails = (formData: any) => {
    let formDataValid = true;

    let errorMessage = '';

    if (formData?.gstNumber) {
      if (!gstRegex.test(formData?.gstNumber?.toUpperCase())) {
        formDataValid = false;
        errorMessage = translate('invalid_gst_error_message');
      }
    }

    if (!panRegex.test(formData?.panNumber?.toUpperCase())) {
      formDataValid = false;
      errorMessage = translate('invalid_pan_error_message');
    }

    return { formDataValid, errorMessage };
  };

  const handleChange = (e: any) => {
    setNewBillingInfo({
      ...newBillingInfo,
      [e.target.name]: e.target.value
    });
  };

  return (
    <BillingDetailsContentContainer>
      <BillingDetailsTitleContainer>
        <BillingDetailsTitle>{translate('billing_details')}</BillingDetailsTitle>
        <EditIcon src={editIcon} onClick={() => setInputsDisabled(false)} data-testid="edit-btn-billing" />
        <If condition={!inputsDisabled}>
          <SaveBtn onClick={handleSaveClick} data-testid="save-btn">
            {translate('save')}
          </SaveBtn>
        </If>
      </BillingDetailsTitleContainer>

      <InputGrid>
        <BillingDetailsInputCapital
          placeholder={translate('pan_no_placeholder')}
          disabled={inputsDisabled}
          value={newBillingInfo?.panNumber}
          name={'panNumber'}
          onChange={handleChange}
          data-testid="pan-no-input"
        />
        <BillingDetailsInput
          placeholder={translate('house_num_plaeholder')}
          disabled={inputsDisabled}
          value={newBillingInfo?.streetAddress}
          name={'streetAddress'}
          onChange={handleChange}
        />
        <BillingDetailsInputCapital
          placeholder={translate('gst_num_placeholder')}
          disabled={inputsDisabled}
          value={newBillingInfo?.gstNumber}
          name={'gstNumber'}
          onChange={handleChange}
          data-testid="gst-number"
        />
        <SmallInputsGrid>
          <BillingDetailsInput
            placeholder={translate('locality')}
            disabled={inputsDisabled}
            value={newBillingInfo?.localeAddress}
            name={'localeAddress'}
            onChange={handleChange}
          />
          <BillingDetailsInput
            placeholder={translate('city')}
            disabled={inputsDisabled}
            value={newBillingInfo?.city}
            name={'city'}
            onChange={handleChange}
          />
        </SmallInputsGrid>
        <SmallInputsGrid>
          <BillingDetailsInput
            placeholder={translate('state')}
            disabled={inputsDisabled}
            value={newBillingInfo?.state}
            name={'state'}
            onChange={handleChange}
          />
          <BillingDetailsInput
            placeholder={translate('post_code')}
            disabled={inputsDisabled}
            value={newBillingInfo?.pinCode}
            name={'pinCode'}
            onChange={handleChange}
          />
        </SmallInputsGrid>
      </InputGrid>
    </BillingDetailsContentContainer>
  );
};

export default BillingDetailsContainer;
