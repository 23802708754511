import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CustomButton } from 'tsw-sdk';
import Tags from '../Tags';
import { ArrowRightOutlined } from '@ant-design/icons';
import { TagsDatatype } from '../../types';

export interface TagsPageProps {
  onApply: Function;
  initialList: any[];
  tagsData: TagsDatatype[];
}

const TagsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  padding: 6.87rem 15.68rem 15.37rem 15.68rem;
  background: ${colors.white};
  overflow-y: auto;
`;

const TagsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const TagsHeading = styled.p`
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw600()};
  line-height: 3.76rem;
  text-align: center;
`;

const TagsSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TagsSubHeading = styled.p`
  ${fonts.size.regular()};
  ${fonts.weights.fw600()};
  line-height: 1.25rem;
  text-align: center;
`;

const TagsWrapContainer = styled.p`
  width: 38.62rem;
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-count: 3;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const TagsPage = ({ onApply, initialList, tagsData }: TagsPageProps) => {
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [selectedTagsData, setSelectedTagsData] = useState<any[]>(initialList);

  useEffect(() => {
    setSelectedTags(selectedTagsData.map((curr) => curr.id));
  }, []);

  const handleTagClick = (tag: any) => {
    if (selectedTags.includes(tag.id)) {
      setSelectedTags((current) => [...current.filter((item) => item !== tag.id)]);
      setSelectedTagsData((currData) => [...currData.filter((item) => item.id !== tag.id)]);
    } else {
      if (selectedTags.length < 3) {
        setSelectedTags((current) => [...current, tag.id]);
        setSelectedTagsData((currData) => [...currData, tag]);
      }
    }
  };

  return (
    <TagsContainer data-testid="tags-portal">
      <TagsContentContainer>
        <TagsHeading>{translate('tags_add')}</TagsHeading>
        <TagsSubContainer>
          <TagsSubHeading>{translate('select_3')}</TagsSubHeading>
          <TagsWrapContainer>
            {tagsData.map((tag) => {
              return (
                <Tags
                  isSelected={selectedTags.includes(tag.id)}
                  tagName={tag.name}
                  key={tag.id}
                  handleClick={() => handleTagClick(tag)}
                />
              );
            })}
          </TagsWrapContainer>
          <CustomButton onClick={() => onApply(selectedTagsData)} data-testid="apply-btn">
            {translate('add_tags_btn')} <ArrowRightOutlined />
          </CustomButton>
        </TagsSubContainer>
      </TagsContentContainer>
    </TagsContainer>
  );
};

export default TagsPage;
