import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {}
});

export default globalSlice.reducer;
