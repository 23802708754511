import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CustomButton, CustomInput, If } from 'tsw-sdk';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Form, message } from 'antd';
import uploadIcon from '@images/icons/upload-icon.svg';
import crossIcon from '@images/icons/MySubmissionIcons/cross-icon.svg';

import { ScreenWritersAssociationProps } from './types';
import { COPYRIGHT_PROTECTION_STEPS, COPYRIGHT_PROTECTION_STRATEGIES } from '@app/utils/constants';
import { validateFileSize } from '@app/utils/uiUtils';

type ButtonProps = {
  selected: boolean;
};

const ScreenWritersAssociationContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
`;

const ScreenWritersAssociationContentContainer = styled.div`
  width: 100%;
  max-width: 38rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
`;

const ScreenWritersAssociationTitle = styled.h2`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  color: ${colors.copyrightColors.copyrightDeclarationTitle};
  margin-bottom: 0;
`;

const StoryRegisteredQuestion = styled.p`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-top: 3rem;
  line-height: 1.96rem;
`;

const StoryRegisteredQuestionBtnContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

const StoryRegisteredQuestionBtn = styled(CustomButton)<ButtonProps>`
  border-width: 2px !important;
  border-style: solid !important;
  border-color: ${(props) => (props.selected ? colors.copyrightColors.btnBorder : 'transparent')} !important;
`;

const StoryRegisteredQuestionBtnYes = styled(CustomButton)<ButtonProps>`
  margin-left: 1.5rem;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: ${(props) => (props.selected ? colors.copyrightColors.btnBorder : 'transparent')} !important;
`;

const SwaRegistrationDetailsContainer = styled.div`
  margin-top: 3rem;
  margin-right: auto;
`;

const SwaRegistrationForm = styled(Form)`
  margin-top: 1rem;
`;

const CustomFormItem = styled(Form.Item)`
  &.ant-form-item {
    width: 16rem;
    margin-bottom: 0;
    row-gap: 0.5rem;
  }
  .ant-form-item-label {
    padding: 0 !important;
    row-gap: 0.5rem;
    & label {
      ${fonts.family.SpoofRegular()};
      ${fonts.size.regular()};
      ${fonts.weights.fw400()};
      line-height: 1.31rem;
    }
  }
`;

const EnterSwaDetailsPrompt = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  line-height: 1.31rem;
`;

const ProceedBtn = styled(CustomButton)`
  margin-top: 3rem;
  && {
    width: 100%;
    border: 1px solid ${colors.primary};
    background-color: transparent;
    justify-content: center;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const OrDetailsDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-top: 1.93rem;
  margin-bottom: 1.99rem;
  position: relative;
`;

const OrText = styled.p`
  width: 1.56rem;
  height: 1.56rem;
  position: absolute;
  left: 50%;
  top: -3px;
  background-color: ${colors.copyrightColors.backgroundColor};
  ${fonts.size.regular()};
  ${fonts.weights.fw800()};
`;

const UploadSwaContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
`;

const UploadSwaTitle = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  line-height: 1.31rem;
  color: ${colors.copyrightColors.timelineItemTextActive};
`;

const UploadSwaBtn = styled(CustomButton)`
  && {
    border: 1px solid ${colors.primary};
    background-color: transparent;
    justify-content: center;
    margin-top: 6px;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const UploadIcon = styled.img`
  margin-left: 0.5rem;
`;

const CutIcon = styled.img`
  margin-left: 0.5rem;
  margin-top: 0.3rem;
  height: 0.62rem;

  cursor: pointer;
`;

const PleaseNoteMessage = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;
  margin-top: 1.5rem;
`;

const PleaseNoteText = styled.span`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.regular()};
`;

const SWAMandatoryMessage = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.xsmall()};
  color: ${colors.redClr};
  line-height: 1.31rem;
  margin-top: 1rem;
`;

// const BtnLink = styled(Link)`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   gap: 0.75rem;
//   cursor: pointer;
// `;

const SWAImg = styled.img`
  height: 7rem;
  width: 8rem;
  block-size: auto;
  object-fit: contain;

  border: 1px solid ${colors.primary};
  border-radius: 1rem;
`;

const SWAImgContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;
`;

const SWAQrCodeFileInput = styled.input`
  display: none;
`;

const FileLimitText = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  margin-top: 0.4rem;
  line-height: 1.31rem;
`;

const StyledList = styled.ul`
  padding-left: 1.5rem;
`;

const StyledListItem = styled.li`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  line-height: 1.31rem;
  color: ${colors.copyrightColors.timelineItemTextActive};

  ::marker {
    font-size: 12px;
  }
`;

const FileUploadedText = styled.p`
  margin-top: 0.5rem;
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-right: auto;
  line-height: 1.31rem;
`;

const DeleteFileBtn = styled.span`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-left: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
`;

const PreviewFileBtn = styled.span`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-left: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
`;

const ScreenWritersAssociation = ({
  isStoryCopyrighted,
  isStoryRegisteredSWA,
  sellerId,
  storyId,
  swaQrCodeFilePath,
  swaRegistrationDetails,
  signedSwaFilePath,
  dispatchGetSwaRegistrationDetails,
  dispatchUpdateSwaRegistrationDetails,
  dispatchUploadSWAQrCodeFile,
  setSwaFormValues,
  dispatchResetSwaQrCodeFilePath,
  dispatchSetCopyrightCurrentStep,
  dispatchPreviewFile,
  setShouldCopyright,
  setIsStoryRegisteredSWA,
  setIsStoryCopyrighted,
  setIsStoryRegistered
}: ScreenWritersAssociationProps) => {
  const [swaForm] = Form.useForm();
  const swaFileRef = useRef<any>(null);
  const [swaFile, setSwaFile] = useState<string>('');
  const [formValues, setFormValues] = useState<any>({});
  const [proceedDisabled, setProceedDisabled] = useState<boolean>(false);
  const [isImage, setIsImage] = useState<boolean>(false);

  useEffect(() => {
    dispatchSetCopyrightCurrentStep(COPYRIGHT_PROTECTION_STEPS.STEP1);
    if (sellerId && storyId) {
      dispatchGetSwaRegistrationDetails(sellerId, storyId);
    }
  }, []);

  useEffect(() => {
    if (swaRegistrationDetails) {
      if (swaRegistrationDetails.registrationDetails) {
        swaForm.setFieldsValue({
          registrationNumber: swaRegistrationDetails?.registrationDetails?.registrationNo,
          membershipNumber: swaRegistrationDetails?.registrationDetails?.membershipNo
        });
      }

      if (
        swaRegistrationDetails.isCopyrightActProtected !== undefined &&
        swaRegistrationDetails.isCopyrightActProtected !== null
      ) {
        setIsStoryCopyrighted(swaRegistrationDetails.isCopyrightActProtected === true ? 'yes' : 'no');
      }

      if (
        swaRegistrationDetails.isStorySwaRegistered !== undefined &&
        swaRegistrationDetails.isStorySwaRegistered !== null
      ) {
        setIsStoryRegisteredSWA(swaRegistrationDetails.isStorySwaRegistered === true ? 'yes' : 'no');
      }
    }
  }, [swaRegistrationDetails]);

  useEffect(() => {
    const isDisabled = getIsDisabled();
    setProceedDisabled(isDisabled);
    const values = swaForm.getFieldsValue();
    setSwaFormValues(values);
  }, [formValues, swaQrCodeFilePath, isStoryRegisteredSWA, isStoryCopyrighted]);

  useEffect(() => {
    if (signedSwaFilePath) {
      setSwaFile(signedSwaFilePath);
      let extension: any = signedSwaFilePath.split('.');
      extension = extension[extension.length - 1];

      if (extension.startsWith('jpg')) {
        setIsImage(true);
      } else {
        setIsImage(false);
      }
    }
  }, [signedSwaFilePath]);

  useEffect(() => {
    if (isStoryRegisteredSWA === 'yes') {
      setIsStoryRegistered(true);
    } else if (isStoryRegisteredSWA === 'no') {
      setIsStoryRegistered(false);
    }
  }, [isStoryRegisteredSWA]);

  useEffect(() => {
    if (isStoryCopyrighted === 'no') {
      if (isStoryRegisteredSWA === 'yes') {
        setShouldCopyright(false);
      } else {
        setShouldCopyright(true);
      }
    }
  }, [isStoryCopyrighted, isStoryRegisteredSWA]);

  const getIsDisabled = () => {
    let isDisabled = false;
    const values = swaForm.getFieldsValue();

    if (isStoryRegisteredSWA === 'yes') {
      if (!(values.membershipNumber && values.registrationNumber) && !swaQrCodeFilePath) {
        isDisabled = true;
      }

      if (swaQrCodeFilePath === '') {
        if (values.membershipNumber && !values.registrationNumber) {
          isDisabled = true;
        }

        if (!values.membershipNumber && values.registrationNumber) {
          isDisabled = true;
        }
      }
    }

    if (isStoryRegisteredSWA !== 'yes' && isStoryRegisteredSWA !== 'no') {
      isDisabled = true;
    }

    if (isStoryCopyrighted !== 'yes' && isStoryCopyrighted !== 'no') {
      isDisabled = true;
    }

    return isDisabled;
  };

  const handleQrCodeUpload = (e: any) => {
    const file = e.target.files[0];
    const validImageTypes = ['image/jpeg', 'image/png'];

    if (validateFileSize(file, 5)) {
      setSwaFile(URL.createObjectURL(file));
      dispatchUploadSWAQrCodeFile({ storyId, file });

      if (validImageTypes.includes(file.type)) {
        setIsImage(true);
      }
    } else {
      message.error(translate('file_size_exceed', { fileSize: '5mb' }));
    }

    e.target.value = null;
  };

  const handleProceed = () => {
    let payload = {};
    const values = swaForm.getFieldsValue();

    if (isStoryRegisteredSWA === 'yes') {
      payload = {
        storyId: storyId,
        isStorySWARegistered: true,
        copyrightId: swaRegistrationDetails?.copyrightId,
        isCopyrightActProtected: isStoryCopyrighted === 'yes',
        registrationDetails: {
          membershipNo: values.membershipNumber,
          registrationNo: values.registrationNumber,
          registrationQRCodePath: swaQrCodeFilePath
        },
        proceedToNextStep: true
      };
    } else if (isStoryRegisteredSWA === 'no') {
      payload = {
        storyId: storyId,
        isStorySWARegistered: false,
        copyrightId: swaRegistrationDetails?.copyrightId,
        isCopyrightActProtected: isStoryCopyrighted === 'yes',
        proceedToNextStep: true
      };
    }

    const strategyDetails = {
      storyId: storyId,
      copyrightId: swaRegistrationDetails?.copyrightId,
      proceedToNextStep: true,
      protectionStrategy: ''
    };

    if (isStoryCopyrighted === 'yes') {
      strategyDetails.protectionStrategy = COPYRIGHT_PROTECTION_STRATEGIES.REGISTRATION_PROVIDED;
    } else if (isStoryCopyrighted === 'no') {
      strategyDetails.protectionStrategy = COPYRIGHT_PROTECTION_STRATEGIES.SELF_ATTESTATION;
    }

    dispatchUpdateSwaRegistrationDetails({ swaDetails: payload, strategyDetails: strategyDetails });
  };

  const handleFormChange = (values: any) => {
    setFormValues(values);
  };

  const handleUploadSWAQrCode = () => {
    swaFileRef.current?.click();
  };

  const handleClearSWAQrCode = () => {
    setSwaFile('');
    dispatchResetSwaQrCodeFilePath();
    setIsImage(false);
  };

  const handleFilePreview = () => {
    let rootFolder: any = swaQrCodeFilePath.split('.')[0];
    rootFolder = rootFolder.split('/');
    rootFolder.pop();

    rootFolder = rootFolder.join('/');

    let fileName: any = swaQrCodeFilePath.split('/');

    fileName = fileName[fileName.length - 1];

    dispatchPreviewFile({
      rootFolder,
      fileName
    });
  };

  const getContent = () => {
    if (isStoryRegisteredSWA === 'yes') {
      return (
        <>
          {/* <SwaRegistrationDetailsContainer>
            <EnterSwaDetailsPrompt>{translate('enter_swa_details_prompt')}</EnterSwaDetailsPrompt>
            <SwaRegistrationForm layout="vertical" form={swaForm} onValuesChange={handleFormChange}>
              <CustomFormItem label={translate('swa_membership_number_label')} name="membershipNumber">
                <CustomInput
                  placeholder={translate('swa_membership_placeholder')}
                  data-testid="swa-membership-number"
                />
              </CustomFormItem>
              <CustomFormItem label={translate('swa_registration_number_label')} name="registrationNumber">
                <CustomInput
                  placeholder={translate('swa_membership_placeholder')}
                  data-testid="swa-registration-number"
                />
              </CustomFormItem>
            </SwaRegistrationForm>
            <SWAMandatoryMessage>{translate('swa_fields_mandatory')}</SWAMandatoryMessage>
          </SwaRegistrationDetailsContainer>
          <OrDetailsDivider>
            <OrText>{translate('or_text')}</OrText>
          </OrDetailsDivider> */}
          <UploadSwaContainer>
            <UploadSwaTitle>{translate('upload_swa_title')}</UploadSwaTitle>
            <StyledList>
              <StyledListItem>{translate('upload_swa_list_item_1')}</StyledListItem>
              <StyledListItem>{translate('upload_swa_list_item_2')}</StyledListItem>
            </StyledList>
            <UploadSwaBtn
              text_color={`${colors.darkBorder}`}
              onClick={handleUploadSWAQrCode}
              data-testid="upload-swa-qr-btn"
            >
              {translate('upload_swa_btn_text')} <UploadIcon src={uploadIcon} />
            </UploadSwaBtn>
            <If condition={swaFile && isImage}>
              <SWAImgContainer>
                <SWAImg src={swaFile} />
                <CutIcon src={crossIcon} onClick={handleClearSWAQrCode} />
              </SWAImgContainer>
            </If>
            <If condition={swaFile && !isImage}>
              <FileUploadedText>
                {translate('noc_uploaded_text', { fileName: 'file' })}

                <PreviewFileBtn data-testid="swa-doc-preview-btn" onClick={handleFilePreview}>
                  {translate('preview')}
                </PreviewFileBtn>

                <DeleteFileBtn onClick={handleClearSWAQrCode} data-testid="govt-id-delete-file-btn">
                  {translate('delete')}
                </DeleteFileBtn>
              </FileUploadedText>
            </If>
          </UploadSwaContainer>
          <PleaseNoteMessage>
            <PleaseNoteText>{translate('please_note_text')}</PleaseNoteText>
            {translate('please_note_message')}
          </PleaseNoteMessage>
        </>
      );
    }
  };

  return (
    <ScreenWritersAssociationContainer data-testid="screen-writers-association-container">
      <SWAQrCodeFileInput
        onChange={handleQrCodeUpload}
        type={'file'}
        accept="image/png, image/jpeg, application/pdf"
        ref={swaFileRef}
        data-testid="swa-file-input"
      />
      <ScreenWritersAssociationContentContainer>
        <ScreenWritersAssociationTitle>{translate('screenwriters_association_title')}</ScreenWritersAssociationTitle>
        <StoryRegisteredQuestion>{translate('story_registered_question')}</StoryRegisteredQuestion>
        <StoryRegisteredQuestionBtnContainer>
          <StoryRegisteredQuestionBtn
            onClick={() => setIsStoryRegisteredSWA('no')}
            data-testid="story-registered-question-no"
            selected={isStoryRegisteredSWA === 'no'}
          >
            {translate('no')}
          </StoryRegisteredQuestionBtn>
          <StoryRegisteredQuestionBtnYes
            onClick={() => setIsStoryRegisteredSWA('yes')}
            data-testid="story-registered-question-yes"
            selected={isStoryRegisteredSWA === 'yes'}
          >
            {translate('yes')}
          </StoryRegisteredQuestionBtnYes>
        </StoryRegisteredQuestionBtnContainer>
        {getContent()}
        <StoryRegisteredQuestion>{translate('copyright_act_question')}</StoryRegisteredQuestion>
        <StoryRegisteredQuestionBtnContainer>
          <StoryRegisteredQuestionBtn
            onClick={() => {
              if (isStoryRegisteredSWA === 'yes') {
                setShouldCopyright(false);
              } else {
                setShouldCopyright(true);
              }
              setIsStoryCopyrighted('no');
            }}
            data-testid="copyright-act-question-screenwriters-association"
            selected={isStoryCopyrighted === 'no'}
          >
            {translate('no')}
          </StoryRegisteredQuestionBtn>
          <StoryRegisteredQuestionBtnYes
            onClick={() => setIsStoryCopyrighted('yes')}
            selected={isStoryCopyrighted === 'yes'}
          >
            {translate('yes')}
          </StoryRegisteredQuestionBtnYes>
        </StoryRegisteredQuestionBtnContainer>
        <ProceedBtn
          text_color={`${colors.darkBorder}`}
          onClick={handleProceed}
          disabled={proceedDisabled}
          data-testid="proceed-btn-swa"
        >
          {translate('proceed')} <ArrowRightOutlined />
        </ProceedBtn>
      </ScreenWritersAssociationContentContainer>
    </ScreenWritersAssociationContainer>
  );
};

export default ScreenWritersAssociation;

export const ScreenWritersAssociationTest = ScreenWritersAssociation;
