import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  requestSignUpUser,
  successSignUpUser,
  failureSignUpUser,
  requestVerifyOtp,
  requestResendOtp,
  failureOtp,
  failureRecaptcha,
  successReCaptcha,
  requestVerifyRecaptcha
} from './reducer';
import { cognito } from 'tsw-sdk';
import { getMutationResponse } from '@utils/graphqlUtils';
import { VERIFY_RECAPTCHA } from '@utils/queries';

const { createCognitoUserPool, signUp, confirmSignUp, resendConfirmationCode } = cognito;
const userPoolId = process.env.USER_POOL_ID as string;
const userPoolClientId = process.env.USER_POOL_CLIENT_ID as string;

export function* getSignUpDetails(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);
    const data = yield call(signUp, action.payload.email, action.payload.password);
    yield put(successSignUpUser(data));
  } catch (err) {
    yield put(failureSignUpUser(err));
  }
}
export function* getVerifyOTPDetails(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);

    const data = yield call(confirmSignUp, action.payload.email, action.payload.otp);
    yield put(successSignUpUser(data));
  } catch (err) {
    yield put(failureOtp(err));
  }
}
export function* resendConfirmation(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);

    const data = yield call(resendConfirmationCode, action.payload.email);

    yield put(successSignUpUser(data));
  } catch (err) {
    yield put(failureOtp(err));
  }
}

export function* getRecaptchaVerify(action: any): Generator<any, any, any> {
  const payload = {
    input: {
      token: action.payload.value
    }
  };
  try {
    const data = yield call(getMutationResponse, VERIFY_RECAPTCHA, payload);
    yield put(successReCaptcha(data));
  } catch (err) {
    yield put(failureRecaptcha(err));
  }
}

// Individual exports for testing
export default function* signUpContainerSaga() {
  yield all([
    takeLatest(requestSignUpUser.toString(), getSignUpDetails),
    takeLatest(requestVerifyOtp.toString(), getVerifyOTPDetails),
    takeLatest(requestResendOtp.toString(), resendConfirmation),
    takeLatest(requestVerifyRecaptcha.toString(), getRecaptchaVerify)
  ]);
}

export const signUpContainerSagaArr = [
  takeLatest(requestSignUpUser.toString(), getSignUpDetails),
  takeLatest(requestVerifyOtp.toString(), getVerifyOTPDetails),
  takeLatest(requestResendOtp.toString(), resendConfirmation),
  takeLatest(requestVerifyRecaptcha.toString(), getRecaptchaVerify)
];
