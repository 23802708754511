import { colors, fonts } from '@app/themes';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import myTicketsCrownIcon from '@images/icons/MyTickets/my-tickets-crown.svg';
import spotTicketIcon from '@images/icons/MyTickets/spot-ticket-icon.svg';
import renewSubscriptionIcon from '@images/icons/MyTickets/subscription-renew.svg';

import { translate } from '@app/components/IntlGlobalProvider';
import { Input, message, Modal, Progress } from 'antd';
import { CustomButton, If } from 'tsw-sdk';
import routeConstants from '@app/utils/routeConstants';
import { useHistory } from 'react-router-dom';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { requestGenerateSpotTicketsLink } from './reducer';
import { createStructuredSelector } from 'reselect';
import { selectAddSpotTicketsLinkLoading } from './selectors';
import { AddSpotTicketsProps } from './types';
import { selectSpotTicketsBalance } from '../MySpotTickets/selectors';
import _ from 'lodash';
import { selectAccountStatusData } from '@app/containers/HomeContainer/selectors';
import moment from 'moment';
import { preventInvalidCharsNumberInput } from '@app/utils/uiUtils';
import { requestRenewSubscription } from '../reducer';

const AddSpotTicketsContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  padding-left: 2rem;
  padding-bottom: 1.5rem;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  background-color: ${colors.dashboardBg};
`;

const AddSpotTicketsSubContainer = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  width: 19.12rem;
  height: 19.5rem;
  border-radius: 1rem;
  background-color: ${colors.white};
  box-shadow: 0px 0px 1.25rem ${colors.myTicketsColors.cardShadow};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 1.12rem;

  &:nth-of-type(2) {
    margin-left: 2rem;
    cursor: pointer;
  }
`;

const MainIconImg = styled.img`
  width: 6rem;
  height: 6rem;
`;

const TicketAmountContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.12rem;
`;

const TicketAmountText = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  color: ${colors.myTicketsColors.ticketAmount};
  margin-left: 0.5rem;
`;

const SecondaryIconImg = styled.img`
  width: 2rem;
  height: 2rem;
`;

const MyTicketTitle = styled.p`
  margin-top: 0.75rem;
  ${fonts.weights.fw800()};
  ${fonts.size.big()};
  line-height: 1.75rem;
  color: ${colors.myTicketsColors.myTicketTitle};
  text-align: center;
`;

const ViewTransactionHistory = styled.p`
  width: 13.875rem;
  margin-top: 8px;
  ${fonts.weights.fw400()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  text-align: center;
  color: ${colors.myTicketsColors.transactionHistoryColor};
`;

const AddSpotTicketModal = styled(Modal)`
  width: 35rem !important;

  && {
    & .ant-modal-footer {
      display: none;
    }

    & .ant-modal-content {
      border-radius: 1.5rem;
    }

    & .ant-modal-body {
      border-radius: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
`;

const AddTicketModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.p`
  width: 24.43rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.large()};
  ${fonts.weights.fw700()};
  line-height: 2.62rem;
  text-align: center;
  color: ${colors.primary};
  margin-left: auto;
  margin-right: auto;
`;

const TicketAmountInput = styled(Input)`
  width: 16rem;
  margin-top: 1.5rem;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${colors.white};
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ContinueButton = styled(CustomButton)`
  width: 8.5rem;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
`;

const SubscriptionCard = styled.div<any>`
  width: 40.18rem;
  height: 19.5rem;
  border-radius: 1rem;
  background-color: ${(props) => (props.cardState ? colors.cardHoverBg : colors.white)};
  box-shadow: 0px 0px 1.25rem ${colors.myTicketsColors.cardShadow};
  padding: 1.43rem 2.37rem 1.5rem 2.5rem;
  margin-top: 3rem;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: ${colors.cardHoverBg};
  }
`;

const SubscriptionCardSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-right: 1.25rem;
`;

const CustomProgressBar = styled(Progress)`
  width: 9.37rem;
  margin-top: 1.25rem;
  && {
    .ant-progress-success-bg,
    .ant-progress-bg {
      background-color: ${colors.offersReceivedColors.collapseBtnBg};
      height: 0.8rem !important;
    }

    .ant-progress-inner {
      background-color: ${colors.cardBg};
    }
  }
`;

const ProgressText = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.xsmall()};
  line-height: 1rem;
  color: ${colors.darkBorder};
  margin-top: 0.18rem;
  margin-bottom: 0.18rem;
`;

const SubscriptionInstructList = styled.ul``;

const SubscriptionIntructItems = styled.li`
  ${fonts.weights.fw400()};
  ${fonts.size.size15px()};
  line-height: 1.5rem;
  color: ${colors.cardListItem};

  &:last-child {
    ${fonts.weights.fw700()};
  }
`;

const RenewButton = styled(CustomButton)`
  width: 10rem;
  margin-top: 1.81rem;
`;

const LaunchOfferText = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.small()};
  line-height: 1rem;
  color: ${colors.primary};
  text-align: center;
  margin-top: 0.5rem;
`;

const StyledImg = styled.img``;

const Wrapper = styled.div<any>`
  padding: 0 0 4.75rem 0;
  width: fit-content;
`;

const AddSpotTickets = ({
  spotTicketsBalance,
  generateSpotTicketsLoading,
  sellerAccountStatusData,
  dispatchGenerateAddSpotTicketsLink,
  dispatchRenewSubscription
}: AddSpotTicketsProps) => {
  const [addSpotTicketModalVisible, setAddSpotTicketModalVisible] = useState<boolean>(false);
  const [tickets, setTickets] = useState<number>(0);
  const [subscriptionCardClicked, setSubscriptionCardClicked] = useState<boolean>(false);
  const eleRef: any = useRef(null);

  const history = useHistory();

  useEffect(() => {
    if (!generateSpotTicketsLoading) {
      setAddSpotTicketModalVisible(false);
    }
  }, [generateSpotTicketsLoading]);

  const handleAddSpotTickets = () => {
    if (tickets) {
      dispatchGenerateAddSpotTicketsLink(tickets);
    }
  };

  const calculateSubscriptionProgress = () => {
    if (sellerAccountStatusData?.subscription?.daysLeft <= 0) {
      return 0;
    } else {
      return (sellerAccountStatusData?.subscription?.daysLeft * 100) / 31;
    }
  };

  const handleRenewSubscription = () => {
    if (spotTicketsBalance < 500) {
      // dispatchGenerateAddSpotTicketsLink(500 - spotTicketsBalance);
      message.error(translate('low_balance'));
    } else {
      dispatchRenewSubscription();
    }
  };

  const ScrollToBottom = () => {
    eleRef.current.scrollIntoView({ block: 'end' });
  };

  return (
    <AddSpotTicketsContainer>
      <AddSpotTicketModal
        centered={true}
        visible={addSpotTicketModalVisible}
        destroyOnClose={true}
        footer={<></>}
        onCancel={() => setAddSpotTicketModalVisible(false)}
      >
        <AddTicketModalContainer>
          <ModalTitle>{translate('add_tickets_modal_title')}</ModalTitle>
          <TicketAmountInput
            type="number"
            min={1}
            placeholder={translate('ticket_input_placeholder')}
            onChange={(e: any) => setTickets(e.target.value)}
            data-testid="add-ticket-input"
            onKeyDown={preventInvalidCharsNumberInput}
          ></TicketAmountInput>
          <ContinueButton
            loading={generateSpotTicketsLoading}
            onClick={handleAddSpotTickets}
            data-testid="add-ticket-continue"
            disabled
          >
            {translate('continue')}
          </ContinueButton>
          <LaunchOfferText>{translate('launch_offer')}</LaunchOfferText>
        </AddTicketModalContainer>
      </AddSpotTicketModal>
      <AddSpotTicketsSubContainer>
        <CardContainer
          // onClick={() => history.push(`${routeConstants.dashboard.route}?activeTab=my-tickets`)}
          data-testid="my-tickets-card-link"
        >
          <MainIconImg src={myTicketsCrownIcon} />
          <TicketAmountContainer>
            <SecondaryIconImg src={myTicketsCrownIcon} />
            {/* <TicketAmountText>{translate('spot_ticket_amount', { ticketAmount: spotTicketsBalance })}</TicketAmountText> */}
            <TicketAmountText>{translate('unlimited_spot_tickets')}</TicketAmountText>
          </TicketAmountContainer>
          <MyTicketTitle>{translate('my_tickets')}</MyTicketTitle>
          {/* <ViewTransactionHistory>{translate('view_transaction_history')}</ViewTransactionHistory> */}
          <ViewTransactionHistory>{translate('launch_offer')}</ViewTransactionHistory>
        </CardContainer>
        <CardContainer
          onClick={() => setAddSpotTicketModalVisible(!addSpotTicketModalVisible)}
          data-testid="add-ticket-modal-toggle"
        >
          <MainIconImg src={spotTicketIcon} />
          <TicketAmountContainer>
            <TicketAmountText>{translate('add_spot_ticket')}</TicketAmountText>
          </TicketAmountContainer>
          <ViewTransactionHistory>{translate('click_to_add_tickets')}</ViewTransactionHistory>
        </CardContainer>
      </AddSpotTicketsSubContainer>
      <Wrapper ref={eleRef} subscriptionCardClicked={subscriptionCardClicked} data-testid="card-wrapper">
        <SubscriptionCard
          data-testid="sub-card"
          cardState={subscriptionCardClicked}
          onClick={() => {
            setSubscriptionCardClicked((prev) => !prev);
            ScrollToBottom();
          }}
        >
          <SubscriptionCardSection>
            <StyledImg src={renewSubscriptionIcon} />
            <CustomProgressBar percent={calculateSubscriptionProgress()} showInfo={false} />
            <ProgressText>{moment(sellerAccountStatusData?.subscription?.dateOfRenewal).format('Do MMM')}</ProgressText>
            <MyTicketTitle>{translate('your_subscription')}</MyTicketTitle>
            <If
              condition={sellerAccountStatusData?.subscription?.daysLeft > 0}
              otherwise={<ViewTransactionHistory>{translate('subscription_expired')}</ViewTransactionHistory>}
            >
              <ViewTransactionHistory>
                {translate('days_left_subscription', { daysLeft: sellerAccountStatusData?.subscription?.daysLeft })}
              </ViewTransactionHistory>
            </If>
          </SubscriptionCardSection>
          <SubscriptionInstructList>
            {_.range(4).map((number) => (
              <SubscriptionIntructItems key={number}>
                {translate(`sub_instruct_list_${number + 1}`)}
              </SubscriptionIntructItems>
            ))}
          </SubscriptionInstructList>
        </SubscriptionCard>
        <If condition={subscriptionCardClicked}>
          <RenewButton onClick={handleRenewSubscription} data-testid="renew-btn">
            {translate('renew_sub')}
          </RenewButton>
        </If>
      </Wrapper>
    </AddSpotTicketsContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  spotTicketsBalance: selectSpotTicketsBalance(),
  generateSpotTicketsLoading: selectAddSpotTicketsLinkLoading(),
  sellerAccountStatusData: selectAccountStatusData()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGenerateAddSpotTicketsLink: (tickets: number) =>
      dispatch(
        requestGenerateSpotTicketsLink({
          tickets
        })
      ),
    dispatchRenewSubscription: () => dispatch(requestRenewSubscription({}))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AddSpotTickets) as React.FC;

export const AddSpotTicketsTest = AddSpotTickets;
