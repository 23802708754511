import React, { useState } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button, Form, Input, message } from 'antd';
import styled from 'styled-components';
import { DeclineOfferModalProps } from './types';
import declineOfferWhiteIcon from '@images/icons/OffersReceived/decline-offer-white.svg';

const DeclineOfferModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6.5rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.white};
`;

const DeclineOfferTitle = styled.p`
  width: 45.8rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  color: ${colors.primary};
  text-align: center;
  line-height: 3.93rem;
  margin-left: auto;
  margin-right: auto;
`;

const DeclineOfferForm = styled(Form)`
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;

  && {
    width: 16rem;

    & > div:nth-of-type(3) {
      margin-top: 3rem;
    }
  }
`;

const DeclineOfferFormItem = styled(Form.Item)`
  && {
    margin-bottom: 0px;

    label {
      ${fonts.weights.fw600()};
      ${fonts.size.regular()};
      line-height: 1.25rem;
      color: ${colors.offersReceivedColors.counterOfferFormLabel};
    }

    input {
      background-color: ${colors.white};
      border-radius: 0.31rem;
    }
  }
`;

const DeclineOfferAmountNote = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.extraSmall()};
  line-height: 0.62rem;
  margin-top: 0.25rem;
  color: ${colors.offersReceivedColors.counterOfferNoteColor};
`;

const FormActionsContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 16.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CancelBtn = styled(Button)`
  width: 7.81rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.offersReceivedColors.grayBtnBg};
  color: ${colors.offersReceivedColors.grayBtnColor};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.offersReceivedColors.grayBtnBg};
    color: ${colors.offersReceivedColors.grayBtnColor};
    border: none;
  }
`;

const DeclineOfferBtn = styled(Button)`
  width: 12.62rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;

const StyledImg = styled.img`
  margin-right: 0.62rem;
`;

const MessageText = styled.p`
  width: 31.12rem;
  margin-top: 1.18rem;
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  color: ${colors.offersReceivedColors.offerAcceptMessageClr};
  text-align: center;
`;

const DeclineOfferModal = ({ handleCancel, declineOffer }: DeclineOfferModalProps) => {
  const [reason, setReason] = useState<string>('');

  return (
    <DeclineOfferModalContent>
      <DeclineOfferTitle>{translate('decline_offer_confirm')}</DeclineOfferTitle>
      <MessageText>{translate('decline_offer_message')}</MessageText>
      <DeclineOfferForm layout="vertical">
        <DeclineOfferFormItem name={'declineReason'} label={translate('please_tell')}>
          <Input
            type="text"
            onChange={(e) => e.target.value.length <= 250 && setReason(e.target.value)}
            value={reason}
            data-testid="decline-reason-input"
            onPaste={(e) => {
              const clipboardData = e.clipboardData;
              const pastedData = clipboardData.getData('Text');

              if (pastedData.length > 250) {
                e.preventDefault();
                message.error(translate('250_chars_limit'));
              } else {
                if (pastedData.length + reason.length > 250) {
                  e.preventDefault();
                  message.error(translate('250_chars_limit'));
                }
              }
            }}
          />
          <DeclineOfferAmountNote>
            {translate('note_from_seller_character_limit', { characterCount: reason.length })}
          </DeclineOfferAmountNote>
        </DeclineOfferFormItem>
      </DeclineOfferForm>
      <FormActionsContainer>
        <CancelBtn onClick={handleCancel} data-testid="decline-offer-cancel">
          {translate('cancel')}
        </CancelBtn>
        <DeclineOfferBtn onClick={() => declineOffer(reason)} data-testid="decline-offer-btn">
          <StyledImg src={declineOfferWhiteIcon} /> {translate('decline_offer')}
        </DeclineOfferBtn>
      </FormActionsContainer>
    </DeclineOfferModalContent>
  );
};

export default DeclineOfferModal;
