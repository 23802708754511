import { colors, fonts } from '@app/themes';
import React, { useEffect, useState } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import styled from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CustomButton } from 'tsw-sdk';
import { CopyrightProtectionQuestionProps } from './types';
import { Link, useHistory } from 'react-router-dom';
import { COPYRIGHT_PROTECTION_STRATEGIES } from '@app/utils/constants';
import { Modal } from 'antd';
import ConfirmProceedSubmission from '../../../../components/ConfirmProceedSubmission';
import routeConstants from '@app/utils/routeConstants';

type ButtonProps = {
  selected: boolean;
};

const CopyrightProtectionQuestionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
`;

const CopyrightProtectionQuestionContentContainer = styled.div`
  width: 100%;
  max-width: 38rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
`;

const CopyrightProtectionQuestionTitle = styled.h2`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xlarge()};
  color: ${colors.copyrightColors.copyrightDeclarationTitle};
  margin-bottom: 0;
`;

const ProceedBtn = styled(CustomButton)`
  margin-top: 3rem;

  && {
    width: 100%;
    border: 1px solid ${colors.primary};
    background-color: transparent;
    justify-content: center;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const CopyrightQuestion = styled.p`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-top: 3rem;
  line-height: 1.96rem;
`;

const CopyrightQuestionBtnContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

const CopyrightQuestionBtn = styled(CustomButton)<ButtonProps>`
  border-width: 2px !important;
  border-style: solid !important;
  border-color: ${(props) => (props.selected ? colors.copyrightColors.btnBorder : 'transparent')} !important;
`;

const CopyrightQuestionBtnYes = styled(CustomButton)<ButtonProps>`
  margin-left: 1.5rem;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: ${(props) => (props.selected ? colors.copyrightColors.btnBorder : 'transparent')} !important;
`;

const PleaseNoteText = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  margin-top: 3rem;
  line-height: 1.31rem;
`;

const BtnLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
`;

const ConfirmPopup = styled(Modal)`
  &&& {
    & .ant-modal-content {
      border-radius: 1.25rem;
    }

    & .ant-modal-footer {
      display: none;
    }
  }
`;

const CopyrightProtectionQuestion = ({
  storyId,
  copyrightId,
  setShouldCopyright,
  dispatchUpdateCopyrightProtectionStrategy
}: CopyrightProtectionQuestionProps) => {
  const [optionSelected, setOptionSelected] = useState<'yes' | 'no' | ''>('');
  const history = useHistory();

  const [confirmSubmissionModalVisible, setConfirmSubmissionModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setShouldCopyright(false);
  }, []);

  const handleConfirm = () => {
    if (optionSelected === 'no') {
      setConfirmSubmissionModalVisible(true);
    } else {
      handleProceed();
    }
  };

  const handleProceed = () => {
    setShouldCopyright(optionSelected === 'yes');

    dispatchUpdateCopyrightProtectionStrategy({
      data: {
        storyId: storyId,
        copyrightId: copyrightId,
        protectionStrategy:
          optionSelected === 'no'
            ? COPYRIGHT_PROTECTION_STRATEGIES.NO_COPYRIGHT_ON_STORY
            : COPYRIGHT_PROTECTION_STRATEGIES.SELF_ATTESTATION,
        proceedToNextStep: optionSelected === 'no'
      },
      redirectToDashboard: false
    });

    if (optionSelected === 'no') {
      history.push(`${routeConstants.copyrightContainer.route}?page=submission`);
    } else {
      history.push(`${routeConstants.copyrightContainer.route}?page=copyright_protection`);
    }
  };

  return (
    <CopyrightProtectionQuestionContainer data-testid="copyright-protection-question-container">
      <ConfirmPopup
        visible={confirmSubmissionModalVisible}
        centered={true}
        onCancel={() => setConfirmSubmissionModalVisible(false)}
        width={800}
        destroyOnClose={true}
      >
        <ConfirmProceedSubmission
          handleProceed={handleProceed}
          handleGoBack={() => setConfirmSubmissionModalVisible(false)}
        />
      </ConfirmPopup>
      <CopyrightProtectionQuestionContentContainer>
        <CopyrightProtectionQuestionTitle>{translate('copyright_protection')}</CopyrightProtectionQuestionTitle>
        <CopyrightQuestion>{translate('copyright_question')}</CopyrightQuestion>
        <CopyrightQuestionBtnContainer>
          <CopyrightQuestionBtn
            onClick={() => setOptionSelected('no')}
            data-testid="copyright-question-no"
            selected={optionSelected === 'no'}
          >
            {translate('no')}
          </CopyrightQuestionBtn>
          <CopyrightQuestionBtnYes
            onClick={() => setOptionSelected('yes')}
            data-testid="copyright-question-yes"
            selected={optionSelected === 'yes'}
          >
            {translate('yes')}
          </CopyrightQuestionBtnYes>
        </CopyrightQuestionBtnContainer>
        <PleaseNoteText>{translate('please_note_message_3')}</PleaseNoteText>
        <ProceedBtn
          onClick={handleConfirm}
          text_color={`${colors.darkBorder}`}
          data-testid="proceed-btn-copyright-question"
          disabled={optionSelected === ''}
        >
          {translate('proceed')} <ArrowRightOutlined />
        </ProceedBtn>
      </CopyrightProtectionQuestionContentContainer>
    </CopyrightProtectionQuestionContainer>
  );
};

export default CopyrightProtectionQuestion;

export const CopyrightProtectionQuestionTest = CopyrightProtectionQuestion;
