import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { CustomButton, If } from 'tsw-sdk';
import { AccessRequestCardProps } from '../../types';

const AccessRequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: 3rem;
  align-items: center;
`;

const AccessRequestCardContainer = styled.div`
  width: 32.5rem;
  height: auto;
  min-height: 10.93rem;
  border-radius: 1.25rem;
  box-shadow: 0px 0px 1.13rem ${colors.offersReceivedColors.offerCardShadowColor};
  display: flex;
  flex-direction: column;
  padding: 2.72rem 0px 1.5rem 3.75rem;
  position: relative;
  background-color: ${colors.white};
  &:after {
    content: ' ';
    position: absolute;
    left: -2.4rem;
    bottom: -1rem;
    border-top: none;
    border-right: 4.68rem solid transparent;
    border-left: 3.12rem solid transparent;
    border-bottom: 3.12rem solid ${colors.white};
    transform: rotate(-20deg);
  }
`;

const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RequestStatusText = styled.p`
  margin-top: 0.625rem;
  ${fonts.weights.fw400()};
  ${fonts.size.xBig()}
  line-height: 1.96rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const RequestNotesText = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.regular()}
  line-height: 1.96rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const UserTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.large()}
  line-height: 2.43rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const RespondButton = styled(Button)`
  border-radius: 0.5rem;
  padding: 0.75rem 8.25rem 0.75rem 8.25rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;
  margin-top: 1.5rem;
  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;

const AccessRequestCard = ({ onClickResponse, text, buyerData, isLatest }: AccessRequestCardProps) => {
  return (
    <AccessRequestContainer>
      <AccessRequestCardContainer>
        <CardContentContainer>
          <UserTitle>{`${buyerData?.firstName} ${buyerData?.lastName}`}</UserTitle>
          <If
            condition={text}
            otherwise={<RequestStatusText>{translate('request_access_placeholder')}</RequestStatusText>}
          >
            <RequestStatusText>{text}</RequestStatusText>
          </If>
        </CardContentContainer>
      </AccessRequestCardContainer>
      <If condition={isLatest}>
        <RespondButton onClick={onClickResponse}>{translate('respond')}</RespondButton>
      </If>
    </AccessRequestContainer>
  );
};

export default AccessRequestCard;
