import React from 'react';
import styled from 'styled-components';
import { TransactionItemProps } from './types';
import transactionIncomingIcon from '@images/icons/MyTickets/transaction-incoming.svg';
import transactionOutgoingIcon from '@images/icons/MyTickets/transaction-outgoing.svg';
import { colors, fonts } from '@app/themes';
import { translate } from '@app/components/IntlGlobalProvider';
import { Link } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';
import { SPOT_TICKET_ACTIONS, TICKET_ACTION_MAP } from '@app/utils/constants';

const TranscationItemContainer = styled.div`
    width: 57rem;
    height: 5rem;
    display grid;
    grid-template-columns: 5rem 13rem 28rem 11rem;
    grid-template-rows: 100%;
    align-items: center;
    background-color: ${colors.white};
    border-radius: 16px;

    &:first-of-type{
      margin-top: 1.5rem
    }
`;

const StyledImg = styled.img`
  margin-left: 1rem;
`;

const SpotTicketAmount = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  line-height: 1.5rem;
  color: ${colors.myTicketsColors.ticketAmount};
`;

const TransactionStatus = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.big()};
  line-height: 1.75rem;
  color: ${colors.myTicketsColors.myTicketTitle};
  margin-left: auto;
  margin-right: auto;
`;

const TransactionMethod = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  color: ${colors.myTicketsColors.transactionHistoryColor};
  padding-right: 0.5rem;
`;

const TransactionItem = ({ ticketAmount, action, method, receiptLink }: TransactionItemProps) => {
  return (
    // <Link to={`${routeConstants.dashboard.route}?activeTab=transaction-details`}>
    <Link
      to={{
        pathname: `${routeConstants.dashboard.route}`,
        search: '?activeTab=transaction-details',
        state: {
          amount: ticketAmount,
          method: method,
          action: action,
          receiptLink: receiptLink
        }
      }}
    >
      <TranscationItemContainer>
        <StyledImg src={action !== SPOT_TICKET_ACTIONS.USED ? transactionIncomingIcon : transactionOutgoingIcon} />
        <SpotTicketAmount>{translate('spot_ticket_amount', { ticketAmount: ticketAmount })}</SpotTicketAmount>
        <TransactionStatus>{TICKET_ACTION_MAP[action]}</TransactionStatus>
        <TransactionMethod>{method}</TransactionMethod>
      </TranscationItemContainer>
    </Link>
  );
};

export default TransactionItem;
