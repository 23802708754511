import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  languageGenreLoading: false,
  loadingUpload: false,
  loadingTags: false,
  loadingStoryTypes: false,
  loadingSubmitStory: false,
  languageList: [],
  genreList: [],
  langGenError: null,
  tagsList: [],
  storyTypeList: [],
  storyTypeError: null,
  tagsError: null,
  submitStoryData: null,
  submitStoryError: null,
  screenPlayFileName: null,
  screenPlayUploading: false,
  screenPlayUploadError: null,
  narrationUploading: false,
  narrationFileName: null,
  narrationUploadError: null,
  imagesFilesUploading: false,
  imagesFileNameList: [],
  imagesFileuploadError: null,
  storiesDetails: null,
  storiesDetailsError: null,
  storiesDetailsLoading: false,
  screenPlayFileId: null,
  narrationFileId: null,
  stateChangeLoading: false,
  stateChangeError: null,
  stateChangeData: {}
};

const newSubmissionSlice = createSlice({
  name: 'submission',
  initialState,
  reducers: {
    requestLangGenre: {
      reducer: (state) => {
        state.languageGenreLoading = true;
      },
      prepare
    },
    requestGetTags: {
      reducer: (state) => {
        state.loadingTags = true;
      },
      prepare
    },
    requestSubmitStory: {
      reducer: (state) => {
        state.loadingSubmitStory = true;
      },
      prepare
    },
    requestGetStoryDetails: {
      reducer: (state) => {
        state.storiesDetailsLoading = true;
      },
      prepare
    },
    requestGetStoryTypes: {
      reducer: (state) => {
        state.loadingStoryTypes = true;
      },
      prepare
    },
    successSubmitStory(state, action) {
      state.loadingSubmitStory = false;
      state.submitStoryData = get(action.payload, 'id', {});
    },
    failureSubmitStory(state, action) {
      state.loadingSubmitStory = false;
      state.submitStoryError = get(action.payload, 'message', 'something went wrong');
    },

    successPutLanguageList(state, action) {
      state.languageGenreLoading = false;
      state.languageList = action.payload;
    },
    successPutGenreList(state, action) {
      state.languageGenreLoading = false;
      state.genreList = action.payload;
    },
    failureGetLangGenre(state, action) {
      state.languageGenreLoading = false;
      state.langGenError = get(action.payload, 'message', 'something went wrong');
      state.languageList = [];
      state.genreList = [];
    },
    successGetTags(state, action) {
      state.loadingTags = false;
      state.tagsList = action.payload;
    },
    failureGetTags(state, action) {
      state.loadingTags = false;
      state.tagsError = get(action.payload, 'message', 'something went wrong');
      state.tagsList = [];
    },
    successGetScreenplayFileName(state, action) {
      state.screenPlayFileName = action.payload;
    },
    successGetNarrationFileName(state, action) {
      state.narrationFileName = action.payload;
    },
    successUploadScreenplayFile(state) {
      state.screenPlayUploading = false;
      state.screenPlayUploadError = null;
    },
    failureUploadScreenPlayFile(state, action) {
      state.screenPlayUploadError = get(action.payload, 'message', 'something went wrong');
      state.screenPlayUploading = false;
    },
    successUploadNarrationFile(state) {
      state.narrationUploading = false;
      state.narrationUploadError = null;
    },
    failureUploadNarrationFile(state, action) {
      state.narrationUploadError = get(action.payload, 'message', 'something went wrong');
      state.narrationUploading = false;
    },
    successUploadImageFiles(state, action) {
      state.imagesFilesUploading = false;
      state.imagesFileNameList = action.payload;
      state.imagesFileuploadError = null;
    },
    failureUploadImageFiles(state, action) {
      state.imagesFileuploadError = get(action.payload, 'message', 'something went wrong');
      state.imagesFilesUploading = false;
    },
    successGetDraftedStory(state, action) {
      state.storiesDetailsLoading = false;
      state.storiesDetails = action.payload;
      state.storiesDetailsError = null;
    },
    failureGetDraftedStory(state, action) {
      state.storiesDetailsError = get(action.payload, 'message', 'something went wrong');
      state.storiesDetailsLoading = false;
    },
    setScreenplayFileId(state, action) {
      state.screenPlayFileId = action.payload;
    },
    setNarrationFileId(state, action) {
      state.narrationFileId = action.payload;
    },
    successGetStoryTypes(state, action) {
      state.loadingStoryTypes = false;
      state.storyTypeList = action.payload;
    },
    failureGetStoryTypes(state, action) {
      state.loadingStoryTypes = false;
      state.storyTypeError = get(action.payload, 'message', 'something went wrong');
      state.storyTypeList = [];
    },
    requestStateChange: {
      reducer: (state) => {
        state.stateChangeLoading = true;
      },
      prepare
    },
    successStateChange(state, action) {
      state.stateChangeLoading = false;
      state.stateChangeData = action.payload;
    },
    failureStateChange(state, action) {
      state.stateChangeLoading = false;
      state.stateChangeError = get(action.payload, 'message', 'something went wrong');
    },

    resetState(state) {
      state.submitStoryData = null;
      state.submitStoryError = null;
      state.screenPlayFileName = null;
      state.screenPlayUploading = false;
      state.screenPlayUploadError = null;
      state.narrationUploading = false;
      state.narrationFileName = null;
      state.narrationUploadError = null;
      state.imagesFilesUploading = false;
      state.imagesFileNameList = [];
      state.imagesFileuploadError = null;
      state.storiesDetails = null;
      state.storiesDetailsError = null;
      state.storiesDetailsLoading = false;
      state.storyTypeList = [];
      state.storyTypeError = null;
      state.loadingStoryTypes = false;
      state.stateChangeLoading = false;
      state.stateChangeError = null;
      state.stateChangeData = {};
    }
  }
});

export const {
  requestLangGenre,
  requestGetStoryTypes,
  failureGetLangGenre,
  successPutLanguageList,
  successPutGenreList,
  requestGetTags,
  successGetTags,
  failureGetTags,
  requestSubmitStory,
  successSubmitStory,
  failureSubmitStory,
  successGetScreenplayFileName,
  successUploadScreenplayFile,
  failureUploadScreenPlayFile,
  successGetNarrationFileName,
  successUploadNarrationFile,
  failureUploadNarrationFile,
  successUploadImageFiles,
  failureUploadImageFiles,
  requestGetStoryDetails,
  successGetDraftedStory,
  failureGetDraftedStory,
  resetState,
  setScreenplayFileId,
  setNarrationFileId,
  successGetStoryTypes,
  failureGetStoryTypes,
  requestStateChange,
  successStateChange,
  failureStateChange
} = newSubmissionSlice.actions;

export default newSubmissionSlice.reducer;
