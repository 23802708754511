import { cognito } from 'tsw-sdk';
import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  failureCreateProfile,
  failureGetUserProfile,
  failurePhoneVerification,
  failureUploadBannerImage,
  failureUploadProfileImage,
  requestCreateProfile,
  requestGetProfile,
  requestPhoneVerification,
  requestUploadBannerImage,
  requestUploadProfileImage,
  requestVerifyOtp,
  successCreateProfile,
  successGetBannerFileName,
  successGetFileName,
  successGetUserProfile,
  successPhoneVerification,
  successUploadBannerImage,
  successUploadProfileImage,
  successVerifyOtp
} from './reducer';
import { getMutationResponse, getQueryResponse } from '@app/utils/graphqlUtils';
import { CREATE_PROFILE, GET_PROFILE_IMAGE_FILE_NAME, GET_SIGNED_URL } from '@utils/queries';
import { get } from 'lodash-es';
import { BANNER_PATH, PROFILE, PROFILE_PATH, SELLER } from '@app/utils/constants';
import { AnyAction } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { uploadFile } from '@app/utils/apiUtils';
import { translate } from '@app/components/IntlGlobalProvider';
import { GET_BANNER_IMAGE_FILE_NAME, GET_USER_PROFILE } from './queries';
import history from '@app/utils/history';
import routeConstants from '@app/utils/routeConstants';

const { createCognitoUserPool, updatePhoneNumberRequest, verifyPhoneNumberRequest } = cognito;

const userPoolId = process.env.USER_POOL_ID as string;
const userPoolClientId = process.env.USER_POOL_CLIENT_ID as string;

export function* getPhoneVerification(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);
    yield call(updatePhoneNumberRequest, action.payload);
    yield put(successPhoneVerification());
  } catch (err) {
    yield put(failurePhoneVerification(err));
  }
}

export function* getVerifyOtp(action: any): Generator<any, any, any> {
  try {
    yield call(createCognitoUserPool, userPoolId, userPoolClientId);
    yield call(verifyPhoneNumberRequest, action.payload);
    yield put(successVerifyOtp());
  } catch (err) {
    yield put(failurePhoneVerification(err));
  }
}

export function* mutateCreateProfile(action: any): Generator<any, any, any> {
  try {
    const payload = {
      ...action.payload.data
    };
    const { ok, error, data } = yield call(getMutationResponse, CREATE_PROFILE, payload);

    if (ok) {
      yield put(successCreateProfile(data));

      if (action.payload.goBack) {
        history.goBack();
      }

      if (!action.payload.data.sellerProfileInput.isSave) {
        history.push(routeConstants.packageDetail.route);
      } else {
        if (!action.payload.autoSave) {
          message.success(translate('form_saved'));
        }
      }
    } else {
      yield put(failureCreateProfile(error));
      const errorMessage = get(error, 'message', translate('something_went_wrong'));
      notification.open({
        message: translate('error'),
        description: errorMessage.substring(errorMessage.indexOf(':') + 1)
      });
    }
  } catch (err) {
    const errorMessage = get(err, 'message', translate('something_went_wrong'));
    yield put(failureCreateProfile(err));
    notification.open({
      message: translate('error'),
      description: errorMessage.substring(errorMessage.indexOf(':') + 1)
    });
  }
}

export function* getUserProfile(): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_USER_PROFILE, {});

    if (ok) {
      yield put(successGetUserProfile(get(data, 'getSellerProfile', {})));
    } else {
      yield put(failureGetUserProfile(error));
    }
  } catch (err) {
    yield put(failureGetUserProfile(err));
  }
}

export function* uploadProfileImage(action: AnyAction): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, GET_PROFILE_IMAGE_FILE_NAME, {});

    const fileName = ok
      ? `${data.uploadSellerProfileImage.fileName}${data.uploadSellerProfileImage.extensions[0]}`
      : '';

    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: PROFILE,
          method: 'PUT',
          rootFolder: PROFILE_PATH,
          fileName: fileName
        }
      };

      const file = action.payload;

      const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (ok) {
        yield call(uploadFile, data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        yield put(successGetFileName(fileName));

        yield put(successUploadProfileImage());
        message.success(translate('profile_image_upload_success'));
      } else {
        yield put(failureUploadProfileImage(get(error, 'message', 'something_went_wrong')));
        message.error(get(error, 'message', 'something_went_wrong'));
      }
    } else {
      yield put(failureUploadProfileImage(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureUploadProfileImage(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export function* uploadBannerImage(action: AnyAction): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, GET_BANNER_IMAGE_FILE_NAME, {});

    const fileName = ok
      ? `${data.uploadSellerProfileBanner.fileName}${data.uploadSellerProfileBanner.extensions[0]}`
      : '';

    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: PROFILE,
          method: 'PUT',
          rootFolder: BANNER_PATH,
          fileName: fileName
        }
      };

      const file = action.payload;

      const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (ok) {
        yield call(uploadFile, data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        yield put(successGetBannerFileName(fileName));

        yield put(successUploadBannerImage());
        message.success(translate('banner_image_upload_success'));
      } else {
        yield put(failureUploadBannerImage(get(error, 'message', 'something_went_wrong')));
        message.error(get(error, 'message', 'something_went_wrong'));
      }
    } else {
      yield put(failureUploadBannerImage(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureUploadBannerImage(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export default function* profileCreationSaga() {
  yield all([
    takeLatest(requestPhoneVerification.toString(), getPhoneVerification),
    takeLatest(requestVerifyOtp.toString(), getVerifyOtp),
    takeLatest(requestCreateProfile.toString(), mutateCreateProfile),
    takeLatest(requestGetProfile.toString(), getUserProfile),
    takeLatest(requestUploadProfileImage.toString(), uploadProfileImage),
    takeLatest(requestUploadBannerImage.toString(), uploadBannerImage)
  ]);
}

export const profileContainerSagaArr = [
  takeLatest(requestPhoneVerification.toString(), getPhoneVerification),
  takeLatest(requestVerifyOtp.toString(), getVerifyOtp),
  takeLatest(requestCreateProfile.toString(), mutateCreateProfile),
  takeLatest(requestGetProfile.toString(), getUserProfile),
  takeLatest(requestUploadProfileImage.toString(), uploadProfileImage),
  takeLatest(requestUploadBannerImage.toString(), uploadBannerImage)
];
