import { translate } from '@app/components/IntlGlobalProvider';
import { fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { CustomButton, If } from 'tsw-sdk';
import { ArrowRightOutlined } from '@ant-design/icons';
import { StoryActionsProps } from '../../types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { STORIES_STATES } from '@app/utils/constants';

const StoryActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ActionContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 0.68rem;
  align-items: flex-end;
`;

const ActionText = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  text-align: right;
`;

const ActionTextBold = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  text-align: right;
`;

const StoryActions = ({
  handleUnpublish,
  setRenewPageVisible,
  handleRepublish,
  expiresAt,
  storyId,
  storyState
}: StoryActionsProps) => {
  return (
    <StoryActionsContainer>
      <If condition={storyState === STORIES_STATES.PUBLISHED}>
        <ActionContainer>
          <ActionText>{translate('expires_on', { date: moment(expiresAt).format('DD/MM/YYYY') })}</ActionText>
          <CustomButton onClick={setRenewPageVisible} data-testid="renew-btn">
            {translate('renew_subscription_now')} <ArrowRightOutlined />
          </CustomButton>
          <ActionText>{translate('renew_subscription_before_expiry')}</ActionText>
        </ActionContainer>
      </If>

      <If condition={storyState === STORIES_STATES.PUBLISHED}>
        <ActionContainer>
          <CustomButton onClick={handleUnpublish} data-testid="unpublish-btn">
            {translate('unpublish')} <ArrowRightOutlined />
          </CustomButton>
          <ActionText>{translate('unpublish_description')}</ActionText>
        </ActionContainer>
      </If>

      <If condition={storyState === STORIES_STATES.EXPIRED}>
        <ActionContainer>
          <ActionText>{translate('expired_on', { date: moment(expiresAt).format('DD/MM/YYYY') })}</ActionText>
          <CustomButton onClick={setRenewPageVisible}>
            {translate('renew_subscription')} <ArrowRightOutlined />
          </CustomButton>
          <ActionText>{translate('renew_subscription_description')}</ActionText>
        </ActionContainer>
      </If>

      <If condition={storyState === STORIES_STATES.UNPUBLISHED}>
        <ActionContainer>
          <ActionText>{translate('expires_on', { date: moment(expiresAt).format('DD/MM/YYYY') })}</ActionText>
          <CustomButton onClick={handleRepublish} data-testid="republish-btn">
            {translate('republish_story')} <ArrowRightOutlined />
          </CustomButton>
          <ActionText>{translate('republish_description')}</ActionText>
        </ActionContainer>
      </If>

      <ActionContainer>
        <Link
          to={{
            pathname: '/dashboard',
            search: `?activeTab=new_submission&duplicateStoryId=${storyId}`
          }}
        >
          <CustomButton>
            {translate('create_duplicate_submission')} <ArrowRightOutlined />
          </CustomButton>
        </Link>

        <ActionText>{translate('create_duplicate_submission_description')}</ActionText>
        <ActionTextBold>{translate('create_duplicate_submission_tip')}</ActionTextBold>
      </ActionContainer>
    </StoryActionsContainer>
  );
};

export default StoryActions;
