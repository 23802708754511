import { gql } from 'apollo-boost';

export const GET_NOTIFICATIONS = gql`
  query Notifications($input: NotificationInput!, $pagination: NotificationPagination!) {
    notifications(input: $input, pagination: $pagination) {
      notifications {
        id
        isRead
        title
        desc
        created_at
        redirect
      }
      total
    }
  }
`;

export const READ_NOTIFICATION = gql`
  mutation ReadNotification($input: ReadInput!) {
    readNotification(input: $input)
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($input: ReadInput!) {
    deleteNotification(input: $input)
  }
`;
