import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import userIcon from '@images/icons/defaultProfilePic.svg';
import downloadOfferIcon from '@images/icons/OffersReceived/download-offer.svg';
import viewedIcon from '@images/icons/MySubmissionIcons/viewed-icon.svg';
import favouriteIcon from '@images/icons/MySubmissionIcons/star-icon.svg';
import offerIcon from '@images/icons/MySubmissionIcons/offer-icon.svg';

import { translate } from '@app/components/IntlGlobalProvider';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';
import { OfferCardProps } from './types';
// import moment from 'moment';
import { PROFILE_IMAGE_PROD_URL, PROFILE_IMAGE_QA_URL, PROFILE_IMAGE_URL } from '@app/utils/constants';
import { If } from 'tsw-sdk';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import { If } from 'tsw-sdk';

const OfferCardContainer = styled.div`
  width: 19.12rem;
  height: 15.87rem;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 1rem;
  box-shadow: 0px 0px 20px ${colors.offersReceivedColors.offerCardShadowColor};
  overflow: hidden;
  cursor: pointer;
`;

const OfferCardHeader = styled.div`
  width: 100%;
  height: 5.25rem;
  background-color: ${colors.offersReceivedColors.offerCardHeaderBg};
  display: flex;
  padding-top: 1.12rem;
  padding-left: 1.2rem;
  padding-right: 1.06rem;
  position: relative;
  overflow: hidden;
`;

const OfferCardHeaderBg = styled.div`
  width: 120%;
  position: absolute;
  top: -1rem;
  left: -1rem;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
`;

const OfferCardHeaderBgText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.xlarge()};
  color: ${colors.offersReceivedColors.cardHeaderBgTextColor};
  white-space: nowrap;
`;

const UserAvatarStack = styled.div`
  height: 2rem;
  margin-left: auto;
  display: flex;
  align-items: center;
  z-index: 3;
`;

const UserAvatarStackItem = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
`;

const OfferCardBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const OfferCardBodyTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StoryNameTitle = styled.h3`
  max-width: 16rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw900()};
  color: ${colors.offersReceivedColors.storyName};
  margin-right: 0.68rem;
  line-height: 1.87rem;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

const DownloadOfferIcon = styled.img`
  width: 0.75rem;
  height: 0.75rem;
`;

// const OfferCardDate = styled.p`
//   width: 100%;
//   margin-top: 0.5rem;
//   ${fonts.weights.fw700()};
//   ${fonts.size.xsmall()};
//   line-height: 1rem;
//   color: ${colors.offersReceivedColors.offerDateColor};
//   text-align: center;
// `;

const ScriptIncludedPill = styled.div`
  width: 6.87rem;
  height: 1.5rem;
  background-color: ${colors.offersReceivedColors.scriptIncludedPillBg};
  border-radius: 6.25rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${colors.offersReceivedColors.scriptIncludedPillColor};
  ${fonts.size.extraSmall()};
  ${fonts.weights.fw800()};
  line-height: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

const OfferStatsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.12rem;
  margin-top: 1rem;
  justify-content: center;
`;

const OfferStatsItem = styled.div`
  display: flex;
  align-items: center;
`;

const OfferStatsItemIcon = styled.img``;

const OfferStateItemText = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.xsmall()};
  line-height: 20px;
  color: ${colors.offersReceivedColors.storyName};
  margin-left: 0.31rem;
`;

const UserStackDiv = styled.div`
  &:not(:first-of-type) {
    margin-left: -0.62rem;
  }
`;

const OfferCard = ({ offer, currentLoadingStoryId, handleDownloadStory }: OfferCardProps) => {
  const history = useHistory();

  const openOffersPage = () => {
    history.push(`${routeConstants.dashboard.route}?activeTab=offers&story=${offer?.storyId}&title=${offer?.title}`);
  };

  return (
    <OfferCardContainer onClick={openOffersPage} data-testid="offer-card">
      <OfferCardHeader>
        <OfferCardHeaderBg>
          {_.range(10).map((number) => (
            <OfferCardHeaderBgText key={number}>{offer?.title}</OfferCardHeaderBgText>
          ))}
        </OfferCardHeaderBg>
        <UserAvatarStack>
          {offer?.offers?.map((individualOffer: any) => (
            <UserStackDiv key={individualOffer.id}>
              <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
                <UserAvatarStackItem
                  src={
                    individualOffer?.offerEntity?.profilePic
                      ? `${PROFILE_IMAGE_QA_URL}/${individualOffer?.offerEntity?.profilePic}`
                      : userIcon
                  }
                />
              </If>
              <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
                <UserAvatarStackItem
                  src={
                    individualOffer?.offerEntity?.profilePic
                      ? `${PROFILE_IMAGE_PROD_URL}/${individualOffer?.offerEntity?.profilePic}`
                      : userIcon
                  }
                />
              </If>
              <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
                <UserAvatarStackItem
                  src={
                    individualOffer?.offerEntity?.profilePic
                      ? `${PROFILE_IMAGE_URL}/${individualOffer?.offerEntity?.profilePic}`
                      : userIcon
                  }
                />
              </If>
            </UserStackDiv>
          ))}
        </UserAvatarStack>
      </OfferCardHeader>
      <OfferCardBody>
        <OfferCardBodyTitle>
          <StoryNameTitle>{offer?.title}</StoryNameTitle>

          <If
            condition={currentLoadingStoryId !== offer?.storyId}
            otherwise={<Spin indicator={<LoadingOutlined spin={true} />} />}
          >
            <DownloadOfferIcon
              src={downloadOfferIcon}
              onClick={(e: any) => {
                e.stopPropagation();
                handleDownloadStory(offer?.storyId);
              }}
              data-testid="download-story"
            />
          </If>
        </OfferCardBodyTitle>

        {/* <If condition={offer?.expiresAt !== undefined && offer?.expiresAt !== null}>
            <OfferCardDate>{moment(offer?.expiresAt).zone('GMT+0530').format('DD.MM.YYYY | HH:MM')}</OfferCardDate>
          </If> */}
        <ScriptIncludedPill>{translate('script_included')}</ScriptIncludedPill>
        <OfferStatsContainer>
          <OfferStatsItem>
            <OfferStatsItemIcon src={viewedIcon} />
            <OfferStateItemText>{offer?.views}</OfferStateItemText>
          </OfferStatsItem>
          <OfferStatsItem>
            <OfferStatsItemIcon src={favouriteIcon} />
            <OfferStateItemText>{offer?.ratings}</OfferStateItemText>
          </OfferStatsItem>
          <OfferStatsItem>
            <OfferStatsItemIcon src={offerIcon} />
            <OfferStateItemText>{offer?.offers?.length}</OfferStateItemText>
          </OfferStatsItem>
        </OfferStatsContainer>
      </OfferCardBody>
    </OfferCardContainer>
  );
};

export default OfferCard;
