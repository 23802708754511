import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button, Form, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CustomInput, If } from 'tsw-sdk';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import Tags from '../NewSubmissionPage/components/Tags';

import ArrowDown from '@images/icons/MySubmissionIcons/arrow-down.svg';
import _ from 'lodash-es';
import {
  requestGetImages,
  requestGetNarration,
  requestGetScreenPlay,
  requestGetStoryDetails,
  requestRenewStorySubmission,
  requestStateChange,
  requestUpdateStory,
  resetState,
  setShowSuccessRenewedPage
} from './reducer';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { injectIntl } from 'react-intl';
import {
  selectImagesUrlList,
  selectIsStoryRenewed,
  selectNarrationUrl,
  selectScreenPlayUrl,
  selectStoryDetails
} from './selectors';
import { SubmittedStoryProps } from './types';
import UnpublishModal from './components/UnpublishModal';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { NARRATION_PATH, SCREENPLAY_PATH, STORIES_STATES, SUPPORTING_MATERIAL_TYPE } from '@app/utils/constants';
import DownloadIcon from '@images/icons/MySubmissionIcons/download-icon.svg';
import {
  requestGeneratePaymentLink,
  requestGenerateStoryPdf,
  resetStoryPdfUrl
} from '@app/containers/CopyrightContainer/reducer';
import { selectGeneratePaymentLinkLoading, selectStoryPdfUrl } from '@app/containers/CopyrightContainer/selectors';
import StoryActions from './components/StoryActions';
import { Portal } from '@app/components/Portal';
import StoryRenewSuccessPage from './components/StoryRenewPage';
import { selectSpotTicketsBalance } from '../MySpotTickets/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';
import { allowOnlyChars, preventInvalidCharsNumberInput } from '@app/utils/uiUtils';
import StoryRenewalSuccessPage from './components/StoryRenewalSuccessPage';

const Container = styled.div`
  width: 100%;
  height: auto;
  background: ${colors.dashboardBg};
`;

const StyledBanner = styled.div`
  width: 100%;
  height: 13.68rem;
  background: ${colors.mySubmissions.cardHeaderBg};
  overflow: hidden;
  position: relative;
`;

const BannerContainer = styled.div`
  width: 150%;
  height: 100%;
  position: absolute;
  left: -2rem;
  top: -2rem;
  display: flex;
  column-gap: 1.37rem;
  flex-wrap: wrap;
`;

const BannerText = styled.h1`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.bannerLarge()};
  ${fonts.weights.fw900()};
  line-height: 5.59rem;
  color: ${colors.mySubmissions.backgroundfTextClr};
  margin-bottom: 0px;
  text-align: center;
`;

const SubmitStoryContainerContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
`;

const SubmittedSubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
  margin-top: 2.56rem;
  padding: 0px 5.43rem 0px 0px;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  margin-top: 2rem;
  width: 4.25rem;
`;

const Heading = styled.h1`
  ${fonts.family.SpoofFamily};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.myDashboardColors.heading};
  margin-bottom: 0;
  margin-top: 1rem;
`;

const FormContainer = styled(Form)`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;

    .ant-form-item {
      margin-bottom: 0px;
    }
  }
`;

const FormItemLabel = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  color: ${colors.mySubmissions.textClr};
`;

const TagsContainer = styled.div`
  width: 16.18rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 0.5rem;
`;

const TagSubContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
`;

const InlineItems = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: flex-end;
  justify-content: flex-end;
`;

const CustomFormItem = styled(Form.Item)`
  &.ant-form-item {
    width: 16.18rem;
    margin-bottom: 0;
    row-gap: 0.5rem;
  }
  .ant-form-item-label {
    padding: 0 !important;
    ${fonts.weights.fw600()};
    ${fonts.size.regular()};
    row-gap: 0.5rem;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 34.12rem;
    background-color: ${colors.mySubmissions.disableClr};
    border: 0.5px solid ${colors.lightBorder};
    border-radius: 5px;

    &.ant-input {
      max-width: 34.12rem;
      line-height: 0.94rem;
    }

    &.ant-input:placeholder-shown {
      color: ${colors.lightBorder};
      ${fonts.weights.fw400()};
      ${fonts.size.xsmall()};
      line-height: 0.94rem;
    }
  }
`;

const StoryActionsContainer = styled.div`
  width: 23.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const CustomDisableInput = styled(CustomInput)`
  && {
    background: ${colors.mySubmissions.disableClr};
  }
`;

const CustomDisableNumInput = styled(CustomInput)`
  && {
    background: ${colors.mySubmissions.disableClr};
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const MultipleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledImg = styled.img``;

const UpdateBtn = styled(Button)`
  height: 1.93rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  cursor: pointer;
  ${fonts.weights.fw700()};
  ${fonts.size.regular()};
  line-height: 1rem;
  border: none;
  margin-bottom: 0.31rem;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InlineDownloadContainers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 3.62rem;

  margin-bottom: 3rem;
`;

const DownloadSubContainer = styled.div`
  width: 13.12rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const DownloadText = styled.p`
  ${fonts.weights.fw800()};
  ${fonts.size.regular()};
  line-height: 1.25rem;

  color: ${colors.mySubmissions.textClr};
  text-decoration: underline;
`;

const SubHeadingText = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;

  color: ${colors.mySubmissions.textClr};
`;

const StoryTableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PointerImg = styled.img`
  cursor: pointer;
`;

const SubmittedStoryPage = ({
  dispatchGetPublishedStory,
  publishedStory,
  userProfile,
  storyPdfUrl,
  screenplayUrl,
  narrationUrl,
  imagesUrl,
  spotTicketsBalance,
  generatePaymentLinkLoading,
  isStoryRenewed,
  dispatchResetState,
  dispatchGenerateStoryPdf,
  dispatchResetStoryPdfUrl,
  dispatchGetScreenplaySignedUrl,
  dispatchGetNarrationUrl,
  dispatchGetImagesUrl,
  dispatchUpdateStory,
  dispatchRenewStory,
  dispatchChangeStoryState,
  dispatchGenerateAddSpotTicketLink,
  dispatchHideStorySuccessPage
}: SubmittedStoryProps) => {
  const [submittedForm] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const [currentStoryId, setCurrentStoryId] = useState<string>('');
  const [unpublish, setUnpublish] = useState<boolean>(false);

  const [screenPlayName, setScreenPlayName] = useState<string>('');
  const [narrationName, setNarrationName] = useState<string>('');
  const [imagesName, setimagesName] = useState<string[]>([]);

  const [expectedOffer, setExpectedOffer] = useState<number>(0);
  const [coAuthor1, setCoAuthor1] = useState<string>('');
  const [coAuthor2, setCoAuthor2] = useState<string>('');
  const [renewPageVisible, setRenewPageVisible] = useState<boolean>(false);
  const params = new URLSearchParams(location?.search);

  const storyId = params.get('storyId');

  const mode = params.get('mode');

  useEffect(() => {
    if (storyId) {
      setCurrentStoryId(storyId);
    }

    if (mode) {
      setRenewPageVisible(true);
    }

    return () => {
      dispatchResetState();
    };
  }, []);

  useEffect(() => {
    if (publishedStory?.supportingMaterials) {
      publishedStory?.supportingMaterials.forEach((el: any) => {
        if (el.attachmentType === SUPPORTING_MATERIAL_TYPE.SCREENPLAY) {
          const newScreenPlayName = el.fileName.replace('storyMaterial/screenplays', '');
          setScreenPlayName(newScreenPlayName);
        } else if (el.attachmentType === SUPPORTING_MATERIAL_TYPE.AUDIO_NARRATION) {
          const newAudioNarration = el.fileName.replace('storyMaterial/audioNarrations', '');
          setNarrationName(newAudioNarration);
        } else {
          setimagesName((curr) => [...curr, el.fileName.replace('storyMaterial/images', '')]);
        }
      });
    }
    if (publishedStory?.expectedOffer) {
      setExpectedOffer(publishedStory?.expectedOffer);
    }

    if (publishedStory?.coAuthor1) {
      setCoAuthor1(publishedStory?.coAuthor1);
    }

    if (publishedStory?.coAuthor2) {
      setCoAuthor2(publishedStory?.coAuthor2);
    }
  }, [publishedStory]);

  useEffect(() => {
    if (storyPdfUrl) {
      const anchorTag = document.createElement('a');
      anchorTag.href = storyPdfUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();
      dispatchResetStoryPdfUrl();
    }
  }, [storyPdfUrl]);

  useEffect(() => {
    if (narrationUrl) {
      const anchorTag = document.createElement('a');
      anchorTag.href = narrationUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();
    }
  }, [narrationUrl]);

  useEffect(() => {
    if (screenplayUrl) {
      const anchorTag = document.createElement('a');
      anchorTag.href = screenplayUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();
    }
  }, [screenplayUrl]);

  useEffect(() => {
    if (imagesUrl?.length > 0) {
      imagesUrl.forEach((curr: string) => {
        const anchorTag = document.createElement('a');
        anchorTag.href = curr;
        anchorTag.setAttribute('target', '_blank');
        anchorTag.click();
      });
    }
  }, [imagesUrl]);

  useEffect(() => {
    if (userProfile?.id && storyId) {
      dispatchGetPublishedStory(0, 1, storyId, userProfile?.id);
    }
  }, [userProfile]);

  const handleCancel = () => {
    setUnpublish(false);
  };

  const onUnpublish = () => {
    dispatchChangeStoryState(currentStoryId, STORIES_STATES.UNPUBLISHED);
  };

  const handleScreenplayDownload = () => {
    if (screenPlayName) {
      dispatchGetScreenplaySignedUrl(SCREENPLAY_PATH, screenPlayName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  const handleNarrationDownload = () => {
    if (narrationName) {
      dispatchGetNarrationUrl(NARRATION_PATH, narrationName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  const handleImagesDownload = () => {
    if (imagesName.length > 0) {
      dispatchGetImagesUrl(imagesName);
    } else {
      message.error(translate('no_file_download'));
    }
  };

  const downloadStoryPdf = () => {
    if (storyId) {
      dispatchGenerateStoryPdf(userProfile?.id, storyId);
    }
  };

  const handleUpdate = () => {
    dispatchUpdateStory(publishedStory.id, expectedOffer, coAuthor1, coAuthor2);
  };

  const handleRenewStory = () => {
    dispatchRenewStory(publishedStory.id);
    setRenewPageVisible(false);
  };

  return (
    <Container>
      <StyledBanner>
        <BannerContainer>
          {_.range(30).map((number: number) => (
            <BannerText key={number}>{publishedStory?.title}</BannerText>
          ))}
        </BannerContainer>
      </StyledBanner>
      <SubmitStoryContainerContainer>
        <BackButton
          onClick={() => history.push(`${routeConstants.dashboard.route}?activeTab=submissions`)}
          data-testid="back-btn"
        >
          <StyledImg src={ArrowLeft} />
          {translate('back')}
        </BackButton>
        <Heading>{`${publishedStory?.title}- Review Submission`}</Heading>
        <SubmittedSubContainer>
          <FormContainer layout="vertical" form={submittedForm}>
            <InlineItems>
              <CustomFormItem label={translate('languages')} name="languages">
                <MultipleContainer>
                  {publishedStory?.languages.map((language: any) => (
                    <CustomDisableInput
                      key={language.id}
                      placeholder={translate('language_placeholder')}
                      suffix={<StyledImg src={ArrowDown} />}
                      disabled
                      value={language.name}
                    />
                  ))}
                </MultipleContainer>
              </CustomFormItem>
              <CustomFormItem label={translate('genres')} name="genres">
                <MultipleContainer>
                  {publishedStory?.genres.map((genre: any) => (
                    <CustomDisableInput
                      key={genre.id}
                      placeholder={translate('genre_placeholder')}
                      suffix={<StyledImg src={ArrowDown} />}
                      disabled
                      value={genre.name}
                    />
                  ))}
                </MultipleContainer>
              </CustomFormItem>
            </InlineItems>
            <InlineItems>
              <CustomFormItem label={translate('story_type')} name="storyType">
                <CustomDisableInput
                  placeholder={translate('web_series')}
                  suffix={<StyledImg src={ArrowDown} />}
                  value={publishedStory?.storyType?.name}
                  disabled
                />
              </CustomFormItem>
              <TagsContainer>
                <FormItemLabel>{translate('tags')}</FormItemLabel>
                <TagSubContainer>
                  {publishedStory?.tags.map((tag: any) => (
                    <Tags key={tag.id} isSelected={false} tagName={tag.name} />
                  ))}
                </TagSubContainer>
              </TagsContainer>
            </InlineItems>
            <CustomFormItem label={translate('logline')} name="logline">
              <StyledTextArea
                placeholder={publishedStory?.logline}
                autoSize
                disabled
                defaultValue={publishedStory?.logline}
              />
            </CustomFormItem>
            <CustomFormItem label={translate('storyline')} name="storyline">
              <StyledTextArea
                placeholder={publishedStory?.storyline}
                disabled
                autoSize={{ minRows: 7 }}
                defaultValue={publishedStory?.storyline}
              />
            </CustomFormItem>

            <InlineItems>
              <CustomFormItem label={translate('expected_price')} name="expectedPrice">
                <CustomDisableNumInput
                  placeholder={translate('amount', {
                    amount: publishedStory?.expectedOffer?.toLocaleString('en-IN')
                  })}
                  type="number"
                  value={expectedOffer}
                  onChange={(e) => setExpectedOffer(Number(e.target.value))}
                  onKeyDown={preventInvalidCharsNumberInput}
                />
              </CustomFormItem>
              <ButtonContainer>
                <UpdateBtn onClick={handleUpdate}>{translate('update')}</UpdateBtn>
              </ButtonContainer>
            </InlineItems>

            <InlineItems>
              <CustomFormItem label={translate('co_writer_1')} name="coWriter1">
                <CustomDisableInput
                  placeholder={publishedStory?.coAuthor1}
                  value={coAuthor1}
                  onChange={(e) => setCoAuthor1(e.target.value)}
                  onKeyDown={allowOnlyChars}
                />
              </CustomFormItem>
              <ButtonContainer>
                <UpdateBtn onClick={handleUpdate}>{translate('update')}</UpdateBtn>
              </ButtonContainer>
            </InlineItems>

            <InlineItems>
              <CustomFormItem label={translate('co_writer_2')} name="coWriter2">
                <CustomDisableInput
                  placeholder={publishedStory?.coAuthor2}
                  value={coAuthor2}
                  onChange={(e) => setCoAuthor2(e.target.value)}
                  onKeyDown={allowOnlyChars}
                />
              </CustomFormItem>
              <ButtonContainer>
                <UpdateBtn onClick={handleUpdate}>{translate('update')}</UpdateBtn>
              </ButtonContainer>
            </InlineItems>

            <InlineDownloadContainers>
              <DownloadSubContainer>
                <SubHeadingText>{translate('story_download')}</SubHeadingText>
                <StoryTableContainer>
                  <DownloadText>{translate('download_pdf')}</DownloadText>
                  <PointerImg src={DownloadIcon} onClick={downloadStoryPdf} data-testid="download-story" />
                </StoryTableContainer>
              </DownloadSubContainer>
              <DownloadSubContainer>
                <SubHeadingText>{translate('screenplay')}</SubHeadingText>
                <StoryTableContainer>
                  <DownloadText>{translate('download_pdf')}</DownloadText>

                  <PointerImg src={DownloadIcon} onClick={handleScreenplayDownload} data-testid="screenplay-download" />
                </StoryTableContainer>
              </DownloadSubContainer>
            </InlineDownloadContainers>
            <InlineDownloadContainers>
              <DownloadSubContainer>
                <SubHeadingText>{translate('audio_narration')}</SubHeadingText>
                <StoryTableContainer>
                  <DownloadText>{translate('download_mp3')}</DownloadText>
                  <PointerImg src={DownloadIcon} onClick={handleNarrationDownload} data-testid="narration-download" />
                </StoryTableContainer>
              </DownloadSubContainer>
              <DownloadSubContainer>
                <SubHeadingText>{translate('supporting_images')}</SubHeadingText>
                <StoryTableContainer>
                  <DownloadText>{translate('download_jpeg')}</DownloadText>
                  <PointerImg src={DownloadIcon} onClick={handleImagesDownload} data-testid="images-download" />
                </StoryTableContainer>
              </DownloadSubContainer>
            </InlineDownloadContainers>
          </FormContainer>
          <StoryActionsContainer>
            <StoryActions
              handleUnpublish={() => setUnpublish(true)}
              expiresAt={publishedStory?.expiresAt}
              setRenewPageVisible={() => handleRenewStory()}
              storyId={currentStoryId}
              storyState={publishedStory?.state}
              handleRepublish={() => dispatchChangeStoryState(currentStoryId, STORIES_STATES.PUBLISHED)}
            />
            {/* <FormItemLabel>{translate('unpublish')}</FormItemLabel>
            <StyledSwitch
              bgColor={unpublish}
              checked={unpublish}
              onChange={() => setUnpublish(true)}
              data-testid="switch"
            /> */}
          </StoryActionsContainer>
        </SubmittedSubContainer>
      </SubmitStoryContainerContainer>
      <If condition={unpublish}>
        <UnpublishModal handleCancel={handleCancel} onUnpublish={onUnpublish} />
      </If>
      <If condition={renewPageVisible}>
        <Portal>
          <StoryRenewSuccessPage
            spotTicketsBalance={spotTicketsBalance}
            storyId={currentStoryId}
            generatePaymentLinkLoading={generatePaymentLinkLoading}
            handleRenewStory={handleRenewStory}
            dispatchGenerateAddSpotTicketLink={dispatchGenerateAddSpotTicketLink}
            handleBack={() => setRenewPageVisible(false)}
          />
        </Portal>
      </If>
      <If condition={isStoryRenewed}>
        <Portal>
          <StoryRenewalSuccessPage handleBack={dispatchHideStorySuccessPage} />
        </Portal>
      </If>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  publishedStory: selectStoryDetails(),
  userProfile: selectUserProfile(),
  storyPdfUrl: selectStoryPdfUrl(),
  screenplayUrl: selectScreenPlayUrl(),
  narrationUrl: selectNarrationUrl(),
  imagesUrl: selectImagesUrlList(),
  spotTicketsBalance: selectSpotTicketsBalance(),
  generatePaymentLinkLoading: selectGeneratePaymentLinkLoading(),
  isStoryRenewed: selectIsStoryRenewed()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetPublishedStory: (limit: number, page: number, storyId: string, sellerId: string) =>
      dispatch(requestGetStoryDetails({ limit, page, storyId, sellerId })),
    dispatchChangeStoryState: (id: string, state: string) => dispatch(requestStateChange({ id, state })),
    dispatchGenerateStoryPdf: (sellerId: string, storyId: string) =>
      dispatch(
        requestGenerateStoryPdf({
          sellerId,
          storyId
        })
      ),
    dispatchResetStoryPdfUrl: () => dispatch(resetStoryPdfUrl()),
    dispatchGetScreenplaySignedUrl: (folderPath: string, fileName: string) =>
      dispatch(requestGetScreenPlay({ folderPath, fileName })),
    dispatchGetNarrationUrl: (folderPath: string, fileName: string) =>
      dispatch(requestGetNarration({ folderPath, fileName })),
    dispatchGetImagesUrl: (fileNames: string[]) => dispatch(requestGetImages({ fileNames })),
    dispatchResetState: () => dispatch(resetState()),
    dispatchUpdateStory: (storyId: string, expectedOffer: number, coAuthor1: string, coAuthor2: string) =>
      dispatch(requestUpdateStory({ storyId, expectedOffer, coAuthor1, coAuthor2 })),
    dispatchRenewStory: (storyId: string) => dispatch(requestRenewStorySubmission({ storyId })),
    dispatchGenerateAddSpotTicketLink: (
      amount: number,
      tickets: number,
      storyId: string,
      reason: string,
      notes: string
    ) =>
      dispatch(
        requestGeneratePaymentLink({
          amount,
          tickets,
          storyId,
          reason,
          notes
        })
      ),
    dispatchHideStorySuccessPage: () => dispatch(setShowSuccessRenewedPage())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo, injectIntl)(SubmittedStoryPage) as React.FC;

export const SubmittedStoryPageTest = SubmittedStoryPage;
