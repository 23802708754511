import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import editIcon from '@images/icons/edit-icon.svg';
import { colors, fonts } from '@app/themes';
import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import {
  selectBannerFilePath,
  selectProfileError,
  selectProfileFilePath,
  selectUserProfile
} from '@app/containers/ProfileContainer/selectors';
import {
  requestCreateProfile,
  requestGetProfile,
  requestUploadBannerImage,
  requestUploadProfileImage
} from '@app/containers/ProfileContainer/reducer';
import { connect } from 'react-redux';
import { MyProfileProps } from './types';
import defaultProfilePic from '@images/icons/defaultProfilePic.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import ExperienceItem from './components/ExperienceItem';
import { ExperienceItemType } from './components/ExperienceItem/types';
import { FilmItemType } from './components/FilmItem/types';
import FilmItem from './components/FilmItem';
import { GenreItemType } from './components/GenreItem/types';
import GenreItem from './components/GenreItem';
import ProfileCropModal from '@app/containers/ProfileContainer/components/ProfileCropModal';
import _ from 'lodash-es';

import { CustomButton, If } from 'tsw-sdk';
import routeConstants from '@app/utils/routeConstants';
import { useHistory } from 'react-router-dom';
import {
  BANNER_IMAGE_PROD_URL,
  BANNER_IMAGE_QA_URL,
  BANNER_IMAGE_URL,
  PROFILE_IMAGE_PROD_URL,
  PROFILE_IMAGE_QA_URL,
  PROFILE_IMAGE_URL
} from '@app/utils/constants';
import BillingDetailsContainer from './components/BillingDetailsContainer';
import LegalContainer from './components/LegalContainer';
import PublishedStories from './components/PublishedStories';
import CustomEmpty from '@app/components/CustomEmpty';

type BannerProps = {
  backgroundImage: string;
};

const ProfilePageContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: ${colors.dashboardBg};
  padding-bottom: 2rem;
`;

const ProfilePageBanner = styled.div<BannerProps>`
  width: 100%;
  height: 12.75rem;
  position: relative;
  background-color: ${colors.btnDisabled};
  background-image: ${(props) => (props.backgroundImage !== '' ? `url(${props.backgroundImage})` : '')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:hover > img {
    opacity: 1;
  }
`;

const EditBannerIcon = styled.img`
  width: 3rem;
  height: 3rem;
  position: absolute;
  bottom: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
  z-index: 1000;
  opacity: 0;
  transition: all 0.2s;
`;

const ProfileInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -4.25rem;
`;

const ProfileImageContainer = styled.div`
  width: 9rem;
  height: 9rem;
  position: relative;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 6.25rem;
`;

const EditProfileBtn = styled.img`
  position: absolute;
  bottom: 0;
  right: -1rem;
  cursor: pointer;
`;

const UserName = styled.p`
  margin-top: 2rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.large()};
  ${fonts.weights.fw900()};
  line-height: 2.68rem;
`;

// const UserDesignation = styled.p`
//   margin-top: 0.75rem;
//   ${fonts.weights.fw700()};
//   ${fonts.size.xBig()};
//   line-height: 2.18rem;
// `;

const UserBio = styled.p`
  max-width: 24rem;
  margin-top: 2rem;
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  position: relative;
`;

const ExperienceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 4.93rem;
  margin-top: 3.75rem;
`;

const ExperienceTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.profilePageColors.titleColor};
`;

const FavouriteFilmsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  padding-left: 4.93rem;
`;

const FavouriteFilmsTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.profilePageColors.titleColor};
  position: relative;
  margin-bottom: 1rem;
`;

const FavMoviesListContainer = styled.div`
  width: 100%;
  padding-right: 9rem;
  display: flex;
  align-items: center;
  margin-top: 2.75rem;
`;

const FavouriteGenresContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  padding-left: 4.93rem;
`;

const FavouriteGenresTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.profilePageColors.titleColor};
  margin-bottom: 1rem;
`;

const FavGenresListContainer = styled.div`
  width: 100%;
  padding-right: 9rem;
  display: flex;
  flex-direction: column;
  margin-top: 2.75rem;
`;

const ImageInput = styled.input`
  display: none;
`;

const UpdateProfileBtn = styled(CustomButton)`
  && {
    justify-content: center;
    width: 8rem;
    margin-top: 2rem;
  }
`;

const MyProfile = ({
  userProfile,
  filePath,
  bannerFilePath,
  profileError,
  dispatchGetUserProfile,
  dispatchCreateUserProfile,
  dispatchUploadUserProfile,
  dispatchUploadBanner
}: MyProfileProps) => {
  const profileInputRef = useRef<any>(null);
  const bannerInputRef = useRef<any>(null);

  const [cropMode, setCropMode] = useState<'profile' | 'banner'>('profile');

  const [imageCropModalVisible, setImageCropModalVisible] = useState<boolean>(false);

  const [profileUrlLocal, setProfileUrlLocal] = useState<string>('');
  const [bannerUrlLocal, setBannerUrlLocal] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    dispatchGetUserProfile();
  }, []);

  useEffect(() => {
    setBannerUrlLocal('');
    setProfileUrlLocal('');
  }, [userProfile]);

  useEffect(() => {
    if (filePath) {
      const newProfileData = constructPayload();

      dispatchCreateUserProfile({
        data: {
          sellerProfileInput: {
            ...newProfileData,
            profilePic: filePath,
            isSave: true
          }
        },
        autoSave: true
      });
    }
  }, [filePath]);

  useEffect(() => {
    if (bannerFilePath) {
      const newProfileData = constructPayload();

      dispatchCreateUserProfile({
        data: {
          sellerProfileInput: {
            ...newProfileData,
            profileBanner: bannerFilePath,
            isSave: true
          }
        },
        autoSave: true
      });
    }
  }, [bannerFilePath]);

  const constructPayload = () => {
    const newProfileData: any = _.cloneDeep(userProfile);

    delete newProfileData?.typename;
    delete newProfileData?.email;
    delete newProfileData?.id;
    delete newProfileData?.billingAddress?.typename;

    if (newProfileData?.experiences) {
      if (newProfileData?.experiences.length) {
        for (let i = 0; i < newProfileData?.experiences?.length; i++) {
          const newExperience: any = {};
          Object.assign(newExperience, newProfileData?.experiences[i]);
          delete newExperience?.typename;
          delete newExperience?.id;
          newProfileData.experiences[i] = newExperience;
        }
      }
    }

    if (newProfileData?.favGenres) {
      if (newProfileData?.favGenres.length) {
        for (let i = 0; i < newProfileData?.favGenres?.length; i++) {
          const newGenre: any = {};
          Object.assign(newGenre, newProfileData?.favGenres[i]);
          delete newGenre?.typename;
          delete newGenre?.id;
          newProfileData.favGenres[i] = newGenre;
        }
      }
    }

    if (newProfileData?.favMovies) {
      if (newProfileData?.favMovies.length) {
        for (let i = 0; i < newProfileData?.favMovies?.length; i++) {
          const newMovie: any = {};
          Object.assign(newMovie, newProfileData?.favMovies[i]);
          delete newMovie?.typename;
          delete newMovie?.id;
          newProfileData.favMovies[i] = newMovie;
        }
      }
    }

    if (newProfileData?.publishedStories) {
      if (newProfileData?.publishedStories.length) {
        for (let i = 0; i < newProfileData?.publishedStories?.length; i++) {
          const story: any = {};
          Object.assign(story, newProfileData?.publishedStories[i]);
          delete story?.typename;
          delete story?.id;
          newProfileData.publishedStories[i] = story;
        }
      }
    }

    return newProfileData;
  };

  const handleImageInputChange = (e: any) => {
    const file = e.target.files[0];

    const fileUrl = URL.createObjectURL(file);
    setCropMode('profile');
    setProfileUrlLocal(fileUrl);
    setImageCropModalVisible(true);
    e.target.value = null;
  };

  const handleBannerInputChange = (e: any) => {
    const file = e.target.files[0];

    const fileUrl = URL.createObjectURL(file);

    setCropMode('banner');
    setBannerUrlLocal(fileUrl);
    setImageCropModalVisible(true);

    e.target.value = null;
  };

  const handleCrop = (file: any) => {
    if (cropMode === 'profile') {
      dispatchUploadUserProfile(file);
    } else {
      dispatchUploadBanner(file);
    }
    setImageCropModalVisible(false);
    setBannerUrlLocal('');
    setProfileUrlLocal('');
  };

  const saveBillingInfo = (billingInfo: any) => {
    const newProfileData = constructPayload();

    dispatchCreateUserProfile({
      data: {
        sellerProfileInput: {
          ...newProfileData,
          billingAddress: billingInfo,
          isSave: true
        }
      },
      autoSave: false
    });
  };

  return (
    <ProfilePageContainer>
      <ImageInput
        type={'file'}
        accept="image/png, image/jpeg"
        onChange={handleImageInputChange}
        ref={profileInputRef}
        data-testid="profile-upload-input"
      />

      <ImageInput
        type={'file'}
        accept="image/png, image/jpeg"
        onChange={handleBannerInputChange}
        ref={bannerInputRef}
        data-testid="banner-upload-input"
      />

      {imageCropModalVisible && (
        <ProfileCropModal
          image={cropMode === 'profile' ? profileUrlLocal : bannerUrlLocal}
          visible={imageCropModalVisible}
          onCrop={handleCrop}
          handleCancel={() => setImageCropModalVisible(false)}
          cropMode={cropMode}
        />
      )}

      <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
        <ProfilePageBanner
          backgroundImage={
            userProfile?.profileBanner !== '' ? `${BANNER_IMAGE_QA_URL}/${userProfile?.profileBanner}` : ''
          }
        >
          <EditBannerIcon
            src={editIcon}
            onClick={() => bannerInputRef?.current?.click()}
            data-testid="edit-banner-btn"
          />
        </ProfilePageBanner>
      </If>

      <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
        <ProfilePageBanner
          backgroundImage={
            userProfile?.profileBanner !== '' ? `${BANNER_IMAGE_PROD_URL}/${userProfile?.profileBanner}` : ''
          }
        >
          <EditBannerIcon
            src={editIcon}
            onClick={() => bannerInputRef?.current?.click()}
            data-testid="edit-banner-btn"
          />
        </ProfilePageBanner>
      </If>

      <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
        <ProfilePageBanner
          backgroundImage={userProfile?.profileBanner !== '' ? `${BANNER_IMAGE_URL}/${userProfile?.profileBanner}` : ''}
        >
          <EditBannerIcon
            src={editIcon}
            onClick={() => bannerInputRef?.current?.click()}
            data-testid="edit-banner-btn"
          />
        </ProfilePageBanner>
      </If>

      <ProfileInfoContainer>
        <ProfileImageContainer>
          <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
            <ProfileImage
              src={
                userProfile?.profilePic !== ''
                  ? `${PROFILE_IMAGE_QA_URL}/${userProfile?.profilePic}`
                  : defaultProfilePic
              }
            />
          </If>
          <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
            <ProfileImage
              src={
                userProfile?.profilePic !== ''
                  ? `${PROFILE_IMAGE_PROD_URL}/${userProfile?.profilePic}`
                  : defaultProfilePic
              }
            />
          </If>

          <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
            <ProfileImage
              src={
                userProfile?.profilePic !== '' ? `${PROFILE_IMAGE_URL}/${userProfile?.profilePic}` : defaultProfilePic
              }
            />
          </If>

          <EditProfileBtn src={editIcon} onClick={() => profileInputRef?.current?.click()} data-testid="edit-btn" />
        </ProfileImageContainer>
        <UserName>{`${userProfile?.firstName} ${userProfile?.lastName}`}</UserName>
        <UpdateProfileBtn onClick={() => history.push(routeConstants.editProfileContainer.route)}>
          {translate('edit_profile')}
        </UpdateProfileBtn>
        <UserBio>{userProfile?.bioDescription}</UserBio>
      </ProfileInfoContainer>
      <ExperienceContainer>
        <ExperienceTitle>{translate('experience')}</ExperienceTitle>
        <If condition={userProfile?.experiences?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
          {userProfile?.experiences?.map((experienceItem: ExperienceItemType) => (
            <ExperienceItem experience={experienceItem} key={experienceItem?.id} />
          ))}
        </If>
      </ExperienceContainer>
      <FavouriteFilmsContainer>
        <FavouriteFilmsTitle>{translate('favourite_films')}</FavouriteFilmsTitle>
        <If condition={userProfile?.favMovies?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
          <FavMoviesListContainer>
            {userProfile?.favMovies?.map((favMovie: FilmItemType) => (
              <FilmItem key={favMovie.id} film={favMovie} />
            ))}
          </FavMoviesListContainer>
        </If>
      </FavouriteFilmsContainer>
      <FavouriteGenresContainer>
        <FavouriteGenresTitle>{translate('favourite_genres')}</FavouriteGenresTitle>
        <If condition={userProfile?.favGenres?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
          <FavGenresListContainer>
            {userProfile?.favGenres?.map((favGenre: GenreItemType) => (
              <GenreItem key={favGenre.id} genre={favGenre} />
            ))}
          </FavGenresListContainer>
        </If>
      </FavouriteGenresContainer>
      <PublishedStories publishedStories={userProfile?.publishedStories} />
      <LegalContainer />
      <BillingDetailsContainer
        billingInfo={userProfile?.billingAddress}
        profileError={profileError}
        handleSave={saveBillingInfo}
      />
    </ProfilePageContainer>
  );
};

export const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile(),
  filePath: selectProfileFilePath(),
  bannerFilePath: selectBannerFilePath(),
  profileError: selectProfileError()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetUserProfile: () => dispatch(requestGetProfile({})),
    dispatchCreateUserProfile: (profileData: any) => dispatch(requestCreateProfile(profileData)),
    dispatchUploadUserProfile: (fileData: any) => dispatch(requestUploadProfileImage(fileData)),
    dispatchUploadBanner: (fileData: any) => dispatch(requestUploadBannerImage(fileData))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MyProfile);

export const MyProfileTest = MyProfile;
