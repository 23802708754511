import { getQueryResponse } from '@app/utils/graphqlUtils';
import { put, takeLatest, call, all } from 'redux-saga/effects';
import { GET_OFFERS_RECEIVED, GET_SUBMISSION_DRAFTS } from './queries';
import { STORIES_STATES } from '@app/utils/constants';

import {
  failureGetOffersReceived,
  failureGetSubmissionDrafts,
  requestGetOffersReceived,
  requestGetSubmissionDrafts,
  successGetOffersReceived,
  successGetSubmissionDrafts
} from './reducer';
import { AnyAction } from '@reduxjs/toolkit';
import moment from 'moment';

export function* getSubmissionDrafts(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      filters: {
        where: {
          state: {
            in: action.payload.state
          }
        },
        asSeller: {
          withID: action.payload.sellerId
        }
      },
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };
    const { ok, data, error } = yield call(getQueryResponse, GET_SUBMISSION_DRAFTS, payload);
    if (ok) {
      const storiesData = data.stories?.stories;
      let storiesDataList = [];
      if (storiesData) {
        storiesDataList = storiesData.map((story: any) => {
          let fieldsFilled = 0;
          if (story.languages) {
            fieldsFilled++;
          }
          if (story.genres) {
            fieldsFilled++;
          }
          if (story.storyType) {
            fieldsFilled++;
          }
          if (story.tags) {
            fieldsFilled++;
          }
          if (story.logline) {
            fieldsFilled++;
          }
          if (story.storyline) {
            fieldsFilled++;
          }
          if (story.title) {
            fieldsFilled++;
          }

          const date = moment(story.updatedAt).zone('GMT+0530').format('DD/MM/YYYY, HH:mm');

          return {
            id: story.id,
            title: story.title,
            date: date,
            progress: fieldsFilled,
            isCopyrightPending: story.state === STORIES_STATES.PENDING_APPROVAL
          };
        });
      }
      yield put(successGetSubmissionDrafts(storiesDataList));
    } else {
      yield put(failureGetSubmissionDrafts(error));
    }
  } catch (err) {
    yield put(failureGetSubmissionDrafts(err));
  }
}

export function* getOffersReceived(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      filters: {
        asSeller: {
          withID: action.payload.sellerId,
          areOpen: true
        }
      },
      page: {
        limit: 4,
        page: 1
      }
    };
    const { ok, data, error } = yield call(getQueryResponse, GET_OFFERS_RECEIVED, payload);
    if (ok) {
      const sellerOffers = data.sellerOffers.offers;
      let offers: any[] = [];

      sellerOffers?.reverse().forEach((story: any) => {
        const storyTitle = story.title;
        if (story.offers?.length > 0) {
          const offerObj = story.offers[story.offers?.length - 1];
          const offerObject = {
            ...offerObj,
            storyTitle: storyTitle
          };

          offers = [...offers, offerObject];
        }
      });

      yield put(successGetOffersReceived(offers));
    } else {
      yield put(failureGetOffersReceived(error));
    }
  } catch (err) {
    yield put(failureGetOffersReceived(err));
  }
}

export default function* submissionDraftSaga() {
  yield all([
    takeLatest(requestGetSubmissionDrafts.toString(), getSubmissionDrafts),
    takeLatest(requestGetOffersReceived.toString(), getOffersReceived)
  ]);
}

export const myDashboardSagaArr = [
  takeLatest(requestGetSubmissionDrafts.toString(), getSubmissionDrafts),
  takeLatest(requestGetOffersReceived.toString(), getOffersReceived)
];
