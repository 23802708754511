import { prepare } from '@app/utils';
import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';

export const initialState = {
  openOffers: [],
  openOffersTotal: 0,
  getOpenOffersLoading: false,
  getOpenOffersError: null,
  closedOffers: [],
  closedOffersTotal: 0,
  getClosedOffersLoading: false,
  getClosedOffersError: null,
  declinedOffers: [],
  declinedOffersTotal: 0,
  getDeclinedOffersLoading: false,
  getDeclinedOffersError: null,
  getScreenplayRequestsLoading: false,
  screenplayRequests: [],
  screenplayRequestsTotal: 0,
  screenplayRequestsError: null
};

const offersReceivedSlice = createSlice({
  name: 'offersReceived',
  initialState,
  reducers: {
    requestGetOpenOffers: {
      reducer: (state) => {
        state.getOpenOffersLoading = true;
      },
      prepare
    },
    requestGetClosedOffers: {
      reducer: (state) => {
        state.getClosedOffersLoading = true;
      },
      prepare
    },
    requestGetDeclinedOffers: {
      reducer: (state) => {
        state.getDeclinedOffersLoading = true;
      },
      prepare
    },
    requestsGetScreenplayRequests: {
      reducer: (state) => {
        state.getScreenplayRequestsLoading = true;
      },
      prepare
    },
    successGetDeclinedOffers(state, action) {
      state.declinedOffers = action.payload.data;
      state.declinedOffersTotal = action.payload.total;
      state.getDeclinedOffersLoading = false;
      state.getDeclinedOffersError = null;
    },
    failureGetDeclinedOffers(state, action) {
      state.getDeclinedOffersLoading = false;
      state.getDeclinedOffersError = get(action.payload, 'message', 'failure');
    },
    successGetClosedOffers(state, action) {
      state.closedOffers = action.payload.data;
      state.closedOffersTotal = action.payload.total;
      state.getClosedOffersLoading = false;
      state.getClosedOffersError = null;
    },
    failureGetClosedOffers(state, action) {
      state.getClosedOffersLoading = false;
      state.getClosedOffersError = get(action.payload, 'message', 'failure');
    },
    successGetOpenOffers(state, action) {
      state.openOffers = action.payload.data;
      state.openOffersTotal = action.payload.total;
      state.getOpenOffersLoading = false;
      state.getOpenOffersError = null;
    },
    failureGetOpenOffers(state, action) {
      state.getOpenOffersLoading = false;
      state.getOpenOffersError = get(action.payload, 'message', 'failure');
    },
    successGetScreenplayRequests(state, action) {
      state.screenplayRequests = action.payload.data;
      state.screenplayRequestsTotal = action.payload.total;
      state.getScreenplayRequestsLoading = false;
      state.screenplayRequestsError = null;
    },
    failureGetScreenplayRequests(state, action) {
      state.getScreenplayRequestsLoading = false;
      state.screenplayRequestsError = get(action.payload, 'message', 'failure');
    },
    resetState(state) {
      state.openOffers = [];
      state.openOffersTotal = 0;
      state.getOpenOffersLoading = false;
      state.getOpenOffersError = null;
      state.closedOffers = [];
      state.closedOffersTotal = 0;
      state.getClosedOffersLoading = false;
      state.getClosedOffersError = null;
      state.declinedOffers = [];
      state.declinedOffersTotal = 0;
      state.getDeclinedOffersLoading = false;
      state.getDeclinedOffersError = null;
      state.getScreenplayRequestsLoading = false;
      state.screenplayRequests = [];
      state.screenplayRequestsError = null;
    }
  }
});

export const {
  requestGetClosedOffers,
  requestsGetScreenplayRequests,
  requestGetOpenOffers,
  requestGetDeclinedOffers,
  successGetOpenOffers,
  failureGetOpenOffers,
  successGetClosedOffers,
  failureGetClosedOffers,
  successGetDeclinedOffers,
  failureGetDeclinedOffers,
  successGetScreenplayRequests,
  failureGetScreenplayRequests,
  resetState
} = offersReceivedSlice.actions;

export default offersReceivedSlice.reducer;
