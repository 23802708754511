import { gql } from 'apollo-boost';

export const GET_PUBLISHED_STORY = gql`
  query Stories($filters: NewStoryFilters, $pagination: StoryPagination!) {
    stories(filters: $filters, pagination: $pagination) {
      stories {
        id
        storyline
        logline
        title
        storyType {
          id
          name
        }
        tags {
          id
          name
        }
        languages {
          id
          name
        }
        genres {
          id
          name
        }
        supportingMaterials {
          id
          fileName
          attachmentType
        }
        expectedOffer
        coAuthor1
        coAuthor2
        expiresAt
        state
      }
      total
    }
  }
`;

export const UPDATE_STORY_STATE = gql`
  mutation updateStoryState($storyStateInput: StoryStateInput!) {
    updateStoryState(input: $storyStateInput) {
      response
      id
      state
    }
  }
`;

export const UPDATE_STORY = gql`
  mutation updatePublishedStory($input: PublishedStorySubmissionInput!) {
    updatePublishedStory(input: $input) {
      response
    }
  }
`;

export const RENEW_STORY = gql`
  mutation renewStorySubmission($input: RenewStorySubmissionInput!) {
    renewStorySubmission(input: $input) {
      currentBalance
      status
    }
  }
`;
