import { gql } from 'apollo-boost';

export const GET_OFFER_DETAILS = gql`
  query BuyerOfferDetails($input: OfferIdInput!) {
    buyerOfferDetails(input: $input) {
      id
      buyerName
      designation
      companyName
      amount
      currency
      notes
      placedOn
      expiresOn
      daysLeft
      profileImage
    }
  }
`;
