import { SELLER } from '@app/utils/constants';
import { getQueryResponse } from '@app/utils/graphqlUtils';
import { GET_SIGNED_URL } from '@app/utils/queries';
import { AnyAction } from '@reduxjs/toolkit';
import { get } from 'lodash-es';
import { put, takeLatest, all, call } from 'redux-saga/effects';
import { GET_PENDING_APPROVAL_STORY_DATA } from './queries';

import {
  requestGetStoryData,
  successGetStoryData,
  failureGetStoryData,
  requestGetFileUrl,
  successGetFileUrl,
  failureGetFileUrl
} from './reducer';

export function* getStoryData(action: AnyAction): Generator<any, any, any> {
  try {
    const filters = {
      id: {
        equalTo: action.payload.storyId
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_PENDING_APPROVAL_STORY_DATA, {
      sellerId: action.payload.sellerId,
      filters: filters,
      pagination: {
        limit: 20,
        page: 1
      }
    });

    if (ok) {
      const stories = data.pendingCopyrightApprovals.stories;

      yield put(successGetStoryData(stories[0]));
    } else {
      yield put(failureGetStoryData(get(error, 'message', 'something_went_wrong')));
    }
  } catch (err) {
    yield put(failureGetStoryData(err));
  }
}

export function* getFileUrl(action: AnyAction): Generator<any, any, any> {
  try {
    const signedUrlPayload = {
      urlinput: {
        user: SELLER,
        method: 'GET',
        rootFolder: action.payload.path,
        fileName: action.payload.fileName
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

    if (ok) {
      const signedUrlPayload = get(data, 'signedUrl', {});

      const fileUrl = get(signedUrlPayload, 'signedUrl', '');

      yield put(successGetFileUrl(fileUrl));
    } else {
      yield put(failureGetFileUrl(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureGetFileUrl(get(e, 'message', 'something_went_wrong')));
  }
}

// Individual exports for testing
export default function* pendingApprovalContainer() {
  yield all([
    takeLatest(requestGetStoryData.toString(), getStoryData),
    takeLatest(requestGetFileUrl.toString(), getFileUrl)
  ]);
}

export const pendingApprovalContainerArr = [
  takeLatest(requestGetStoryData.toString(), getStoryData),
  takeLatest(requestGetFileUrl.toString(), getFileUrl)
];
