export const awsConfig = {
  Auth: {
    region: process.env.REGION,
    userPoolId: process.env.USER_POOL_ID,
    userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,
    authFlowType: '',
    oauth: {
      domain: process.env.DOMAIN,
      redirectSignIn: process.env.REDIRECT_SIGNIN,
      redirectSignOut: process.env.REDIRECT_SIGNOUT,
      responseType: 'code'
    }
  }
};
