import { createGlobalStyle } from 'styled-components';
import { colors } from '@app/themes';

const GlobalStyle = createGlobalStyle`

  html,
  body {
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      height: -webkit-fill-available;
    }
    @supports not (-webkit-touch-callout: none) {
      /* CSS specific to Android devices */
    height: 100vh;
    }
    width: 100vw;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }

 
  body ,body * {
    font-family: 'Mulish', 'Spoof-Black', 'Spotwriter-Headline', sans-serif;
  }
 
  body.fontLoaded {
    font-family: 'Mulish', 'Spoof-Black', 'Spotwriter-Headline', sans-serif;
  }

  #app {
    background-color: ${colors.secondaryText};
    min-height: 100%;
    min-width: 100%;
  }

  p,
  span,
  button,
  label {
    font-family: 'Mulish', sans-serif;
    line-height: .5em;
    margin-bottom: 0;
  }
 h {
  font-family: 'Spotwriter-Headline',sans-serif;
 }

 .ant-message .anticon {
  vertical-align: middle;
  margin-bottom: 4px;
 }


`;

export default GlobalStyle;
