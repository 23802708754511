import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { Button, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { UnpublishModalProps } from '../../types';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0.75rem;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6.87rem 15.87rem;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalHeading = styled.h1`
  ${fonts.family.SpoofFamily};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  text-align: center;
  color: ${colors.primary};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

const CancelButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.disabledBg};
    color: ${colors.mySubmissions.buttonClr};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()}
    padding: 0.62rem 2rem;
  }
`;

const UnpublishButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: ${colors.mySubmissions.headingClr};
    color: ${colors.white};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()}
    border:none;
    padding: 0.62rem 2rem;
  }
`;

const UnpublishModal = ({ handleCancel, onUnpublish }: UnpublishModalProps) => {
  return (
    <StyledModal visible={true} centered={true} footer={null} closable={false} destroyOnClose={true} width={1120}>
      <ModalContent>
        <ModalHeading>{translate('want_to_unpublish')}</ModalHeading>
        <ButtonContainer>
          <CancelButton onClick={handleCancel}>{translate('cancel')}</CancelButton>
          <UnpublishButton onClick={onUnpublish}>{translate('ok_unpublish')}</UnpublishButton>
        </ButtonContainer>
      </ModalContent>
    </StyledModal>
  );
};

export default UnpublishModal;
