import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectMySpotTicketsReducer = (state: any) => state.mySpotTickets || initialState;

export const selectSpotTicketsBalance = () =>
  createSelector(selectMySpotTicketsReducer, (substate) => get(substate, 'spotTicketsBalance'));

export const selectSpotTicketsTransactions = () =>
  createSelector(selectMySpotTicketsReducer, (substate) => get(substate, 'spotTicketsTransactions'));
