import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { colors, fonts } from '@app/themes';
import { translate } from '@app/components/IntlGlobalProvider';
import defaultAvatarIcon from '@images/avatar.png';
import declineOfferIcon from '@images/icons/OffersReceived/decline-offer.svg';
import counterOfferIcon from '@images/icons/OffersReceived/counter-offer.svg';
import acceptOfferIcon from '@images/icons/OffersReceived/accept-offer.svg';
import CounterOfferModal from './components/CounterOfferModal';
import AcceptOfferModal from './components/AcceptOfferModal';
import DeclineOfferModal from './components/DeclineOfferModal';
import { useLocation } from 'react-router-dom';
import { Portal } from '@app/components/Portal';
import { If } from 'tsw-sdk';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { requestGetOfferDetails } from './reducer';
import { selectOfferDetails } from './selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ManageOfferProps } from './types';
import moment from 'moment';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { requestConcludeOffer, requestCounterOffer } from '../OfferHistory/reducer';
import { OFFER_ACTIONS, PROFILE_IMAGE_PROD_URL, PROFILE_IMAGE_QA_URL, PROFILE_IMAGE_URL } from '@app/utils/constants';
import CounteringLoader from './components/CounteringOffer';
import { selectCounteringOffer } from '../OfferHistory/selectors';

const ManageOfferContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  padding-left: 2.56rem;
  padding-bottom: 2rem;
  background-color: ${colors.dashboardBg};
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.06rem;
`;

const ProfileImage = styled.img`
  width: 9rem;
  height: 9rem;
  border-radius: 100%;
`;

const UserName = styled.p`
  margin-top: 2rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.large()};
  line-height: 2.68rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const UserOrg = styled.p`
  margin-top: 0.68rem;
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  line-height: 2.18rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const UserDesignation = styled.p`
  margin-top: 0.68rem;
  ${fonts.weights.fw400()};
  ${fonts.size.xBig()};
  line-height: 2.18rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const OfferAmount = styled.p`
  margin-top: 2rem;
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 1.25rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const OfferMetaContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 3rem;
`;

const OfferMetaItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OfferMetaItemTitle = styled.p`
  text-transform: uppercase;
  ${fonts.weights.fw700()};
  ${fonts.size.extraSmall()};
  line-height: 0.75rem;
  color: ${colors.offersReceivedColors.offerDateColor};
`;

const OfferMetaItemDescription = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.xsmall()};
  line-height: 0.87rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const NotesContainer = styled.div`
  margin-top: 4rem;
  width: 41rem;
  display: flex;
  flex-direction: column;
`;

const NotesTitle = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  line-height: 1.75rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const Notes = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.big()};
  line-height: 1.75rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const NotesActionsContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: center;
`;

const DeclineOfferBtn = styled.div`
  width: 10.12rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.offersReceivedColors.grayBtnBg};
  color: ${colors.offersReceivedColors.grayBtnColor};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
`;

const CounterOfferBtn = styled.div`
  width: 13.56rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.offersReceivedColors.grayBtnBg};
  color: ${colors.offersReceivedColors.grayBtnColor};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
`;

const AcceptOfferBtn = styled.div`
  width: 12.93rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
`;

const BtnImage = styled.img`
  margin-right: 0.625rem;
`;

const ManageOffer = ({
  offerDetails,
  userProfile,
  counteringOffer,
  dispatchGetOfferDetails,
  dispatchConcludeOffer,
  dispatchCounterOffer
}: ManageOfferProps) => {
  const [counterOfferModalVisible, setCounterOfferModalVisible] = useState<boolean>(false);
  const [acceptOfferModalVisible, setAcceptOfferModalVisible] = useState<boolean>(false);
  const [declineOfferModalVisible, setDeclineOfferModalVisible] = useState<boolean>(false);
  const [currOfferId, setCurrOfferId] = useState<string>('');

  const location: any = useLocation();

  const params = new URLSearchParams(location.search);

  const offerId = params.get('offerId');

  useEffect(() => {
    if (offerId) {
      dispatchGetOfferDetails(offerId);
      setCurrOfferId(offerId);
    }
  }, [offerId]);

  const handleGoBack = () => {
    history.back();
  };

  const handleCounterOffer = (counterOfferAmount: number, notesFromSeller: string) => {
    if (location.state.transaction && location.state.storyId) {
      const transaction = location.state.transaction;
      const buyerId = transaction.offerEntity.id;
      const storyId = location.state.storyId;

      dispatchCounterOffer(storyId, currOfferId, userProfile?.id, buyerId, counterOfferAmount, notesFromSeller);

      setCounterOfferModalVisible(false);
    }
  };

  const handleAcceptOffer = () => {
    if (location.state.transaction && location.state.storyId) {
      const transaction = location.state.transaction;
      const buyerId = transaction.offerEntity.id;
      const storyId = location.state.storyId;

      dispatchConcludeOffer(storyId, currOfferId, userProfile?.id, buyerId, OFFER_ACTIONS.ACCEPT_OFFER, '');

      setAcceptOfferModalVisible(false);
    }
  };

  const handleDeclineOffer = (reason: string) => {
    if (location.state.transaction && location.state.storyId) {
      const transaction = location.state.transaction;
      const buyerId = transaction.offerEntity.id;
      const storyId = location.state.storyId;

      dispatchConcludeOffer(storyId, currOfferId, userProfile?.id, buyerId, OFFER_ACTIONS.DECLINE_OFFER, reason);

      setDeclineOfferModalVisible(false);
    }
  };

  return (
    <ManageOfferContainer data-testid="manage-offer-container">
      <BackButton onClick={handleGoBack} data-testid="back-btn">
        <StyledImg src={ArrowLeft} />
        {translate('back')}
      </BackButton>
      <ProfileContainer>
        <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
          <ProfileImage
            src={
              offerDetails?.profileImage ? `${PROFILE_IMAGE_QA_URL}/${offerDetails?.profileImage}` : defaultAvatarIcon
            }
          />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
          <ProfileImage
            src={
              offerDetails?.profileImage ? `${PROFILE_IMAGE_PROD_URL}/${offerDetails?.profileImage}` : defaultAvatarIcon
            }
          />
        </If>
        <If condition={process.env.ENVIRONMENT_NAME !== 'qa' && process.env.ENVIRONMENT_NAME !== 'production'}>
          <ProfileImage
            src={offerDetails?.profileImage ? `${PROFILE_IMAGE_URL}/${offerDetails?.profileImage}` : defaultAvatarIcon}
          />
        </If>
        <UserName>{offerDetails?.buyerName}</UserName>
        <UserOrg>{offerDetails?.companyName}</UserOrg>
        <UserDesignation>{offerDetails?.designation}</UserDesignation>
        <OfferAmount>
          {translate('transaction_item_amount', { offerAmount: offerDetails?.amount?.toLocaleString('en-IN') })}
        </OfferAmount>
        <OfferMetaContainer>
          <OfferMetaItem>
            <OfferMetaItemTitle>{translate('offer_placed_on')}</OfferMetaItemTitle>

            <OfferMetaItemDescription>
              {moment(offerDetails?.placedOn).zone('GMT+0530').format('MMM DD, YYYY')}
            </OfferMetaItemDescription>
          </OfferMetaItem>
          <OfferMetaItem>
            <OfferMetaItemTitle>{translate('offer_ends_on')}</OfferMetaItemTitle>
            <OfferMetaItemDescription>
              {moment(offerDetails?.expiresOn).zone('GMT+0530').format('MMM DD, YYYY')}
            </OfferMetaItemDescription>
          </OfferMetaItem>
          <OfferMetaItem>
            <OfferMetaItemTitle>{translate('days_to_close')}</OfferMetaItemTitle>
            <OfferMetaItemDescription>
              {translate('days_left_amount', { dayAmount: offerDetails?.daysLeft })}
            </OfferMetaItemDescription>
          </OfferMetaItem>
        </OfferMetaContainer>
        <NotesContainer>
          <NotesTitle>{translate('notes_from_producer')}</NotesTitle>
          <Notes>{offerDetails?.notes ? offerDetails?.notes : translate('no_notes')}</Notes>
        </NotesContainer>
        {!location?.state?.currentState?.includes('SELLER') ? (
          <NotesActionsContainer>
            <DeclineOfferBtn onClick={() => setDeclineOfferModalVisible(true)} data-testid="decline-btn">
              <BtnImage src={declineOfferIcon} /> {translate('decline')}
            </DeclineOfferBtn>
            <CounterOfferBtn onClick={() => setCounterOfferModalVisible(true)} data-testid="counter-btn">
              <BtnImage src={counterOfferIcon} /> {translate('counter_offer')}
            </CounterOfferBtn>
            <AcceptOfferBtn onClick={() => setAcceptOfferModalVisible(true)} data-testid="accept-btn">
              <BtnImage src={acceptOfferIcon} /> {translate('accept_offer')}
            </AcceptOfferBtn>
          </NotesActionsContainer>
        ) : (
          <></>
        )}
      </ProfileContainer>
      <If condition={acceptOfferModalVisible}>
        <Portal>
          <AcceptOfferModal
            handleCancel={() => setAcceptOfferModalVisible(false)}
            acceptOffer={handleAcceptOffer}
            transaction={{
              amount: offerDetails?.amount,
              offerEntity: {
                firstName: offerDetails?.buyerName?.split(' ')[0],
                lastName: offerDetails?.buyerName?.split(' ')[1],
                companyName: offerDetails?.companyName,
                designation: offerDetails?.designation
              }
            }}
          />
        </Portal>
      </If>

      <If condition={counterOfferModalVisible}>
        <Portal>
          <CounterOfferModal
            handleCancel={() => setCounterOfferModalVisible(false)}
            counteringOffer={counteringOffer}
            currentOffer={offerDetails?.amount}
            handleCounterOffer={handleCounterOffer}
          />
        </Portal>
      </If>

      <If condition={declineOfferModalVisible}>
        <Portal>
          <DeclineOfferModal
            handleCancel={() => setDeclineOfferModalVisible(false)}
            declineOffer={handleDeclineOffer}
          />
        </Portal>
      </If>

      {counteringOffer && (
        <Portal>
          <CounteringLoader />
        </Portal>
      )}
    </ManageOfferContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  offerDetails: selectOfferDetails(),
  userProfile: selectUserProfile(),
  counteringOffer: selectCounteringOffer()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetOfferDetails: (offerId: string) =>
      dispatch(
        requestGetOfferDetails({
          offerId
        })
      ),
    dispatchConcludeOffer: (
      storyId: string,
      offerId: string,
      sellerId: string,
      buyerId: string,
      action: string,
      reason: string
    ) =>
      dispatch(
        requestConcludeOffer({
          action: action,
          storyId,
          offerId,
          sellerId,
          buyerId,
          reason
        })
      ),
    dispatchCounterOffer: (
      storyId: string,
      offerId: string,
      sellerId: string,
      buyerId: string,
      counterOfferAmount: number,
      notesFromSeller: string
    ) =>
      dispatch(
        requestCounterOffer({
          amount: counterOfferAmount,
          notes: notesFromSeller,
          storyId,
          offerId,
          sellerId,
          buyerId
        })
      )
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ManageOffer) as React.FC;

export const ManageOfferTest = ManageOffer;
