import React, { useEffect } from 'react';
import styled from 'styled-components';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { colors, fonts } from '@app/themes';
import { translate } from '@app/components/IntlGlobalProvider';
import TransactionItem from './components/TransactionItem';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { requestGetSpotTickets } from './reducer';
import { selectSpotTicketsBalance, selectSpotTicketsTransactions } from './selectors';
import { MySpotTicketsProps } from './types';

const MySpotTicketsContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  padding-top: 4.5rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  background-color: ${colors.dashboardBg};
  padding-bottom: 2rem;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  margin-top: 2rem;
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const PageTitle = styled.p`
  margin-top: 3.5rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.myTicketsColors.pageTitle};
`;

const CurrentBalance = styled.p`
  margin-top: 3rem;
  color: ${colors.myTicketsColors.currentBalanceText};
  ${fonts.weights.fw800()};
  ${fonts.size.xBig()};
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
`;

const CurrentBalanceAmount = styled.span`
  color: ${colors.myTicketsColors.myTicketTitle};
`;

const MySpotTickets = ({
  spotTicketsBalance,
  spotTicketTransactions,
  userProfile,
  dispatchGetMySpotTickets
}: MySpotTicketsProps) => {
  useEffect(() => {
    dispatchGetMySpotTickets(userProfile?.id);
  }, []);

  return (
    <MySpotTicketsContainer>
      <BackButton onClick={() => history.back()}>
        <StyledImg src={ArrowLeft} />
        {translate('back')}
      </BackButton>
      <PageTitle>{translate('my_spot_tickets')}</PageTitle>
      <CurrentBalance>
        {translate('current_balance')}
        <CurrentBalanceAmount>
          {translate('spot_ticket_amount', { ticketAmount: spotTicketsBalance })}
        </CurrentBalanceAmount>
      </CurrentBalance>
      {spotTicketTransactions?.map((spotTransaction: any) => (
        <TransactionItem
          key={spotTransaction?.id}
          action={spotTransaction?.action}
          ticketAmount={spotTransaction?.amount}
          method={spotTransaction?.actionVia}
          receiptLink={spotTransaction?.receiptLink}
        />
      ))}
    </MySpotTicketsContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  spotTicketsBalance: selectSpotTicketsBalance(),
  spotTicketTransactions: selectSpotTicketsTransactions(),
  userProfile: selectUserProfile()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetMySpotTickets: (sellerId: string) =>
      dispatch(
        requestGetSpotTickets({
          sellerId
        })
      )
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MySpotTickets) as React.FC;

export const MySpotTicketsTest = MySpotTickets;
