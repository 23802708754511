import React from 'react';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import downloadOfferIcon from '@images/icons/OffersReceived/download-offer.svg';

import { translate } from '@app/components/IntlGlobalProvider';
import _ from 'lodash';
import { DeclinedOfferCardProps } from './types';
import moment from 'moment';
import { If } from 'tsw-sdk';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const DeclineOfferCardContainer = styled.div`
  width: 19.12rem;
  height: 15.87rem;
  display: flex;
  flex-direction: column;
  background-color: ${colors.offersReceivedColors.scriptIncludedPillBg};
  border-radius: 1rem;
  box-shadow: 0px 0px 20px ${colors.offersReceivedColors.offerCardShadowColor};
  overflow: hidden;
`;

const DeclineOfferCardHeader = styled.div`
  width: 100%;
  height: 5.25rem;
  background-color: ${colors.offersReceivedColors.offerCardHeaderBg};
  display: flex;
  padding-top: 1.12rem;
  padding-left: 1.2rem;
  padding-right: 1.06rem;
  position: relative;
  overflow: hidden;
`;

const DeclineOfferCardHeaderBg = styled.div`
  position: absolute;
  top: -1rem;
  left: -1rem;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
  z-index: 2;
`;

const DeclineOfferCardHeaderBgText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.xlarge()};
  color: ${colors.offersReceivedColors.cardHeaderBgTextColor};
  white-space: nowrap;
`;

const DeclineOfferCardBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const DeclineOfferCardBodyTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StoryNameTitle = styled.h3`
  max-width: 15rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw900()};
  color: ${colors.offersReceivedColors.storyName};
  margin-right: 0.68rem;
  line-height: 1.87rem;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DownloadOfferIcon = styled.img`
  width: 0.75rem;
  height: 0.75rem;
  cursor: pointer;
`;

const DeclineOfferCardDate = styled.p`
  width: 100%;
  margin-top: 0.5rem;
  ${fonts.weights.fw700()};
  ${fonts.size.xsmall()};
  line-height: 1rem;
  color: ${colors.offersReceivedColors.offerDateColor};
  text-align: center;
`;

const OfferAmountText = styled.p`
  ${fonts.size.extraSmall()};
  ${fonts.weights.fw800()};
  line-height: 1rem;
  color: ${colors.offersReceivedColors.offerDateColor};
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  text-align: center;
`;

const DeclinePill = styled.div`
  width: 6.87rem;
  height: 1.5rem;
  background-color: ${colors.offersReceivedColors.closedPillBg};
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${colors.offersReceivedColors.offerDateColor};
  ${fonts.size.regular()};
  ${fonts.weights.fw800()};
  line-height: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

const DeclineOfferCard = ({ offer, currentLoadingStoryId, handleDownloadStory }: DeclinedOfferCardProps) => {
  return offer?.offers?.map((storyOffer: any) => (
    <DeclineOfferCardContainer key={storyOffer.id}>
      <DeclineOfferCardHeader>
        <DeclineOfferCardHeaderBg>
          {_.range(10).map((number) => (
            <DeclineOfferCardHeaderBgText key={number}>{offer?.title}</DeclineOfferCardHeaderBgText>
          ))}
        </DeclineOfferCardHeaderBg>
      </DeclineOfferCardHeader>
      <DeclineOfferCardBody>
        <DeclineOfferCardBodyTitle>
          <StoryNameTitle>{offer?.title}</StoryNameTitle>
          <If
            condition={currentLoadingStoryId !== offer?.storyId}
            otherwise={<Spin indicator={<LoadingOutlined spin={true} />} />}
          >
            <DownloadOfferIcon
              src={downloadOfferIcon}
              onClick={(e: any) => {
                e.stopPropagation();
                handleDownloadStory(offer?.storyId);
              }}
            />
          </If>
        </DeclineOfferCardBodyTitle>
        <DeclineOfferCardDate>
          {moment(storyOffer.createdAt).zone('GMT+0530').format('DD.MM.YYYY | HH:mm')}
        </DeclineOfferCardDate>
        <OfferAmountText>
          {translate('offer_amount', { offerAmount: storyOffer?.amount?.toLocaleString('en-IN') })}
        </OfferAmountText>
        <DeclinePill>{translate('closed')}</DeclinePill>
      </DeclineOfferCardBody>
    </DeclineOfferCardContainer>
  ));
};

export default DeclineOfferCard;
