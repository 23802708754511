import React, { useState } from 'react';
import { Checkbox, Input, Modal } from 'antd';
import styled from 'styled-components';
import { LegalModalProps } from '../../types';
import { colors, fonts } from '@app/themes';
import { translate } from '@app/components/IntlGlobalProvider';
import { CustomButton } from 'tsw-sdk';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';

const ModalContainer = styled(Modal)`
  &&& {
    width: 70rem !important;

    .ant-modal-content {
      height: 38rem;
      border-radius: 1.5rem;
    }

    .ant-modal-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    & .ant-modal-footer {
      display: none;
    }
  }
`;

const ModalContentContainer = styled.div`
  height: 100%;
  padding-top: 1.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.primary};
  text-align: center;
`;

const ModalSubtitle = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  color: ${colors.offersReceivedColors.counterOfferFormLabel};
  margin-top: 2.5rem;
`;

const OptionsContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
`;

const CheckboxContainerTitle = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  color: ${colors.offersReceivedColors.counterOfferFormLabel};
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 50% 50%;
  grid-gap: 0.5rem;
  margin-top: 0.5rem;

  & label {
    margin-left: 0px !important;
    align-items: center;

    & .ant-checkbox {
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid ${colors.checkboxborder};
      border-radius: 0.25rem;
      top: 0 !important;

      & + span {
        white-space: nowrap;
        padding-left: 4px;
        ${fonts.size.xsmall()};
        ${fonts.weights.fw400()};
        line-height: 0.93rem;
        color: ${colors.offersReceivedColors.checkboxLabelColor};
      }

      & .ant-checkbox-inner {
        width: 100%;
        height: 100%;
        margin: 0px;
        border: none;
      }
    }
  }
`;

const CustomCheckbox = styled(Checkbox)``;

const InformationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.18rem;
`;

const InfoTextArea = styled(Input.TextArea)`
  width: 17rem;
  height: 3.5rem;
  margin-top: 0.93rem;
  background-color: ${colors.white};
  border-radius: 0.25rem;
  border: 1px solid ${colors.checkboxborder};
  padding: 0.25rem;
`;

const FooterBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.81rem;
`;

const CancelBtn = styled(CustomButton)`
  width: 7.8rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem;

  &&& {
    background: ${colors.offersReceivedColors.tagBg};
    background-color: ${colors.offersReceivedColors.tagBg};
    color: ${colors.offersReceivedColors.cancelBtnText};
  }
`;

const ProceedBtn = styled(CustomButton)`
  width: 13.75rem;
  height: 3rem;
  margin-left: 2rem;
`;

const LegalDeclinedSupportModal = ({ visible, onClose }: LegalModalProps) => {
  const history = useHistory();
  const [selectedOptions, setSelectedOptions] = useState<any>({
    option_1: false,
    option_2: false
  });
  const [information, setInformation] = useState<string>('');

  const constructMailBody = () => {
    let emailBody = '';

    if (selectedOptions.option_1 && selectedOptions.option_2) {
      emailBody += encodeURIComponent('My story’s legal verification has failed for: SWA & Copyright') + '%0D%0A ';
    } else if (selectedOptions.option_1) {
      emailBody += 'My story’s legal verification has failed for: SWA%0D%0A';
    } else {
      emailBody += 'My story’s legal verification has failed for: Copyright%0D%0A ';
    }

    emailBody += `Information: ${information}`;

    return emailBody;
  };

  const handleProceed = () => {
    const emailBody = constructMailBody();

    window.open(`mailto:finance@spotwriters.com?subject=support&body=${emailBody}`);
    history.push(`${routeConstants.dashboard.route}?activeTab=offers_recieved_nav`);
  };

  return (
    <ModalContainer visible={visible} centered={true} onCancel={onClose} destroyOnClose={true}>
      <ModalContentContainer>
        <ModalTitle>{translate('support')}</ModalTitle>
        <ModalSubtitle>{translate('legal_declined_modal_subtitle')}</ModalSubtitle>
        <OptionsContainer>
          <CheckboxContainerTitle>{translate('story_verification_failed')}</CheckboxContainerTitle>
          <CheckboxContainer>
            <CustomCheckbox
              onChange={(e) =>
                setSelectedOptions({
                  ...selectedOptions,
                  option_1: e.target.checked
                })
              }
              data-testid="checkbox-1"
            >
              {translate('swa_registration')}
            </CustomCheckbox>
            <CustomCheckbox
              onChange={(e) =>
                setSelectedOptions({
                  ...selectedOptions,
                  option_2: e.target.checked
                })
              }
              data-testid="checkbox-2"
            >
              {translate('copyright')}
            </CustomCheckbox>
          </CheckboxContainer>
        </OptionsContainer>
        <InformationContainer>
          <ModalSubtitle>{translate('more_information_title')}</ModalSubtitle>
          <InfoTextArea
            placeholder={translate('more_information_placeholder')}
            onChange={(e) => setInformation(e.target.value)}
            data-testid="information-input"
          />
        </InformationContainer>
        <FooterBtns>
          <CancelBtn onClick={onClose}>{translate('cancel')}</CancelBtn>
          <ProceedBtn onClick={handleProceed} data-testid="send-request-btn">
            {translate('send_request')} <ArrowRightOutlined />
          </ProceedBtn>
        </FooterBtns>
      </ModalContentContainer>
    </ModalContainer>
  );
};

export default LegalDeclinedSupportModal;
