import { translate } from '@app/components/IntlGlobalProvider';
import { fonts, colors } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { CustomButton } from 'tsw-sdk';
import CopyrightTimeline from '../CopyrightTimeline';
import { CopyrightSidebarProps } from './types';

const CopyrightSidebarContainer = styled.div`
  width: 19.5rem;
  height: 100vh;
  padding: 3rem 5.37rem 6.81rem 4.75rem;
  display: flex;
  flex-direction: column;
`;

const CopyrightSidebarTitle = styled.p`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.xBig()}
  line-height: 31.44px;
  text-align: center;
`;

const CopyrightSaveAndExitBtn = styled(CustomButton)`
  && {
    justify-content: center;
    border: 1px solid ${colors.primary};
    padding: 0.5rem 1.5rem;
    margin-top: auto;
    margin-left: -2rem;
  }
`;

const CopyrightSidebar = ({ currentState, descriptionText, saveProcessAndExit }: CopyrightSidebarProps) => {
  return (
    <CopyrightSidebarContainer>
      <CopyrightSidebarTitle>{translate('copyright_submission')}</CopyrightSidebarTitle>
      <CopyrightTimeline currentState={currentState} descriptionText={descriptionText} />
      <CopyrightSaveAndExitBtn
        bg_color={`${colors.secondaryText}`}
        text_color={`${colors.darkBorder}`}
        onClick={saveProcessAndExit}
        data-testid="save-and-exit-btn"
      >
        {translate('save_progress_exit')}
      </CopyrightSaveAndExitBtn>
    </CopyrightSidebarContainer>
  );
};

export default CopyrightSidebar;
