import { getQueryResponse } from '@app/utils/graphqlUtils';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get } from 'lodash-es';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_REQUESTS_DETAILS } from './queries';
import { failureGetRequestsDetails, requestGetScreenplayRequestsDetails, successGetRequestsDetails } from './reducer';

export function* getScreenplayRequestsDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        storyId: action.payload.storyId,
        isBuyer: false
      },
      pagination: {
        limit: 0,
        page: 1
      }
    };
    const { ok, data, error } = yield call(getQueryResponse, GET_REQUESTS_DETAILS, payload);

    if (ok) {
      const conversationDetails = data.storyConversations.conversations?.filter(
        (conversation: any) => conversation.isRequestPending
      );

      yield put(successGetRequestsDetails(conversationDetails));
    } else {
      yield put(failureGetRequestsDetails(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureGetRequestsDetails(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export default function* requestsDetailsSaga() {
  yield all([takeLatest(requestGetScreenplayRequestsDetails.toString(), getScreenplayRequestsDetails)]);
}

export const requestsDetailsSagaArr = [
  takeLatest(requestGetScreenplayRequestsDetails.toString(), getScreenplayRequestsDetails)
];
