import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { If } from 'tsw-sdk';
import CrossIcon from '@images/icons/MySubmissionIcons/cross-icon.svg';

export interface TagProps {
  isSelected: boolean;
  tagName?: string;
  handleClick?: () => void;
  isDeletable?: boolean;
  handleDelete?: (tagName: string) => void;
}

const Tag = styled.div<TagProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  background: ${(props) => (props.isSelected ? `${colors.tagBorder}` : `${colors.tagBg}`)};
  border: 0.5px solid ${(props) => (props.isSelected ? `${colors.white}` : `${colors.tagBorder}`)};
  border-radius: 1rem;
  cursor: pointer;
  gap: 0.75rem;
`;

const TagText = styled.p<TagProps>`
  ${fonts.weights.fw400()};
  ${fonts.size.xsmall()};
  line-height: 1.12rem;
  text-align: center;
  color: ${(props) => (props.isSelected ? `${colors.white}` : `${colors.tagBorder}`)};
`;

const StyledImg = styled.img``;

const Tags = ({ isSelected, tagName, handleClick, isDeletable = false, handleDelete }: TagProps) => {
  return (
    <Tag isSelected={isSelected} onClick={handleClick} data-testid="tags">
      <TagText isSelected={isSelected}>{tagName}</TagText>
      {handleDelete !== undefined && (
        <If condition={isDeletable}>
          <StyledImg data-testid="delete-tag" src={CrossIcon} onClick={() => handleDelete(tagName!)} />
        </If>
      )}
    </Tag>
  );
};

export default Tags;
