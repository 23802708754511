import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { ExperienceItemProps } from './types';

const ExperienceItemContainer = styled.div`
  max-width: 57rem;
  height: auto;
  min-height: 5rem;
  background-color: ${colors.white};
  display: grid;
  grid-template-columns: 5rem 10rem 15rem 18rem 8rem;
  grid-template-rows: 100%;
  justify-items: flex-start;

  margin-top: 1.5rem;
  border-radius: 1rem;
  padding-right: 2rem;
  padding-top: 1rem;
  cursor: pointer;
`;

const CompanyLogo = styled.div`
  width: 2.87rem;
  height: 2.87rem;
  background-color: ${colors.profilePageColors.companyLogoBg};
  border-radius: 0.68rem;
  margin-left: 1rem;
`;

const CompanyName = styled.p`
  width: 100%;
  ${fonts.size.big()};
  ${fonts.weights.fw700()};
  line-height: 1.5rem;
  color: ${colors.profilePageColors.companyName};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 0.6rem;
`;

const Role = styled.p`
  width: 100%;
  ${fonts.size.big()};
  ${fonts.weights.fw800()};
  line-height: 1.75rem;
  color: ${colors.profilePageColors.companyRole};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 0.6rem;
`;

const RoleDuration = styled.p`
  width: 100%;
  color: ${colors.profilePageColors.roleDuration};
  ${fonts.size.xRegular()};
  ${fonts.weights.fw400()};
  line-height: 1.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 0.8rem;
  padding-top: 0.6rem;
`;

const RoleDescription = styled.p`
  width: 100%;
  color: ${colors.profilePageColors.roleDuration};
  ${fonts.size.xRegular()};
  ${fonts.weights.fw400()};
  line-height: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 0.6rem;
`;

const ExperienceItem = ({ experience }: ExperienceItemProps) => {
  const getDuration = () => {
    const startDate = new Date(experience.startDate * 1000);
    const endDate = new Date(experience.endDate * 1000);

    if (experience.startDate && !experience.endDate) {
      return `${startDate.getFullYear()}-Present`;
    }

    if (experience.startDate && experience.endDate) {
      return `${startDate.getFullYear()}-${endDate.getFullYear()}`;
    }
  };

  return (
    <ExperienceItemContainer>
      <CompanyLogo />
      <CompanyName>{experience.company}</CompanyName>
      <Role>{experience.designation}</Role>
      <RoleDescription>{experience.description}</RoleDescription>
      <RoleDuration>{getDuration()}</RoleDuration>
    </ExperienceItemContainer>
  );
};

export default ExperienceItem;
