import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  loading: false,
  applyCouponSuccess: false,
  applyCouponLoading: false,
  applyCouponError: null,
  paymentError: null
};

const packageDetailSlice = createSlice({
  name: 'packageDetails',
  initialState,
  reducers: {
    requestPaymentData: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestApplyCoupon: {
      reducer: (state) => {
        state.applyCouponLoading = true;
      },
      prepare
    },
    successApplyCoupon(state) {
      state.applyCouponLoading = false;
      state.applyCouponError = null;
      state.applyCouponSuccess = true;
    },
    failureApplyCoupon(state, action) {
      state.applyCouponError = get(action.payload, 'message', 'something went wrong');
      state.applyCouponLoading = false;
      state.applyCouponSuccess = false;
    },
    successPaymentData(state) {
      state.paymentError = null;
      state.loading = false;
    },
    failurePaymentData(state, action) {
      state.paymentError = get(action.payload, 'message', 'something went wrong');
      state.loading = false;
    },
    resetState(state) {
      state.loading = false;
      state.applyCouponLoading = false;
      state.applyCouponError = null;
      state.paymentError = null;
    }
  }
});

export const {
  requestPaymentData,
  successPaymentData,
  failurePaymentData,
  requestApplyCoupon,
  successApplyCoupon,
  failureApplyCoupon,
  resetState
} = packageDetailSlice.actions;

export default packageDetailSlice.reducer;
