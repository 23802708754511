import { colors, fonts } from '@app/themes';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ArrowRight from '@images/icons/ArrowRightIcon.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import { pendingApprovalType } from '../../types';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
`;

const StoryTitle = styled.h1`
  width: 18.18rem;
  ${fonts.family.SpoofFamily()}
  ${fonts.size.xBig()}
  ${fonts.weights.fw900()}
  color: ${colors.darkBorder};
  line-height: 1.87rem;
  margin-bottom: 0;
`;

const DateText = styled.p`
  ${fonts.size.xsmall()}
  ${fonts.weights.fw700()}
  color:${colors.myDashboardColors.tableText};
  line-height: 1rem;
`;

const ViewSubmissionContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
`;

const SubmissionText = styled.p`
  ${fonts.size.small()}
  ${fonts.weights.fw800}
  color:${colors.myDashboardColors.viewText};
  line-height: 1rem;
`;

const StyledImg = styled.img``;

const PendingApprovalItem = ({ id, title, date }: pendingApprovalType) => {
  return (
    <Container>
      <ContentContainer>
        <DateText>{date}</DateText>
        <StoryTitle>{title}</StoryTitle>
      </ContentContainer>
      {/* pass id here as search */}
      <ViewSubmissionContainer
        to={{
          pathname: '/dashboard',
          search: `?activeTab=pending-approval&storyId=${id}`
        }}
      >
        <SubmissionText>{translate('view_submission')}</SubmissionText>
        <StyledImg src={ArrowRight} />
      </ViewSubmissionContainer>
    </Container>
  );
};

export default PendingApprovalItem;
