import { prepare } from '@app/utils';
import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';

export const initialState = {
  requestsDetails: [],
  requestsDetailsError: null,
  getScreenplayRequestsLoading: false
};

const screenplayRequestsSlice = createSlice({
  name: 'screenplayRequests',
  initialState,
  reducers: {
    requestGetScreenplayRequestsDetails: {
      reducer: (state) => {
        state.getScreenplayRequestsLoading = true;
      },
      prepare
    },
    successGetRequestsDetails(state, action) {
      state.getScreenplayRequestsLoading = false;
      state.requestsDetailsError = null;
      state.requestsDetails = action.payload;
    },
    failureGetRequestsDetails(state, action) {
      state.getScreenplayRequestsLoading = false;
      state.requestsDetailsError = get(action.payload, 'message', 'failure');
    },
    resetState(state) {
      state.requestsDetails = [];
      state.requestsDetailsError = null;
      state.getScreenplayRequestsLoading = false;
    }
  }
});

export const { requestGetScreenplayRequestsDetails, successGetRequestsDetails, failureGetRequestsDetails, resetState } =
  screenplayRequestsSlice.actions;

export default screenplayRequestsSlice.reducer;
