import React from 'react';
import styled from 'styled-components';
import cancelIcon from '@images/icons/cancelIcon.svg';
import { If } from 'tsw-sdk';
import { colors, fonts } from '@app/themes';
import { translate } from '../IntlGlobalProvider';

type InfoTagProps = {
  onDelete: (x: string) => void;
  id: string;
  isExp: boolean;
  companyName?: string;
  designation?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  storyName?: string;
  dateOfRelease?: string;
  currentlyWorkingId?: string;
};

const TagContainer = styled.div`
  width: auto;
  max-width: 13.68rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.25rem 0.5rem;
  gap: 0.5rem;
  background: ${colors.tagBg};
  border: 0.5px solid ${colors.tagBorder};
  border-radius: 0.5rem;
`;

const TagContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Heading = styled.p`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

const TexContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

const DescriptionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.cardHeader};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const DescriptionText = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.small()};
  line-height: 1.125rem;
`;

const Infotag = ({
  isExp,
  onDelete,
  id,
  companyName,
  designation,
  description,
  startDate,
  endDate,
  storyName,
  dateOfRelease,
  currentlyWorkingId
}: InfoTagProps) => {
  const renderStoriesTag = () => (
    <TagContainer>
      <TexContainer>
        <Text>{storyName}</Text>
        <Text>|</Text>
        <Text>{dateOfRelease}</Text>
      </TexContainer>
      <img src={cancelIcon} onClick={() => id && onDelete(id)} data-testid="cancel-icon" />
    </TagContainer>
  );
  return (
    <If condition={isExp} otherwise={renderStoriesTag()}>
      <TagContainer>
        <TagContent>
          <Heading>{companyName}</Heading>
          <TexContainer>
            <Text>{designation}</Text>
            <Text>|</Text>
            {currentlyWorkingId === id ? (
              <Text>{`${startDate}-${translate('present')}`}</Text>
            ) : (
              <Text>{`${startDate}-${endDate}`}</Text>
            )}
          </TexContainer>
          <DescriptionDivider />
          <DescriptionText>{description}</DescriptionText>
        </TagContent>
        <img src={cancelIcon} onClick={() => id && onDelete(id)} data-testid="cancel-icon" />
      </TagContainer>
    </If>
  );
};

export default Infotag;
