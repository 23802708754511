import { gql } from 'apollo-boost';

export const GET_OFFER_DETAILS = gql`
  query OfferDetails($input: OfferDetailsInput!, $pagination: OffersPagination!) {
    offerDetails(input: $input, pagination: $pagination) {
      offerDetails {
        id
        offerId
        amount
        currency
        offerState
        daysToClose
        offerEntity {
          id
          placedBy
          firstName
          lastName
          profilePic
          profileBanner
          companyName
          designation
          email
        }
      }
      total
    }
  }
`;
