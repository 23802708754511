import React, { useState, useEffect } from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import { ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import { CustomButton, If } from 'tsw-sdk';
import { SubmissionPageProps } from './types';
import SpotTicketsBalanceModal from './components/SpotTicketsBalanceModal';
import { Portal } from '@app/components/Portal';
import PaymentCompleteModal from './components/PaymentCompleteModal';
import { useLocation } from 'react-router-dom';

const SubmissionPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
`;

const SubmissionPageContentContainer = styled.div`
  width: 100%;
  max-width: 38rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
`;

const SubmissionPageTitle = styled.h2`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xlarge()};
  color: ${colors.copyrightColors.copyrightDeclarationTitle};
  margin-bottom: 0;
`;

const StoryReadyTitle = styled.p`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-top: 3rem;
  line-height: 1.96rem;
`;

const SubmitBtn = styled(CustomButton)`
  && {
    width: 100%;
    justify-content: center;

    &:disabled {
      background-color: ${colors.copyrightColors.btnDisabled};
    }
  }
`;

const MarketPlaceDisclaimer = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;
  color: ${colors.copyrightColors.timelineItemTextActive};
  margin-top: 3rem;
`;

const ClickSubmitBtnMessage = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;
  color: ${colors.copyrightColors.timelineItemTextActive};
  margin-top: 3rem;
  margin-right: auto;
  margin-bottom: 1rem;
`;

const StoryUploadedInfo = styled.p`
  width: 80%;
  ${fonts.weights.fw700()};
  ${fonts.size.small()};
  line-height: 18px;
  color: ${colors.copyrightColors.storyUploadedInfo};
  margin-top: 1rem;
  text-align: center;
`;

const PaymentOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.56rem;
  margin-top: 3rem;
`;

const ChoosePaymentOptionText = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;
  color: ${colors.copyrightColors.timelineItemTextActive};
  margin-top: 1rem;
`;

const PaymentOptionsButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

const PaymentOptionBtn = styled(CustomButton)`
  && {
    width: 47%;
    justify-content: center;

    &:disabled {
      background-color: ${colors.copyrightColors.btnDisabled};
    }
  }
`;

const TotalDueTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw900()};
  color: ${colors.copyrightColors.totalDueTextColor};
  line-height: 1.93rem;
`;

const DueAmount = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw900()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  line-height: 1.93rem;
  margin-top: 0.1rem;

  &:first-of-type {
    text-decoration: line-through;
  }
`;

const DueAmountContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const LaunchOfferText = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.small()};
  line-height: 1rem;
  color: ${colors.primary};
  text-align: end;
  margin-top: 0.5rem;
`;

const SubmissionPage = ({
  storyId,
  sellerId,
  copyrightPreSubmissionDetails,
  swaRegistrationDetails,
  copyrightProtectionDetails,
  confirmStorySubmissionLoading,
  spotTicketsBalance,
  copyrightPaymentStatusData,
  generatePaymentLinkLoading,
  copyrightWithTicketsLoading,
  dispatchGetCopyrightPreSubmissionDetails,
  dispatchConfirmStorySubmission,
  dispatchGetSwaRegistrationDetails,
  dispatchGetCopyrightProtectionDetails,
  dispatchChangeStoryState,
  dispatchResetStoryPdfUrl,
  dispatchGetHasSelfAttestedStory,
  dispatchGetMySpotTickets,
  dispatchGetCopyrightPaymentStatusData,
  dispatchCopyrightWithTickets,
  dispatchGenerateCopyrightPaymentLink
}: SubmissionPageProps) => {
  const location = useLocation();

  const [publishTag, setPublishTag] = useState<string>('');
  const [showPaymentDone, setShowPaymentDone] = useState<boolean>(false);

  const [payWithSpotTicketsVisible, setPayWithSpotTicketsVisible] = useState<boolean>(false);

  const params = new URLSearchParams(location.search);

  const mode: any = params.get('mode');

  useEffect(() => {
    dispatchResetStoryPdfUrl();
  }, []);

  useEffect(() => {
    if (mode === 'razorpay') {
      setShowPaymentDone(true);
    } else {
      if (mode === 'spot_ticket') {
        if (copyrightPaymentStatusData?.isPaymentDone) {
          setShowPaymentDone(true);
        } else {
          setPayWithSpotTicketsVisible(true);
        }
      }
    }
  }, [copyrightPaymentStatusData]);

  useEffect(() => {
    if (storyId && sellerId) {
      dispatchGetSwaRegistrationDetails(sellerId, storyId);
      dispatchGetCopyrightPreSubmissionDetails(sellerId, storyId);
      dispatchGetCopyrightProtectionDetails(sellerId, storyId);
      dispatchGetHasSelfAttestedStory();
      dispatchGetMySpotTickets(sellerId);
    }
  }, [sellerId, storyId]);

  useEffect(() => {
    if (copyrightProtectionDetails) {
      dispatchGetCopyrightPaymentStatusData(sellerId, storyId, copyrightProtectionDetails?.copyrightProtectionId);
    }
  }, [copyrightProtectionDetails]);

  useEffect(() => {
    if (copyrightPreSubmissionDetails?.publishTag) {
      setPublishTag(copyrightPreSubmissionDetails?.publishTag);
    }
  }, [copyrightPreSubmissionDetails]);

  const handleCopyrightWithTickets = () => {
    if (storyId && copyrightProtectionDetails) {
      dispatchCopyrightWithTickets(storyId, copyrightProtectionDetails?.copyrightProtectionId);
    }
  };

  const getType = () => {
    let type = '';
    if (swaRegistrationDetails?.isCopyrightActProtected === true) {
      type = 'COPYRIGHTREGISTRATION';
    }

    if (swaRegistrationDetails?.isStorySwaRegistered === true) {
      type = 'SWA';
    }

    if (
      swaRegistrationDetails?.isCopyrightActProtected === true &&
      swaRegistrationDetails?.isStorySwaRegistered === true
    ) {
      type = 'SWA_REGISTRATION';
    }

    if (copyrightProtectionDetails?.strategySelected === 'SELF_ATTESTATION') {
      type = 'SELFATTESTATION';

      if (swaRegistrationDetails?.isStorySwaRegistered === true) {
        type = 'SWA_ATTESTATION';
      }
    }

    return type;
  };

  const handleSubmit = () => {
    const type = getType();

    dispatchConfirmStorySubmission(sellerId, storyId, type);
    dispatchChangeStoryState(storyId);
  };

  const handleAddSpotTickets = () => {
    dispatchGenerateCopyrightPaymentLink(
      0,
      500 - spotTicketsBalance,
      storyId,
      'COPYRIGHT_ADD_TICKETS',
      'adding missing tickets amount to seller balance'
    );
  };

  const handleRazorPayPayment = () => {
    dispatchGenerateCopyrightPaymentLink(500, 1, storyId, 'COPYRIGHT_FEES', 'payment for copyright using razorpay');
  };

  return (
    <SubmissionPageContainer data-testid="submission-page-container">
      <SubmissionPageContentContainer>
        <SubmissionPageTitle>{translate('submission_title')}</SubmissionPageTitle>
        <StoryReadyTitle>{translate('story_ready_title')}</StoryReadyTitle>
        <MarketPlaceDisclaimer>
          <If
            condition={swaRegistrationDetails?.isStorySwaRegistered}
            otherwise={translate('marketplace_disclaimer_self')}
          >
            {translate('marketplace_disclaimer_swa')}
          </If>
        </MarketPlaceDisclaimer>
        <ClickSubmitBtnMessage>{translate('click_submit_message')}</ClickSubmitBtnMessage>
        <If condition={copyrightPaymentStatusData?.isPaymentRequired}>
          <PaymentOptionsContainer>
            <TotalDueTitle>{translate('total_due')}</TotalDueTitle>
            <DueAmountContainer>
              <DueAmount>{translate('copyright_price')}</DueAmount>
              <DueAmount>{translate('free_as_now')}</DueAmount>
            </DueAmountContainer>

            <ChoosePaymentOptionText>{translate('choose_payment_method')}</ChoosePaymentOptionText>
            <PaymentOptionsButtonContainer>
              <PaymentOptionBtn
                onClick={() => setPayWithSpotTicketsVisible(true)}
                disabled={copyrightPaymentStatusData?.isPaymentDone}
                data-testid="pay-with-spottickets"
              >
                {translate('pay_with_spot_tickets')}
                {copyrightPaymentStatusData?.isPaymentDone &&
                copyrightPaymentStatusData?.paymentMethod === 'SPOT_TICKETS' ? (
                  <CheckOutlined />
                ) : (
                  <ArrowRightOutlined />
                )}
              </PaymentOptionBtn>

              <PaymentOptionBtn
                // disabled={copyrightPaymentStatusData?.isPaymentDone}
                onClick={handleRazorPayPayment}
                loading={generatePaymentLinkLoading}
                data-testid="pay-with-razorpay"
                disabled
              >
                {translate('pay_with_payment_portal')}
                {copyrightPaymentStatusData?.isPaymentDone &&
                copyrightPaymentStatusData?.paymentMethod === 'RAZORPAY' ? (
                  <CheckOutlined />
                ) : (
                  <ArrowRightOutlined />
                )}
              </PaymentOptionBtn>
            </PaymentOptionsButtonContainer>
            <LaunchOfferText>{translate('launch_offer')}</LaunchOfferText>
          </PaymentOptionsContainer>
        </If>

        <If condition={payWithSpotTicketsVisible}>
          <Portal>
            <SpotTicketsBalanceModal
              spotTicketBalance={spotTicketsBalance}
              copyrightWithTicketsLoading={copyrightWithTicketsLoading}
              generatePaymentLinkLoading={generatePaymentLinkLoading}
              handleCopyrightWithTickets={handleCopyrightWithTickets}
              handleAddSpotTickets={handleAddSpotTickets}
            />
          </Portal>
        </If>
        <If condition={showPaymentDone}>
          <Portal>
            <PaymentCompleteModal spotTickets={spotTicketsBalance} mode={mode} storyId={storyId} />
          </Portal>
        </If>
        <SubmitBtn
          onClick={handleSubmit}
          disabled={
            !storyId ||
            !sellerId ||
            !swaRegistrationDetails ||
            !copyrightProtectionDetails ||
            (copyrightPaymentStatusData?.isPaymentRequired && !copyrightPaymentStatusData?.isPaymentDone)
          }
          data-testid="submit-btn-submission"
          loading={confirmStorySubmissionLoading}
        >
          {translate('submit')} <ArrowRightOutlined />
        </SubmitBtn>
        {copyrightPreSubmissionDetails?.publishTag && (
          <StoryUploadedInfo>{translate('story_uploaded_info', { publishTag: publishTag })}</StoryUploadedInfo>
        )}
      </SubmissionPageContentContainer>
    </SubmissionPageContainer>
  );
};

export default SubmissionPage;
