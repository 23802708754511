import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectProfileConatinerDomain = (state: any) => state.profileCreate || initialState;

export const selectVerifyOtpSuccess = () =>
  createSelector(selectProfileConatinerDomain, (substate) => get(substate, 'otpVerifySuccess'));

export const selectVerifuOtpError = () =>
  createSelector(selectProfileConatinerDomain, (substate) => get(substate, 'otpVerifyError'));

export const selectUserProfile = () =>
  createSelector(selectProfileConatinerDomain, (substate) => get(substate, 'userProfile'));

export const selectProfileFilePath = () =>
  createSelector(selectProfileConatinerDomain, (substate) => get(substate, 'fileName'));

export const selectBannerFilePath = () =>
  createSelector(selectProfileConatinerDomain, (substate) => get(substate, 'bannerFileName'));

export const selectLoading = () => createSelector(selectProfileConatinerDomain, (substate) => get(substate, 'loading'));

export const selectProfileError = () =>
  createSelector(selectProfileConatinerDomain, (substate) => get(substate, 'profileError'));
