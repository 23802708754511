import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectCopyrightContainerReducerDomain = (state: any) => state.copyrightContainerReducer || initialState;

export const selectIsStoryCopyrighted = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'isStoryCopyrighted'));

export const selectShouldCopyright = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'shouldCopyright'));

export const selectIsStoryRegistered = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'isStoryRegistered'));

export const selectIsAuthor = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'isAuthor'));

export const selectIsStoryRegisteredSWA = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'isStoryRegisteredSWA'));

export const selectCurrentStoryId = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'currentStoryId'));

export const selectBeginSubmissionProcess = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'beginSubmissionProcessLoading'));

export const selectSubmissionStatus = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'submissionStatus'));

export const selectCopyrightAuthorVerificationDetails = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) =>
    get(substate, 'copyrightAuthorVerificationDetails')
  );

export const selectNocActive = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'nocActive'));

export const selectNocFilePath = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'nocFilePath'));

export const selectNocFileName = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'nocFileName'));

export const selectSwaRegistrationDetails = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'swaRegistrationDetails'));

export const selectSwaQrCodeFilePath = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'swaQrCodeFilePath'));

export const selectCopyrightProtectionCurrentStep = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'copyrightProcessCurrentStep'));

export const selectBackPressed = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'backPressed'));

export const selectCopyrightProtectionDetails = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'copyrightProtectionDetails'));

export const selectCopyrightRegistrationDetails = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'copyrightRegistrationDetails'));

export const selectCopyrightPreSubmissionDetails = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'copyrightPreSubmissionDetails'));

export const selectSignedStoryDocumentPath = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'signedStoryDocumentPath'));

export const selectSignedPOADocumentPath = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'signedPoaDocumentPath'));

export const selectSignedStoryFileName = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'signedStoryDocumentFileName'));

export const selectSignedPoaFileName = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'signedPoaDocumentFileName'));

export const selectStoryPdfUrl = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'storyPdfUrl'));

export const selectSelfAttestationDetails = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'selfAttestationDetails'));

export const selectStoryTitle = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'storyTitle'));

export const selectConfirmStorySubmissionLoading = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'confirmStorySubmissionLoading'));

export const selectHasSelfAttestedStory = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'hasSelfAttestedStory'));

export const selectCopyrightPaymentStatusData = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'copyrightPaymentStatusData'));

export const selectGeneratePaymentLinkLoading = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'generatePaymentLinkLoading'));

export const selectCopyrightWithTicketsLoading = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'copyrightWithTicketsLoading'));

export const selectGovtIdFilePath = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'govtIdFilePath'));

export const selectGovtIdFileName = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'govtIdFileName'));

export const selectSignedGovtidFilePath = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'signedGovtIdFilePath'));

export const selectSignedSwaQrCodeFilePath = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'signedSwaFilePath'));

export const selectGenerateStoryPdfLoading = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'generateStoryPdfLoading'));

export const selectPoaSignedDocument = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'poaSignedDocumentPath'));

export const selectNocSignedDocument = () =>
  createSelector(selectCopyrightContainerReducerDomain, (substate) => get(substate, 'nocSignedDocumentPath'));
