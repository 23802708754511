import { all } from 'redux-saga/effects';
import { copyrightContainerSagaArr } from '../CopyrightContainer/saga';
import { myDashboardSagaArr } from '../DashboardContainerPage/MyDashboard/saga';
import { mySubmissionSagaArr } from '../DashboardContainerPage/MySubmissions/saga';
import { newSubmissionSagaArr } from '../DashboardContainerPage/NewSubmissionPage/saga';
import { submittedStorySagaArr } from '../DashboardContainerPage/SubmittedStoryPage/saga';

import { homeContainerSagaArr } from '../HomeContainer/saga';
import { loginContainerSagaArr } from '../LoginContainer/saga';
import { packageDetailsContainerSagaArr } from '../PackageDetailScreen/saga';
import { profileContainerSagaArr } from '../ProfileContainer/saga';
import { signUpContainerSagaArr } from '../SignUpContainer/saga';
import { pendingApprovalContainerArr } from '../DashboardContainerPage/PendingApproval/saga';
import { offersReceivedSagaArr } from '../DashboardContainerPage/OfferView/saga';
import { offerHistoryContainerSagaArr } from '../DashboardContainerPage/OfferHistory/saga';
import { offersReceivedPerStorySagaArr } from '../DashboardContainerPage/OffersRecieved/saga';
import { offerDetailsContainerSagaArr } from '../DashboardContainerPage/ManageOffer/saga';
import { mySpotTicketsSagaArr } from '../DashboardContainerPage/MySpotTickets/saga';
import { addSpotTicketsSagaArr } from '../DashboardContainerPage/AddSpotTickets/saga';
import { myNotificationsSagaArr } from '../DashboardContainerPage/MyNotifications/saga';
import { dashboardContainerSagaArr } from '../DashboardContainerPage/saga';
import { acceptedOfferSagaArr } from '../DashboardContainerPage/AcceptedOffer/saga';
import { requestsDetailsSagaArr } from '../DashboardContainerPage/RequestView/saga';

export default function* RootSaga() {
  yield all([
    ...homeContainerSagaArr,
    ...loginContainerSagaArr,
    ...profileContainerSagaArr,
    ...copyrightContainerSagaArr,
    ...myDashboardSagaArr,
    ...newSubmissionSagaArr,
    ...signUpContainerSagaArr,
    ...packageDetailsContainerSagaArr,
    ...mySubmissionSagaArr,
    ...submittedStorySagaArr,
    ...pendingApprovalContainerArr,
    ...offersReceivedSagaArr,
    ...offerHistoryContainerSagaArr,
    ...offersReceivedPerStorySagaArr,
    ...offerDetailsContainerSagaArr,
    ...mySpotTicketsSagaArr,
    ...addSpotTicketsSagaArr,
    ...myNotificationsSagaArr,
    ...dashboardContainerSagaArr,
    ...acceptedOfferSagaArr,
    ...requestsDetailsSagaArr
  ]);
}
