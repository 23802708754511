import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, fonts } from '@app/themes';
import { translate } from '@app/components/IntlGlobalProvider';
import DefaultProfileIcon from '@images/icons/defaultProfilePic.svg';
import OffersRecieved from './components/OffersRecieved';
import SubmissionDrafts from './components/SubmissionDrafts';
import SubmissionCard from './components/SubmissionCard';
import submissionGuideIcon from '@images/icons/MyDashboarIcons/submissionSideIcon.svg';
import spotTicketIcon from '@images/icons/MyDashboarIcons/spotTicketsIcon.svg';
import sideTicketIcon from '@images/icons/MyDashboarIcons/SideTicketIcon.svg';
import notificationBellIcon from '@images/icons/MyDashboarIcons/notification-bell-icon.svg';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { requestGetOffersReceived, requestGetSubmissionDrafts } from './reducer';
import { connect } from 'react-redux';
import { selectSubmissionDraftData, selectOffersReceivedData } from './selectors';
import { injectIntl } from 'react-intl';
import {
  FAQ_URL,
  PROFILE_IMAGE_PROD_URL,
  PROFILE_IMAGE_QA_URL,
  PROFILE_IMAGE_URL,
  STORIES_STATES
} from '@app/utils/constants';
import { resetState } from '@app/containers/CopyrightContainer/reducer';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { selectAccountStatusData } from '@app/containers/HomeContainer/selectors';
import { If } from 'tsw-sdk';
import SubscribeReminderCard from './components/SubscribeReminderCard';
import { MyDashboardProps } from './types';
import SubmissionGuidelinesModal from '../../../components/SubmissionGuidelinesModal';
import { selectSpotTicketsBalance } from '../MySpotTickets/selectors';
import { Accordion, AccordionPanel, TipInfo, UnderlineText, UnderlineTextLink } from '../NewSubmissionPage';
import TipsAndTricksModal from '@app/components/TipsAndTricksModal';

const DashboardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
`;

const DashBoardContentContainer = styled.div`
  width: calc(100% - 27rem);
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  background: ${colors.dashboardBg};
`;

const DashBoardContentHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DashBoardHeadline = styled.h1`
  ${fonts.style.spoofHeading}
  color:${colors.myDashboardColors.heading};
  margin-bottom: 0;
`;

const RightPanelContainer = styled.div`
  width: 27rem;
  background: ${colors.white};
  padding: 3rem 1.87rem 1.87rem 1.87rem;
`;

const DashBoardSubContainer = styled.div`
  width: 100%;
  margin-top: 3.56rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;
`;

const SideCard = styled.div`
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0px 0px 1.25rem 0px ${colors.myDashboardColors.cardShadow};
  display: flex;
  align-items: flex-start;
  margin-top: 1.8rem;
  padding: 1rem;
  cursor: pointer;
  position: relative;
`;

const NewNotificationPill = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: ${colors.notificationColors.notificationPillColor};
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
`;

const SideContent = styled.div`
  width: 13.25rem;
  display: flex;
  flex-direction: column;
  margin-left: 2.12rem;
`;

const SideCardTitle = styled.h1`
  width: 100%;
  ${fonts.weights.bold()}
  ${fonts.size.big()}
  line-height: 1.75rem;
  margin-bottom: 0px;
`;

const SideCardMessage = styled.p`
  width: 100%;
  ${fonts.weights.fw400()}
  ${fonts.size.xRegular()}
  margin-top: 0.5rem;
  line-height: 1.5rem;
`;

const TicketsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin-top: 0.62rem;
`;

const TicketText = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.xRegular()};
  color: ${colors.darkBorder};
  line-height: 1.5rem;
`;

const ProfilePic = styled.img`
  width: 3.18rem;
  height: 3.18rem;
  border-radius: 100%;
`;

const StyledLink = styled(Link)`
  color: ${colors.notifMessage};
`;

export function MyDashboard({
  submissionDraft,
  offersReceived,
  accountStatusData,
  userProfile,
  spotTicketsBalance,
  dispatchResetCopyrightState,
  dispatchGetOffersReceived,
  dispatchGetSubmissionDrafts
}: MyDashboardProps) {
  const [profileUrlLocal, setProfileUrlLocal] = useState<string>('');
  const [submissionGuidelinesVisible, setSubissionGuidelinesVisible] = useState<boolean>(false);
  const [tipsAndTricksModalVisible, setTipsAndTricksModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (userProfile?.id) {
      const state = [STORIES_STATES.DRAFTED, STORIES_STATES.PENDING_APPROVAL, STORIES_STATES.UNPUBLISHED];
      dispatchGetSubmissionDrafts(state, 3, 1, userProfile?.id);
      dispatchGetOffersReceived(userProfile?.id);
      dispatchResetCopyrightState();
    }

    if (userProfile?.profilePic) {
      if (process.env.ENVIRONMENT_NAME === 'qa') {
        setProfileUrlLocal(`${PROFILE_IMAGE_QA_URL}/${userProfile?.profilePic}`);
      } else if (process.env.ENVIRONMENT_NAME === 'production') {
        setProfileUrlLocal(`${PROFILE_IMAGE_PROD_URL}/${userProfile?.profilePic}`);
      } else {
        setProfileUrlLocal(`${PROFILE_IMAGE_URL}/${userProfile?.profilePic}`);
      }
    }
  }, [userProfile]);

  return (
    <DashboardContainer>
      <SubmissionGuidelinesModal
        visible={submissionGuidelinesVisible}
        handleClose={() => setSubissionGuidelinesVisible(false)}
      />
      <TipsAndTricksModal visible={tipsAndTricksModalVisible} handleClose={() => setTipsAndTricksModalVisible(false)} />
      <DashBoardContentContainer>
        <DashBoardContentHeader>
          <DashBoardHeadline>{translate('dashboard_title')}</DashBoardHeadline>
          <ProfilePic src={profileUrlLocal !== '' ? profileUrlLocal : DefaultProfileIcon} />
        </DashBoardContentHeader>
        <DashBoardSubContainer>
          <OffersRecieved offers={offersReceived} />
          <SubmissionDrafts submissionDraft={submissionDraft} />
        </DashBoardSubContainer>
        <Link to="dashboard?activeTab=new_submission">
          <SubmissionCard />
        </Link>
      </DashBoardContentContainer>
      <RightPanelContainer>
        <StyledLink to="dashboard?activeTab=notifications">
          <SideCard>
            <If condition={accountStatusData?.unreadNotifications > 0}>
              <NewNotificationPill />
            </If>

            <img src={notificationBellIcon} />
            <SideContent>
              <SideCardTitle>{translate('notifications')}</SideCardTitle>
              <SideCardMessage>{translate('notification_card_description')}</SideCardMessage>
            </SideContent>
          </SideCard>
        </StyledLink>

        {/* <SideCard onClick={() => setSubissionGuidelinesVisible(!submissionGuidelinesVisible)}>
          <img src={submissionGuideIcon} />
          <SideContent>
            <SideCardTitle>{translate('sub_guide_title')}</SideCardTitle>
            <SideCardMessage>{translate('sub_guide_message')}</SideCardMessage>
          </SideContent>
        </SideCard> */}

        <Accordion accordion={true} expandIconPosition={'right'}>
          <AccordionPanel header={translate('submission_guidelines_title')} key={1}>
            <TipInfo>
              {translate('submission_guidelines_info_before_underline')}
              <UnderlineText data-testid="sub-text" onClick={() => setSubissionGuidelinesVisible(true)}>
                {translate('submission_guidelines_info_underline')}
              </UnderlineText>
              {translate('submission_guidelines_info_after_underline')}
            </TipInfo>
          </AccordionPanel>
          <AccordionPanel header={translate('tips_and_tricks_title')} key={2}>
            <TipInfo>
              {translate('tips_and_tricks_info_before_underline')}
              <UnderlineText data-testid="tips-text" onClick={() => setTipsAndTricksModalVisible(true)}>
                {translate('tips_and_tricks_info_underline')}
              </UnderlineText>
              {translate('tips_and_tricks_info_after_underline')}
            </TipInfo>
          </AccordionPanel>
          <AccordionPanel header={translate('copyright_and_hosting_fees')} key={3}>
            <TipInfo>{translate('copyright_hosting_fees')}</TipInfo>
          </AccordionPanel>
          <AccordionPanel header={translate('commission')} key={4}>
            <TipInfo>{translate('commission_message')}</TipInfo>
          </AccordionPanel>
          <AccordionPanel header={translate('faqs')} key={5}>
            <TipInfo>
              {translate('faq_before_underline')}
              <UnderlineTextLink href={FAQ_URL} target="_blank">
                {translate('faq_underline')}
              </UnderlineTextLink>
              {translate('faq_after_underline')}
            </TipInfo>
          </AccordionPanel>
        </Accordion>

        {/* <Link to="dashboard?activeTab=spot_tickets">
          <If
            condition={Object.keys(accountStatusData).length > 0 ? accountStatusData?.subscription?.daysLeft > 5 : true}
            otherwise={
              <SubscribeReminderCard
                ticketsLeft={spotTicketsBalance}
                renewalDaysLeft={accountStatusData?.subscription?.daysLeft}
              />
            }
          >
            <SideCard>
              <img src={spotTicketIcon} />
              <SideContent>
                <SideCardTitle>{translate('add_tickets_title')}</SideCardTitle>
                <SideCardMessage>{translate('add_tickets_message')}</SideCardMessage>
                <TicketsContainer>
                  <img src={sideTicketIcon} />
                  <TicketText>{translate('spot_ticket_amount', { ticketAmount: spotTicketsBalance })}</TicketText>
                </TicketsContainer>
              </SideContent>
            </SideCard>
          </If>
        </Link> */}
      </RightPanelContainer>
    </DashboardContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  submissionDraft: selectSubmissionDraftData(),
  offersReceived: selectOffersReceivedData(),
  userProfile: selectUserProfile(),
  accountStatusData: selectAccountStatusData(),
  spotTicketsBalance: selectSpotTicketsBalance()
});
export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetSubmissionDrafts: (state: any[], limit: number, page: number, sellerId: string) =>
      dispatch(requestGetSubmissionDrafts({ limit, page, state, sellerId })),
    dispatchGetOffersReceived: (sellerId: string) => dispatch(requestGetOffersReceived({ sellerId })),
    dispatchResetCopyrightState: () => dispatch(resetState())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo, injectIntl)(MyDashboard) as React.FC;

export const MyDashboardTest = MyDashboard;
