export type RouteConstant = {
  route: string;
  exact?: boolean;
  isProtected?: boolean;
  props?: object;
  showHeader?: boolean;
};

const routeConstants: Record<string, RouteConstant> = {
  home: {
    route: '/',
    exact: true,
    showHeader: false,
    isProtected: true
  },
  signUp: {
    route: '/signup',
    exact: true,
    showHeader: true,
    isProtected: false
  },
  login: {
    route: '/login',
    exact: true,
    showHeader: true,
    isProtected: false
  },
  authPage: {
    route: '/auth',
    exact: false,
    showHeader: true,
    isProtected: false
  },
  profile: {
    route: '/profile',
    exact: true,
    showHeader: true,
    isProtected: true
  },
  packageDetail: {
    route: '/package-detail',
    exact: true,
    showHeader: true,
    isProtected: true
  },
  paymentComplete: {
    route: '/payment-complete',
    exact: true,
    showHeader: true,
    isProtected: true
  },
  dashboard: {
    route: '/dashboard',
    exact: true,
    showHeader: false,
    isProtected: true
  },
  copyrightContainer: {
    route: '/copyright',
    exact: true,
    showHeader: false,
    isProtected: true
  },
  editProfileContainer: {
    route: '/edit-profile',
    exact: true,
    showHeader: true,
    isProtected: true
  }
};

export type RouteKeys = keyof typeof routeConstants;

export default routeConstants;
