import { gql } from 'apollo-boost';

export const COPYRIGHT_SUBMISSION_STATUS = gql`
  query CopyrightSubmissionStatus($sqInput: SubmissionQueryInput!) {
    copyrightSubmissionStatus(input: $sqInput) {
      stepOneStatus {
        status
        remarks
      }
      stepTwoStatus {
        status
        remarks
      }
      stepThreeStatus {
        status
        remarks
      }
      stepFourStatus {
        status
        remarks
      }
      stepFiveStatus {
        status
        remarks
      }
    }
  }
`;

export const BEGIN_SUBMISSION_PROCESS = gql`
  mutation BeginSubmissionProcess($sqInput: BeginSubmissionProcessInput!) {
    beginSubmissionProcess(input: $sqInput)
  }
`;

export const GET_COPYRIGHT_AUTHOR_VERIFICATION_DETAILS = gql`
  query CopyrightAuthorVerificationDetails($sqInput: SubmissionQueryInput!) {
    copyrightAuthorVerificationDetails(input: $sqInput) {
      copyrightId
      declaration
      nocRequired
      noc {
        uploaded
        filepath
      }
    }
  }
`;

export const GET_NOC_FILE_PATH_DATA = gql`
  query UploadNOCDocument($input: FileUploadInput!) {
    uploadNOCDocument(input: $input) {
      filepath
      fileName
      extensions
    }
  }
`;

export const UPDATE_AUTHOR_VERIFICATION_DETAILS = gql`
  mutation UpdateAuthorVerificationDetails($input: SubmitAuthorVerificationInput!) {
    updateAuthorVerificationDetails(input: $input)
  }
`;

export const GET_SWA_REGISTRATION_DETAILS = gql`
  query CopyrightSWARegistrationDetails($sqInput: SubmissionQueryInput!) {
    copyrightSWARegistrationDetails(input: $sqInput) {
      copyrightId
      isStorySWARegistered
      registrationDetails {
        membershipNo
        registrationNo
        registrationQRCode
      }
      isCopyrightActProtected
    }
  }
`;

export const UPDATE_SWA_REGISTRATION_DETAILS = gql`
  mutation UpdateSWARegistrationDetails($input: SubmitSWARegistrationInput!) {
    updateSWARegistrationDetails(input: $input)
  }
`;

export const GET_SWA_QR_CODE_FILE_PATH_DATA = gql`
  query UploadSWAQRCode($input: FileUploadInput!) {
    uploadSWAQRCode(input: $input) {
      filepath
      fileName
      extensions
    }
  }
`;

export const UPDATE_COPYRIGHT_PROTECTION_STRATEGY = gql`
  mutation UpdateCopyrightProtectionStrategy($input: SubmitCopyrightProtectionStrategyInput!) {
    updateCopyrightProtectionStrategy(input: $input) {
      copyrightProtectionID
    }
  }
`;

export const GET_COPYRIGHT_PROTECTION_DETAILS = gql`
  query CopyrightProtectionDetails($sqInput: SubmissionQueryInput!) {
    copyrightProtectionDetails(input: $sqInput) {
      copyrightProtectionID
      strategySelected
    }
  }
`;

export const UPDATE_COPYRIGHT_PROCESS_STEP = gql`
  mutation updateCopyrightSelfAttestationDetails($input: SubmitCopyrightSelfAttestationInput!) {
    updateCopyrightSelfAttestationDetails(input: $input)
  }
`;

export const UPDATE_COPYRIGHT_PROTECTION_REGISTRATION_DETAILS = gql`
  mutation UpdateCopyrightRegistrationDetails($input: SubmitCopyrightRegistrationInput!) {
    updateCopyrightRegistrationDetails(input: $input)
  }
`;

export const GET_COPYRIGHT_PROTECTION_REGISTRATION_DEFAILS = gql`
  query CopyrightRegistrationDetails($input: CopyrightProtectionInput!) {
    copyrightRegistrationDetails(input: $input) {
      copyrightProtectionID
      applicantName
      diaryNumber
      submissionCategory
      submittedAt
    }
  }
`;

export const CONFIRM_STORY_SUBMISSION = gql`
  mutation ConfirmStorySubmission($sqInput: SubmissionQueryInput!) {
    confirmStorySubmission(input: $sqInput)
  }
`;

export const GET_COPYRIGHT_PRESUBMISSION_DETAILS = gql`
  query CopyrightPreSubmissionDetails($sqInput: SubmissionQueryInput!) {
    copyrightPreSubmissionDetails(input: $sqInput) {
      publishTag
    }
  }
`;

export const UPLOAD_SIGNED_STORY_DOCUMENT = gql`
  query UploadSignedStoryDocument($input: FileUploadInput!) {
    uploadSignedStoryDocument(input: $input) {
      filepath
      fileName
      extensions
    }
  }
`;

export const UPLOAD_SIGNED_POA_DOCUMENT = gql`
  query UploadSignedPOADocument($input: FileUploadInput!) {
    uploadSignedPOADocument(input: $input) {
      filepath
      fileName
      extensions
    }
  }
`;

export const SET_STATE_COPYRIGHT_PENDING = gql`
  mutation updateStoryState($storyStateInput: StoryStateInput!) {
    updateStoryState(input: $storyStateInput) {
      response
      id
      state
    }
  }
`;

export const GENERATE_STORY_PDF = gql`
  query GenerateStoryPdf($input: SubmissionQueryInput!) {
    generateStoryPdf(input: $input)
  }
`;

export const GET_SELF_ATTESTATION_DETAILS = gql`
  query GetSelfAttestationDetails($input: CopyrightProtectionInput!) {
    selfAttestationDetails(input: $input) {
      id
      copyrightProtectionID
      name
      nationality
      address
      storyTitle
      designation
      attestedStoryPath
      signedPOAPath
      currentStep
    }
  }
`;

export const SEND_MAIL = gql`
  mutation SendEmail($input: SendMailInput) {
    sendEmail(input: $input)
  }
`;

export const GET_COPYRIGHT_PAYMENT_STATUS = gql`
  query CopyrightPaymentStatus($input: CopyrightPaymentStatusInput!) {
    copyrightPaymentStatus(input: $input) {
      isPaymentRequired
      isPaymentDone
      paymentMethod
      reason
    }
  }
`;

export const COPYRIGHT_WITH_TICKETS = gql`
  mutation CopyrightWithTickets($input: CopyrightWithTicketsInput!) {
    copyrightWithTickets(input: $input) {
      currentBalance
      status
      storyId
    }
  }
`;

export const COPYRIGHT_PAYMENT = gql`
  mutation GeneratePaymentLink($amount: Int, $tickets: Int, $storyId: Int, $reason: PaymentMetaData, $notes: String!) {
    generatePaymentLink(
      input: { amount: $amount, tickets: $tickets, storyId: $storyId, reason: $reason, notes: $notes }
    ) {
      url
      status
      referenceId
    }
  }
`;

export const GOVT_ID_FILE_NAME = gql`
  query UploadGovtDoc($input: FileUploadInput!) {
    uploadGovtDoc(input: $input) {
      filepath
      fileName
      extensions
    }
  }
`;

export const GET_COPYRIGHT_GOVT_DETAILS = gql`
  query CopyrightGovtDetails($input: SubmissionQueryInput!) {
    copyrightGovtDocumentDetails(input: $input) {
      copyrightId
      doc {
        uploaded
        filepath
      }
    }
  }
`;

export const GET_POA_DOCUMENT = gql`
  query GetSellerPoaDocument($sellerId: String, $storyId: String!) {
    getSellerPOADocument(sellerId: $sellerId, storyId: $storyId)
  }
`;

export const GET_NOC_DOCUMENT = gql`
  query GetSellerNocDocument($sellerId: String) {
    getSellerNOCDocument(sellerId: $sellerId)
  }
`;
