import { translate } from '@app/components/IntlGlobalProvider';
import {
  NARRATION_PATH,
  SCREENPLAY_PATH,
  SELLER,
  STORY_SUBMITTED_MODE,
  SUPPORTING_IMAGES_PATH
} from '@app/utils/constants';
import { setCurrentStoryId, setStoryTitle } from '@app/containers/CopyrightContainer/reducer';
import { getMutationResponse, getQueryResponse } from '@app/utils/graphqlUtils';
import history from '@app/utils/history';
import routeConstants from '@app/utils/routeConstants';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get, isEmpty } from 'lodash-es';
import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  GET_LANGUAGE_GENRE,
  GET_TAGS,
  SUBMIT_STORY,
  GET_NARRATION_FILE_NAME,
  GET_SCREENPLAY_FILE_NAME,
  GET_SUPPORT_IMAGES_FILE_NAME,
  GET_DRAFTED_STORY,
  GET_STORY_TYPES
} from './queries';
import {
  failureGetDraftedStory,
  failureGetLangGenre,
  failureGetStoryTypes,
  failureGetTags,
  failureStateChange,
  failureSubmitStory,
  failureUploadImageFiles,
  failureUploadNarrationFile,
  failureUploadScreenPlayFile,
  requestGetStoryDetails,
  requestGetStoryTypes,
  requestGetTags,
  requestLangGenre,
  requestStateChange,
  requestSubmitStory,
  successGetDraftedStory,
  successGetNarrationFileName,
  successGetScreenplayFileName,
  successGetStoryTypes,
  successGetTags,
  successPutGenreList,
  successPutLanguageList,
  successStateChange,
  successSubmitStory,
  successUploadImageFiles,
  successUploadNarrationFile,
  successUploadScreenplayFile
} from './reducer';
import { OptionsType } from './types';
import { GET_SIGNED_URL } from '@utils/queries';
import { uploadFile } from '@app/utils/apiUtils';
import { UPDATE_STORY_STATE } from '../SubmittedStoryPage/queries';

export function* getLangAndGenre(action: any): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_LANGUAGE_GENRE, payload);

    if (ok) {
      const genreListData = data.genres.genreData;
      const languageListData = data.languages.languageData;
      let genreList: OptionsType[] = [];
      let languageList: OptionsType[] = [];
      /* istanbul ignore else */
      if (genreListData) {
        genreList = genreListData.map((genreData: any) => ({ label: genreData.name, value: genreData.id }));
        yield put(successPutGenreList(genreList));
      }
      /* istanbul ignore else */
      if (languageListData) {
        languageList = languageListData.map((languageData: any) => ({
          label: languageData.name,
          value: languageData.id
        }));
        yield put(successPutLanguageList(languageList));
      }
    } else {
      yield put(failureGetLangGenre(error));
    }
  } catch (err) {
    yield put(failureGetLangGenre(err));
  }
}

export function* getTags(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_TAGS, payload);

    if (ok) {
      const tagsData = data.tags.tagsData;
      let tagsDataList = [];
      if (tagsData) {
        tagsDataList = tagsData.map((tag: any) => ({ id: tag.id, name: tag.name }));
      }
      yield put(successGetTags(tagsDataList));
    } else {
      yield put(failureGetTags(error));
    }
  } catch (err) {
    yield put(failureGetLangGenre(err));
  }
}

export function* submitStory(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      ...action.payload.storyData
    };
    let scrrenPlayFileName = '';
    if (!isEmpty(payload.storySubmissionInput.screenPlay) && payload.storySubmissionInput.screenPlay?.isUploaded) {
      scrrenPlayFileName = yield call(uploadScreenplayFile, payload.storySubmissionInput.screenPlay?.file);
    }
    let narrationFileName = '';
    if (
      !isEmpty(payload.storySubmissionInput.audioNarration) &&
      payload.storySubmissionInput.audioNarration?.isUploaded
    ) {
      narrationFileName = yield call(uploadNarrationFile, payload.storySubmissionInput.audioNarration?.file);
    }
    let imagesFileNameList = [];
    if (payload.storySubmissionInput.images.length > 0) {
      imagesFileNameList = yield call(uploadImageFiles, payload.storySubmissionInput.images);
    }
    if (!isEmpty(payload.storySubmissionInput.screenPlay)) {
      if (scrrenPlayFileName) {
        payload.storySubmissionInput.screenPlay = {
          id: payload.storySubmissionInput?.screenPlay?.id,
          name: SCREENPLAY_PATH + scrrenPlayFileName
        };
      } else {
        payload.storySubmissionInput.screenPlay = {
          id: payload.storySubmissionInput?.screenPlay?.id,
          name: payload.storySubmissionInput?.screenPlay?.name
        };
      }
    } else {
      payload.storySubmissionInput.screenPlay = null;
    }

    if (!isEmpty(payload.storySubmissionInput.audioNarration)) {
      if (narrationFileName) {
        payload.storySubmissionInput.audioNarration = {
          id: payload.storySubmissionInput?.audioNarration?.id,
          name: NARRATION_PATH + narrationFileName
        };
      } else {
        payload.storySubmissionInput.audioNarration = {
          id: payload.storySubmissionInput?.audioNarration?.id,
          name: payload.storySubmissionInput?.audioNarration?.name
        };
      }
    } else {
      payload.storySubmissionInput.audioNarration = null;
    }

    const imageUploadPayloadList = imagesFileNameList.map((fileName: string) => ({
      id: 0,
      name: SUPPORTING_IMAGES_PATH + fileName
    }));
    payload.storySubmissionInput.images = imageUploadPayloadList;

    const { ok, error, data } = yield call(getMutationResponse, SUBMIT_STORY, payload);

    if (ok) {
      yield put(successSubmitStory(data.updateStorySubmission));

      const storyId = action.payload.storyData.storySubmissionInput.storyId;
      /* istanbul ignore else */
      if (storyId === '') {
        yield put(setCurrentStoryId(data.updateStorySubmission.id));
      }

      if (payload?.storySubmissionInput?.mode === STORY_SUBMITTED_MODE.DRAFT) {
        /* istanbul ignore else */
        if (!action.payload.isAutoSave) {
          message.success(translate('submit_story_draft_success'));
          history.push('dashboard?activeTab=submissions');
        }
      } else {
        yield put(setStoryTitle(payload.storySubmissionInput.title));

        history.push(
          `${routeConstants.copyrightContainer.route}?page=protect_story&storyId=${data.updateStorySubmission.id}`
        );
      }
    } else {
      yield put(failureSubmitStory(error));
      message.error(get(error, 'message', 'something went wrong'));
    }
  } catch (err) {
    yield put(failureSubmitStory(err));
  }
}

export function* uploadScreenplayFile(file: any): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, GET_SCREENPLAY_FILE_NAME, {});
    const fileName = ok ? `${data.uploadStoryScreenPlay.fileName}${data.uploadStoryScreenPlay.extensions[0]}` : '';

    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: SELLER,
          method: 'PUT',
          rootFolder: SCREENPLAY_PATH,
          fileName: fileName
        }
      };

      yield put(successGetScreenplayFileName(fileName));

      const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (ok) {
        yield call(uploadFile, data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });
        yield put(successUploadScreenplayFile());
        return fileName;
      } else {
        yield put(failureUploadScreenPlayFile(get(error, 'message', 'something_went_wrong')));
      }
    } else {
      yield put(failureUploadScreenPlayFile(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureUploadScreenPlayFile(get(e, 'message', 'something_went_wrong')));
  }
}

export function* uploadNarrationFile(file: any): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, GET_NARRATION_FILE_NAME, {});
    const fileName = ok
      ? `${data.uploadStoryAudioNarration.fileName}${data.uploadStoryAudioNarration.extensions[0]}`
      : '';

    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: SELLER,
          method: 'PUT',
          rootFolder: NARRATION_PATH,
          fileName: fileName
        }
      };

      yield put(successGetNarrationFileName(fileName));

      const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (ok) {
        yield call(uploadFile, data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });
        yield put(successUploadNarrationFile());
        return fileName;
      } else {
        yield put(failureUploadNarrationFile(get(error, 'message', 'something_went_wrong')));
      }
    } else {
      yield put(failureUploadNarrationFile(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureUploadNarrationFile(get(e, 'message', 'something_went_wrong')));
  }
}

export function* uploadSingleImageFile(file: any): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, GET_SUPPORT_IMAGES_FILE_NAME, {});
    const fileName = ok
      ? `${data.uploadStorySupportingImage.fileName}${data.uploadStorySupportingImage.extensions[0]}`
      : '';
    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: SELLER,
          method: 'PUT',
          rootFolder: SUPPORTING_IMAGES_PATH,
          fileName: fileName
        }
      };

      const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (ok) {
        yield call(uploadFile, data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        return fileName;
      } else {
        yield put(failureUploadImageFiles(get(error, 'message', 'something_went_wrong')));
      }
    } else {
      yield put(failureUploadImageFiles(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureUploadImageFiles(get(e, 'message', 'something_went_wrong')));
  }
}

export function* uploadImageFiles(files: any[]): Generator<any, any, any> {
  try {
    let responseList: any[] = [];
    for (const file of files) {
      const response = yield call(uploadSingleImageFile, file.originFileObj);
      responseList = [...responseList, response];
    }
    /* istanbul ignore else */
    if (responseList.length > 0) {
      yield put(successUploadImageFiles(responseList));
      return responseList;
    }
  } catch (e: any) {
    yield put(failureUploadImageFiles(get(e, 'message', 'something_went_wrong')));
  }
}

export function* getStoriesDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      filters: {
        where: {
          id: {
            equalTo: action.payload.draftId
          }
        },
        asSeller: {
          withID: action.payload.sellerId
        }
      },
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_DRAFTED_STORY, payload);

    if (ok) {
      const storiesData = data.stories.stories[0];

      yield put(successGetDraftedStory(storiesData));
    } else {
      yield put(failureGetDraftedStory(error));
    }
  } catch (err) {
    yield put(failureGetDraftedStory(err));
  }
}

export function* getStoryTypes(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_STORY_TYPES, payload);

    if (ok) {
      const storyTypeData = data.storyTypes.storyTypeData;
      let storyTypeDataList = [];
      if (storyTypeData) {
        storyTypeDataList = storyTypeData.map((storyType: any) => ({ value: storyType.id, label: storyType.name }));
      }
      yield put(successGetStoryTypes(storyTypeDataList));
    } else {
      yield put(failureGetStoryTypes(error));
    }
  } catch (err) {
    yield put(failureGetStoryTypes(err));
  }
}

export function* mutateStoryState(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      storyStateInput: {
        storyId: action.payload.id,
        state: action.payload.state
      }
    };

    const { ok, data, error } = yield call(getMutationResponse, UPDATE_STORY_STATE, payload);

    if (ok) {
      yield put(successStateChange(get(data, 'updateStoryState', {})));
      if (!action.payload.doNotredirect) {
        history.push('dashboard?activeTab=submissions');
        message.success(translate('draft_deleted'));
      }
    } else {
      yield put(failureStateChange(error));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (err) {
    yield put(failureStateChange(err));
    const errorMessage = get(err, 'message', 'something_went_wrong');

    message.error(errorMessage.substring(errorMessage.indexOf(':') + 1));
  }
}

export default function* newSubmissionSaga() {
  yield all([
    takeLatest(requestLangGenre.toString(), getLangAndGenre),
    takeLatest(requestGetTags.toString(), getTags),
    takeLatest(requestSubmitStory.toString(), submitStory),
    takeLatest(requestGetStoryDetails.toString(), getStoriesDetails),
    takeLatest(requestGetStoryTypes.toString(), getStoryTypes),
    takeLatest(requestStateChange.toString(), mutateStoryState)
  ]);
}

export const newSubmissionSagaArr = [
  takeLatest(requestLangGenre.toString(), getLangAndGenre),
  takeLatest(requestGetTags.toString(), getTags),
  takeLatest(requestSubmitStory.toString(), submitStory),
  takeLatest(requestGetStoryDetails.toString(), getStoriesDetails),
  takeLatest(requestGetStoryTypes.toString(), getStoryTypes),
  takeLatest(requestStateChange.toString(), mutateStoryState)
];
