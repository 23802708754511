import { getMutationResponse, getQueryResponse } from '@app/utils/graphqlUtils';
import history from '@app/utils/history';
import { AnyAction } from '@reduxjs/toolkit';
import { get } from 'lodash-es';
import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  failedRenewStory,
  failureGetImageUrls,
  failureGetNarrationUrl,
  failureGetScreenPlayurl,
  failureGetSubmittedStory,
  failureStateChange,
  failureUpdateStory,
  requestGetImages,
  requestGetNarration,
  requestGetScreenPlay,
  requestGetStoryDetails,
  requestRenewStorySubmission,
  requestStateChange,
  requestUpdateStory,
  successGetImageUrls,
  successGetNarrationUrl,
  successGetScreenplayUrl,
  successGetSubmittedStory,
  successRenewStory,
  successStateChange,
  successUpdateStory
} from './reducer';
import { GET_PUBLISHED_STORY, UPDATE_STORY, RENEW_STORY, UPDATE_STORY_STATE } from './queries';
import { SUPPORTING_IMAGES_PATH } from '@app/utils/constants';
import { GET_SIGNED_URL } from '@app/utils/queries';
import { message } from 'antd';
import { translate } from '@app/components/IntlGlobalProvider';
import { successUpdateSpotTicketBalance } from '../MySpotTickets/reducer';

export function* getStoriesDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      filters: {
        where: {
          id: {
            equalTo: action.payload.storyId
          }
        },
        asSeller: {
          withID: action.payload.sellerId
        }
      },
      pagination: {
        limit: action.payload.limit,
        page: action.payload.page
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_PUBLISHED_STORY, payload);

    if (ok) {
      const storiesData = data.stories.stories[0];

      yield put(successGetSubmittedStory(storiesData));
    } else {
      yield put(failureGetSubmittedStory(error));
    }
  } catch (err) {
    yield put(failureGetSubmittedStory(err));
  }
}

export function* mutateStoryState(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      storyStateInput: {
        storyId: action.payload.id,
        state: action.payload.state
      }
    };

    const { ok, data, error } = yield call(getMutationResponse, UPDATE_STORY_STATE, payload);

    if (ok) {
      yield put(successStateChange(get(data, 'updateStoryState', {})));
      history.push('dashboard?activeTab=submissions');
      message.success(translate('story_state_updated'));
    } else {
      yield put(failureStateChange(error));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (err) {
    yield put(failureStateChange(err));
    const errorMessage = get(err, 'message', 'something_went_wrong');

    message.error(errorMessage.substring(errorMessage.indexOf(':') + 1));
  }
}

export function* getScreenplaySignedUrl(action: AnyAction): Generator<any, any, any> {
  try {
    const signedUrlPayload = {
      urlinput: {
        user: 'SELLER',
        method: 'GET',
        rootFolder: action.payload.folderPath,
        fileName: action.payload.fileName
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

    if (ok) {
      const signedUrlPayload = get(data, 'signedUrl', {});

      const signedUrl = get(signedUrlPayload, 'signedUrl', '');

      yield put(successGetScreenplayUrl(signedUrl));
    } else {
      yield put(failureGetScreenPlayurl(error));
    }
  } catch (e: any) {
    yield put(failureGetScreenPlayurl(e));
  }
}

export function* getNarrationSignedUrl(action: AnyAction): Generator<any, any, any> {
  try {
    const signedUrlPayload = {
      urlinput: {
        user: 'SELLER',
        method: 'GET',
        rootFolder: action.payload.folderPath,
        fileName: action.payload.fileName
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

    if (ok) {
      const signedUrlPayload = get(data, 'signedUrl', {});

      const signedUrl = get(signedUrlPayload, 'signedUrl', '');

      yield put(successGetNarrationUrl(signedUrl));
    } else {
      yield put(failureGetNarrationUrl(error));
    }
  } catch (e: any) {
    yield put(failureGetNarrationUrl(e));
  }
}

export function* getSingleImageSignedUrl(fileName: string): Generator<any, any, any> {
  try {
    const signedUrlPayload = {
      urlinput: {
        user: 'SELLER',
        method: 'GET',
        rootFolder: SUPPORTING_IMAGES_PATH,
        fileName: fileName
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

    if (ok) {
      const signedUrlPayload = get(data, 'signedUrl', {});

      const signedUrl = get(signedUrlPayload, 'signedUrl', '');

      return signedUrl;
    } else {
      yield put(failureGetImageUrls(error));
    }
  } catch (e: any) {
    yield put(failureGetImageUrls(e));
  }
}

export function* getImageSignedUrls(action: AnyAction): Generator<any, any, any> {
  try {
    let responseList: any[] = [];
    for (const names of action.payload.fileNames) {
      const response = yield call(getSingleImageSignedUrl, names);
      responseList = [...responseList, response];
    }

    if (responseList.length > 0) {
      yield put(successGetImageUrls(responseList));
    }
  } catch (e: any) {
    yield put(failureGetImageUrls(get(e, 'message', 'something_went_wrong')));
  }
}

export function* updateStory(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      input: {
        storyId: action.payload.storyId,
        expectedOffer: action.payload.expectedOffer,
        coAuthor1: action.payload.coAuthor1,
        coAuthor2: action.payload.coAuthor2
      }
    };

    const { ok, data, error } = yield call(getMutationResponse, UPDATE_STORY, payload);

    if (ok) {
      yield put(successUpdateStory(get(data.updatePublishedStory, 'response', '')));
      message.success(translate('field_update'));
    } else {
      yield put(failureUpdateStory(error));
    }
  } catch (err) {
    yield put(failureUpdateStory(err));
  }
}

export function* renewStory(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      input: {
        storyId: action.payload.storyId
      }
    };

    const { ok, data, error } = yield call(getMutationResponse, RENEW_STORY, payload);

    if (ok) {
      yield put(successRenewStory(data?.renewStorySubmission));
      yield put(successUpdateSpotTicketBalance(data?.renewStorySubmission));
      // history.push('dashboard?activeTab=submissions');
      // message.success(translate('story_renewed'));
    } else {
      yield put(failedRenewStory(error));
    }
  } catch (err) {
    yield put(failedRenewStory(err));
  }
}

export default function* submittedStorySaga() {
  yield all([
    takeLatest(requestGetStoryDetails.toString(), getStoriesDetails),
    takeLatest(requestStateChange.toString(), mutateStoryState),
    takeLatest(requestGetScreenPlay.toString(), getScreenplaySignedUrl),
    takeLatest(requestGetNarration.toString(), getNarrationSignedUrl),
    takeLatest(requestGetImages.toString(), getImageSignedUrls),
    takeLatest(requestUpdateStory.toString(), updateStory),
    takeLatest(requestRenewStorySubmission.toString(), renewStory)
  ]);
}

export const submittedStorySagaArr = [
  takeLatest(requestGetStoryDetails.toString(), getStoriesDetails),
  takeLatest(requestStateChange.toString(), mutateStoryState),
  takeLatest(requestGetScreenPlay.toString(), getScreenplaySignedUrl),
  takeLatest(requestGetNarration.toString(), getNarrationSignedUrl),
  takeLatest(requestGetImages.toString(), getImageSignedUrls),
  takeLatest(requestUpdateStory.toString(), updateStory),
  takeLatest(requestRenewStorySubmission.toString(), renewStory)
];
