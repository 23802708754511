import React from 'react';
import { colors, fonts } from '@app/themes';
import { Modal } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import { translate } from '../IntlGlobalProvider';
import { TipsAndTricksModalProps } from './types';

const CustomModal = styled(Modal)`
  margin: 2rem;

  &&& {
    & .ant-modal-content {
      border-radius: 1.25rem;
    }

    & .ant-modal-footer {
      display: none;
    }
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.p`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  text-align: center;
  color: ${colors.primary};
`;

const TipsAndTricksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const TipsAndTricksList = styled.ol`
  width: 100%;
  padding-left: 1.5rem;
`;

const TipsAndTricksListItem = styled.li`
  ${fonts.size.big()};
  ${fonts.weights.fw600()};

  &:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

const TipsAndTricksHeading = styled.p`
  ${fonts.size.big()};
  ${fonts.weights.fw600()};
  line-height: 1rem;
`;

const TipsAndTricksBody = styled.p`
  ${fonts.size.size15px()};
  margin-top: 0.5rem;
  line-height: 1.1rem;
`;

const TipsAndTricksModal = ({ visible, handleClose }: TipsAndTricksModalProps) => {
  return (
    <CustomModal
      centered={true}
      visible={visible}
      destroyOnClose={true}
      footer={<></>}
      onCancel={handleClose}
      width={700}
    >
      <ContentContainer>
        <ModalTitle>{translate('10_tips_title')}</ModalTitle>

        <TipsAndTricksContainer>
          <TipsAndTricksList>
            {_.range(10).map((number) => (
              <TipsAndTricksListItem key={number}>
                <TipsAndTricksHeading>{translate(`tips_and_tricks_tip_heading_${number + 1}`)}</TipsAndTricksHeading>
                <TipsAndTricksBody>{translate(`tips_and_tricks_tip_body_${number + 1}`)}</TipsAndTricksBody>
              </TipsAndTricksListItem>
            ))}
          </TipsAndTricksList>
        </TipsAndTricksContainer>
      </ContentContainer>
    </CustomModal>
  );
};

export default TipsAndTricksModal;
