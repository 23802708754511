import { Button, message, Modal, notification } from 'antd';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import 'react-image-crop/dist/ReactCrop.css';
import { ProfileCropModalProps } from './types';
import ReactCrop, { Crop } from 'react-image-crop';
import { colors, fonts } from '@app/themes';
import { translate } from '@app/components/IntlGlobalProvider';
import { dataURLtoFile } from '@app/utils/uiUtils';

const ImageCropModal = styled(Modal)`
  && {
    & .ant-modal-footer {
      display: none;
    }

    & .ant-modal-content {
      border-radius: 1.5rem;
    }

    & .ant-modal-body {
      border-radius: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
`;

const CancelBtn = styled(Button)`
  width: 7.81rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.offersReceivedColors.grayBtnBg};
  color: ${colors.offersReceivedColors.grayBtnColor};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.offersReceivedColors.grayBtnBg};
    color: ${colors.offersReceivedColors.grayBtnColor};
    border: none;
  }
`;

const AcceptOfferBtn = styled(Button)`
  width: 5rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;

const ModalTitle = styled.p`
  width: 100%;
  ${fonts.weights.fw800()};
  ${fonts.size.xBig()};
  margin-bottom: 1rem;
  text-align: left;
`;

const ProfileCropModal = ({ image, visible, cropMode, onCrop, handleCancel }: ProfileCropModalProps) => {
  const [crop, setCrop] = useState<Crop>({ width: 0, height: 0, x: 0, y: 0, unit: 'px' });
  const [imageCropped, setImageCropped] = useState<boolean>(false);
  const imageRef = useRef<any>(null);

  const handleOk = () => {
    if (!imageCropped) {
      message.error(translate('image_not_cropped_error'));
      return;
    }

    const image = imageRef.current as HTMLImageElement;

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL('image/jpeg');

    const file = dataURLtoFile(base64Image, 'image.png');

    onCrop(file);
  };

  const cropProps =
    cropMode === 'profile'
      ? {
          aspect: 1,
          circularCrop: true
        }
      : {
          circularCrop: false
        };

  return (
    <ImageCropModal visible={visible} centered={true} onCancel={handleCancel} destroyOnClose={true} footer={<></>}>
      <ModalTitle>{translate('crop_image_title')}</ModalTitle>
      <ReactCrop
        crop={crop}
        onChange={(c) => {
          setCrop(c);
          setImageCropped(true);
        }}
        {...cropProps}
      >
        <img src={image} ref={imageRef} />
      </ReactCrop>
      <ButtonsContainer>
        <CancelBtn onClick={handleCancel} data-testid="cancel-crop">
          {translate('cancel')}
        </CancelBtn>
        <AcceptOfferBtn onClick={handleOk} data-testid="ok-crop">
          {translate('ok')}
        </AcceptOfferBtn>
      </ButtonsContainer>
    </ImageCropModal>
  );
};

export default ProfileCropModal;
