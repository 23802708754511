import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectMySubmissionDomain = (state: any) => state.mySubmission || initialState;

export const selectDrafts = () => createSelector(selectMySubmissionDomain, (substate) => get(substate, 'draftsData'));

export const selectPublishedStories = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'publishedStoriesData'));

export const selectPendingApprovalStories = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'pendingApprovalData'));

export const selectDraftStoriesTotal = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'darftsTotal'));

export const selectPendingApporvalTotal = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'pendingApprovalTotal'));

export const selectPublishedStoriesTotal = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'publishedStoriesTotal'));

export const selectUnpublishedTotal = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'unpublishedStoriesTotal'));

export const selectUnpublishedStories = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'unpublishedStoriesData'));

export const selectDraftsLoading = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'loadingDrafts'));

export const selectPendingApprovalLoading = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'loadingPendingApprovalStories'));

export const selectPublishedStoriesLoading = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'loadingPublishedStories'));

export const selectDeletedDraftsLoading = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'loadingdeletedDrafts'));

export const selectDeletedDraftsStories = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'deletedDraftsData'));

export const selectDeletedDraftsTotal = () =>
  createSelector(selectMySubmissionDomain, (substate) => get(substate, 'deletedDraftsTotal'));
