import { colors, fonts } from '@app/themes';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import PendingApprovalItem from './components/PendingApprovalItem';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { selectGetFileUrlLoading, selectSignedUrl, selectStoryData } from './selectors';
import { createStructuredSelector } from 'reselect';
import { requestGetFileUrl, requestGetStoryData, resetFileUrl } from './reducer';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { PendingApprovalProps } from './types';
import { useLocation } from 'react-router-dom';
import { requestGenerateStoryPdf, resetStoryPdfUrl } from '@app/containers/CopyrightContainer/reducer';
import { selectGenerateStoryPdfLoading, selectStoryPdfUrl } from '@app/containers/CopyrightContainer/selectors';

const PendingApprovalContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: ${colors.dashboardBg};
  padding-top: 4.25rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const PendingApprovalTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.pendingApprovalColors.titleColor};
  margin-top: 2.25rem;
`;

const PendingApproval = ({
  storyData,
  userProfile,
  currentFileUrl,
  storyPdfUrl,
  getFileUrlLoading,
  generateStoryPdfUrlLoading,
  dispatchGetStoryData,
  dispatchGetFileUrl,
  dispatchResetState,
  dispatchGenerateStoryPdf,
  dispatchResetStoryPdfUrl
}: PendingApprovalProps) => {
  const location = useLocation();

  const params = new URLSearchParams(location?.search);

  const storyId = params.get('storyId');

  useEffect(() => {
    return () => {
      dispatchResetState();
      dispatchResetStoryPdfUrl();
    };
  }, []);

  useEffect(() => {
    if (userProfile && storyId) {
      dispatchGetStoryData(userProfile?.id, storyId);
    }
  }, [userProfile]);

  useEffect(() => {
    if (currentFileUrl) {
      const anchorTag = document.createElement('a');

      anchorTag.href = currentFileUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();
    }
  }, [currentFileUrl]);

  useEffect(() => {
    if (storyPdfUrl) {
      const anchorTag = document.createElement('a');

      anchorTag.href = storyPdfUrl;
      anchorTag.setAttribute('target', '_blank');
      anchorTag.click();

      dispatchResetStoryPdfUrl();
    }
  }, [storyPdfUrl]);

  const handleGoBack = () => {
    history.back();
  };

  const generateStoryPdf = () => {
    if (userProfile && storyId) {
      dispatchGenerateStoryPdf(userProfile?.id, storyId);
    }
  };

  return (
    <PendingApprovalContainer>
      <BackButton onClick={handleGoBack} data-testid="back-btn">
        <StyledImg src={ArrowLeft} />
        {translate('back')}
      </BackButton>
      <PendingApprovalTitle>{translate('pending_approval')}</PendingApprovalTitle>
      {storyData && (
        <PendingApprovalItem
          storyData={storyData}
          getFileUrlLoading={getFileUrlLoading}
          generateStoryPdfUrlLoading={generateStoryPdfUrlLoading}
          getSignedUrl={dispatchGetFileUrl}
          getStoryPdfUrl={generateStoryPdf}
        />
      )}
    </PendingApprovalContainer>
  );
};

export const mapStateToProps = createStructuredSelector({
  storyData: selectStoryData(),
  userProfile: selectUserProfile(),
  currentFileUrl: selectSignedUrl(),
  storyPdfUrl: selectStoryPdfUrl(),
  getFileUrlLoading: selectGetFileUrlLoading(),
  generateStoryPdfUrlLoading: selectGenerateStoryPdfLoading()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetStoryData: (sellerId: string, storyId: string) => dispatch(requestGetStoryData({ sellerId, storyId })),
    dispatchGetFileUrl: (path: string, fileName: string) => dispatch(requestGetFileUrl({ path, fileName })),
    dispatchResetState: () => dispatch(resetFileUrl()),
    dispatchGenerateStoryPdf: (sellerId: string, storyId: string) =>
      dispatch(
        requestGenerateStoryPdf({
          sellerId,
          storyId
        })
      ),
    dispatchResetStoryPdfUrl: () => dispatch(resetStoryPdfUrl())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PendingApproval);

export const PendingApprovalTest = PendingApproval;
