import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  loading: false,
  publishedStoryData: null,
  publishedStoryError: null,
  stateChangeLoading: false,
  stateChangeData: null,
  stateChangeError: null,
  getScreenPlaySignedUrlLoading: false,
  getNarrationSignedUrlLoading: false,
  getImagesSignedUrlLoading: false,
  screenPlayUrl: '',
  imagesUrl: [],
  narrationUrl: '',
  screenPlayUrlError: null,
  narrationUrlError: null,
  imageUrlError: null,
  updateStoryLoading: false,
  updateStorySuccess: '',
  updateStoryError: null,
  renewStoryLoading: false,
  spotBalanceAfterRenew: 0,
  renewStoryError: null,
  isStoryRenewed: false
};

const submittedStorySlice = createSlice({
  name: 'submittedStory',
  initialState,
  reducers: {
    requestGetStoryDetails: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestStateChange: {
      reducer: (state) => {
        state.stateChangeLoading = true;
      },
      prepare
    },
    requestGetScreenPlay: {
      reducer: (state) => {
        state.getScreenPlaySignedUrlLoading = true;
      },
      prepare
    },
    requestGetNarration: {
      reducer: (state) => {
        state.getNarrationSignedUrlLoading = true;
      },
      prepare
    },
    requestGetImages: {
      reducer: (state) => {
        state.getImagesSignedUrlLoading = true;
      },
      prepare
    },
    requestUpdateStory: {
      reducer: (state) => {
        state.updateStoryLoading = true;
      },
      prepare
    },
    requestRenewStorySubmission: {
      reducer: (state) => {
        state.renewStoryLoading = true;
      },
      prepare
    },
    successGetSubmittedStory(state, action) {
      state.loading = false;
      state.publishedStoryData = action.payload;
    },
    failureGetSubmittedStory(state, action) {
      state.loading = false;
      state.publishedStoryError = get(action.payload, 'message', 'something went wrong');
    },
    successStateChange(state, action) {
      state.stateChangeLoading = false;
      state.stateChangeData = action.payload;
    },
    failureStateChange(state, action) {
      state.stateChangeLoading = false;
      state.stateChangeError = get(action.payload, 'message', 'something went wrong');
    },
    successGetScreenplayUrl(state, action) {
      state.getScreenPlaySignedUrlLoading = false;
      state.screenPlayUrl = action.payload;
    },
    failureGetScreenPlayurl(state, action) {
      state.getScreenPlaySignedUrlLoading = false;
      state.screenPlayUrlError = get(action.payload, 'message', 'something went wrong');
    },
    successGetNarrationUrl(state, action) {
      state.getNarrationSignedUrlLoading = false;
      state.narrationUrl = action.payload;
    },
    failureGetNarrationUrl(state, action) {
      state.getNarrationSignedUrlLoading = false;
      state.narrationUrlError = get(action.payload, 'message', 'something went wrong');
    },
    successGetImageUrls(state, action) {
      state.getImagesSignedUrlLoading = false;
      state.imagesUrl = action.payload;
    },
    failureGetImageUrls(state, action) {
      state.getImagesSignedUrlLoading = false;
      state.imageUrlError = get(action.payload, 'message', 'something went wrong');
    },
    successUpdateStory(state, action) {
      state.updateStoryLoading = false;
      state.updateStorySuccess = action.payload;
    },
    failureUpdateStory(state, action) {
      state.updateStoryLoading = false;
      state.updateStoryError = get(action.payload, 'message', 'something went wrong');
    },
    successRenewStory(state, action) {
      state.renewStoryLoading = false;
      state.spotBalanceAfterRenew = get(action.payload, 'currentBalance', 0);
      state.isStoryRenewed = true;
    },
    failedRenewStory(state, action) {
      state.renewStoryLoading = false;
      state.isStoryRenewed = false;
      state.renewStoryError = get(action.payload, 'message', 'something went wrong');
    },
    setShowSuccessRenewedPage(state) {
      state.isStoryRenewed = false;
    },

    resetState(state) {
      state.publishedStoryData = null;
      state.publishedStoryError = null;
      state.stateChangeLoading = false;
      state.stateChangeData = null;
      state.stateChangeError = null;
      state.screenPlayUrl = '';
      state.narrationUrl = '';
      state.imagesUrl = [];
      state.updateStoryLoading = false;
      state.updateStoryError = null;
      state.updateStorySuccess = '';
      state.spotBalanceAfterRenew = 0;
      state.isStoryRenewed = false;
    }
  }
});

export const {
  requestGetStoryDetails,
  successGetSubmittedStory,
  failureGetSubmittedStory,
  requestStateChange,
  successStateChange,
  failureStateChange,
  requestGetScreenPlay,
  successGetScreenplayUrl,
  failureGetScreenPlayurl,
  successGetNarrationUrl,
  failureGetNarrationUrl,
  requestGetNarration,
  requestGetImages,
  successGetImageUrls,
  failureGetImageUrls,
  requestUpdateStory,
  successUpdateStory,
  failureUpdateStory,
  requestRenewStorySubmission,
  successRenewStory,
  failedRenewStory,
  resetState,
  setShowSuccessRenewedPage
} = submittedStorySlice.actions;

export default submittedStorySlice.reducer;
