import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  requestPaymentData,
  successPaymentData,
  failurePaymentData,
  successApplyCoupon,
  requestApplyCoupon,
  failureApplyCoupon
} from './reducer';

import { getMutationResponse, getQueryResponse } from '@utils/graphqlUtils';
import { PAYMENT, SellerAccountStatus } from '@utils/queries';
import { SELLER } from '@app/utils/constants';
import { APPLY_COUPON } from './queries';
import { message } from 'antd';
import { translate } from '@app/components/IntlGlobalProvider';
import { get } from 'lodash-es';
import history from '@app/utils/history';
import routeConstants from '@app/utils/routeConstants';
import { failureAccountStatus, successAccountStatus } from '../HomeContainer/reducer';

export function* getPaymentData(action: any): Generator<any, any, any> {
  try {
    const payload = {
      amount: action.payload.amount,
      reason: action.payload.reason,
      notes: action.payload.notes
    };
    const { ok, data, error } = yield call(getMutationResponse, PAYMENT, payload);

    if (ok) {
      const generatePaymentLink = get(data, 'generatePaymentLink', {});
      const url = get(generatePaymentLink, 'url', '');

      window.open(url, '_self');

      yield put(successPaymentData());
    } else {
      yield put(failurePaymentData(error));
      message.error(get(error, 'message', translate('something_went_wrong')));
    }
  } catch (err) {
    yield put(failurePaymentData(err));
    message.error(get(err, 'message', translate('something_went_wrong')));
  }
}

export function* applyCoupon(action: any): Generator<any, any, any> {
  try {
    const payload = {
      isBuyer: false,
      code: action.payload.code,
      userType: SELLER
    };
    const { ok, error, data } = yield call(getMutationResponse, APPLY_COUPON, { input: payload });

    if (ok) {
      const applyCoupon = get(data, 'applyCoupon', {});

      const status = get(applyCoupon, 'status', '');

      if (status === 'Success') {
        yield put(successApplyCoupon());

        const { ok, data, error } = yield call(getQueryResponse, SellerAccountStatus);

        if (ok) {
          const sellerAccountStatus = get(data, 'sellerAccountStatus', {});

          yield put(successAccountStatus(sellerAccountStatus));
          history.push(routeConstants.dashboard.route);
        } else {
          yield put(failureAccountStatus(error));
        }
      } else {
        message.error(status);
        yield put(failureApplyCoupon(status));
      }
    } else {
      yield put(failureApplyCoupon(error));

      message.error(get(error, 'message', translate('something_went_wrong')).replace('GraphQL error:', ''));
    }
  } catch (err) {
    yield put(failureApplyCoupon(err));
    message.error(get(err, 'message', translate('something_went_wrong')).replace('GraphQL error:', ''));
  }
}

// Individual exports for testing
export default function* packageDetailsContainerSaga() {
  yield all([
    takeLatest(requestPaymentData.toString(), getPaymentData),
    takeLatest(requestApplyCoupon.toString(), applyCoupon)
  ]);
}

export const packageDetailsContainerSagaArr = [
  takeLatest(requestPaymentData.toString(), getPaymentData),
  takeLatest(requestApplyCoupon.toString(), applyCoupon)
];
