import { prepare } from '@app/utils';
import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';

export const initialState = {
  sendMailToLegalTeamLoading: false,
  sendMailToLegalTeamError: null
};

const acceptedOffer = createSlice({
  name: 'acceptedOfferSlice',
  initialState,
  reducers: {
    requestSendMailToLegalTeam: {
      reducer: (state) => {
        state.sendMailToLegalTeamLoading = true;
      },
      prepare
    },
    successSendMailToLegalTeam(state) {
      state.sendMailToLegalTeamLoading = false;
    },
    failureSendMailToLegalTeam(state, action) {
      state.sendMailToLegalTeamLoading = false;
      state.sendMailToLegalTeamError = get(action.payload, 'message', 'failure');
    }
  }
});

export const { requestSendMailToLegalTeam, successSendMailToLegalTeam, failureSendMailToLegalTeam } =
  acceptedOffer.actions;

export default acceptedOffer.reducer;
