import { colors, fonts } from '@app/themes';
import React, { memo, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import SubmissionIcon from '@images/icons/MySubmissionIcons/new-submission.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import MyDrafts from './components/MyDrafts';
import SubmittedStoriesCard from './components/SubmittedStoriesCard';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import {
  requestGetDeletedDraft,
  requestGetDrafts,
  requestGetPendingApprovalStories,
  requestGetPublishedStories,
  requestGetUnpublishedStories
} from './reducer';
import { connect } from 'react-redux';
import {
  selectDrafts,
  selectPublishedStories,
  selectPendingApprovalStories,
  selectPendingApporvalTotal,
  selectDraftStoriesTotal,
  selectPublishedStoriesTotal,
  selectUnpublishedTotal,
  selectUnpublishedStories,
  selectDraftsLoading,
  selectPendingApprovalLoading,
  selectPublishedStoriesLoading,
  selectDeletedDraftsTotal,
  selectDeletedDraftsLoading,
  selectDeletedDraftsStories
} from './selectors';
import { injectIntl } from 'react-intl';
import {
  MySybmissionProps,
  PageAnchorTypes,
  pendingApprovalType,
  PublishedStoriesProps,
  unpublishedStoriesProps
} from './types';
import { STORIES_STATES } from '@app/utils/constants';
import routeConstants from '@app/utils/routeConstants';
import PendingApprovalItem from './components/PendingApprovalItem';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import { requestGenerateStoryPdf, resetStoryPdfUrl } from '@app/containers/CopyrightContainer/reducer';
import { selectStoryPdfUrl } from '@app/containers/CopyrightContainer/selectors';
import { If, Pagination } from 'tsw-sdk';
import CustomEmpty from '@app/components/CustomEmpty';
import StyledSpin from '@app/components/StyledSpin';
import { Button } from 'antd';
import { sectionAnchorData } from '../../../utils/sectionAnchorData';
import { requestStateChange } from '../NewSubmissionPage/reducer';

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 3.52rem 7.87rem 0px 2rem;
  gap: 2.56rem;
  background: ${colors.dashboardBg};
`;

const SubmissionCard = styled.div`
  width: 19.12rem;
  height: 19.5rem;
  display: inline-flex;
  flex-direction: column;
  background: ${colors.secondaryText};
  box-shadow: 0px 0px 1.25rem 0px ${colors.myDashboardColors.cardShadow};
  border-radius: 1rem;
  padding: 2.5rem 2.62rem 2.62rem 2.62rem;
  cursor: pointer;

  & img {
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
  }
`;

const SubmissionCardTitle = styled.h1`
  width: 100%;
  ${fonts.weights.fw800()};
  ${fonts.size.big()};
  color: ${colors.myDashboardColors.subHeading};
  line-height: 1.75rem;
  text-align: center;
  margin-top: 2.12rem;
  margin-bottom: 0;
`;

const SubmissionCardSubTitle = styled.p`
  width: 100%;
  ${fonts.weights.fw400()};
  ${fonts.size.xRegular()};
  color: ${colors.cardListItem};
  line-height: 1.5rem;
  text-align: center;
  margin-top: 0.5rem;
`;

const HeadingContainer = styled.div`
  width: 100%;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.h1`
  ${fonts.family.SpoofFamily};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.myDashboardColors.heading};
  margin-bottom: 0;
`;

const SubmissionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const PendingApprovalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
`;

const SectionAnchor = styled(Button)`
  ${fonts.weights.fw800()};
  ${fonts.size.big()};
  color: ${colors.myDashboardColors.subHeading};
  width: 19.125rem;
  height: 4rem;
  padding: 1.25rem;
  background: ${colors.secondaryText};
  box-shadow: 0px 0px 1.25rem 0px ${colors.myDashboardColors.cardShadow};
  border-radius: 1rem;
  cursor: pointer;

  display: flex;
  gap: 1.25rem;
  align-items: center;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.mySubmissions.anchorHover};
    path {
      fill: ${colors.white};
    }
  }
`;

const SectionAnchorWrapper = styled.div`
  height: 19.5rem;
  width: 19.125rem;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 2.375rem;
`;

const SubText = styled.div`
  color: ${colors.mySubmissions.lightText};
  ${fonts.size.xsmall};
  ${fonts.weights.fw600()};
  margin-top: 5px;
`;

const MySubmissions = ({
  dispatchGetDrafts,
  drafts,
  userProfile,
  publishedStories,
  pendingApprovals,
  storyPdfUrl,
  draftsTotal,
  pendingApprovalTotal,
  publishedStoriesTotal,
  unpublishedAndExpiredStories,
  unpublishedAndExpiredTotal,
  loadingDrafts,
  loadingPendingApprovals,
  loadingPublishedStories,
  loadingDeletedDrafts,
  deletedDraftsTotal,
  deletedDraftsStories,
  dispatchGetPendingApprovals,
  dispatchGetPublishedStories,
  dispatchGenerateStoryPdf,
  dispatchResetStoryPdfUrl,
  dispatchGetUnpublishedStories,
  dispatchGetDeletedDraftStories,
  dispatchChangeStoryState
}: MySybmissionProps) => {
  const perPage = 5;

  const [draftStoriesCurrentPage, setDraftStoriesCurrentPage] = useState<number>(1);
  const [pendingApprovalCurrentPage, setPendingApprovalCurrentPage] = useState<number>(1);
  const [publishedStoriesCurrentPage, setPublishedStoriesCurrentPage] = useState<number>(1);
  const [currentLoadingStoryId, setCurrentLoadingStoryId] = useState<string>('');
  const [unpublishedStoriesCurrentPage, setUnpublishedStoriesCurrentPage] = useState<number>(1);
  const [deletedDraftsCurrentPage, setDeletedDraftsCurrentPage] = useState<number>(1);
  const myDraftRef = useRef<HTMLDivElement>(null);
  const mySubRef = useRef<HTMLDivElement>(null);
  const myUnStoryRef = useRef<HTMLDivElement>(null);
  const deletedDraftRef = useRef<HTMLDivElement>(null);

  const refArray = [myDraftRef, mySubRef, myUnStoryRef, deletedDraftRef];
  sectionAnchorData.forEach((data: any, index) => {
    return (data.refValue = refArray[index]?.current);
  });

  useEffect(() => {
    if (userProfile?.id) {
      dispatchGetDrafts([STORIES_STATES.DRAFTED], perPage, draftStoriesCurrentPage, userProfile?.id);
      dispatchGetPublishedStories([STORIES_STATES.PUBLISHED], perPage, publishedStoriesCurrentPage, userProfile?.id);
      dispatchGetPendingApprovals(
        [STORIES_STATES.PENDING_APPROVAL],
        perPage,
        pendingApprovalCurrentPage,
        userProfile?.id
      );
      dispatchGetUnpublishedStories(
        [STORIES_STATES.UNPUBLISHED, STORIES_STATES.EXPIRED],
        perPage,
        unpublishedStoriesCurrentPage,
        userProfile?.id
      );
      dispatchGetDeletedDraftStories([STORIES_STATES.DELETED], perPage, deletedDraftsCurrentPage, userProfile?.id);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile?.id) {
      dispatchGetDeletedDraftStories([STORIES_STATES.DELETED], perPage, deletedDraftsCurrentPage, userProfile?.id);
    }
  }, [deletedDraftsCurrentPage]);

  useEffect(() => {
    if (userProfile?.id) {
      dispatchGetDrafts(
        [STORIES_STATES.DRAFTED, STORIES_STATES.UNPUBLISHED],
        perPage,
        draftStoriesCurrentPage,
        userProfile?.id
      );
    }
  }, [draftStoriesCurrentPage]);

  useEffect(() => {
    if (userProfile?.id) {
      dispatchGetPendingApprovals(
        [STORIES_STATES.PENDING_APPROVAL],
        perPage,
        pendingApprovalCurrentPage,
        userProfile?.id
      );
    }
  }, [pendingApprovalCurrentPage]);

  useEffect(() => {
    if (userProfile?.id) {
      dispatchGetPublishedStories([STORIES_STATES.PUBLISHED], perPage, publishedStoriesCurrentPage, userProfile?.id);
    }
  }, [publishedStoriesCurrentPage]);

  useEffect(() => {
    if (userProfile?.id) {
      dispatchGetUnpublishedStories(
        [STORIES_STATES.UNPUBLISHED, STORIES_STATES.EXPIRED],
        perPage,
        unpublishedStoriesCurrentPage,
        userProfile?.id
      );
    }
  }, [unpublishedStoriesCurrentPage]);

  useEffect(() => {
    if (storyPdfUrl) {
      const downloadTag = document.createElement('a');
      downloadTag.href = storyPdfUrl;
      downloadTag.setAttribute('target', '_blank');
      downloadTag.click();
      dispatchResetStoryPdfUrl();
      setCurrentLoadingStoryId('');
    }
  }, [storyPdfUrl]);

  useEffect(() => {
    return () => {
      dispatchResetStoryPdfUrl();
    };
  }, []);

  const handleContinue = (draftId: string) => {
    dispatchChangeStoryState(STORIES_STATES.DRAFTED, draftId, true);
  };

  return (
    <Container>
      <Wrapper>
        <StyledLink to={`${routeConstants.dashboard.route}?activeTab=new_submission`}>
          <SubmissionCard>
            <img src={SubmissionIcon} />
            <SubmissionCardTitle>{translate('start_submission')}</SubmissionCardTitle>
            <SubmissionCardSubTitle>{translate('tips')}</SubmissionCardSubTitle>
          </SubmissionCard>
        </StyledLink>
        <SectionAnchorWrapper>
          {sectionAnchorData.map((data: PageAnchorTypes, index) => (
            <SectionAnchor
              key={index}
              onClick={() => data?.refValue?.scrollIntoView({ block: 'start' })}
              data-testid={`section-anchor-${index}`}
            >
              {data.icon}
              <span>{translate(data.title)}</span>
            </SectionAnchor>
          ))}
        </SectionAnchorWrapper>
      </Wrapper>
      <HeadingContainer>
        <Heading>{translate('pending_approval')}</Heading>
        <Pagination
          total={pendingApprovalTotal}
          currentPage={pendingApprovalCurrentPage}
          perPage={perPage}
          setPage={(page: number) => setPendingApprovalCurrentPage(page)}
          testKey="pending-approval"
        />
      </HeadingContainer>
      <If condition={!loadingPendingApprovals} otherwise={<StyledSpin />}>
        <If condition={pendingApprovals?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
          <PendingApprovalContainer>
            {pendingApprovals?.map(({ id, title, date }: pendingApprovalType) => (
              <PendingApprovalItem key={id} id={id} title={title} date={date} />
            ))}
          </PendingApprovalContainer>
        </If>
      </If>
      <HeadingContainer ref={myDraftRef}>
        <Heading>{translate('drafts')}</Heading>
        <Pagination
          total={draftsTotal}
          currentPage={draftStoriesCurrentPage}
          perPage={perPage}
          setPage={(page: number) => setDraftStoriesCurrentPage(page)}
          testKey="drafts"
        />
      </HeadingContainer>
      <If condition={!loadingDrafts} otherwise={<StyledSpin />}>
        <If condition={drafts && drafts?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
          <MyDrafts drafts={drafts} />
        </If>
      </If>
      <HeadingContainer ref={mySubRef}>
        <Heading>{translate('my_submissions')}</Heading>
        <Pagination
          total={publishedStoriesTotal}
          currentPage={publishedStoriesCurrentPage}
          perPage={perPage}
          setPage={(page: number) => setPublishedStoriesCurrentPage(page)}
          testKey="submissions"
        />
      </HeadingContainer>
      <If condition={!loadingPublishedStories} otherwise={<StyledSpin />}>
        <If condition={publishedStories && publishedStories?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
          <SubmissionsContainer>
            {publishedStories?.map((story: PublishedStoriesProps) => (
              <SubmittedStoriesCard
                key={story.id}
                storyDetails={story}
                sellerId={userProfile?.id}
                currentLoadingStoryId={currentLoadingStoryId}
                dispatchGenerateStoryPdf={(sellerId, storyId) => {
                  setCurrentLoadingStoryId(storyId);
                  dispatchGenerateStoryPdf(sellerId, storyId);
                }}
                storyState={story?.state}
              />
            ))}
          </SubmissionsContainer>
        </If>
      </If>
      <HeadingContainer ref={myUnStoryRef}>
        <Heading>{translate('unpublished_stories')}</Heading>
        <Pagination
          total={unpublishedAndExpiredTotal}
          currentPage={unpublishedStoriesCurrentPage}
          perPage={perPage}
          setPage={(page: number) => setUnpublishedStoriesCurrentPage(page)}
          testKey="unpublished"
        />
      </HeadingContainer>
      <If
        condition={unpublishedAndExpiredStories && unpublishedAndExpiredStories?.length > 0}
        otherwise={<CustomEmpty text={'no_data'} />}
      >
        <SubmissionsContainer>
          {unpublishedAndExpiredStories?.map((story: unpublishedStoriesProps) => (
            <SubmittedStoriesCard
              key={story.id}
              storyDetails={story}
              sellerId={userProfile?.id}
              dispatchGenerateStoryPdf={(sellerId, storyId) => {
                setCurrentLoadingStoryId(storyId);
                dispatchGenerateStoryPdf(sellerId, storyId);
              }}
              currentLoadingStoryId={currentLoadingStoryId}
              storyState={story?.state}
            />
          ))}
        </SubmissionsContainer>
      </If>
      <div>
        <HeadingContainer ref={deletedDraftRef}>
          <Heading>{translate('deleted_drafts')}</Heading>
          <Pagination
            total={deletedDraftsTotal}
            currentPage={deletedDraftsCurrentPage}
            perPage={perPage}
            setPage={(page: number) => setDeletedDraftsCurrentPage(page)}
            testKey="deleted_drafts"
          />
        </HeadingContainer>
        <SubText>{translate('deleted_drafts_subtext')}</SubText>
      </div>
      <If condition={!loadingDeletedDrafts} otherwise={<StyledSpin />}>
        <If condition={deletedDraftsStories?.length > 0} otherwise={<CustomEmpty text={'no_data'} />}>
          <MyDrafts
            drafts={deletedDraftsStories}
            type={'deleted'}
            marginBottom="3rem"
            handleContinue={handleContinue}
          />
        </If>
      </If>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  drafts: selectDrafts(),
  publishedStories: selectPublishedStories(),
  pendingApprovals: selectPendingApprovalStories(),
  userProfile: selectUserProfile(),
  storyPdfUrl: selectStoryPdfUrl(),
  draftsTotal: selectDraftStoriesTotal(),
  pendingApprovalTotal: selectPendingApporvalTotal(),
  publishedStoriesTotal: selectPublishedStoriesTotal(),
  unpublishedAndExpiredStories: selectUnpublishedStories(),
  unpublishedAndExpiredTotal: selectUnpublishedTotal(),
  loadingDrafts: selectDraftsLoading(),
  loadingPendingApprovals: selectPendingApprovalLoading(),
  loadingPublishedStories: selectPublishedStoriesLoading(),
  loadingDeletedDrafts: selectDeletedDraftsLoading(),
  deletedDraftsTotal: selectDeletedDraftsTotal(),
  deletedDraftsStories: selectDeletedDraftsStories()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetDrafts: (state: any[], limit: number, page: number, sellerId: string) =>
      dispatch(requestGetDrafts({ limit, page, state, sellerId })),
    dispatchGetPublishedStories: (state: any[], limit: number, page: number, sellerId: string) =>
      dispatch(requestGetPublishedStories({ limit, page, state, sellerId })),
    dispatchGetPendingApprovals: (state: any[], limit: number, page: number, sellerId: string) =>
      dispatch(requestGetPendingApprovalStories({ limit, page, state, sellerId })),
    dispatchGetUnpublishedStories: (state: any[], limit: number, page: number, sellerId: string) =>
      dispatch(requestGetUnpublishedStories({ limit, page, state, sellerId })),
    dispatchGenerateStoryPdf: (sellerId: string, storyId: string) =>
      dispatch(
        requestGenerateStoryPdf({
          sellerId,
          storyId
        })
      ),
    dispatchResetStoryPdfUrl: () => dispatch(resetStoryPdfUrl()),
    dispatchGetDeletedDraftStories: (state: any[], limit: number, page: number, sellerId: string) =>
      dispatch(requestGetDeletedDraft({ limit, page, state, sellerId })),
    dispatchChangeStoryState: (state: string, id: string, doNotredirect?: boolean) =>
      dispatch(requestStateChange({ id, state, doNotredirect }))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo, injectIntl)(MySubmissions) as React.FC;

export const MySubmissionsTest = MySubmissions;
