import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Step5Props } from './types';
import { CustomButton } from 'tsw-sdk';
import uploadIcon from '@images/icons/upload-icon.svg';
import { ArrowRightOutlined } from '@ant-design/icons';
import _ from 'lodash-es';
import { COPYRIGHT_PROTECTION_STEPS } from '@app/utils/constants';
import { validateFileSize } from '@app/utils/uiUtils';
import { message, Modal } from 'antd';
import ConfirmProceedSubmission from '../../../../../../components/ConfirmProceedSubmission';

const StepContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const StepTitle = styled.h2`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
`;

const StepDescription = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;

  &:first-of-type {
    margin-top: 1.5rem;
  }
`;

const UploadDocumentBtn = styled(CustomButton)`
  margin-top: 1.5rem;

  && {
    border: 1px solid ${colors.primary};
    justify-content: center;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const UploadIcon = styled.img`
  margin-left: 0.5rem;
`;

const ProceedBtn = styled(CustomButton)`
  margin-top: 3rem;
  margin-bottom: 2rem;

  && {
    width: 100%;
    border: 1px solid ${colors.primary};
    justify-content: center;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileUploadedText = styled.p`
  margin-top: 0.5rem;
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-right: auto;
  line-height: 1.31rem;
  margin-bottom: 1.5rem;
`;

const PreviewFileBtn = styled.span`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-left: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
`;

const DeleteFileBtn = styled.span`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-left: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
`;

const StoryDownloadLink = styled.a`
  display: none;
`;

const FileLimitText = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  margin-top: 0.4rem;
  line-height: 1.31rem;
`;

const ConfirmPopup = styled(Modal)`
  &&& {
    & .ant-modal-content {
      border-radius: 1.25rem;
    }

    & .ant-modal-footer {
      display: none;
    }
  }
`;

const Step5 = ({
  storyId,
  signedPoaDocumentPath,
  copyrightProtectionDetails,
  signedStoryPoaFileName,
  swaRegistrationDetails,
  selfAttestationDetails,
  dispatchUpdateCopyrightSelfAttestationDetails,
  dispatchSetSignedPoaDocumentPath,
  dispatchUploadPoaDocumentDocument,
  dispatchPreviewFile
}: Step5Props) => {
  const fileInputRef = useRef<any>(null);
  const fileDownloadRef = useRef<any>(null);

  const [fileName, setFileName] = useState<string>('');
  const [confirmSubmissionModalVisible, setConfirmSubmissionModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (selfAttestationDetails) {
      if (selfAttestationDetails.signedPoaPath) {
        const storyPath = selfAttestationDetails.signedPoaPath;

        dispatchSetSignedPoaDocumentPath(storyPath);
        const filePathArr = storyPath.split('/');
        const fileNameString = filePathArr[filePathArr.length - 1];

        setFileName(fileNameString);
      }
    }
  }, [selfAttestationDetails]);

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];

    if (validateFileSize(file, 5)) {
      setFileName(file.name);
      dispatchUploadPoaDocumentDocument({ file, storyId });
    } else {
      message.error(translate('file_size_exceed', { fileSize: '5mb' }));
    }

    e.target.value = null;
  };

  const handleFilePreview = () => {
    const storyPath = selfAttestationDetails?.signedPoaPath;
    if (storyPath) {
      let rootFolder: any = storyPath.split('.')[0];
      rootFolder = rootFolder.split('/');
      rootFolder.pop();

      rootFolder = rootFolder.join('/');

      let fileName: any = storyPath.split('/');

      fileName = fileName[fileName.length - 1];

      dispatchPreviewFile({
        rootFolder,
        fileName
      });
    } else {
      fileDownloadRef.current?.click();
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleProceed = () => {
    dispatchUpdateCopyrightSelfAttestationDetails({
      data: {
        storyId: storyId,
        copyrightProtectionID: copyrightProtectionDetails?.copyrightProtectionId,
        mode: 'PROCEED',
        signedPOADocumentPath: signedStoryPoaFileName !== '' ? signedStoryPoaFileName : signedPoaDocumentPath,
        currentStep: COPYRIGHT_PROTECTION_STEPS.STEP5
      },
      copyrightId: swaRegistrationDetails.copyrightId,
      nextStep: COPYRIGHT_PROTECTION_STEPS.DONE
    });
  };

  const handleConfirmSubmission = () => {
    setConfirmSubmissionModalVisible(true);
  };

  return (
    <StepContainer>
      <ConfirmPopup
        visible={confirmSubmissionModalVisible}
        centered={true}
        onCancel={() => setConfirmSubmissionModalVisible(false)}
        width={800}
        destroyOnClose={true}
      >
        <ConfirmProceedSubmission
          handleProceed={handleProceed}
          handleGoBack={() => setConfirmSubmissionModalVisible(false)}
        />
      </ConfirmPopup>
      <FileInput
        ref={fileInputRef}
        onChange={handleFileChange}
        type="file"
        accept="application/pdf"
        data-testid="file-input-step5"
      />
      <StoryDownloadLink href={signedPoaDocumentPath} ref={fileDownloadRef} target="_blank" download={true} />

      <StepTitle>{translate('step5_title')}</StepTitle>
      <UploadDocumentBtn
        text_color={`${colors.darkBorder}`}
        onClick={handleUploadClick}
        data-testid="upload-file-step5"
      >
        {translate('upload_document')} <UploadIcon src={uploadIcon} />
      </UploadDocumentBtn>
      <FileLimitText>{translate('5mb_size_limit')}</FileLimitText>

      {signedPoaDocumentPath !== '' && (
        <FileUploadedText>
          {translate('noc_uploaded_text', { fileName: fileName })}
          <PreviewFileBtn onClick={handleFilePreview} data-testid="preview-btn-step5">
            {translate('preview')}
          </PreviewFileBtn>
          <DeleteFileBtn onClick={() => dispatchSetSignedPoaDocumentPath('')} data-testid="delete-btn-step5">
            {translate('delete')}
          </DeleteFileBtn>
        </FileUploadedText>
      )}

      <ProceedBtn
        onClick={handleConfirmSubmission}
        text_color={`${colors.darkBorder}`}
        data-testid="step5-proceed"
        disabled={signedPoaDocumentPath === ''}
      >
        {translate('proceed_to_submission')} <ArrowRightOutlined />
      </ProceedBtn>
    </StepContainer>
  );
};

export default Step5;
