import { translate } from '@app/components/IntlGlobalProvider';
import { uploadFile } from '@app/utils/apiUtils';
import { COPYRIGHT_PROTECTION_STEPS, SELLER, STORIES_STATES } from '@app/utils/constants';
import { getMutationResponse, getQueryResponse } from '@app/utils/graphqlUtils';
import history from '@app/utils/history';
import { GET_SIGNED_URL } from '@app/utils/queries';
import routeConstants from '@app/utils/routeConstants';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get } from 'lodash-es';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_SELF_ATTESTED_STORY } from '../DashboardContainerPage/NewSubmissionPage/queries';

import {
  BEGIN_SUBMISSION_PROCESS,
  CONFIRM_STORY_SUBMISSION,
  COPYRIGHT_SUBMISSION_STATUS,
  GENERATE_STORY_PDF,
  GET_COPYRIGHT_AUTHOR_VERIFICATION_DETAILS,
  GET_COPYRIGHT_PRESUBMISSION_DETAILS,
  GET_COPYRIGHT_PROTECTION_DETAILS,
  GET_COPYRIGHT_PROTECTION_REGISTRATION_DEFAILS,
  GET_NOC_FILE_PATH_DATA,
  GET_SELF_ATTESTATION_DETAILS,
  GET_SWA_QR_CODE_FILE_PATH_DATA,
  GET_SWA_REGISTRATION_DETAILS,
  UPDATE_AUTHOR_VERIFICATION_DETAILS,
  UPDATE_COPYRIGHT_PROCESS_STEP,
  UPDATE_COPYRIGHT_PROTECTION_REGISTRATION_DETAILS,
  UPDATE_COPYRIGHT_PROTECTION_STRATEGY,
  UPDATE_SWA_REGISTRATION_DETAILS,
  UPLOAD_SIGNED_POA_DOCUMENT,
  UPLOAD_SIGNED_STORY_DOCUMENT,
  SET_STATE_COPYRIGHT_PENDING,
  SEND_MAIL,
  GET_COPYRIGHT_PAYMENT_STATUS,
  COPYRIGHT_WITH_TICKETS,
  COPYRIGHT_PAYMENT,
  GOVT_ID_FILE_NAME,
  GET_COPYRIGHT_GOVT_DETAILS,
  GET_POA_DOCUMENT,
  GET_NOC_DOCUMENT
} from './queries';

import {
  requestBeginSubmissionProcess,
  requestGetSubmissionStatus,
  requestGetCopyrightAuthorVerificationDetails,
  successBeginSubmissionProcess,
  successGetSubmissionStatus,
  successGetCopyrightAuthorVerificationDetails,
  failureBeginSubmissionProcess,
  failureGetSubmissionStatus,
  failureGetCopyrightAuthorVerificationDetails,
  requestUpdateAuhtorVerificationDetails,
  successUpdateAuthorVerificationDetails,
  failureUpdateAuthorVerificationDetails,
  failureUploadNocFile,
  successUploadNocFile,
  requestUploadNocFile,
  setNocFileName,
  successGetSwaRegistrationDetails,
  failureGetSwaRegistrationDetails,
  requestGetSwaRegistrationDetails,
  successUpdateSwaRegistrationDetails,
  failureUpdateSwaRegistrationDetails,
  successUploadSwaQrCode,
  failureUploadSwaQrCode,
  requestUpdateSwaRegistrationDetails,
  requestUploadSwaQrCode,
  successUpdateCopyrightSelfAttestationDetails,
  failureUpdateCopyrightSelfAttestationDetails,
  requestUpdateCopyrightSelfAttestationDetails,
  setCopyrightCurrentStep,
  successUpdateCopyrightRegistrationDetails,
  failureUpdateCopyrightRegistrationDetails,
  requestUpdateCopyrightRegistrationDetails,
  successGetCopyrightProtectionDetails,
  failureGetCopyrightProtectionDetails,
  requestGetCopyrightProtectionDetails,
  successUpdateCopyrightProtectionStrategy,
  failureUpdateCopyrightProtectionStrategy,
  requestUpdateCopyrightProtectionStrategy,
  successGetCopyrightRegistrationDetails,
  failureGetCopyrightRegistrationDetails,
  requestGetCopyrightRegistrationDetails,
  successGetCopyrightPreSubmissionDetails,
  failureGetCopyrightPreSubmissionDetails,
  requestGetCopyrightPreSubmissionDetails,
  successConfirmStorySubmission,
  failureConfirmStorySubmission,
  requestConfirmStorySubmission,
  successUploadSignedStoryDocument,
  failureUploadSignedStoryDocument,
  successUploadSignedPoaDocument,
  failureUploadSignedPoaDocument,
  requestUploadSignedStoryDocument,
  requestUploadSignedPoaDocument,
  setSignedPoaFileName,
  setSignedStoryFileName,
  successStateChange,
  failureStateChange,
  requestStateChange,
  successGenerateStoryPdf,
  failureGenerateStoryPdf,
  requestGenerateStoryPdf,
  successGetSelfAttestationDetails,
  failureGetSelfAttestationDetails,
  requestGetSelfAttestationDetails,
  resetSwaRegistrationDetails,
  successGetHasSelfAttestedStory,
  failureGetHasSelfAttestedStory,
  requestGetHasSelfAttestedStory,
  successGetCopyrightPaymentStatus,
  failureGetCopyrightPaymentStatus,
  requestGetCopyrightPaymentStatus,
  requestCopyrightWithTickets,
  successCopyrightWithTickets,
  failureCopyrightWithTickets,
  failureGeneratePaymentLink,
  requestGeneratePaymentLink,
  successGeneratePaymentLink,
  failureUploadGovtId,
  successUploadGovtId,
  requestUploadGovtId,
  successGetGovtDetails,
  failureGetGovtDetails,
  requestGetGovtDetails,
  requestPreviewFile,
  successPreviewFile,
  failurePreviewFile,
  setSignedSwaQrCodeFilePath,
  requestGetPoaSignedDocument,
  failureGetPoaSignedDocumentPath,
  successGetPoaSignedDocumentPath,
  failureGetNocSignedDocumentPath,
  successGetNocSignedDocumentPath,
  requestGetNocSignedDocument
} from './reducer';

export function* getCopyrightSubmissionStatus(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  try {
    const { ok, data, error } = yield call(getQueryResponse, COPYRIGHT_SUBMISSION_STATUS, { sqInput: payload });

    if (ok) {
      const copyrightSubmissionStatus = get(data, 'copyrightSubmissionStatus');
      yield put(successGetSubmissionStatus(copyrightSubmissionStatus));
    } else {
      yield put(failureGetSubmissionStatus(error));
    }
  } catch (err) {
    yield put(failureGetSubmissionStatus(err));
  }
}

export function* beginSubmissionProcess(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  try {
    const { ok, error } = yield call(getMutationResponse, BEGIN_SUBMISSION_PROCESS, { sqInput: payload });

    if (ok) {
      yield put(successBeginSubmissionProcess());

      if (action.payload.proceedToNextStep) {
        history.push(`${routeConstants.copyrightContainer.route}?page=declaration`);
      } else {
        history.push(`${routeConstants.dashboard.route}`);
      }
    } else {
      yield put(failureBeginSubmissionProcess(error));
    }
  } catch (err) {
    yield put(failureBeginSubmissionProcess(err));
  }
}

export function* getAuthorVerificationDetails(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_COPYRIGHT_AUTHOR_VERIFICATION_DETAILS, {
      sqInput: payload
    });

    if (ok) {
      const copyrightAuthorVerificationDetails = get(data, 'copyrightAuthorVerificationDetails', {});

      yield put(successGetCopyrightAuthorVerificationDetails(copyrightAuthorVerificationDetails));
    } else {
      yield put(failureGetCopyrightAuthorVerificationDetails(error));
    }
  } catch (err) {
    yield put(failureGetCopyrightAuthorVerificationDetails(err));
  }
}

export function* updateAuthorVerificationDetails(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  try {
    const { ok, error } = yield call(getMutationResponse, UPDATE_AUTHOR_VERIFICATION_DETAILS, {
      input: payload
    });

    if (ok) {
      yield put(successUpdateAuthorVerificationDetails());

      if (action.payload.proceedToNextStep) {
        history.push(`${routeConstants.copyrightContainer.route}?page=swa`);
      } else {
        history.push(routeConstants.dashboard.route);
      }
    } else {
      yield put(failureUpdateAuthorVerificationDetails(error));
    }
  } catch (err) {
    yield put(failureUpdateAuthorVerificationDetails(err));
  }
}

export function* uploadNocFile(action: any): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, GET_NOC_FILE_PATH_DATA, {
      input: {
        storyId: action.payload.storyId
      }
    });

    const fileName = ok ? `${data.uploadNocDocument.fileName}${data.uploadNocDocument.extensions[0]}` : '';

    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: SELLER,
          method: 'PUT',
          rootFolder: data.uploadNocDocument.filepath,
          fileName: fileName
        }
      };

      yield put(setNocFileName(`${data.uploadNocDocument.filepath}/${fileName}`));

      const file = action.payload.file;

      const response = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (response.ok) {
        yield call(uploadFile, response.data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        const signedUrlGetPayload = {
          urlinput: {
            user: SELLER,
            method: 'GET',
            rootFolder: data.uploadNocDocument.filepath,
            fileName: fileName
          }
        };

        const response2 = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlGetPayload);

        if (response2.ok) {
          const signedUrlPayload = get(response2.data, 'signedUrl', {});

          const fileUrl = get(signedUrlPayload, 'signedUrl', '');

          yield put(successUploadNocFile(fileUrl));
        } else {
          yield put(failureUploadNocFile(get(error, 'message', 'something_went_wrong')));
        }
      } else {
        yield put(failureUploadNocFile(get(response.error, 'message', 'something_went_wrong')));
      }
    } else {
      yield put(failureUploadNocFile(get(error, 'message', 'something_went_wrong')));
    }
  } catch (err) {
    yield put(failureUploadNocFile(err));
  }
}

export function* getSwaRegistrationDetails(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_SWA_REGISTRATION_DETAILS, {
      sqInput: payload
    });

    if (ok) {
      const copyrightSWARegistrationDetails = get(data, 'copyrightSwaRegistrationDetails', {});

      const filePath = copyrightSWARegistrationDetails.registrationDetails.registrationQrCode;

      if (filePath) {
        let fileName = filePath.split('/');

        fileName = fileName[fileName.length - 1];

        let rootFolder = filePath.split('/');

        rootFolder.pop();

        rootFolder = rootFolder.join('/');

        const signedUrlPayload = {
          urlinput: {
            user: SELLER,
            method: 'GET',
            rootFolder: rootFolder,
            fileName: fileName
          }
        };

        const response = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

        const signedFilePath = response.ok ? response.data.signedUrl.signedUrl : '';

        yield put(setSignedSwaQrCodeFilePath(signedFilePath));
      }

      yield put(successGetSwaRegistrationDetails(copyrightSWARegistrationDetails));
      yield put(successUploadSwaQrCode(copyrightSWARegistrationDetails.registrationDetails.registrationQrCode));
    } else {
      yield put(failureGetSwaRegistrationDetails(error));
    }
  } catch (err) {
    yield put(failureGetSwaRegistrationDetails(err));
  }
}

export function* updateSwaRegistrationDetails(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload.swaDetails
  };

  try {
    const { ok, error } = yield call(getMutationResponse, UPDATE_SWA_REGISTRATION_DETAILS, {
      input: payload
    });

    if (ok) {
      if (action.payload.swaDetails.proceedToNextStep) {
        yield call(getMutationResponse, UPDATE_COPYRIGHT_PROTECTION_STRATEGY, {
          input: action.payload.strategyDetails
        });
      }

      yield put(successUpdateSwaRegistrationDetails());

      if (action.payload.swaDetails.proceedToNextStep) {
        yield put(resetSwaRegistrationDetails());
        history.push(`${routeConstants.copyrightContainer.route}?page=copyright_protection`);
      } else {
        history.push(routeConstants.dashboard.route);
      }
    } else {
      yield put(failureUpdateSwaRegistrationDetails(error));
    }
  } catch (err) {
    yield put(failureUpdateSwaRegistrationDetails(err));
  }
}

export function* uploadSwaQRCodeFile(action: any): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, GET_SWA_QR_CODE_FILE_PATH_DATA, {
      input: {
        storyId: action.payload.storyId
      }
    });

    const fileName = ok
      ? `${data.uploadSwaqrCode.fileName}${
          action.payload.file.type === 'application/pdf'
            ? data.uploadSwaqrCode.extensions[0]
            : data.uploadSwaqrCode.extensions[1]
        }`
      : '';

    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: SELLER,
          method: 'PUT',
          rootFolder: data.uploadSwaqrCode.filepath,
          fileName: fileName
        }
      };

      const file = action.payload.file;

      const response = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (response.ok) {
        yield call(uploadFile, response.data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        yield put(successUploadSwaQrCode(`${data.uploadSwaqrCode.filepath}/${fileName}`));
        message.success(translate('swa_doc_uploaded'));
      } else {
        yield put(failureUploadSwaQrCode(get(response.error, 'message', 'something_went_wrong')));
      }
    } else {
      yield put(failureUploadSwaQrCode(get(error, 'message', 'something_went_wrong')));
    }
  } catch (err) {
    yield put(failureUploadSwaQrCode(err));
  }
}

export function* getCopyrightRegistrationDetails(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_COPYRIGHT_PROTECTION_REGISTRATION_DEFAILS, {
      input: payload
    });

    if (ok) {
      const copyrightRegistrationDetails = get(data, 'copyrightRegistrationDetails', {});

      yield put(successGetCopyrightRegistrationDetails(copyrightRegistrationDetails));
    } else {
      yield put(failureGetCopyrightRegistrationDetails(error));
    }
  } catch (err) {
    yield put(failureGetCopyrightRegistrationDetails(err));
  }
}

export function* updateCopyrightRegistrationDetails(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  try {
    const { ok, error } = yield call(getMutationResponse, UPDATE_COPYRIGHT_PROTECTION_REGISTRATION_DETAILS, {
      input: payload
    });

    if (ok) {
      yield put(successUpdateCopyrightRegistrationDetails());

      if (action.payload.proceedToPreSubmission) {
        history.push(`${routeConstants.copyrightContainer.route}?page=submission`);
      } else {
        history.push(`${routeConstants.dashboard.route}?activePage=submissions`);
      }
    } else {
      yield put(failureUpdateCopyrightRegistrationDetails(error));
    }
  } catch (err) {
    yield put(failureUpdateCopyrightRegistrationDetails(err));
  }
}

export function* updateCopyrightSelfAttestationDetails(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload.data
  };

  try {
    const { ok, error } = yield call(getMutationResponse, UPDATE_COPYRIGHT_PROCESS_STEP, {
      input: payload
    });

    if (ok) {
      yield put(successUpdateCopyrightSelfAttestationDetails());
      if (action.payload.data.mode === 'PROCEED') {
        if (action.payload.nextStep === COPYRIGHT_PROTECTION_STEPS.DONE) {
          history.push(`${routeConstants.copyrightContainer.route}?page=submission`);
        } else {
          yield put(setCopyrightCurrentStep(action.payload.nextStep));
        }
      } else {
        history.push(`${routeConstants.dashboard.route}`);
      }
    } else {
      yield put(failureUpdateCopyrightSelfAttestationDetails(error));
    }
  } catch (err) {
    yield put(failureUpdateCopyrightSelfAttestationDetails(err));
  }
}

export function* getCopyrightProtectionDetails(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_COPYRIGHT_PROTECTION_DETAILS, {
      sqInput: payload
    });

    if (ok) {
      const copyrightProtectionDetails = get(data, 'copyrightProtectionDetails', {});

      yield put(successGetCopyrightProtectionDetails(copyrightProtectionDetails));
    } else {
      yield put(failureGetCopyrightProtectionDetails(error));
    }
  } catch (err) {
    yield put(failureGetCopyrightProtectionDetails(err));
  }
}

export function* updateCopyrightProtectionStrategy(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload.data
  };

  try {
    const { ok, error } = yield call(getMutationResponse, UPDATE_COPYRIGHT_PROTECTION_STRATEGY, {
      input: payload
    });

    if (ok) {
      yield put(successUpdateCopyrightProtectionStrategy());

      if (action.payload.data.proceedToNextStep) {
        history.push(`${routeConstants.copyrightContainer.route}?page=submission`);
      } else {
        if (action.payload.redirectToDashboard) {
          history.push(routeConstants.dashboard.route);
        }
      }
    } else {
      yield put(failureUpdateCopyrightProtectionStrategy(error));
    }
  } catch (err) {
    yield put(failureUpdateCopyrightProtectionStrategy(err));
  }
}

export function* getCopyrightPreSubmissionDetails(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_COPYRIGHT_PRESUBMISSION_DETAILS, {
      sqInput: payload
    });

    if (ok) {
      const copyrightPreSubmissionDetails = get(data, 'copyrightPreSubmissionDetails', {});

      yield put(successGetCopyrightPreSubmissionDetails(copyrightPreSubmissionDetails));
    } else {
      yield put(failureGetCopyrightPreSubmissionDetails(error));
    }
  } catch (err) {
    yield put(failureGetCopyrightPreSubmissionDetails(err));
  }
}

export function* confirmStorySubmission(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  delete payload.type;

  try {
    yield call(getMutationResponse, CONFIRM_STORY_SUBMISSION, {
      sqInput: payload
    });

    const { ok, error } = yield call(getMutationResponse, SEND_MAIL, {
      input: { ...action.payload }
    });

    if (ok) {
      yield put(successConfirmStorySubmission());
      history.push(`${routeConstants.dashboard.route}?activeTab=submissions`);
    } else {
      yield put(failureConfirmStorySubmission(error));
    }
  } catch (err) {
    yield put(failureConfirmStorySubmission(err));
  }
}

export function* uploadSignedStoryDocument(action: any): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, UPLOAD_SIGNED_STORY_DOCUMENT, {
      input: {
        storyId: action.payload.storyId
      }
    });

    const fileName = ok
      ? `${data.uploadSignedStoryDocument.fileName}${data.uploadSignedStoryDocument.extensions[0]}`
      : '';

    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: SELLER,
          method: 'PUT',
          rootFolder: data.uploadSignedStoryDocument.filepath,
          fileName: fileName
        }
      };

      yield put(setSignedStoryFileName(`${data.uploadSignedStoryDocument.filepath}/${fileName}`));

      const file = action.payload.file;

      const response = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (response.ok) {
        yield call(uploadFile, response.data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        const signedUrlGetPayload = {
          urlinput: {
            user: SELLER,
            method: 'GET',
            rootFolder: data.uploadSignedStoryDocument.filepath,
            fileName: fileName
          }
        };

        const response2 = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlGetPayload);

        if (response2.ok) {
          const signedUrlPayload = get(response2.data, 'signedUrl', {});

          const fileUrl = get(signedUrlPayload, 'signedUrl', '');

          yield put(successUploadSignedStoryDocument(fileUrl));
        } else {
          yield put(failureUploadSignedStoryDocument(get(error, 'message', 'something_went_wrong')));
        }
      } else {
        yield put(failureUploadSignedStoryDocument(get(response.error, 'message', 'something_went_wrong')));
      }
    } else {
      yield put(failureUploadSignedStoryDocument(get(error, 'message', 'something_went_wrong')));
    }
  } catch (err) {
    yield put(failureUploadSignedStoryDocument(err));
  }
}

export function* uploadSignedPOADocument(action: any): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, UPLOAD_SIGNED_POA_DOCUMENT, {
      input: {
        storyId: action.payload.storyId
      }
    });

    const fileName = ok ? `${data.uploadSignedPoaDocument.fileName}${data.uploadSignedPoaDocument.extensions[0]}` : '';

    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: SELLER,
          method: 'PUT',
          rootFolder: data.uploadSignedPoaDocument.filepath,
          fileName: fileName
        }
      };

      yield put(setSignedPoaFileName(`${data.uploadSignedPoaDocument.filepath}/${fileName}`));

      const file = action.payload.file;

      const response = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (response.ok) {
        yield call(uploadFile, response.data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        const signedUrlGetPayload = {
          urlinput: {
            user: SELLER,
            method: 'GET',
            rootFolder: data.uploadSignedPoaDocument.filepath,
            fileName: fileName
          }
        };

        const response2 = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlGetPayload);

        if (response2.ok) {
          const signedUrlPayload = get(response2.data, 'signedUrl', {});

          const fileUrl = get(signedUrlPayload, 'signedUrl', '');

          yield put(successUploadSignedPoaDocument(fileUrl));
        } else {
          yield put(failureUploadSignedPoaDocument(get(error, 'message', 'something_went_wrong')));
        }
      } else {
        yield put(failureUploadSignedPoaDocument(get(response.error, 'message', 'something_went_wrong')));
      }
    } else {
      yield put(failureUploadSignedPoaDocument(get(error, 'message', 'something_went_wrong')));
    }
  } catch (err) {
    yield put(failureUploadSignedPoaDocument(err));
  }
}

export function* changeStoryState(action: AnyAction): Generator<any, any, any> {
  try {
    const payload: any = {
      storyStateInput: {
        storyId: action.payload.id,
        state: STORIES_STATES.PENDING_APPROVAL
      }
    };

    const { ok, data, error } = yield call(getMutationResponse, SET_STATE_COPYRIGHT_PENDING, payload);

    if (ok) {
      yield put(successStateChange(get(data, 'updateStoryState', {})));
      // history.push('dashboard?activeTab=submissions');
    } else {
      yield put(failureStateChange(error));
    }
  } catch (err) {
    yield put(failureStateChange(err));
  }
}

export function* generateStoryPdf(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  try {
    const { ok, data, error } = yield call(getQueryResponse, GENERATE_STORY_PDF, {
      input: payload
    });

    if (ok) {
      const pdfUrl = get(data, 'generateStoryPdf', '');

      yield put(successGenerateStoryPdf(pdfUrl));
    } else {
      yield put(failureGenerateStoryPdf(error));
    }
  } catch (err) {
    yield put(failureGenerateStoryPdf(err));
  }
}

export function* getSelfAttestationDetails(action: any): Generator<any, any, any> {
  const payload = {
    ...action.payload
  };

  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_SELF_ATTESTATION_DETAILS, {
      input: payload
    });

    if (ok) {
      const selfAttestationDetails = get(data, 'selfAttestationDetails', {});

      // yield put(setCopyrightCurrentStep(selfAttestationDetails.currentStep));
      yield put(successGetSelfAttestationDetails(selfAttestationDetails));
    } else {
      yield put(failureGetSelfAttestationDetails(error));
    }
  } catch (err) {
    yield put(failureGetSelfAttestationDetails(err));
  }
}

export function* getHasSelfAttestedStory(): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_SELF_ATTESTED_STORY, {});

    if (ok) {
      const hasSelfAttestedStory = get(data, 'hasSelfAttestedStory', false);

      yield put(successGetHasSelfAttestedStory(hasSelfAttestedStory));
    } else {
      yield put(failureGetHasSelfAttestedStory(error));
    }
  } catch (err) {
    yield put(failureGetHasSelfAttestedStory(err));
  }
}

export function* getCopyrightPaymentStatus(action: AnyAction): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_COPYRIGHT_PAYMENT_STATUS, {
      input: {
        sellerId: action.payload.sellerId,
        storyId: action.payload.storyId,
        copyrightID: action.payload.copyrightID
      }
    });

    if (ok) {
      const copyrightPaymentStatus = get(data, 'copyrightPaymentStatus', false);

      yield put(successGetCopyrightPaymentStatus(copyrightPaymentStatus));
    } else {
      yield put(failureGetCopyrightPaymentStatus(error));
    }
  } catch (err) {
    yield put(failureGetCopyrightPaymentStatus(err));
  }
}

export function* copyrightWithTickets(action: AnyAction): Generator<any, any, any> {
  try {
    const { ok, error } = yield call(getMutationResponse, COPYRIGHT_WITH_TICKETS, {
      input: {
        storyId: action.payload.storyId,
        copyrightId: action.payload.copyrightId,
        reason: 'Payment for copyright with 500 Spot Tickets'
      }
    });

    if (ok) {
      yield put(successCopyrightWithTickets());

      const response = yield call(getQueryResponse, GET_COPYRIGHT_PAYMENT_STATUS, {
        input: {
          sellerId: action.payload.sellerId,
          storyId: action.payload.storyId,
          copyrightID: action.payload.copyrightId
        }
      });

      if (response.ok) {
        const copyrightPaymentStatus = get(response.data, 'copyrightPaymentStatus', false);

        yield put(successGetCopyrightPaymentStatus(copyrightPaymentStatus));

        history.push(
          `${routeConstants.copyrightContainer.route}?page=submission&storyId=${action.payload.storyId}&mode=spot_ticket`
        );
      } else {
        yield put(failureGetCopyrightPaymentStatus(response.error));
      }
    } else {
      yield put(failureCopyrightWithTickets(error));
    }
  } catch (err) {
    yield put(failureCopyrightWithTickets(err));
  }
}

export function* generateCopyrightPaymentLink(action: AnyAction): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getMutationResponse, COPYRIGHT_PAYMENT, {
      amount: action.payload.amount,
      tickets: action.payload.tickets,
      storyId: action.payload.storyId,
      reason: action.payload.reason,
      notes: action.payload.notes
    });

    if (ok) {
      const generatePaymentLink = get(data, 'generatePaymentLink', {});

      const url = get(generatePaymentLink, 'url', '');

      window.open(url, '_self');

      yield put(successGeneratePaymentLink());
    } else {
      yield put(failureGeneratePaymentLink(error));
    }
  } catch (err) {
    yield put(failureGeneratePaymentLink(err));
  }
}

export function* uploadGovtId(action: any): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getQueryResponse, GOVT_ID_FILE_NAME, {
      input: {
        storyId: action.payload.storyId
      }
    });

    const fileName = ok
      ? `${data.uploadGovtDoc.fileName}${
          action.payload.file.type === 'application/pdf'
            ? data.uploadGovtDoc.extensions[0]
            : data.uploadGovtDoc.extensions[1]
        }`
      : '';

    if (ok) {
      const signedUrlPayload = {
        urlinput: {
          user: SELLER,
          method: 'PUT',
          rootFolder: data.uploadGovtDoc.filepath,
          fileName: fileName
        }
      };

      const file = action.payload.file;

      const response = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

      if (response.ok) {
        yield call(uploadFile, response.data.signedUrl.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          body: file
        });

        yield put(successUploadGovtId({ filePath: `${data.uploadGovtDoc.filepath}/${fileName}`, fileName: fileName }));
        message.success(translate('govt_id_uploaded'));
      } else {
        yield put(failureUploadGovtId(get(response.error, 'message', 'something_went_wrong')));
      }
    } else {
      yield put(failureUploadGovtId(get(error, 'message', 'something_went_wrong')));
    }
  } catch (err) {
    yield put(failureUploadGovtId(err));
  }
}

export function* getGovtDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const { ok, data, error } = yield call(getQueryResponse, GET_COPYRIGHT_GOVT_DETAILS, {
      input: {
        sellerId: action.payload.sellerId,
        storyId: action.payload.storyId
      }
    });

    if (ok) {
      const copyrightGovtDocumentDetails = get(data, 'copyrightGovtDocumentDetails', {});

      const doc = get(copyrightGovtDocumentDetails, 'doc', {});

      const filePath = get(doc, 'filepath', '');

      let fileName = '';
      let signedFilePath = '';

      if (filePath) {
        fileName = filePath.split('/');

        fileName = fileName[fileName.length - 1];

        let rootFolder = filePath.split('/');

        rootFolder.pop();

        rootFolder = rootFolder.join('/');

        const signedUrlPayload = {
          urlinput: {
            user: SELLER,
            method: 'GET',
            rootFolder: rootFolder,
            fileName: fileName
          }
        };

        const response = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

        signedFilePath = response.ok ? response.data.signedUrl.signedUrl : '';
      }

      yield put(
        successGetGovtDetails({ filePath: filePath, fileName: fileName, signedGovtIdFilePath: signedFilePath })
      );
    } else {
      yield put(failureGetGovtDetails(error));
    }
  } catch (err) {
    yield put(failureGetGovtDetails(err));
  }
}

export function* previewFile(action: AnyAction): Generator<any, any, any> {
  try {
    const signedUrlPayload = {
      urlinput: {
        user: SELLER,
        method: 'GET',
        rootFolder: action.payload.rootFolder,
        fileName: action.payload.fileName
      }
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_SIGNED_URL, signedUrlPayload);

    if (ok) {
      const signedUrlPayload = get(data, 'signedUrl', {});

      const signedUrl = get(signedUrlPayload, 'signedUrl', '');

      window.open(signedUrl, '_blank');

      yield put(successPreviewFile());
    } else {
      yield put(failurePreviewFile(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failurePreviewFile(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export function* getPoaSignedDocument(action: AnyAction): Generator<any, any, any> {
  try {
    const poaPayload = {
      sellerId: action.payload.sellerId,
      storyId: action.payload.storyId
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_POA_DOCUMENT, poaPayload);

    if (ok) {
      const poaSignedDocument = get(data, 'getSellerPoaDocument', '');

      yield put(successGetPoaSignedDocumentPath(poaSignedDocument));
    } else {
      yield put(failureGetPoaSignedDocumentPath(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureGetPoaSignedDocumentPath(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export function* getNocSignedDocument(action: AnyAction): Generator<any, any, any> {
  try {
    const poaPayload = {
      sellerId: action.payload.sellerId
    };

    const { ok, data, error } = yield call(getQueryResponse, GET_NOC_DOCUMENT, poaPayload);

    if (ok) {
      const nocSignedDocument = get(data, 'getSellerNocDocument', '');

      yield put(successGetNocSignedDocumentPath(nocSignedDocument));
    } else {
      yield put(failureGetNocSignedDocumentPath(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureGetNocSignedDocumentPath(get(e, 'message', 'something_went_wrong')));
    message.error(get(e, 'message', 'something_went_wrong'));
  }
}

export default function* copyrightContainerSaga() {
  yield all([
    takeLatest(requestGetSubmissionStatus.toString(), getCopyrightSubmissionStatus),
    takeLatest(requestBeginSubmissionProcess.toString(), beginSubmissionProcess),
    takeLatest(requestGetCopyrightAuthorVerificationDetails.toString(), getAuthorVerificationDetails),
    takeLatest(requestUpdateAuhtorVerificationDetails.toString(), updateAuthorVerificationDetails),
    takeLatest(requestUploadNocFile.toString(), uploadNocFile),
    takeLatest(requestGetSwaRegistrationDetails.toString(), getSwaRegistrationDetails),
    takeLatest(requestUpdateSwaRegistrationDetails.toString(), updateSwaRegistrationDetails),
    takeLatest(requestUploadSwaQrCode.toString(), uploadSwaQRCodeFile),
    takeLatest(requestUpdateCopyrightRegistrationDetails.toString(), updateCopyrightRegistrationDetails),
    takeLatest(requestGetCopyrightRegistrationDetails.toString(), getCopyrightRegistrationDetails),
    takeLatest(requestUpdateCopyrightSelfAttestationDetails.toString(), updateCopyrightSelfAttestationDetails),
    takeLatest(requestGetCopyrightProtectionDetails.toString(), getCopyrightProtectionDetails),
    takeLatest(requestUpdateCopyrightProtectionStrategy.toString(), updateCopyrightProtectionStrategy),
    takeLatest(requestGetCopyrightPreSubmissionDetails.toString(), getCopyrightPreSubmissionDetails),
    takeLatest(requestConfirmStorySubmission.toString(), confirmStorySubmission),
    takeLatest(requestUploadSignedStoryDocument.toString(), uploadSignedStoryDocument),
    takeLatest(requestUploadSignedPoaDocument.toString(), uploadSignedPOADocument),
    takeLatest(requestStateChange.toString(), changeStoryState),
    takeLatest(requestGenerateStoryPdf.toString(), generateStoryPdf),
    takeLatest(requestGetSelfAttestationDetails.toString(), getSelfAttestationDetails),
    takeLatest(requestGetHasSelfAttestedStory.toString(), getHasSelfAttestedStory),
    takeLatest(requestGetCopyrightPaymentStatus.toString(), getCopyrightPaymentStatus),
    takeLatest(requestCopyrightWithTickets.toString(), copyrightWithTickets),
    takeLatest(requestGeneratePaymentLink.toString(), generateCopyrightPaymentLink),
    takeLatest(requestUploadGovtId.toString(), uploadGovtId),
    takeLatest(requestGetGovtDetails.toString(), getGovtDetails),
    takeLatest(requestPreviewFile.toString(), previewFile),
    takeLatest(requestGetPoaSignedDocument.toString(), getPoaSignedDocument),
    takeLatest(requestGetNocSignedDocument.toString(), getNocSignedDocument)
  ]);
}

export const copyrightContainerSagaArr = [
  takeLatest(requestGetSubmissionStatus.toString(), getCopyrightSubmissionStatus),
  takeLatest(requestBeginSubmissionProcess.toString(), beginSubmissionProcess),
  takeLatest(requestGetCopyrightAuthorVerificationDetails.toString(), getAuthorVerificationDetails),
  takeLatest(requestUpdateAuhtorVerificationDetails.toString(), updateAuthorVerificationDetails),
  takeLatest(requestUploadNocFile.toString(), uploadNocFile),
  takeLatest(requestGetSwaRegistrationDetails.toString(), getSwaRegistrationDetails),
  takeLatest(requestUpdateSwaRegistrationDetails.toString(), updateSwaRegistrationDetails),
  takeLatest(requestUploadSwaQrCode.toString(), uploadSwaQRCodeFile),
  takeLatest(requestUpdateCopyrightRegistrationDetails.toString(), updateCopyrightRegistrationDetails),
  takeLatest(requestGetCopyrightRegistrationDetails.toString(), getCopyrightRegistrationDetails),
  takeLatest(requestUpdateCopyrightSelfAttestationDetails.toString(), updateCopyrightSelfAttestationDetails),
  takeLatest(requestGetCopyrightProtectionDetails.toString(), getCopyrightProtectionDetails),
  takeLatest(requestUpdateCopyrightProtectionStrategy.toString(), updateCopyrightProtectionStrategy),
  takeLatest(requestGetCopyrightPreSubmissionDetails.toString(), getCopyrightPreSubmissionDetails),
  takeLatest(requestConfirmStorySubmission.toString(), confirmStorySubmission),
  takeLatest(requestUploadSignedStoryDocument.toString(), uploadSignedStoryDocument),
  takeLatest(requestUploadSignedPoaDocument.toString(), uploadSignedPOADocument),
  takeLatest(requestStateChange.toString(), changeStoryState),
  takeLatest(requestGenerateStoryPdf.toString(), generateStoryPdf),
  takeLatest(requestGetSelfAttestationDetails.toString(), getSelfAttestationDetails),
  takeLatest(requestGetHasSelfAttestedStory.toString(), getHasSelfAttestedStory),
  takeLatest(requestGetCopyrightPaymentStatus.toString(), getCopyrightPaymentStatus),
  takeLatest(requestCopyrightWithTickets.toString(), copyrightWithTickets),
  takeLatest(requestGeneratePaymentLink.toString(), generateCopyrightPaymentLink),
  takeLatest(requestUploadGovtId.toString(), uploadGovtId),
  takeLatest(requestGetGovtDetails.toString(), getGovtDetails),
  takeLatest(requestPreviewFile.toString(), previewFile),
  takeLatest(requestGetPoaSignedDocument.toString(), getPoaSignedDocument),
  takeLatest(requestGetNocSignedDocument.toString(), getNocSignedDocument)
];
