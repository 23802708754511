import { getMutationResponse } from '@app/utils/graphqlUtils';

import { AnyAction } from '@reduxjs/toolkit';
import { get } from 'lodash-es';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  requestGenerateSpotTicketsLink,
  successGenerateSpotTicketsLink,
  failureGenerateSpotTicketsLink
} from './reducer';
import { PAYMENT } from '@app/utils/queries';

export function* generateAddSpotTicketsLink(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      amount: 0,
      tickets: Number(action.payload.tickets),
      reason: 'SELLER_ADD_TICKETS',
      notes: `adding ${action.payload.tickets} tickets`
    };
    const { ok, data, error } = yield call(getMutationResponse, PAYMENT, payload);

    if (ok) {
      const generatePaymentLink = data.generatePaymentLink;

      window.open(generatePaymentLink.url, '_self');

      yield put(successGenerateSpotTicketsLink());
    } else {
      yield put(failureGenerateSpotTicketsLink(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureGenerateSpotTicketsLink(get(e, 'message', 'something_went_wrong')));
  }
}

export default function* addSpotTicketsContainerSaga() {
  yield all([takeLatest(requestGenerateSpotTicketsLink.toString(), generateAddSpotTicketsLink)]);
}

export const addSpotTicketsSagaArr = [
  takeLatest(requestGenerateSpotTicketsLink.toString(), generateAddSpotTicketsLink)
];
