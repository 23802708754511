import { gql } from 'apollo-boost';

export const GET_OFFER_HISTORY = gql`
  query OfferDetailsHistory($input: OfferHistoryInput!, $pagination: OffersPagination!) {
    offerDetailsHistory(input: $input, pagination: $pagination) {
      transactions {
        id
        amount
        currency
        notes
        offerEntity {
          id
          placedBy
          firstName
          lastName
          profilePic
          profileBanner
          designation
          companyName
          email
        }
        offerState
        createdAt
        updatedAt
        deletedAt
      }
      offerState
      offerId
      placedOn
      expiresOn
      daysLeft
      total
    }
  }
`;

export const COUNTER_OFFER = gql`
  mutation CounterOffer($input: PlaceOfferInput!) {
    placeOfferOnStory(input: $input)
  }
`;

export const CONCLUDE_OFFER = gql`
  mutation ConcludeOffer($input: ConcludeOfferInput!) {
    concludeOfferOnStory(input: $input)
  }
`;

export const GET_CONVERSATION_HISTORY = gql`
  query conversationHistory($input: ConversationHistoryInput!, $pagination: ConversationPagination!) {
    conversationHistory(input: $input, pagination: $pagination) {
      conversationDetails {
        type
        state
        message
        userType
      }
      seller {
        id
      }
      buyer {
        id
        firstName
        lastName
      }
      total
    }
  }
`;

export const SEND_REQUEST_MESSAGE = gql`
  mutation sendMessage($input: ConversationInput!) {
    sendMessage(input: $input)
  }
`;
