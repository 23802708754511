import { createSlice } from '@reduxjs/toolkit';
import { prepare } from '@app/utils';
import { get } from 'lodash-es';
import { COPYRIGHT_PROTECTION_STEPS } from '@app/utils/constants';
import { appendFileSync } from 'fs';

export const initialState = {
  shouldCopyright: false,
  storyTitle: '',
  isStoryCopyrighted: '',
  isStoryRegistered: false,
  isAuthor: '',
  isStoryRegisteredSWA: '',
  nocActive: false,
  nocFilePath: '',
  nocFileName: '',
  swaQrCodeFilePath: '',
  signedSwaFilePath: '',
  submissionStatus: null,
  backPressed: false,
  getSubmissionStatusLoading: false,
  getSubmissionStatusError: null,
  currentStoryId: null,
  copyrightProcessCurrentStep: COPYRIGHT_PROTECTION_STEPS.STEP1,
  signedStoryDocumentPath: '',
  signedStoryDocumentFileName: '',
  signedPoaDocumentPath: '',
  signedPoaDocumentFileName: '',
  storyPdfUrl: '',
  generateStoryPdfLoading: false,
  generateStoryPdfError: null,
  beginSubmissionProcessLoading: false,
  beginSubmissionProcessError: null,
  copyrightAuthorVerificationDetails: null,
  getCopyrightAuthorVerificationDetailsLoading: false,
  getCopyrightAuthorVerificationDetailsError: null,
  updateAuthorVerificationDetailsLoading: false,
  updateAuthorVerificationDetailsError: null,
  swaRegistrationDetails: null,
  getSwaRegistrationDetailsLoading: false,
  getSwaRegistrationDetailsError: null,
  uploadNocFileLoading: false,
  uploadNocFileError: null,
  updateSwaRegistrationDetailsLoading: false,
  updateSwaRegistrationDetailsError: null,
  uploadSwaQrCodeLoading: false,
  uploadSwaQrCodeError: null,
  updateCopyrightRegistrationDetailsLoading: false,
  updateCopyrightRegistrationDetailsError: null,
  updateCopyrightSelfAttestationLoading: false,
  updateCopyrightSelfAttestationError: null,
  copyrightProtectionDetails: null,
  getCopyrightProtectionDetailsLoading: false,
  getCopyrightProtectionDetailsError: null,
  updateCopyrightProtectionStrategyLoading: false,
  updateCopyrightProtectionStrategyError: null,
  copyrightRegistrationDetails: null,
  getCopyrightRegistrationDetailsLoading: false,
  getCopyrightRegistrationDetailsError: null,
  copyrightPreSubmissionDetails: null,
  getCopyrightPreSubmissionDetailsLoading: false,
  getCopyrightPreSubmissionDetailsError: null,
  confirmStorySubmissionLoading: false,
  confirmStorySubmissionError: null,
  uploadSignedStoryDocumentLoading: false,
  uploadSignedStoryDocumentError: null,
  uploadSignedPOADocumentLoading: false,
  uploadSignedPOADocumentError: null,
  stateChangeLoading: false,
  stateChangeData: null,
  stateChangeError: null,
  selfAttestationDetails: null,
  getSelfAttestationDetailsLoading: false,
  getSelfAttestationDetailsError: null,
  hasSelfAttestedStory: false,
  getHasSelfAttestedStoryLoading: false,
  getHasSelfAttestedStoryError: null,
  copyrightPaymentStatusData: null,
  getCopyrightPaymentStatusLoading: false,
  getCopyrightPaymentStatusError: null,
  copyrightWithTicketsLoading: false,
  copyrightWithTicketsError: null,
  generatePaymentLinkLoading: false,
  generatePaymentLinkError: null,
  uploadGovtIdLoading: false,
  uploadGovtIdError: null,
  govtIdFileName: '',
  govtIdFilePath: '',
  signedGovtIdFilePath: '',
  getGovtDetailsLoading: false,
  getGovtDetailsError: null,
  previewFileLoading: false,
  previewFileError: null,
  poaSignedDocumentPath: '',
  getPoaSignedDocumentPathLoading: false,
  getPoaSignedDocumentPathError: null,
  nocSignedDocumentPath: '',
  getNocSignedDocumentPathLoading: false,
  getNocSignedDocumentPathError: null
};

const copyrightContainerSlice = createSlice({
  name: 'copyrightContainer',
  initialState,
  reducers: {
    requestGetSubmissionStatus: {
      reducer: (state) => {
        state.getSubmissionStatusLoading = true;
      },
      prepare
    },
    requestBeginSubmissionProcess: {
      reducer: (state) => {
        state.beginSubmissionProcessLoading = true;
      },
      prepare
    },
    requestGetCopyrightAuthorVerificationDetails: {
      reducer: (state) => {
        state.getCopyrightAuthorVerificationDetailsLoading = true;
      },
      prepare
    },
    requestUpdateAuhtorVerificationDetails: {
      reducer: (state) => {
        state.updateAuthorVerificationDetailsLoading = true;
      },
      prepare
    },
    requestUploadNocFile: {
      reducer: (state) => {
        state.uploadNocFileLoading = true;
      },
      prepare
    },
    requestGetSwaRegistrationDetails: {
      reducer: (state) => {
        state.swaRegistrationDetails = null;
        state.getSwaRegistrationDetailsLoading = true;
      },
      prepare
    },
    requestUpdateSwaRegistrationDetails: {
      reducer: (state) => {
        state.updateSwaRegistrationDetailsLoading = true;
      },
      prepare
    },
    requestUploadSwaQrCode: {
      reducer: (state) => {
        state.uploadSwaQrCodeLoading = true;
      },
      prepare
    },
    requestUpdateCopyrightSelfAttestationDetails: {
      reducer: (state) => {
        state.updateCopyrightSelfAttestationLoading = true;
      },
      prepare
    },
    requestUpdateCopyrightRegistrationDetails: {
      reducer: (state) => {
        state.updateCopyrightRegistrationDetailsLoading = true;
      },
      prepare
    },
    requestGetCopyrightProtectionDetails: {
      reducer: (state) => {
        state.getCopyrightProtectionDetailsLoading = true;
      },
      prepare
    },
    requestUpdateCopyrightProtectionStrategy: {
      reducer: (state) => {
        state.updateCopyrightProtectionStrategyLoading = true;
      },
      prepare
    },
    requestGetCopyrightRegistrationDetails: {
      reducer: (state) => {
        state.getCopyrightRegistrationDetailsLoading = true;
      },
      prepare
    },
    requestGetCopyrightPreSubmissionDetails: {
      reducer: (state) => {
        state.getCopyrightPreSubmissionDetailsLoading = true;
      },
      prepare
    },
    requestConfirmStorySubmission: {
      reducer: (state) => {
        state.confirmStorySubmissionLoading = true;
      },
      prepare
    },
    requestUploadSignedStoryDocument: {
      reducer: (state) => {
        state.uploadSignedStoryDocumentLoading = true;
      },
      prepare
    },
    requestUploadSignedPoaDocument: {
      reducer: (state) => {
        state.uploadSignedPOADocumentLoading = true;
      },
      prepare
    },
    requestStateChange: {
      reducer: (state) => {
        state.stateChangeLoading = true;
      },
      prepare
    },
    requestGenerateStoryPdf: {
      reducer: (state) => {
        state.generateStoryPdfLoading = true;
      },
      prepare
    },
    requestGetSelfAttestationDetails: {
      reducer: (state) => {
        state.getSelfAttestationDetailsLoading = true;
      },
      prepare
    },
    requestGetHasSelfAttestedStory: {
      reducer: (state) => {
        state.hasSelfAttestedStory = true;
      },
      prepare
    },
    requestGetCopyrightPaymentStatus: {
      reducer: (state) => {
        state.getCopyrightPaymentStatusLoading = true;
      },
      prepare
    },
    requestCopyrightWithTickets: {
      reducer: (state) => {
        state.copyrightWithTicketsLoading = true;
      },
      prepare
    },
    requestGeneratePaymentLink: {
      reducer: (state) => {
        state.generatePaymentLinkLoading = true;
      },
      prepare
    },
    requestUploadGovtId: {
      reducer: (state) => {
        state.uploadGovtIdLoading = true;
      },
      prepare
    },
    requestGetGovtDetails: {
      reducer: (state) => {
        state.getGovtDetailsLoading = true;
      },
      prepare
    },
    requestPreviewFile: {
      reducer: (state) => {
        state.previewFileLoading = true;
      },
      prepare
    },
    requestGetPoaSignedDocument: {
      reducer: (state) => {
        state.getPoaSignedDocumentPathLoading = true;
      },
      prepare
    },
    requestGetNocSignedDocument: {
      reducer: (state) => {
        state.getNocSignedDocumentPathLoading = true;
      },
      prepare
    },
    successGetNocSignedDocumentPath(state, action) {
      state.getNocSignedDocumentPathLoading = false;
      state.nocSignedDocumentPath = action.payload;
    },
    failureGetNocSignedDocumentPath(state, action) {
      state.getNocSignedDocumentPathLoading = false;
      state.getNocSignedDocumentPathError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetPoaSignedDocumentPath(state, action) {
      state.getPoaSignedDocumentPathLoading = false;
      state.poaSignedDocumentPath = action.payload;
    },
    failureGetPoaSignedDocumentPath(state, action) {
      state.getPoaSignedDocumentPathLoading = false;
      state.getPoaSignedDocumentPathError = get(action.payload, 'message', 'something_went_wrong');
    },
    successPreviewFile(state) {
      state.previewFileLoading = false;
    },
    failurePreviewFile(state, action) {
      state.previewFileLoading = false;
      state.previewFileError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetGovtDetails(state, action) {
      state.getGovtDetailsLoading = false;
      state.govtIdFilePath = action.payload.filePath;
      state.govtIdFileName = action.payload.fileName;
      state.signedGovtIdFilePath = action.payload.signedGovtIdFilePath;
    },
    failureGetGovtDetails(state, action) {
      state.getGovtDetailsLoading = false;
      state.getGovtDetailsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successUploadGovtId(state, action) {
      state.uploadGovtIdLoading = false;
      state.govtIdFilePath = action.payload.filePath;
      state.govtIdFileName = action.payload.fileName;
    },
    failureUploadGovtId(state, action) {
      state.uploadGovtIdLoading = false;
      state.uploadGovtIdError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGeneratePaymentLink(state) {
      state.generatePaymentLinkLoading = false;
    },
    failureGeneratePaymentLink(state, action) {
      state.generatePaymentLinkLoading = false;
      state.generatePaymentLinkError = get(action.payload, 'message', 'something_went_wrong');
    },
    successCopyrightWithTickets(state) {
      state.copyrightWithTicketsLoading = false;
    },
    failureCopyrightWithTickets(state, action) {
      state.copyrightWithTicketsLoading = false;
      state.copyrightWithTicketsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetCopyrightPaymentStatus(state, action) {
      state.copyrightPaymentStatusData = action.payload;
      state.getCopyrightPaymentStatusLoading = false;
    },
    failureGetCopyrightPaymentStatus(state, action) {
      state.getCopyrightPaymentStatusLoading = false;
      state.getCopyrightPaymentStatusError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetHasSelfAttestedStory(state, action) {
      state.hasSelfAttestedStory = action.payload;
      state.getHasSelfAttestedStoryLoading = false;
    },
    failureGetHasSelfAttestedStory(state, action) {
      state.hasSelfAttestedStory = false;
      state.getHasSelfAttestedStoryError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetSelfAttestationDetails(state, action) {
      state.selfAttestationDetails = action.payload;
      state.getSelfAttestationDetailsLoading = false;
    },
    failureGetSelfAttestationDetails(state, action) {
      state.getSelfAttestationDetailsLoading = false;
      state.getSelfAttestationDetailsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGenerateStoryPdf(state, action) {
      state.storyPdfUrl = action.payload;
      state.generateStoryPdfLoading = false;
    },
    failureGenerateStoryPdf(state, action) {
      state.generateStoryPdfLoading = false;
      state.generateStoryPdfError = get(action.payload, 'message', 'something_went_wrong');
    },
    successUploadSignedPoaDocument(state, action) {
      state.uploadSignedPOADocumentLoading = false;
      state.signedPoaDocumentPath = action.payload;
    },
    failureUploadSignedPoaDocument(state, action) {
      state.uploadSignedPOADocumentLoading = false;
      state.uploadSignedPOADocumentError = get(action.payload, 'message', 'something_went_wrong');
    },
    successUploadSignedStoryDocument(state, action) {
      state.uploadSignedStoryDocumentLoading = false;
      state.signedStoryDocumentPath = action.payload;
    },
    failureUploadSignedStoryDocument(state, action) {
      state.uploadSignedStoryDocumentLoading = false;
      state.uploadSignedStoryDocumentError = get(action.payload, 'message', 'something_went_wrong');
    },
    successConfirmStorySubmission(state) {
      state.confirmStorySubmissionLoading = false;
    },
    failureConfirmStorySubmission(state, action) {
      state.confirmStorySubmissionLoading = false;
      state.confirmStorySubmissionError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetCopyrightPreSubmissionDetails(state, action) {
      state.getCopyrightPreSubmissionDetailsLoading = false;
      state.copyrightPreSubmissionDetails = action.payload;
    },
    failureGetCopyrightPreSubmissionDetails(state, action) {
      state.getCopyrightPreSubmissionDetailsLoading = false;
      state.getCopyrightPreSubmissionDetailsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetCopyrightRegistrationDetails(state, action) {
      state.copyrightRegistrationDetails = action.payload;
      state.getCopyrightRegistrationDetailsLoading = false;
    },
    failureGetCopyrightRegistrationDetails(state, action) {
      state.getCopyrightRegistrationDetailsLoading = false;
      state.getCopyrightRegistrationDetailsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successUpdateCopyrightProtectionStrategy(state) {
      state.updateCopyrightProtectionStrategyLoading = false;
    },
    failureUpdateCopyrightProtectionStrategy(state, action) {
      state.updateCopyrightProtectionStrategyLoading = false;
      state.updateCopyrightProtectionStrategyError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetCopyrightProtectionDetails(state, action) {
      state.getCopyrightProtectionDetailsLoading = false;
      state.copyrightProtectionDetails = action.payload;
    },
    failureGetCopyrightProtectionDetails(state, action) {
      state.getCopyrightProtectionDetailsLoading = false;
      state.getCopyrightProtectionDetailsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successUpdateCopyrightRegistrationDetails(state) {
      state.updateCopyrightRegistrationDetailsLoading = false;
    },
    failureUpdateCopyrightRegistrationDetails(state, action) {
      state.updateCopyrightRegistrationDetailsLoading = false;
      state.updateCopyrightRegistrationDetailsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successUpdateCopyrightSelfAttestationDetails(state) {
      state.updateCopyrightSelfAttestationLoading = false;
    },
    failureUpdateCopyrightSelfAttestationDetails(state, action) {
      state.updateCopyrightSelfAttestationLoading = false;
      state.updateCopyrightSelfAttestationError = get(action.payload, 'message', 'something_went_wrong');
    },
    successUploadSwaQrCode(state, action) {
      state.uploadSwaQrCodeLoading = false;
      state.swaQrCodeFilePath = action.payload;
    },
    failureUploadSwaQrCode(state, action) {
      state.uploadSwaQrCodeLoading = false;
      state.uploadSwaQrCodeError = get(action.payload, 'message', 'something_went_wrong');
    },
    successUpdateSwaRegistrationDetails(state) {
      state.updateSwaRegistrationDetailsLoading = false;
    },
    failureUpdateSwaRegistrationDetails(state, action) {
      state.updateSwaRegistrationDetailsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetSwaRegistrationDetails(state, action) {
      state.getSwaRegistrationDetailsLoading = false;
      state.swaRegistrationDetails = action.payload;
    },
    failureGetSwaRegistrationDetails(state, action) {
      state.getSwaRegistrationDetailsLoading = false;
      state.getSwaRegistrationDetailsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successUploadNocFile(state, action) {
      state.uploadNocFileLoading = false;
      state.nocFilePath = action.payload;
    },
    failureUploadNocFile(state, action) {
      state.uploadNocFileLoading = false;
      state.uploadNocFileError = get(action.payload, 'message', 'something_went_wrong');
    },
    successUpdateAuthorVerificationDetails(state) {
      state.updateAuthorVerificationDetailsLoading = false;
    },
    failureUpdateAuthorVerificationDetails(state, action) {
      state.updateAuthorVerificationDetailsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetCopyrightAuthorVerificationDetails(state, action) {
      state.getCopyrightAuthorVerificationDetailsLoading = false;
      state.copyrightAuthorVerificationDetails = action.payload;
    },
    failureGetCopyrightAuthorVerificationDetails(state, action) {
      state.getCopyrightAuthorVerificationDetailsLoading = false;
      state.getCopyrightAuthorVerificationDetailsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successBeginSubmissionProcess(state) {
      state.beginSubmissionProcessLoading = false;
    },
    failureBeginSubmissionProcess(state, action) {
      state.beginSubmissionProcessLoading = false;
      state.beginSubmissionProcessError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetSubmissionStatus(state, action) {
      state.getSubmissionStatusLoading = false;
      state.submissionStatus = action.payload;
    },
    failureGetSubmissionStatus(state, action) {
      state.getSubmissionStatusLoading = false;
      state.getSubmissionStatusError = get(action.payload, 'message', 'something_went_wrong');
    },
    successStateChange(state, action) {
      state.stateChangeLoading = false;
      state.stateChangeData = action.payload;
    },
    failureStateChange(state, action) {
      state.stateChangeLoading = false;
      state.stateChangeError = get(action.payload, 'message', 'something went wrong');
    },
    setBackPressed(state, action) {
      state.backPressed = action.payload;
    },
    setCurrentStoryId(state, action) {
      state.currentStoryId = action.payload;
    },
    setIsStoryCopyrighted(state, action) {
      state.isStoryCopyrighted = action.payload;
    },
    setShouldCopyright(state, action) {
      state.shouldCopyright = action.payload;
    },
    setIsStoryRegistered(state, action) {
      state.isStoryRegistered = action.payload;
    },
    setIsStoryRegisteredSWA(state, action) {
      state.isStoryRegisteredSWA = action.payload;
    },
    setIsAuthor(state, action) {
      state.isAuthor = action.payload;
    },
    setNocActive(state, action) {
      state.nocActive = action.payload;
    },
    setNocFilePath(state, action) {
      state.nocFilePath = action.payload;
    },
    setNocFileName(state, action) {
      state.nocFileName = action.payload;
    },
    setCopyrightCurrentStep(state, action) {
      state.copyrightProcessCurrentStep = action.payload;
    },
    setSignedStoryDocumentPath(state, action) {
      state.signedStoryDocumentPath = action.payload;
    },
    setSignedPoaDocumentPath(state, action) {
      state.signedPoaDocumentPath = action.payload;
    },
    setSignedStoryFileName(state, action) {
      state.signedStoryDocumentFileName = action.payload;
    },
    setSignedPoaFileName(state, action) {
      state.signedPoaDocumentFileName = action.payload;
    },
    setStoryTitle(state, action) {
      state.storyTitle = action.payload;
    },
    setSignedSwaQrCodeFilePath(state, action) {
      state.signedSwaFilePath = action.payload;
    },
    resetSwaRegistrationDetails(state) {
      state.swaRegistrationDetails = null;
    },
    resetCopyrightDeclarationState(state) {
      state.isAuthor = '';
      state.nocActive = false;
      state.nocFileName = '';
      state.nocFilePath = '';
    },
    resetSwaState(state) {
      state.isStoryRegisteredSWA = '';
      state.isStoryCopyrighted = '';
    },
    resetCopyrightProtection(state) {
      state.shouldCopyright = false;
    },
    resetStoryPdfUrl(state) {
      state.storyPdfUrl = '';
      state.generateStoryPdfLoading = false;
    },
    resetSwaQrCodeFilePath(state) {
      state.swaQrCodeFilePath = '';
      state.signedSwaFilePath = '';
    },
    resetGovtIdFilePath(state) {
      state.govtIdFilePath = '';
      state.govtIdFileName = '';
      state.signedGovtIdFilePath = '';
    },
    resetState(state) {
      state.shouldCopyright = false;
      state.storyTitle = '';
      state.isStoryCopyrighted = '';
      state.isStoryRegistered = false;
      state.isAuthor = '';
      state.isStoryRegisteredSWA = '';
      state.nocActive = false;
      state.nocFilePath = '';
      state.nocFileName = '';
      state.swaQrCodeFilePath = '';
      state.signedSwaFilePath = '';
      state.submissionStatus = null;
      state.backPressed = false;
      state.getSubmissionStatusLoading = false;
      state.getSubmissionStatusError = null;
      state.currentStoryId = null;
      state.copyrightProcessCurrentStep = COPYRIGHT_PROTECTION_STEPS.STEP1;
      state.signedStoryDocumentPath = '';
      state.signedStoryDocumentFileName = '';
      state.signedPoaDocumentPath = '';
      state.signedPoaDocumentFileName = '';
      state.beginSubmissionProcessLoading = false;
      state.beginSubmissionProcessError = null;
      state.copyrightAuthorVerificationDetails = null;
      state.getCopyrightAuthorVerificationDetailsLoading = false;
      state.getCopyrightAuthorVerificationDetailsError = null;
      state.updateAuthorVerificationDetailsLoading = false;
      state.updateAuthorVerificationDetailsError = null;
      state.swaRegistrationDetails = null;
      state.getSwaRegistrationDetailsLoading = false;
      state.getSwaRegistrationDetailsError = null;
      state.uploadNocFileLoading = false;
      state.uploadNocFileError = null;
      state.updateSwaRegistrationDetailsLoading = false;
      state.updateSwaRegistrationDetailsError = null;
      state.uploadSwaQrCodeLoading = false;
      state.uploadSwaQrCodeError = null;
      state.updateCopyrightRegistrationDetailsLoading = false;
      state.updateCopyrightRegistrationDetailsError = null;
      state.updateCopyrightSelfAttestationLoading = false;
      state.updateCopyrightSelfAttestationError = null;
      state.copyrightProtectionDetails = null;
      state.getCopyrightProtectionDetailsLoading = false;
      state.getCopyrightProtectionDetailsError = null;
      state.updateCopyrightProtectionStrategyLoading = false;
      state.updateCopyrightProtectionStrategyError = null;
      state.copyrightRegistrationDetails = null;
      state.getCopyrightRegistrationDetailsLoading = false;
      state.getCopyrightRegistrationDetailsError = null;
      state.copyrightPreSubmissionDetails = null;
      state.getCopyrightPreSubmissionDetailsLoading = false;
      state.getCopyrightPreSubmissionDetailsError = null;
      state.confirmStorySubmissionLoading = false;
      state.confirmStorySubmissionError = null;
      state.uploadSignedStoryDocumentLoading = false;
      state.uploadSignedStoryDocumentError = null;
      state.uploadSignedPOADocumentLoading = false;
      state.uploadSignedPOADocumentError = null;
      state.stateChangeLoading = false;
      state.stateChangeData = null;
      state.stateChangeError = null;
      state.hasSelfAttestedStory = false;
      state.getHasSelfAttestedStoryLoading = false;
      state.getHasSelfAttestedStoryError = null;
      state.copyrightPaymentStatusData = null;
      state.getCopyrightPaymentStatusLoading = false;
      state.getCopyrightPaymentStatusError = null;
      state.copyrightWithTicketsLoading = false;
      state.copyrightWithTicketsError = null;
      state.generatePaymentLinkLoading = false;
      state.generatePaymentLinkError = null;
      state.uploadGovtIdLoading = false;
      state.uploadGovtIdError = null;
      state.govtIdFileName = '';
      state.govtIdFilePath = '';
      state.getGovtDetailsLoading = false;
      state.getGovtDetailsError = null;
      state.previewFileLoading = false;
      state.previewFileError = null;
      state.poaSignedDocumentPath = '';
      state.getPoaSignedDocumentPathLoading = false;
      state.getPoaSignedDocumentPathError = null;
      state.nocSignedDocumentPath = '';
      state.getNocSignedDocumentPathLoading = false;
      state.getNocSignedDocumentPathError = null;
    }
  }
});

export const {
  setIsStoryCopyrighted,
  setShouldCopyright,
  setIsStoryRegistered,
  setIsAuthor,
  setCurrentStoryId,
  setIsStoryRegisteredSWA,
  setNocActive,
  setNocFilePath,
  setNocFileName,
  setCopyrightCurrentStep,
  setBackPressed,
  setSignedStoryDocumentPath,
  setSignedPoaDocumentPath,
  setSignedStoryFileName,
  setSignedPoaFileName,
  setStoryTitle,
  requestGetSubmissionStatus,
  successGetSubmissionStatus,
  failureGetSubmissionStatus,
  requestBeginSubmissionProcess,
  successBeginSubmissionProcess,
  failureBeginSubmissionProcess,
  requestGetCopyrightAuthorVerificationDetails,
  successGetCopyrightAuthorVerificationDetails,
  failureGetCopyrightAuthorVerificationDetails,
  requestUpdateAuhtorVerificationDetails,
  successUpdateAuthorVerificationDetails,
  failureUpdateAuthorVerificationDetails,
  requestUploadNocFile,
  successUploadNocFile,
  failureUploadNocFile,
  requestGetSwaRegistrationDetails,
  successGetSwaRegistrationDetails,
  failureGetSwaRegistrationDetails,
  requestUpdateSwaRegistrationDetails,
  successUpdateSwaRegistrationDetails,
  failureUpdateSwaRegistrationDetails,
  requestUploadSwaQrCode,
  successUploadSwaQrCode,
  failureUploadSwaQrCode,
  requestUpdateCopyrightRegistrationDetails,
  successUpdateCopyrightRegistrationDetails,
  failureUpdateCopyrightRegistrationDetails,
  requestUpdateCopyrightSelfAttestationDetails,
  successUpdateCopyrightSelfAttestationDetails,
  failureUpdateCopyrightSelfAttestationDetails,
  requestGetCopyrightProtectionDetails,
  successGetCopyrightProtectionDetails,
  failureGetCopyrightProtectionDetails,
  resetState,
  requestUpdateCopyrightProtectionStrategy,
  successUpdateCopyrightProtectionStrategy,
  failureUpdateCopyrightProtectionStrategy,
  requestGetCopyrightRegistrationDetails,
  successGetCopyrightRegistrationDetails,
  failureGetCopyrightRegistrationDetails,
  requestGetCopyrightPreSubmissionDetails,
  successGetCopyrightPreSubmissionDetails,
  failureGetCopyrightPreSubmissionDetails,
  requestConfirmStorySubmission,
  successConfirmStorySubmission,
  failureConfirmStorySubmission,
  requestUploadSignedPoaDocument,
  successUploadSignedPoaDocument,
  failureUploadSignedPoaDocument,
  requestUploadSignedStoryDocument,
  successUploadSignedStoryDocument,
  failureUploadSignedStoryDocument,
  requestStateChange,
  successStateChange,
  failureStateChange,
  requestGenerateStoryPdf,
  successGenerateStoryPdf,
  failureGenerateStoryPdf,
  requestGetSelfAttestationDetails,
  successGetSelfAttestationDetails,
  failureGetSelfAttestationDetails,
  resetCopyrightDeclarationState,
  resetSwaState,
  resetCopyrightProtection,
  resetSwaRegistrationDetails,
  resetStoryPdfUrl,
  requestGetHasSelfAttestedStory,
  successGetHasSelfAttestedStory,
  failureGetHasSelfAttestedStory,
  resetSwaQrCodeFilePath,
  requestGetCopyrightPaymentStatus,
  successGetCopyrightPaymentStatus,
  failureGetCopyrightPaymentStatus,
  requestCopyrightWithTickets,
  successCopyrightWithTickets,
  failureCopyrightWithTickets,
  requestGeneratePaymentLink,
  successGeneratePaymentLink,
  failureGeneratePaymentLink,
  requestUploadGovtId,
  successUploadGovtId,
  failureUploadGovtId,
  resetGovtIdFilePath,
  requestGetGovtDetails,
  successGetGovtDetails,
  failureGetGovtDetails,
  requestPreviewFile,
  successPreviewFile,
  failurePreviewFile,
  setSignedSwaQrCodeFilePath,
  requestGetPoaSignedDocument,
  successGetPoaSignedDocumentPath,
  failureGetPoaSignedDocumentPath,
  requestGetNocSignedDocument,
  successGetNocSignedDocumentPath,
  failureGetNocSignedDocumentPath
} = copyrightContainerSlice.actions;

export default copyrightContainerSlice.reducer;
