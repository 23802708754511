import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { translate } from '@app/components/IntlGlobalProvider';
import { Link } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';
import defaultAvatarIcon from '@images/avatar.png';
import { RequestDetailsCardProps } from './types';
import { PROFILE_IMAGE_PROD_URL, PROFILE_IMAGE_QA_URL, PROFILE_IMAGE_URL } from '@app/utils/constants';
import { If } from 'tsw-sdk';

const OfferCardContainer = styled.div`
  width: 19.12rem;
  height: 20.75rem;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 1rem;
  box-shadow: 0px 0px 20px ${colors.offersReceivedColors.offerCardShadowColor};
  overflow: hidden;
`;

const OfferCardHeader = styled.div`
  width: 100%;
  height: 5.25rem;
  background-color: ${colors.offersReceivedColors.offerCardHeaderBg};
  display: flex;
  padding-top: 1.12rem;
  padding-left: 1.2rem;
  padding-right: 1.06rem;
  position: relative;
`;

const UserProfileImage = styled.img`
  width: 6.25rem;
  height: 6.25rem;
  position: absolute;
  bottom: -100%;
  left: 32%;
  border-radius: 100%;
`;

const OfferCardBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 3.25rem;
  padding-right: 3.43rem;
  margin-top: 4rem;
`;

const OfferCardBodyTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const UserNameTitle = styled.h3`
  max-width: 15rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw900()};
  color: ${colors.offersReceivedColors.storyName};
  line-height: 1.87rem;
  margin-bottom: 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserOrganisation = styled.p`
  ${fonts.weights.fw700()}
  ${fonts.size.xRegular()}
  line-height: 1.5rem;
  color: ${colors.offersReceivedColors.storyName};
  margin-top: 0.25rem;
  text-align: center;
`;

const UserDesignation = styled.p`
  ${fonts.weights.fw700()}
  ${fonts.size.xsmall()}
  line-height: 1.25rem;
  color: ${colors.offersReceivedColors.storyName};
  text-align: center;
`;

const ViewOfferBtn = styled.div`
  width: 9.56rem;
  height: 1.75rem;
  background: ${colors.offersReceivedColors.storyName};
  border-radius: 0.5rem;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  ${fonts.weights.fw800()};
  ${fonts.size.small()}
  line-height: 1rem;
`;

const RequestDetialsCard = ({ requestDetail, storyId }: RequestDetailsCardProps) => {
  return (
    <Link
      to={`${routeConstants.dashboard.route}?activeTab=offer-history&storyId=${storyId}&buyerId=${requestDetail?.buyer?.id}&type=conversation`}
    >
      <OfferCardContainer>
        <OfferCardHeader>
          <If condition={process.env.ENVIRONMENT_NAME === 'qa'}>
            <UserProfileImage
              src={
                requestDetail?.buyer?.profileImage
                  ? `${PROFILE_IMAGE_QA_URL}/${requestDetail?.buyer?.profileImage}`
                  : defaultAvatarIcon
              }
            />
          </If>
          <If condition={process.env.ENVIRONMENT_NAME === 'production'}>
            <UserProfileImage
              src={
                requestDetail?.buyer?.profileImage
                  ? `${PROFILE_IMAGE_PROD_URL}/${requestDetail?.buyer?.profileImage}`
                  : defaultAvatarIcon
              }
            />
          </If>
          <If condition={process.env.ENVIRONMENT_NAME !== 'production' && process.env.ENVIRONMENT_NAME !== 'qa'}>
            <UserProfileImage
              src={
                requestDetail?.buyer?.profileImage
                  ? `${PROFILE_IMAGE_URL}/${requestDetail?.buyer?.profileImage}`
                  : defaultAvatarIcon
              }
            />
          </If>
        </OfferCardHeader>
        <OfferCardBody>
          <OfferCardBodyTitle>
            <UserNameTitle>{`${requestDetail?.buyer?.firstName} ${requestDetail?.buyer?.lastName}`}</UserNameTitle>
          </OfferCardBodyTitle>
          <UserOrganisation>{requestDetail?.buyer?.companyName}</UserOrganisation>
          <UserDesignation>{requestDetail?.buyer?.designation}</UserDesignation>

          <ViewOfferBtn>{translate('respond')}</ViewOfferBtn>
        </OfferCardBody>
      </OfferCardContainer>
    </Link>
  );
};

export default RequestDetialsCard;
