import React from 'react';
import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import { CustomButton, If } from 'tsw-sdk';
import { SpotTicketsBalanceModalProps } from './types';
import { ArrowRightOutlined } from '@ant-design/icons';
import { message } from 'antd';

const SpotTicketsBalanceModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
`;

const PayWithSpotTicketsHeading = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  color: ${colors.primary};
  text-align: center;
  margin-bottom: 2rem;
`;

const SpotTicketsBalanceContainer = styled.div`
  width: 32rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.37rem;
`;

const SpotTicketsBalanceRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SpotTicketsBalanceRowText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  line-height: 1.62rem;
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-top: 0.68rem;
`;

const TableRowDivider = styled.div`
  width: 100%;
  height: 0.125rem;
  background-color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-top: 1.75rem;
  margin-bottom: 0.5rem;
`;

const ConfirmButton = styled(CustomButton)`
  && {
    width: 15rem;
    justify-content: center;

    &:disabled {
      background-color: ${colors.copyrightColors.btnDisabled};
    }
  }
`;

const SpotTicketsBalanceModal = ({
  spotTicketBalance,
  generatePaymentLinkLoading,
  copyrightWithTicketsLoading,
  handleCopyrightWithTickets,
  handleAddSpotTickets
}: SpotTicketsBalanceModalProps) => {
  const handleSubmit = () => {
    if (spotTicketBalance - 500 >= 0) {
      handleCopyrightWithTickets();
    } else {
      // handleAddSpotTickets();
      message.error(translate('low_balance'));
    }
  };

  return (
    <SpotTicketsBalanceModalContainer>
      <PayWithSpotTicketsHeading>{translate('pay_with_spot_tickets')}</PayWithSpotTicketsHeading>
      <SpotTicketsBalanceContainer>
        <SpotTicketsBalanceRow>
          <SpotTicketsBalanceRowText>{translate('current_spot_ticket_balance')}</SpotTicketsBalanceRowText>
          <SpotTicketsBalanceRowText>{translate('tickets_amount', { amount: 'Unlimited' })}</SpotTicketsBalanceRowText>
        </SpotTicketsBalanceRow>
        <SpotTicketsBalanceRow>
          <SpotTicketsBalanceRowText>{translate('copyright_application_cost')}</SpotTicketsBalanceRowText>
          <SpotTicketsBalanceRowText>{translate('tickets_amount', { amount: 500 })}</SpotTicketsBalanceRowText>
        </SpotTicketsBalanceRow>
        <TableRowDivider />
        <SpotTicketsBalanceRow>
          <If
            condition={spotTicketBalance >= 500}
            otherwise={
              <>
                <SpotTicketsBalanceRowText>{translate('min_required')}</SpotTicketsBalanceRowText>
                <SpotTicketsBalanceRowText>{translate('tickets_amount', { amount: 500 })}</SpotTicketsBalanceRowText>
              </>
            }
          >
            <SpotTicketsBalanceRowText>{translate('balance')}</SpotTicketsBalanceRowText>
            {/* <SpotTicketsBalanceRowText>
              {translate('tickets_amount', { amount: spotTicketBalance - 500 })}
            </SpotTicketsBalanceRowText> */}
            <SpotTicketsBalanceRowText>
              {translate('tickets_amount', { amount: 'Unlimited' })}
            </SpotTicketsBalanceRowText>
          </If>
        </SpotTicketsBalanceRow>
      </SpotTicketsBalanceContainer>
      <ConfirmButton
        onClick={handleSubmit}
        loading={generatePaymentLinkLoading || copyrightWithTicketsLoading}
        data-testid="confirm-spot-ticket-payment"
      >
        {spotTicketBalance - 500 >= 0 ? translate('confirm_payment') : translate('add_with_razorpay')}
        <ArrowRightOutlined />
      </ConfirmButton>
    </SpotTicketsBalanceModalContainer>
  );
};

export default SpotTicketsBalanceModal;
