import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectTokenDomain = (state: any) => state.jwtToken || initialState;

export const selectIdToken = () => createSelector(selectTokenDomain, (substate) => get(substate, 'idToken'));

export const selectRefreshToken = () => createSelector(selectTokenDomain, (substate) => get(substate, 'refreshToken'));

export const selectAccessToken = () => createSelector(selectTokenDomain, (substate) => get(substate, 'accessToken'));

export const selectCredential = () => createSelector(selectTokenDomain, (substate) => get(substate, 'credential'));
