import { prepare } from '@app/utils';
import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';

export const initialState = {
  submissionDraftLoading: false,
  offersReceivedLoading: false,
  submissionDraftData: [],
  submissionDraftError: null,
  offersReceivedData: [],
  offersReceivedError: null
};

const myDashboardSlice = createSlice({
  name: 'myDashboard',
  initialState,
  reducers: {
    requestGetSubmissionDrafts: {
      reducer: (state) => {
        state.submissionDraftLoading = true;
      },
      prepare
    },
    requestGetOffersReceived: {
      reducer: (state) => {
        state.offersReceivedLoading = true;
      },
      prepare
    },

    successGetSubmissionDrafts(state, action) {
      state.submissionDraftLoading = false;
      state.submissionDraftData = action.payload;
      state.submissionDraftError = null;
    },
    failureGetSubmissionDrafts(state, action) {
      state.submissionDraftLoading = false;
      state.submissionDraftError = get(action.payload, 'message', 'something went wrong');
      state.submissionDraftData = [];
    },
    successGetOffersReceived(state, action) {
      state.offersReceivedLoading = false;
      state.offersReceivedData = action.payload;
      state.offersReceivedError = null;
    },
    failureGetOffersReceived(state, action) {
      state.offersReceivedLoading = false;
      state.offersReceivedData = [];
      state.offersReceivedError = get(action.payload, 'message', 'something went wrong');
    }
  }
});

export const {
  requestGetSubmissionDrafts,
  successGetSubmissionDrafts,
  failureGetSubmissionDrafts,
  requestGetOffersReceived,
  successGetOffersReceived,
  failureGetOffersReceived
} = myDashboardSlice.actions;

export default myDashboardSlice.reducer;
