import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import downloadOfferIcon from '@images/icons/OffersReceived/download-offer.svg';
import viewedIcon from '@images/icons/MySubmissionIcons/viewed-icon.svg';
import favouriteIcon from '@images/icons/MySubmissionIcons/star-icon.svg';
import offerIcon from '@images/icons/MySubmissionIcons/offer-icon.svg';

import { translate } from '@app/components/IntlGlobalProvider';
import _ from 'lodash';
import { RequestCardProps } from './types';
import { If } from 'tsw-sdk';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import routeConstants from '@app/utils/routeConstants';
import { useHistory } from 'react-router-dom';

const RequestCardContainer = styled.div`
  width: 19.12rem;
  height: 15.87rem;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 1rem;
  box-shadow: 0px 0px 20px ${colors.offersReceivedColors.offerCardShadowColor};
  overflow: hidden;
  cursor: pointer;
`;

const RequestCardHeader = styled.div`
  width: 100%;
  height: 5.25rem;
  background-color: ${colors.offersReceivedColors.offerCardHeaderBg};
  display: flex;
  padding-top: 1.12rem;
  padding-left: 1.2rem;
  padding-right: 1.06rem;
  position: relative;
  overflow: hidden;
`;

const RequestCardHeaderBg = styled.div`
  width: 120%;
  position: absolute;
  top: -1rem;
  left: -1rem;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
`;

const RequestCardHeaderBgText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.xlarge()};
  color: ${colors.offersReceivedColors.cardHeaderBgTextColor};
  white-space: nowrap;
`;

const RequestCardBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const RequestCardBodyTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StoryNameTitle = styled.h3`
  max-width: 16rem;
  ${fonts.family.SpoofFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw900()};
  color: ${colors.offersReceivedColors.storyName};
  margin-right: 0.68rem;
  line-height: 1.87rem;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

const DownloadOfferIcon = styled.img`
  width: 0.75rem;
  height: 0.75rem;
`;

// const OfferCardDate = styled.p`
//   width: 100%;
//   margin-top: 0.5rem;
//   ${fonts.weights.fw700()};
//   ${fonts.size.xsmall()};
//   line-height: 1rem;
//   color: ${colors.offersReceivedColors.offerDateColor};
//   text-align: center;
// `;

const ScriptIncludedPill = styled.div`
  width: 6.87rem;
  height: 1.5rem;
  background-color: ${colors.offersReceivedColors.scriptIncludedPillBg};
  border-radius: 6.25rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${colors.offersReceivedColors.scriptIncludedPillColor};
  ${fonts.size.extraSmall()};
  ${fonts.weights.fw800()};
  line-height: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

const RequestStatsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.12rem;
  margin-top: 1rem;
  justify-content: center;
`;

const RequestStatsItem = styled.div`
  display: flex;
  align-items: center;
`;

const RequestStatsItemIcon = styled.img``;

const RequestStateItemText = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.xsmall()};
  line-height: 20px;
  color: ${colors.offersReceivedColors.storyName};
  margin-left: 0.31rem;
`;

const RequestCard = ({ request, currentLoadingStoryId, handleDownloadStory }: RequestCardProps) => {
  const history = useHistory();

  const openDetailsPage = () => {
    history.push(
      `${routeConstants.dashboard.route}?activeTab=request-view&story=${request?.storyId}&title=${request?.title}`
    );
  };
  return (
    <RequestCardContainer onClick={openDetailsPage}>
      <RequestCardHeader>
        <RequestCardHeaderBg>
          {_.range(10).map((number) => (
            <RequestCardHeaderBgText key={number}>{request?.title}</RequestCardHeaderBgText>
          ))}
        </RequestCardHeaderBg>
      </RequestCardHeader>
      <RequestCardBody>
        <RequestCardBodyTitle>
          <StoryNameTitle>{request?.title}</StoryNameTitle>

          <If
            condition={currentLoadingStoryId !== request?.storyId}
            otherwise={<Spin indicator={<LoadingOutlined spin={true} />} />}
          >
            <DownloadOfferIcon
              src={downloadOfferIcon}
              onClick={(e: any) => {
                e.stopPropagation();
                handleDownloadStory(request?.storyId);
              }}
              data-testid="download-story"
            />
          </If>
        </RequestCardBodyTitle>

        <ScriptIncludedPill>{translate('script_included')}</ScriptIncludedPill>
        <RequestStatsContainer>
          <If condition={request?.views}>
            <RequestStatsItem>
              <RequestStatsItemIcon src={viewedIcon} />
              <RequestStateItemText>{request?.views}</RequestStateItemText>
            </RequestStatsItem>
          </If>
          <If condition={request?.ratings}>
            <RequestStatsItem>
              <RequestStatsItemIcon src={favouriteIcon} />
              <RequestStateItemText>{request?.ratings}</RequestStateItemText>
            </RequestStatsItem>
          </If>

          <If condition={request?.offers?.length}>
            <RequestStatsItem>
              <RequestStatsItemIcon src={offerIcon} />
              <RequestStateItemText>{request?.offers?.length}</RequestStateItemText>
            </RequestStatsItem>
          </If>
        </RequestStatsContainer>
      </RequestCardBody>
    </RequestCardContainer>
  );
};

export default RequestCard;
