import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { CustomButton } from 'tsw-sdk';
import { translate } from '@app/components/IntlGlobalProvider';
import { StoryRenewalSuccessPageProps } from '../../types';
import { useHistory } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';

const SuccessPageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  padding: 2.25rem;
`;

const PageTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.primary};
  margin-bottom: 2rem;
`;

const ContainerText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  line-height: 1.62rem;
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
`;

const RenewButton = styled(CustomButton)`
  width: 8.5rem;
  margin-top: 2.37rem;
  margin-left: auto;
  margin-right: auto;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  margin-top: 2rem;
  width: 4.25rem;
`;

const SuccessPageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const StyledImg = styled.img``;

const StoryRenewalSuccessPage = ({ handleBack }: StoryRenewalSuccessPageProps) => {
  const history = useHistory();

  return (
    <SuccessPageContainer>
      <BackButton onClick={handleBack} data-testid="back-transaction-btn">
        <StyledImg src={ArrowLeft} />
        {translate('back')}
      </BackButton>
      <SuccessPageContentContainer>
        <PageTitle>{translate('renewal_complete')}</PageTitle>
        <ContainerText>{translate('free_as_of_now')}</ContainerText>
        <RenewButton
          onClick={() => {
            history.push(`${routeConstants.dashboard.route}?activeTab=submissions`);
          }}
        >
          {translate('done')}
        </RenewButton>
      </SuccessPageContentContainer>
    </SuccessPageContainer>
  );
};

export default StoryRenewalSuccessPage;
