import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { CopyrightTimelineProps } from './types';
import timelineCheckedIcon from '@images/icons/timeline-checked-icon.svg';

const CopyrightTimelineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3.06rem;
`;

const CopyrightTimelineItem = styled.div`
  width: 100%;
  height: auto;
  min-height: 2rem;
  padding-left: 2.75rem;
  position: relative;
  display: flex;
  flex-direction: column;

  &:not(:first-of-type) {
    margin-top: 2rem;
  }
`;

const CopyrightTimelineItemTitle = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.small()};
  color: ${colors.copyrightColors.timelineItemText};
  line-height: 1.125rem;
  white-space: nowrap;
`;

const CopyrightTimelineItemTitleActive = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.small()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  line-height: 1.125rem;
  white-space: nowrap;
`;

const CopyrightTimelineBox = styled.div`
  height: 2rem;
  width: 2rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${colors.copyrightColors.timelineItemBox};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
`;

const CopyrightTimelineBoxActiveMark = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  background-color: ${colors.copyrightColors.timelineItemBoxActive};
`;

const CopyrightTimelineBoxChecked = styled.img`
  position: absolute;
  left: 0;
  top: 0;
`;

const CopyrightTimelineTrack = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${colors.copyrightColors.timelineTrackColor};
  position: absolute;
  top: 2rem;
  left: 1rem;
`;

const CopyrightTimelineTrackActive = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${colors.copyrightColors.timelineTrackColorActive};
  position: absolute;
  top: 2rem;
  left: 1rem;
`;

const TimelineItemDescriptionText = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.small()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  line-height: 1.125rem;
  margin-top: 0.68rem;
  margin-left: 0.31rem;
`;

const CopyrightTimeline = ({ currentState, descriptionText }: CopyrightTimelineProps) => {
  const timelineStates = [
    {
      title: translate('introduction'),
      key: 1
    },
    {
      title: translate('are_you_author'),
      key: 2
    },
    {
      title: translate('swa'),
      key: 3
    },
    {
      title: translate('copyright_protection'),
      key: 4
    },
    {
      title: translate('submission'),
      key: 5
    }
  ];

  return (
    <CopyrightTimelineContainer>
      {timelineStates.map((timelineState) => (
        <CopyrightTimelineItem key={timelineState.key}>
          {currentState === timelineState.key ? (
            <CopyrightTimelineItemTitleActive>{timelineState.title}</CopyrightTimelineItemTitleActive>
          ) : (
            <CopyrightTimelineItemTitle>{timelineState.title}</CopyrightTimelineItemTitle>
          )}
          {descriptionText.length && timelineState.key === currentState
            ? descriptionText.map((descriptionTextItem, index) => (
                <TimelineItemDescriptionText key={index}>{descriptionTextItem}</TimelineItemDescriptionText>
              ))
            : undefined}

          {currentState > timelineState.key ? (
            <CopyrightTimelineBoxChecked src={timelineCheckedIcon} />
          ) : (
            <CopyrightTimelineBox>
              {currentState === timelineState.key && <CopyrightTimelineBoxActiveMark />}
            </CopyrightTimelineBox>
          )}

          {timelineState.key !== 5 &&
            (currentState > timelineState.key ? <CopyrightTimelineTrackActive /> : <CopyrightTimelineTrack />)}
        </CopyrightTimelineItem>
      ))}
    </CopyrightTimelineContainer>
  );
};

export default CopyrightTimeline;

export const CopyrightTimelineTest = CopyrightTimeline;
