import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  loading: false,
  loginData: {},
  sendEmailData: {},
  resetPasswordData: null,
  loginError: null,
  sendEmailError: null,
  resetPasswordError: null,
  reCaptchaState: null,
  otpError: null,
  isOtpSent: false
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    requestLoginUser: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestVerifyRecaptcha: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestSendEmail: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestResetPassword: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestSendOtp: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestVerifyOtp: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestRefreshToken: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    successRefreshToken(state) {
      state.loginError = null;
      state.loading = false;
    },
    failureSendOtp(state, action) {
      state.loading = false;
      state.otpError = get(action.payload, 'message', 'something_went_wrong');
    },
    successLoginUser(state, action) {
      state.loginError = null;
      state.loginData = action.payload;
      state.loading = false;
    },
    failureLoginUser(state, action) {
      state.loginError = get(action.payload, 'message', 'something_went_wrong');
      state.loading = false;
    },
    successSendMail(state, action) {
      state.sendEmailError = null;
      state.sendEmailData = action.payload;
      state.loading = false;
    },
    failureSendMail(state, action) {
      state.sendEmailError = get(action.payload, 'message', 'something_went_wrong');
      state.loading = false;
    },
    successResetPassword(state, action) {
      state.resetPasswordError = null;
      state.resetPasswordData = get(action.payload, 'message', 'Success');
      state.loading = false;
    },
    failureResetPassword(state, action) {
      state.resetPasswordError = get(action.payload, 'message', 'something_went_wrong');
      state.loading = false;
    },
    successReCaptcha(state, action) {
      state.reCaptchaState = get(action.payload, 'message', 'Success');
      state.loading = false;
    },
    failureRecaptcha(state, action) {
      state.reCaptchaState = get(action.payload, 'message', 'Failure');
      state.loading = false;
    },
    resetLoginError(state) {
      state.loginError = null;
    },
    resetState(state) {
      state.loginError = null;
      state.loginData = {};
      state.loading = false;
      state.resetPasswordError = null;
      state.resetPasswordData = null;
      state.sendEmailError = null;
      state.sendEmailData = {};
    },
    resetCaptchState(state) {
      state.reCaptchaState = null;
    }
  }
});

export const {
  requestLoginUser,
  successLoginUser,
  failureLoginUser,
  resetState,
  successReCaptcha,
  failureRecaptcha,
  requestVerifyRecaptcha,
  requestSendEmail,
  successSendMail,
  failureSendMail,
  requestResetPassword,
  successResetPassword,
  failureResetPassword,
  resetCaptchState,
  requestSendOtp,
  failureSendOtp,
  requestVerifyOtp,
  requestRefreshToken,
  successRefreshToken,
  resetLoginError
} = loginSlice.actions;

export default loginSlice.reducer;
