export const HEADER_HEIGHT = '7rem';
export const MIN_SIDEBAR_WIDTH = '4.5rem';
export const MOBILE_DRAWER_BREAKPOINT = 450;
export const BACKSPACE = 'Backspace';
export const ARROWLEFT = 'ArrowLeft';
export const ARROWRIGHT = 'ArrowRight';
export const SELLER_EXISTS_ERROR = 'PreSignUp failed with error seller already exist.';
export const USER_DOES_NOT_EXISTS = 'User does not exist.';
export const USER_IS_NOT_CONFIRMED = 'User is not confirmed.';
export const PROFILE_PATH = 'images/profile';
export const BANNER_PATH = 'images/banner';
export const SCREENPLAY_PATH = 'storyMaterial/screenplays';
export const NARRATION_PATH = 'storyMaterial/audioNarrations';
export const SUPPORTING_IMAGES_PATH = 'storyMaterial/images';
export const SELLER = 'SELLER';
export const BUYER = 'BUYER';

export const FREEMONTHCOUPON = 'FirstMonth';

export const PROFILE = 'PROFILE';
export const NOC_TEMPLATE_URL =
  'https://tsw-assets.s3.ap-south-1.amazonaws.com/copyright-templates/tsw-noc-template.pdf';
export const POA_TEMPLATE_URL =
  'https://tsw-assets.s3.ap-south-1.amazonaws.com/copyright-templates/tsw-poa-template.pdf';

export const PROFILE_IMAGE_URL = 'https://tsw-profile-assets.s3.ap-south-1.amazonaws.com/images/profile';
export const BANNER_IMAGE_URL = 'https://tsw-profile-assets.s3.ap-south-1.amazonaws.com/images/banner';

export const PROFILE_IMAGE_QA_URL = 'https://tsw-profile-assets-qa.s3.ap-south-1.amazonaws.com/images/profile';
export const BANNER_IMAGE_QA_URL = 'https://tsw-profile-assets-qa.s3.ap-south-1.amazonaws.com/images/banner';

export const PROFILE_IMAGE_PROD_URL = 'https://tsw-profile-assets-prod.s3.ap-south-1.amazonaws.com/images/profile';
export const BANNER_IMAGE_PROD_URL = 'https://tsw-profile-assets-prod.s3.ap-south-1.amazonaws.com/images/banner';

export const TERMS_AND_CONDITIONS_URL = 'https://tsw-assets.s3.ap-south-1.amazonaws.com/tnc_spotwriters.pdf';

export const PRIVACY_POLICY_URL = 'https://tsw-assets.s3.ap-south-1.amazonaws.com/privacy_policy_spotwriters.pdf';

export const FAQ_URL = 'https://www.spotwriters.com/faq';

export const STORY_SUBMITTED_MODE = {
  DRAFT: 'DRAFT',
  CONTINUE: 'CONTINUE'
};

export const AUTHOR_STATES = {
  CLAIMED_AUTHOR: 'CLAIMED_AUTHOR',
  DISCLAIMED_AUTHOR: 'DISCLAIMED_AUTHOR'
};

export const COPYRIGHT_PROTECTION_STEPS: any = {
  STEP1: 'STEP1',
  STEP2: 'STEP2',
  STEP3: 'STEP3',
  STEP4: 'STEP4',
  STEP5: 'STEP5',
  DONE: 'DONE'
};

export const COPYRIGHT_PROTECTION_STEPS_MAPPING: any = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3,
  STEP4: 4,
  STEP5: 5
};

export const COPYRIGHT_PROTECTION_STRATEGIES = {
  NO_COPYRIGHT_ON_STORY: 'NO_COPYRIGHT_ON_STORY',
  SELF_ATTESTATION: 'SELF_ATTESTATION',
  REGISTRATION_PROVIDED: 'REGISTRATION_PROVIDED'
};

export const STORIES_STATES = {
  DRAFTED: 'DRAFTED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  UNPUBLISHED: 'UNPUBLISHED',
  PUBLISHED: 'PUBLISHED',
  EXPIRED: 'EXPIRED',
  DELETED: 'DELETED'
};

export const SUPPORTING_MATERIAL_TYPE = {
  IMAGE: 'IMAGE',
  SCREENPLAY: 'SCREENPLAY',
  AUDIO_NARRATION: 'AUDIO_NARRATION'
};

export const OFFER_STATES = {
  OFFER_PLACED: 'OFFER_PLACED',
  SELLER_COUNTERED: 'SELLER_COUNTERED',
  BUYER_COUNTERED: 'BUYER_COUNTERED',
  SELLER_ACCEPTED: 'SELLER_ACCEPTED',
  BUYER_ACCEPTED: 'BUYER_ACCEPTED',
  SELLER_DECLINED: 'SELLER_DECLINED',
  BUYER_DECLINED: 'BUYER_DECLINED',
  BUYER_RETRACTED: 'BUYER_RETRACTED',
  SELLER_RETRACTED: 'SELLER_RETRACTED'
};

export const OFFER_ACTIONS = {
  ACCEPT_OFFER: 'ACCEPT_OFFER',
  DECLINE_OFFER: 'DECLINE_OFFER',
  RETRACT_OFFER: 'RETRACT_OFFER'
};

export const SPOT_TICKET_ACTIONS = {
  EARNED: 'EARNED',
  ADDED: 'ADDED',
  USED: 'USED'
};

export const TICKET_ACTION_MAP = {
  EARNED: 'Earned',
  ADDED: 'Paid',
  USED: 'Spent'
};

export const NOTIFICATION_TYPES = {
  OFFER: 'offer',
  OFFER_CRONJOB: 'offer-cronjob',
  COPYRIGHT: 'copyright',
  TRANSACTION: 'transaction',
  MY_OFFER: 'my-offer',
  CONVERSATION: 'conversation',
  ACCEPTED_OFFER: 'offer-accepted'
};

export const SORT_BY_TYPES = {
  ID: 'ID',
  DAYS_LEFT: 'DAYS_LEFT',
  OFFER_AMOUNT: 'OFFER_AMOUNT'
};

export const SORT_ORDER_TYPES = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const RECIEPTS_CLOUD_FOLDER = 'paymentReceipts';

export const SCREENPLAY_ACCESS_STATE = {
  SCREENPLAY_NOT_REQUESTED: 'SCREENPLAY_NOT_REQUESTED',
  SCREENPLAY_REQUESTED: 'SCREENPLAY_REQUESTED',
  SCREENPLAY_APPROVED: 'SCREENPLAY_APPROVED',
  SCREENPLAY_DECLINED: 'SCREENPLAY_DECLINED'
};

export const MESSAGE_TYPES = {
  SCREENPLAY_REQUEST: 'SCREENPLAY_REQUEST',
  CHAT: 'CHAT'
};

export const REQUEST_STATE = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED'
};
