import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import sidebarConstants from '@app/utils/sidebarConstants';
import { translate } from '../IntlGlobalProvider';
import logoutIcon from '@images/icons/logoutIcon.svg';
import { AnyAction } from '@reduxjs/toolkit';
import { logoutAction } from '@app/reducers';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setClient } from '@app/utils/graphqlUtils';
import { Auth } from 'aws-amplify';

export interface NavBarProps {
  dispatchLogout: () => AnyAction;
}

const NavBarContainer = styled.div`
  width: 18.75rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  text-align: center;
  padding: 1.437rem 4rem 4rem 4.68rem;
`;

const LogoTextContainer = styled.div`
  width: 9.93rem;
  margin-bottom: 2.187rem;
`;

const LogoText = styled.h1`
  ${fonts.style.spotwriterHeading}
  line-height: 2.125rem;
  text-align: center;
`;

const NavBarListContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const NavbarItems = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
`;

const NavbarItemLogout = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
`;

const StyledText = styled.p`
  ${fonts.size.small()}
  ${fonts.weights.fw700()}
  line-height: 1.12rem;
  color: ${colors.navbarText};
  text-align: center;
  white-space: nowrap;
`;

const LogoutContainer = styled.div`
  margin-top: auto;
  @media (max-height: 46.87rem) {
    margin-top: 3rem;
  }
`;

const NavBar = ({ dispatchLogout }: NavBarProps) => {
  const handleLogout = async () => {
    dispatchLogout();
    setClient('');
    localStorage.clear();
    await Auth.signOut();
  };

  const location = useLocation();

  const parms = new URLSearchParams(location.search);

  const activeTab = parms.get('activeTab');

  return (
    <NavBarContainer>
      <LogoTextContainer>
        <LogoText>{translate('spotwriters_logo')}</LogoText>
      </LogoTextContainer>
      <NavBarListContainer>
        {sidebarConstants.map((item) => (
          <NavbarItems to={`dashboard?activeTab=${item.key}`} key={item.key}>
            <img src={activeTab === item.key ? item.activeIcon : item.icon} />
            <StyledText>{translate(item.key)}</StyledText>
          </NavbarItems>
        ))}
      </NavBarListContainer>
      <LogoutContainer>
        <NavbarItemLogout onClick={handleLogout}>
          <img src={logoutIcon} />
          <StyledText>{translate('logout')}</StyledText>
        </NavbarItemLogout>
      </LogoutContainer>
    </NavBarContainer>
  );
};

const withConnect = connect(null, mapDispatchToProps);

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchLogout: () => dispatch(logoutAction())
  };
}

export default withConnect(NavBar);
