import { gql } from 'apollo-boost';

export const GET_PENDING_APPROVAL_STORY_DATA = gql`
  query pendingCopyrightApprovalsStory(
    $sellerId: ID
    $filters: PendingApprovalsWhere
    $pagination: PendingApprovalsPagination!
  ) {
    pendingCopyrightApprovals(sellerId: $sellerId, filters: $filters, pagination: $pagination) {
      stories {
        id
        title
        submittedAt
        createdAt
        signedStory {
          filepath
          updatedAt
        }
        signedNoc {
          filepath
          updatedAt
        }
        swaDetails {
          filepath
          updatedAt
        }
        signedPOA {
          filepath
          updatedAt
        }
        copyrightDetails {
          filepath
          updatedAt
        }
        selfAttestationDetails {
          filepath
          updatedAt
        }
        govtDocDetails {
          filepath
          updatedAt
        }
      }
      total
    }
  }
`;
