import { prepare } from '@app/utils';
import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';

export const initialState = {
  spotTicketsLoading: false,
  spotTicketsBalance: 0,
  spotTicketsTransactions: [],
  spotTicketsError: null
};

const mySpotTicketsSlice = createSlice({
  name: 'mySpotTickets',
  initialState,
  reducers: {
    requestGetSpotTickets: {
      reducer: (state) => {
        state.spotTicketsLoading = true;
      },
      prepare
    },
    successGetSpotTickets(state, action) {
      state.spotTicketsLoading = false;
      state.spotTicketsBalance = action.payload.currentBalance;
      state.spotTicketsTransactions = action.payload.transactionsHistory;
    },
    failureGetSpotTickets(state, action) {
      state.spotTicketsLoading = false;
      state.spotTicketsError = get(action.payload, 'message', 'failure');
    },
    successUpdateSpotTicketBalance(state, action) {
      state.spotTicketsBalance = action.payload.currentBalance;
    }
  }
});

export const { requestGetSpotTickets, successGetSpotTickets, failureGetSpotTickets, successUpdateSpotTicketBalance } =
  mySpotTicketsSlice.actions;

export default mySpotTicketsSlice.reducer;
