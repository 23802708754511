import React, { useEffect } from 'react';
import { CopyrightProtectionProps } from './types';
import CopyrightProtectionForm from './CopyrightProtectionForm';
import CopyrightStepsFlow from './CopyrightStepsFlow';
import CopyrightProtectionQuestion from './CopyrightProtectionQuestion';

const CopyrightProtection = ({
  shouldCopyright,
  isStoryCopyrighted,
  swaRegistrationDetails,
  sellerId,
  storyId,
  copyrightProcessCurrentStep,
  copyrightProtectionDetails,
  copyrightRegistrationDetails,
  signedPoaDocumentPath,
  signedStoryDocumentPath,
  signedStoryDocumentFileName,
  signedStoryPoaFileName,
  storyPdfUrl,
  selfAttestationDetails,
  userProfile,
  storyTitle,
  poaSignedDocumentPath,
  setStepFormValues,
  setIsStoryCopyrighted,
  setShouldCopyright,
  dispatchGetSwaRegistrationDetails,
  dispatchUpdateCopyrightRegistrationDetails,
  dispatchUpdateCopyrightSelfAttestationDetails,
  dispatchGetCopyrightProtectionDetails,
  dispatchUpdateCopyrightProtectionStrategy,
  dispatchGetCopyrightRegistrationDetails,
  dispatchSetSignedPoaDocumentPath,
  dispatchSetSignedStoryPath,
  dispatchUploadPoaDocumentDocument,
  dispatchUploadSignedStoryDocument,
  dispatchGenerateStoryPdf,
  dispatchGetSelfAttestationDetails,
  setCopyrightRegistrationFormValues,
  dispatchResetStoryPdfUrl,
  dispatchPreviewFile,
  dispatchGetPoaDocument
}: CopyrightProtectionProps) => {
  useEffect(() => {
    if (sellerId && storyId) {
      dispatchGetCopyrightProtectionDetails(sellerId, storyId);
      dispatchGetSwaRegistrationDetails(sellerId, storyId);
    }
  }, []);

  useEffect(() => {
    if (copyrightProtectionDetails && sellerId && storyId) {
      dispatchGetSelfAttestationDetails(sellerId, storyId, copyrightProtectionDetails.copyrightProtectionId);
    }
  }, [copyrightProtectionDetails, copyrightProcessCurrentStep]);

  useEffect(() => {
    if (swaRegistrationDetails) {
      if (!swaRegistrationDetails.isCopyrightActProtected && !swaRegistrationDetails.isStorySwaRegistered) {
        setShouldCopyright(true);
      }

      if (
        swaRegistrationDetails.isCopyrightActProtected !== null &&
        swaRegistrationDetails.isCopyrightActProtected !== undefined
      ) {
        setIsStoryCopyrighted(swaRegistrationDetails.isCopyrightActProtected ? 'yes' : 'no');
      }
    }
  }, [swaRegistrationDetails]);

  const getContent = () => {
    if (isStoryCopyrighted === 'yes') {
      return (
        <CopyrightProtectionForm
          sellerId={sellerId}
          storyId={storyId}
          dispatchUpdateCopyrightRegistrationDetails={dispatchUpdateCopyrightRegistrationDetails}
          copyrightProtectionDetails={copyrightProtectionDetails}
          dispatchGetCopyrightRegistrationDetails={dispatchGetCopyrightRegistrationDetails}
          copyrightRegistrationDetails={copyrightRegistrationDetails}
          setCopyrightRegistrationFormValues={setCopyrightRegistrationFormValues}
        />
      );
    } else {
      if (shouldCopyright) {
        return (
          <CopyrightStepsFlow
            storyId={storyId}
            sellerId={sellerId}
            copyrightProcessCurrentStep={copyrightProcessCurrentStep}
            copyrightProtectionDetails={copyrightProtectionDetails}
            signedPoaDocumentPath={signedPoaDocumentPath}
            signedStoryDocumentPath={signedStoryDocumentPath}
            signedStoryDocumentFileName={signedStoryDocumentFileName}
            signedStoryPoaFileName={signedStoryPoaFileName}
            swaRegistrationDetails={swaRegistrationDetails}
            storyPdfUrl={storyPdfUrl}
            selfAttestationDetails={selfAttestationDetails}
            userProfile={userProfile}
            storyTitle={storyTitle}
            poaSignedDocumentPath={poaSignedDocumentPath}
            setStepFormValues={setStepFormValues}
            dispatchUpdateCopyrightSelfAttestationDetails={dispatchUpdateCopyrightSelfAttestationDetails}
            dispatchSetSignedPoaDocumentPath={dispatchSetSignedPoaDocumentPath}
            dispatchSetSignedStoryPath={dispatchSetSignedStoryPath}
            dispatchUploadPoaDocumentDocument={dispatchUploadPoaDocumentDocument}
            dispatchUploadSignedStoryDocument={dispatchUploadSignedStoryDocument}
            dispatchGenerateStoryPdf={dispatchGenerateStoryPdf}
            dispatchResetStoryPdfUrl={dispatchResetStoryPdfUrl}
            dispatchPreviewFile={dispatchPreviewFile}
            dispatchGetPoaDocument={dispatchGetPoaDocument}
          />
        );
      } else {
        return (
          <CopyrightProtectionQuestion
            setShouldCopyright={setShouldCopyright}
            storyId={storyId}
            copyrightId={swaRegistrationDetails?.copyrightId}
            dispatchUpdateCopyrightProtectionStrategy={dispatchUpdateCopyrightProtectionStrategy}
          />
        );
      }
    }
  };

  return getContent();
};

export default CopyrightProtection;

export const CopyrightProtectionTest = CopyrightProtection;
