import React, { useEffect } from 'react';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { AnyAction, compose } from '@reduxjs/toolkit';

import { createStructuredSelector } from 'reselect';

import { requestGetScreenplayRequestsDetails } from './reducer';
import { RequestViewProps } from './types';
import { selectRequestsDetails } from './selectors';
import RequestDetialsCard from './components/RequestDetailsCard';

const RequestViewContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  background-color: ${colors.dashboardBg};
  padding-left: 2rem;
  padding-bottom: 2rem;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.93rem;
  padding-right: 4rem;
`;

const StoryNameTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  color: ${colors.offersReceivedColors.titleColor};
`;

const CardsContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 2.56rem;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
`;

const RequestView = ({ requestDetails, dispatchGetRequestsDetails }: RequestViewProps) => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const storyTitle = params.get('title');

  const storyId = params.get('story');

  useEffect(() => {
    if (storyId) {
      dispatchGetRequestsDetails(storyId);
    }
  }, []);

  const handleGoBack = () => {
    history.back();
  };

  return (
    <RequestViewContainer data-testid="offer-view-container">
      <BackButton onClick={handleGoBack} data-testid="back-btn">
        <StyledImg src={ArrowLeft} />
        {translate('back')}
      </BackButton>
      <TitleContainer>
        <StoryNameTitle>{storyTitle}</StoryNameTitle>
      </TitleContainer>

      <CardsContainer>
        {requestDetails.map((requestDetail: any) => (
          <RequestDetialsCard requestDetail={requestDetail} storyId={storyId} key={requestDetail.conversationId} />
        ))}
      </CardsContainer>
    </RequestViewContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  requestDetails: selectRequestsDetails()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetRequestsDetails: (storyId: string) =>
      dispatch(
        requestGetScreenplayRequestsDetails({
          storyId
        })
      )
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(RequestView) as React.FC;

export const RequestViewTest = RequestView;
