import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import { SCREENPLAY_ACCESS_STATE } from '@app/utils/constants';
import React from 'react';
import styled from 'styled-components';
import { If } from 'tsw-sdk';
import { AccessResponseCardProps } from '../../types';

const AccessResponseCardContainer = styled.div`
  width: 32.5rem;
  height: auto;
  min-height: 10.93rem;
  border-radius: 1.25rem;
  margin-left: auto;
  box-shadow: 0px 0px 1.13rem ${colors.offersReceivedColors.offerCardShadowColor};
  display: flex;
  flex-direction: column;
  padding: 2.72rem 0px 1.5rem 3.75rem;
  position: relative;
  background-color: ${colors.white};
  &:after {
    content: ' ';
    position: absolute;
    right: -3.4rem;
    bottom: -1rem;
    border-top: none;
    border-right: 4.68rem solid transparent;
    border-left: 3.12rem solid transparent;
    border-bottom: 3.12rem solid ${colors.white};
    transform: rotate(25deg);
  }
`;

const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const UserTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw900()};
  ${fonts.size.large()}
  line-height: 2.43rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const ResponseStatusText = styled.p`
  margin-top: 0.625rem;
  ${fonts.weights.fw400()};
  ${fonts.size.xBig()}
  line-height: 1.96rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const ResponseNotesText = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.regular()}
  line-height: 1.96rem;
  color: ${colors.offersReceivedColors.storyName};
`;

const AccessResponseCard = ({ text, state }: AccessResponseCardProps) => {
  return (
    <AccessResponseCardContainer>
      <CardContentContainer>
        <UserTitle>{translate('you')}</UserTitle>
        <If
          condition={state === SCREENPLAY_ACCESS_STATE.SCREENPLAY_APPROVED}
          otherwise={<ResponseStatusText>{translate('access_declined')}</ResponseStatusText>}
        >
          <ResponseStatusText>{translate('access_granted')}</ResponseStatusText>
        </If>

        <ResponseNotesText>{text}</ResponseNotesText>
      </CardContentContainer>
    </AccessResponseCardContainer>
  );
};

export default AccessResponseCard;
