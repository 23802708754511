import { gql } from 'apollo-boost';

export const GET_SUBMISSION_DRAFTS = gql`
  query Stories($filters: NewStoryFilters, $pagination: StoryPagination!) {
    stories(filters: $filters, pagination: $pagination) {
      stories {
        id
        title
        languages {
          id
        }
        genres {
          id
        }
        storyType {
          id
          name
        }
        tags {
          id
        }
        logline
        storyline
        createdAt
        updatedAt
        state
        expiresAt
        views
        ratings
        offers
      }
      total
    }
  }
`;

export const GET_OFFERS_RECEIVED = gql`
  query SellerOffers($filters: OfferFilters, $page: OffersPagination!) {
    sellerOffers(filters: $filters, pagination: $page) {
      offers {
        storyId
        title
        languages {
          id
          name
        }
        genres {
          id
          name
        }
        storyType {
          id
          name
        }
        tags {
          id
          name
        }
        ratings
        views
        offers {
          id
          offerId
          amount
          currency
          offerState
          offerEntity {
            id
            firstName
            lastName
            email
            profilePic
            profileBanner
            designation
            companyName
          }
          createdAt
          updatedAt
          deletedAt
          expiresAt
        }
      }
      total
    }
  }
`;
