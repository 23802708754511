import React, { useEffect, useState } from 'react';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import ArrowLeft from '@images/icons/ArrowLeftIcon.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import CounterOfferMessage from './components/CounterOfferMessage';
import OfferMessage from './components/OfferMessage';
import { Link, useLocation } from 'react-router-dom';
import routeConstants from '@app/utils/routeConstants';
import { If } from '@app/components';
import { Portal } from '@app/components/Portal';
import AcceptOfferModal from '../ManageOffer/components/AcceptOfferModal';
import CounterOfferModal from '../ManageOffer/components/CounterOfferModal';
import DeclineOfferModal from '../ManageOffer/components/DeclineOfferModal';
import declineOfferIcon from '@images/icons/OffersReceived/decline-offer.svg';
import counterOfferIcon from '@images/icons/OffersReceived/counter-offer.svg';
import acceptOfferIcon from '@images/icons/OffersReceived/accept-offer.svg';
import retractOfferIcon from '@images/icons/OffersReceived/retract-offer.svg';

import { connect } from 'react-redux';
import { AnyAction, compose } from '@reduxjs/toolkit';
import {
  requestConcludeOffer,
  requestCounterOffer,
  requestGetConversationData,
  requestGetTransactionData,
  requestSendMessage
} from './reducer';
import { createStructuredSelector } from 'reselect';
import {
  selectBuyerData,
  selectConversationData,
  selectCounteringOffer,
  selectDaysLeft,
  selectOfferEndsOn,
  selectOfferId,
  selectOfferPlacedOn,
  selectOfferState,
  selectTransactionData
} from './selectors';
import { NavigateProps, OfferHistoryProps, ToggleHistoryType } from './types';
import { BUYER, MESSAGE_TYPES, OFFER_ACTIONS, OFFER_STATES, REQUEST_STATE, SELLER } from '@app/utils/constants';
import { selectUserProfile } from '@app/containers/ProfileContainer/selectors';
import CounteringLoader from '../ManageOffer/components/CounteringOffer';
import RetractOfferModal from '../ManageOffer/components/RetractOfferModal';
import { Button } from 'antd';
import AccessResponseCard from './components/AccessResponseCard';
import AccessRequestCard from './components/AccessRequestCard';
import ScreenplayAccessModal from './components/ScreenplayAccessModal';

const OfferHistoryContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  background-color: ${colors.dashboardBg};
  padding-left: 2rem;
  padding-bottom: 2rem;
  padding-right: 1rem;
`;

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${fonts.weights.fw800()}
  ${fonts.size.small()}
  line-height: 1rem;
  color: ${colors.tagBorder};
  width: 4.25rem;
`;

const StyledImg = styled.img``;

const OfferHistoryLogContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 3.25rem;
  padding-right: 4.21rem;
  margin-bottom: 8rem;
`;

const AwaitingResponseContainer = styled.div`
  width: 41rem;
  margin-top: 1.875rem;
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  line-height: 1.75rem;
  color: ${colors.offersReceivedColors.storyName};
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const OfferEndsPill = styled.div`
  width: fit-content;
  height: 2.4rem;
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.39rem 1.18rem;
  background: ${colors.offersReceivedColors.scriptIncludedPillBg};
  color: ${colors.offersReceivedColors.scriptIncludedPillColor};
  border-radius: 1.18rem;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  ${fonts.weights.fw800()};
  ${fonts.size.small()};
  line-height: 1.56rem;
  white-space: nowrap;
`;

const NotesActionsContainer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const DeclineOfferBtn = styled.div`
  width: 10.12rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.offersReceivedColors.grayBtnBg};
  color: ${colors.offersReceivedColors.grayBtnColor};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
`;

const CounterOfferBtn = styled.div`
  width: 13.56rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.offersReceivedColors.grayBtnBg};
  color: ${colors.offersReceivedColors.grayBtnColor};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
`;

const AcceptOfferBtn = styled.div`
  width: 12.93rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: 2rem;
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
`;

const BtnImage = styled.img`
  margin-right: 0.625rem;
`;

const RetractOfferButton = styled(Button)`
  && {
    width: 13.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.62rem;
    background-color: ${colors.darkBorder};
    color: ${colors.white};
    border-radius: 0.5rem;
    ${fonts.weights.fw800()};
    ${fonts.size.regular()};
    line-height: 1.5rem;
    border: none;
    padding: 0.62rem 2rem;
    margin: 1.43rem auto;
  }
`;

const StyledLink = styled(Link)`
  && {
    width: 13.25rem;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NavigateButtonContainer = styled.div`
  display: flex;
`;

const NavigateButton = styled(Button)<NavigateProps>`
  border-radius: 0.5rem;
  padding: 0.75rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.isActive ? `${colors.primary}` : `${colors.offersReceivedColors.disableBackground}`};
  color: ${colors.white};
  cursor: pointer;
  ${fonts.weights.fw800()};
  ${fonts.size.xRegular()};
  line-height: 1.5rem;
  border: none;

  &:hover {
    background-color: ${(props) =>
      props.isActive ? `${colors.primary}` : `${colors.offersReceivedColors.disableBackground}`};
    color: ${colors.white};
    border: none;
  }

  &:focus {
    background-color: ${(props) =>
      props.isActive ? `${colors.primary}` : `${colors.offersReceivedColors.disableBackground}`};
    color: ${colors.white};
    border: none;
  }
`;

const EmptyDataContainer = styled.div`
  display: flex;
  margin-top: 4rem;
  align-items: center;
  justify-content: center;
`;
const EmptyDataText = styled.h2`
  ${fonts.weights.fw800()};
  ${fonts.size.regular()};
  line-height: 1.5rem;
  color: ${colors.primary};
`;

const OfferHistory = ({
  offerState,
  transactions,
  offerPlacedOn,
  offerEndsOn,
  daysLeft,
  userProfile,
  counteringOffer,
  conversationData,
  buyerData,
  offerId,
  dispatchGetOfferHistory,
  dispatchCounterOffer,
  dispatchConcludeOffer,
  dispatchGetConversationHistory,
  dispatchSendAccessResponse
}: OfferHistoryProps) => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const storyId = params.get('storyId');
  const buyerId = params.get('buyerId');
  const historyType = params.get('type');

  const [counterOfferModalVisible, setCounterOfferModalVisible] = useState<boolean>(false);
  const [acceptOfferModalVisible, setAcceptOfferModalVisible] = useState<boolean>(false);
  const [declineOfferModalVisible, setDeclineOfferModalVisible] = useState<boolean>(false);
  const [showRetractOffer, setShowRetractOffer] = useState<boolean>(false);
  const [lastValidTransaction, setLastValidTransaction] = useState<any>({});
  const [showHistory, setShowHistory] = useState<ToggleHistoryType>('showOfferHistory');
  const [showScreenplayAccessModal, setShowScreenplayAccessModal] = useState<boolean>(false);
  const [currStoryId, setCurrStoryId] = useState<string>('');
  const [currBuyerId, setCurrBuyerId] = useState<string>('');

  useEffect(() => {
    if (!counteringOffer && counterOfferModalVisible) {
      setCounterOfferModalVisible(false);
    }
  }, [counteringOffer]);

  useEffect(() => {
    if (historyType === 'conversation') {
      setShowHistory('showRequestHistory');
    } else {
      setShowHistory('showOfferHistory');
    }
  }, []);

  useEffect(() => {
    if (storyId && buyerId && userProfile?.id) {
      dispatchGetOfferHistory(storyId, userProfile?.id, buyerId);
      dispatchGetConversationHistory(storyId, userProfile?.id, buyerId);
      setCurrStoryId(storyId);
      setCurrBuyerId(buyerId);
    }
  }, [storyId, buyerId, userProfile]);

  useEffect(() => {
    const lastValidTransaction = getLastValidTransaction();

    setLastValidTransaction(lastValidTransaction);
  }, [transactions]);

  const getLastValidTransaction = () => {
    let lastValidTransaction = transactions[transactions.length - 1];

    if (transactions.length > 1) {
      const buyerTransactions = [OFFER_STATES.BUYER_COUNTERED, OFFER_STATES.OFFER_PLACED];

      const sellerOffers = transactions.filter((transaction: any) =>
        buyerTransactions.includes(transaction.offerState)
      );

      lastValidTransaction = sellerOffers[sellerOffers.length - 1];
    }

    return lastValidTransaction;
  };

  const handleGoBack = () => {
    history.back();
  };

  const handleCounterOffer = (counterOfferAmount: number, notesFromSeller: string) => {
    const buyerId = transactions[0].offerEntity.id;

    dispatchCounterOffer(currStoryId, offerId, userProfile?.id, buyerId, counterOfferAmount, notesFromSeller);

    setCounterOfferModalVisible(false);
  };

  const handleAcceptOffer = () => {
    const buyerId = transactions[0].offerEntity.id;

    dispatchConcludeOffer(currStoryId, offerId, userProfile?.id, buyerId, OFFER_ACTIONS.ACCEPT_OFFER, '');

    setAcceptOfferModalVisible(false);
  };

  const handleDeclineOffer = (reason: string) => {
    const buyerId = transactions[0].offerEntity.id;

    dispatchConcludeOffer(currStoryId, offerId, userProfile?.id, buyerId, OFFER_ACTIONS.DECLINE_OFFER, reason);

    setDeclineOfferModalVisible(false);
  };

  const handleRetractOffer = (reason: string) => {
    const buyerId = transactions[0].offerEntity.id;
    dispatchConcludeOffer(currStoryId, offerId, userProfile?.id, buyerId, OFFER_ACTIONS.RETRACT_OFFER, reason);

    setShowRetractOffer(false);
  };

  const handleSendResponse = (responseMessage: string, state: string) => {
    setShowScreenplayAccessModal(false);

    dispatchSendAccessResponse(
      currBuyerId,
      userProfile?.id,
      currStoryId,
      responseMessage,
      MESSAGE_TYPES.SCREENPLAY_REQUEST,
      state
    );
  };

  const getPillMessage = () => {
    let message = '';

    if (offerState === OFFER_STATES.SELLER_ACCEPTED) {
      message = translate('offer_accepted_by_you');
    } else if (offerState === OFFER_STATES.BUYER_ACCEPTED) {
      message = translate('offer_accepted_by_professional');
    } else {
      message = translate('offer_ends_in', { time: daysLeft });
    }

    return message;
  };

  return (
    <OfferHistoryContainer data-testid="offer-history-container">
      <NavigationContainer>
        <BackButton onClick={handleGoBack} data-testid="back-btn">
          <StyledImg src={ArrowLeft} />
          {translate('back')}
        </BackButton>
        <NavigateButtonContainer>
          <NavigateButton
            isActive={showHistory === 'showRequestHistory'}
            onClick={() => setShowHistory('showRequestHistory')}
            data-testid="request-history-btn"
          >
            {translate('send_requests')}
          </NavigateButton>
          <NavigateButton
            isActive={showHistory === 'showOfferHistory'}
            onClick={() => setShowHistory('showOfferHistory')}
            data-testid="offer-history-btn"
          >
            {translate('offers')}
          </NavigateButton>
        </NavigateButtonContainer>
      </NavigationContainer>
      <If condition={showHistory === 'showOfferHistory' && transactions?.length > 0}>
        <OfferHistoryLogContainer>
          {transactions.map((transaction: any) =>
            transaction.offerState !== OFFER_STATES.SELLER_DECLINED &&
            transaction.offerState !== OFFER_STATES.SELLER_ACCEPTED &&
            transaction.offerState !== OFFER_STATES.BUYER_DECLINED &&
            transaction.offerState !== OFFER_STATES.BUYER_ACCEPTED ? (
              transaction.offerEntity?.placedBy === SELLER ? (
                <CounterOfferMessage
                  transaction={transaction}
                  offerPlacedOn={offerPlacedOn}
                  offerEndsOn={offerEndsOn}
                  daysLeft={daysLeft}
                />
              ) : (
                <StyledLink
                  to={{
                    pathname: routeConstants.dashboard.route,
                    search: `?activeTab=offer-detail&offerId=${offerId}`,
                    state: {
                      transaction: transaction,
                      storyId: storyId,
                      currentState: offerState
                    }
                  }}
                >
                  <OfferMessage
                    transaction={transaction}
                    offerPlacedOn={offerPlacedOn}
                    offerEndsOn={offerEndsOn}
                    daysLeft={daysLeft}
                  />
                </StyledLink>
              )
            ) : (
              <></>
            )
          )}
        </OfferHistoryLogContainer>
        {offerState === OFFER_STATES.OFFER_PLACED || offerState === OFFER_STATES.BUYER_COUNTERED ? (
          <NotesActionsContainer>
            <DeclineOfferBtn onClick={() => setDeclineOfferModalVisible(true)} data-testid="decline-btn">
              <BtnImage src={declineOfferIcon} /> {translate('decline')}
            </DeclineOfferBtn>
            <CounterOfferBtn onClick={() => setCounterOfferModalVisible(true)} data-testid="counter-btn">
              <BtnImage src={counterOfferIcon} /> {translate('counter_offer')}
            </CounterOfferBtn>
            <AcceptOfferBtn onClick={() => setAcceptOfferModalVisible(true)} data-testid="accept-btn">
              <BtnImage src={acceptOfferIcon} /> {translate('accept_offer')}
            </AcceptOfferBtn>
          </NotesActionsContainer>
        ) : offerState === OFFER_STATES.SELLER_DECLINED ? (
          <AwaitingResponseContainer>{translate('declined_by_seller')}</AwaitingResponseContainer>
        ) : offerState === OFFER_STATES.BUYER_DECLINED ? (
          <AwaitingResponseContainer>{translate('declined_by_buyer')}</AwaitingResponseContainer>
        ) : (
          <>
            <If condition={offerState === OFFER_STATES.OFFER_PLACED || offerState === OFFER_STATES.SELLER_COUNTERED}>
              <AwaitingResponseContainer>{translate('awaiting_response')}</AwaitingResponseContainer>
            </If>

            {offerState !== OFFER_STATES.SELLER_DECLINED && offerState !== OFFER_STATES.BUYER_DECLINED && (
              <OfferEndsPill>{getPillMessage()}</OfferEndsPill>
            )}

            <If condition={offerState === OFFER_STATES.OFFER_PLACED || offerState === OFFER_STATES.SELLER_COUNTERED}>
              <RetractOfferButton onClick={() => setShowRetractOffer(true)} data-testid="retract-offer-btn">
                <StyledImg src={retractOfferIcon} />
                {translate('retract')}
              </RetractOfferButton>
            </If>
          </>
        )}
      </If>

      <If condition={showHistory === 'showOfferHistory' && transactions?.length === 0}>
        <EmptyDataContainer>
          <EmptyDataText>{translate('no_offer_history')}</EmptyDataText>
        </EmptyDataContainer>
      </If>

      <If condition={showHistory === 'showRequestHistory' && conversationData?.length === 0}>
        <EmptyDataContainer>
          <EmptyDataText>{translate('no_request_history')}</EmptyDataText>
        </EmptyDataContainer>
      </If>

      <If condition={showHistory === 'showRequestHistory' && conversationData?.length > 0}>
        <OfferHistoryLogContainer>
          {conversationData.map((conversation: any, index: number) =>
            conversation?.userType === BUYER ? (
              <AccessRequestCard
                key={index}
                text={conversation?.message}
                onClickResponse={() => setShowScreenplayAccessModal(true)}
                buyerData={buyerData}
                isLatest={index === conversationData?.length - 1}
              />
            ) : (
              <AccessResponseCard key={index} text={conversation?.message} state={conversation?.state} />
            )
          )}
        </OfferHistoryLogContainer>
      </If>

      <If condition={acceptOfferModalVisible}>
        <Portal>
          <AcceptOfferModal
            transaction={lastValidTransaction}
            handleCancel={() => setAcceptOfferModalVisible(false)}
            acceptOffer={handleAcceptOffer}
          />
        </Portal>
      </If>

      <If condition={counterOfferModalVisible}>
        <Portal>
          <CounterOfferModal
            currentOffer={lastValidTransaction?.amount}
            counteringOffer={counteringOffer}
            handleCancel={() => setCounterOfferModalVisible(false)}
            handleCounterOffer={handleCounterOffer}
          />
        </Portal>
      </If>

      <If condition={declineOfferModalVisible}>
        <Portal>
          <DeclineOfferModal
            handleCancel={() => setDeclineOfferModalVisible(false)}
            declineOffer={handleDeclineOffer}
          />
        </Portal>
      </If>
      {counteringOffer && (
        <Portal>
          <CounteringLoader />
        </Portal>
      )}

      <If condition={showRetractOffer}>
        <Portal>
          <RetractOfferModal handleCancel={() => setShowRetractOffer(false)} handleRetract={handleRetractOffer} />
        </Portal>
      </If>

      <If condition={showScreenplayAccessModal}>
        <Portal>
          <ScreenplayAccessModal
            handleBack={() => setShowScreenplayAccessModal(false)}
            handleSendResponse={handleSendResponse}
          />
        </Portal>
      </If>
    </OfferHistoryContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  offerState: selectOfferState(),
  transactions: selectTransactionData(),
  offerPlacedOn: selectOfferPlacedOn(),
  offerEndsOn: selectOfferEndsOn(),
  daysLeft: selectDaysLeft(),
  userProfile: selectUserProfile(),
  counteringOffer: selectCounteringOffer(),
  conversationData: selectConversationData(),
  buyerData: selectBuyerData(),
  offerId: selectOfferId()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetOfferHistory: (storyId: string, sellerId: string, buyerId: string) =>
      dispatch(
        requestGetTransactionData({
          storyId,
          sellerId,
          buyerId
        })
      ),
    dispatchCounterOffer: (
      storyId: string,
      offerId: string,
      sellerId: string,
      buyerId: string,
      counterOfferAmount: number,
      notesFromSeller: string
    ) =>
      dispatch(
        requestCounterOffer({
          amount: counterOfferAmount,
          notes: notesFromSeller,
          storyId,
          offerId,
          sellerId,
          buyerId
        })
      ),
    dispatchConcludeOffer: (
      storyId: string,
      offerId: string,
      sellerId: string,
      buyerId: string,
      action: string,
      reason: string
    ) =>
      dispatch(
        requestConcludeOffer({
          action: action,
          storyId,
          offerId,
          sellerId,
          buyerId,
          reason
        })
      ),
    dispatchGetConversationHistory: (storyId: string, sellerId: string, buyerId: string) =>
      dispatch(
        requestGetConversationData({
          storyId,
          sellerId,
          buyerId
        })
      ),
    dispatchSendAccessResponse: (
      buyerId: string,
      sellerId: string,
      storyId: string,
      message: string,
      type: string,
      state: string
    ) => dispatch(requestSendMessage({ buyerId, sellerId, storyId, message, type, state }))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferHistory) as React.FC;

export const OfferHistoryTest = OfferHistory;
