import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectPublishedStoryDomain = (state: any) => state.submittedStory || initialState;

export const selectStoryDetails = () =>
  createSelector(selectPublishedStoryDomain, (substate) => get(substate, 'publishedStoryData'));

export const selectScreenPlayUrl = () =>
  createSelector(selectPublishedStoryDomain, (substate) => get(substate, 'screenPlayUrl'));

export const selectNarrationUrl = () =>
  createSelector(selectPublishedStoryDomain, (substate) => get(substate, 'narrationUrl'));

export const selectImagesUrlList = () =>
  createSelector(selectPublishedStoryDomain, (substate) => get(substate, 'imagesUrl'));

export const selectSpotTicketBalanceAfterRenew = () =>
  createSelector(selectPublishedStoryDomain, (substate) => get(substate, 'spotBalanceAfterRenew'));

export const selectIsStoryRenewed = () =>
  createSelector(selectPublishedStoryDomain, (substate) => get(substate, 'isStoryRenewed'));
