import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  loading: false,
  signUpData: {},
  signUpError: null,
  otpError: null,
  reCaptchaState: null
};

const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    requestSignUpUser: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestVerifyOtp: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestResendOtp: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestVerifyRecaptcha: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    successSignUpUser(state, action) {
      state.signUpError = null;
      state.signUpData = action.payload;
      state.loading = false;
    },
    failureSignUpUser(state, action) {
      state.signUpError = get(action.payload, 'message', 'something went wrong');
      state.loading = false;
    },
    failureOtp(state, action) {
      state.otpError = get(action.payload, 'message', action.payload.message);
      state.loading = false;
    },
    successReCaptcha(state, action) {
      state.reCaptchaState = get(action.payload, 'message', 'Success');
      state.loading = false;
    },
    failureRecaptcha(state, action) {
      state.reCaptchaState = get(action.payload, 'message', 'Failure');
      state.loading = false;
    },
    resetState(state) {
      state.signUpError = null;
      state.otpError = null;
      state.signUpData = {};
      state.loading = false;
    },
    resetOtpErrorState(state) {
      state.otpError = null;
    },
    resetCaptchaState(state) {
      state.reCaptchaState = null;
    }
  }
});

export const {
  requestSignUpUser,
  requestVerifyOtp,
  requestResendOtp,
  requestVerifyRecaptcha,
  successSignUpUser,
  failureSignUpUser,
  failureOtp,
  successReCaptcha,
  failureRecaptcha,
  resetState,
  resetOtpErrorState,
  resetCaptchaState
} = signUpSlice.actions;

export default signUpSlice.reducer;
