import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { LegalModalProps } from '../../types';
import { colors, fonts } from '@app/themes';
import { translate } from '@app/components/IntlGlobalProvider';
import { CustomButton } from 'tsw-sdk';
import { If } from '@app/components';

const ModalContainer = styled(Modal)`
  &&& {
    width: 70rem !important;

    .ant-modal-content {
      height: 38rem;
      border-radius: 1.5rem;
    }

    .ant-modal-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .ant-modal-footer {
      display: none;
    }
  }
`;

const ModalContentContainer = styled.div`
  width: 32rem;
  height: 100%;
  padding-top: 1.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.primary};
  text-align: center;
`;

const ModalSubtitle = styled.p`
  ${fonts.weights.fw600()};
  ${fonts.size.regular()};
  line-height: 1.25rem;
  color: ${colors.offersReceivedColors.counterOfferFormLabel};
  margin-top: 3.18rem;
  text-align: center;
`;

const FooterBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.25rem;
`;

const NoBtn = styled(CustomButton)`
  width: 5.5rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.75rem 2rem;

  &&& {
    background: ${colors.offersReceivedColors.tagBg};
    background-color: ${colors.offersReceivedColors.tagBg};
    color: ${colors.offersReceivedColors.cancelBtnText};
  }
`;

const YesBtn = styled(CustomButton)`
  width: 5.5rem;
  height: 3rem;
  margin-left: 2rem;
`;

const LegalModal = ({ visible, offerId, onClose, dispatchSendMailToLegalTeam }: LegalModalProps) => {
  const [optionSelected, setOptionSelected] = useState<'yes' | 'no' | ''>('');

  useEffect(() => {
    if (optionSelected) {
      if (optionSelected === 'yes') {
        dispatchSendMailToLegalTeam(true, offerId);
      } else {
        dispatchSendMailToLegalTeam(false, offerId);
      }
    }
  }, [optionSelected]);

  return (
    <ModalContainer
      visible={visible}
      centered={true}
      onCancel={() => {
        setOptionSelected('');
        onClose();
      }}
      destroyOnClose={true}
    >
      <ModalContentContainer>
        <ModalTitle>{translate('legal_and_next_steps')}</ModalTitle>
        <If condition={optionSelected === ''}>
          <ModalSubtitle>{translate('congratulations_on_accepting')}</ModalSubtitle>
          <ModalSubtitle>{translate('legal_support_required')}</ModalSubtitle>
        </If>

        <If condition={optionSelected === 'yes'}>
          <ModalSubtitle>{translate('legal_team_available')}</ModalSubtitle>
        </If>

        <If condition={optionSelected === 'no'}>
          <ModalSubtitle>{translate('legal_team_shortly_connect')}</ModalSubtitle>
          <ModalSubtitle>{translate('legal_team_note')}</ModalSubtitle>
          <ModalSubtitle>{translate('legal_binding_offer')}</ModalSubtitle>
          <FooterBtns>
            <NoBtn
              onClick={() => {
                setOptionSelected('');
                onClose();
              }}
              data-testid="close-btn"
            >
              {translate('close')}
            </NoBtn>
            <YesBtn onClick={() => setOptionSelected('')} data-testid="change-response-btn">
              {translate('change_response')}
            </YesBtn>
          </FooterBtns>
        </If>

        <If condition={optionSelected === ''}>
          <FooterBtns>
            <NoBtn onClick={() => setOptionSelected('no')} data-testid="no-btn">
              {translate('no')}
            </NoBtn>
            <YesBtn onClick={() => setOptionSelected('yes')} data-testid="yes-btn">
              {translate('yes')}
            </YesBtn>
          </FooterBtns>
        </If>
      </ModalContentContainer>
    </ModalContainer>
  );
};

export default LegalModal;
