import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectNewSubmissionDomain = (state: any) => state.submission || initialState;

export const selectLanguageList = () =>
  createSelector(selectNewSubmissionDomain, (substate) => get(substate, 'languageList'));

export const selectGenreList = () =>
  createSelector(selectNewSubmissionDomain, (substate) => get(substate, 'genreList'));

export const selectLanGenreLoading = () =>
  createSelector(selectNewSubmissionDomain, (substate) => get(substate, 'languageGenreLoading'));

export const selectTagsList = () => createSelector(selectNewSubmissionDomain, (substate) => get(substate, 'tagsList'));

export const selectDraftedStory = () =>
  createSelector(selectNewSubmissionDomain, (substate) => get(substate, 'storiesDetails'));

export const selectSubmitStoryLoading = () =>
  createSelector(selectNewSubmissionDomain, (substate) => get(substate, 'loadingSubmitStory'));

export const selectScreenplayFileId = () =>
  createSelector(selectNewSubmissionDomain, (substate) => get(substate, 'screenPlayFileId'));

export const selectNarrationFileId = () =>
  createSelector(selectNewSubmissionDomain, (substate) => get(substate, 'narrationFileId'));

export const selectStoryTypeList = () =>
  createSelector(selectNewSubmissionDomain, (substate) => get(substate, 'storyTypeList'));
