import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  loadingDrafts: false,
  draftsData: null,
  draftsError: null,
  darftsTotal: 0,
  loadingPublishedStories: false,
  publishedStoriesData: null,
  publishedStoriesError: null,
  publishedStoriesTotal: 0,
  loadingPendingApprovalStories: false,
  pendingApprovalData: null,
  pendingApprovalError: null,
  pendingApprovalTotal: 0,
  loadingUnpublishedStories: false,
  unpublishedStoriesData: null,
  unpublishedStoriesError: null,
  unpublishedStoriesTotal: 0,
  loadingdeletedDrafts: false,
  deletedDraftsData: {},
  deletedDraftsTotal: 0,
  deletedDraftsError: null
};

const mySubmissionSlice = createSlice({
  name: 'mySubmission',
  initialState,
  reducers: {
    requestGetDrafts: {
      reducer: (state) => {
        state.loadingDrafts = true;
      },
      prepare
    },
    requestGetPublishedStories: {
      reducer: (state) => {
        state.loadingPublishedStories = true;
      },
      prepare
    },
    requestGetPendingApprovalStories: {
      reducer: (state) => {
        state.loadingPendingApprovalStories = true;
      },
      prepare
    },
    requestGetUnpublishedStories: {
      reducer: (state) => {
        state.loadingUnpublishedStories = true;
      },
      prepare
    },
    successGetDraftsData(state, action) {
      state.loadingDrafts = false;
      state.draftsData = action.payload.data;
      state.darftsTotal = action.payload.total;
    },
    failureGetDraftsData(state, action) {
      state.draftsError = get(action.payload, 'message', 'something went wrong');
      state.loadingDrafts = false;
    },
    successGetPublishedStories(state, action) {
      state.loadingPublishedStories = false;
      state.publishedStoriesData = action.payload.data;
      state.publishedStoriesTotal = action.payload.total;
    },
    failureGetPublishedStories(state, action) {
      state.publishedStoriesError = get(action.payload, 'message', 'something went wrong');
      state.loadingPublishedStories = false;
    },
    successGetPendingApprovalStories(state, action) {
      state.loadingPendingApprovalStories = false;
      state.pendingApprovalData = action.payload.data;
      state.pendingApprovalTotal = action.payload.total;
    },
    failureGetPendingApprovalStories(state, action) {
      state.pendingApprovalError = get(action.payload, 'message', 'something went wrong');
      state.loadingPendingApprovalStories = false;
    },
    successGetUnpublishedStories(state, action) {
      state.loadingUnpublishedStories = false;
      state.unpublishedStoriesData = action.payload.data;
      state.unpublishedStoriesTotal = action.payload.total;
    },
    failureGetUnpublishedStories(state, action) {
      state.unpublishedStoriesError = get(action.payload, 'message', 'something went wrong');
      state.loadingUnpublishedStories = false;
    },
    requestGetDeletedDraft: {
      reducer: (state) => {
        state.loadingdeletedDrafts = true;
      },
      prepare
    },
    successGetDeletedDraft(state, action) {
      state.loadingdeletedDrafts = false;
      state.deletedDraftsData = action.payload.data;
      state.deletedDraftsTotal = action.payload.total;
    },
    failureGetDeletedDraft(state, action) {
      state.loadingdeletedDrafts = false;
      state.deletedDraftsError = get(action.payload, 'message', 'something went wrong');
    }
  }
});

export const {
  requestGetDrafts,
  successGetDraftsData,
  failureGetDraftsData,
  requestGetPublishedStories,
  successGetPublishedStories,
  failureGetPublishedStories,
  requestGetPendingApprovalStories,
  successGetPendingApprovalStories,
  failureGetPendingApprovalStories,
  requestGetUnpublishedStories,
  successGetUnpublishedStories,
  failureGetUnpublishedStories,
  requestGetDeletedDraft,
  successGetDeletedDraft,
  failureGetDeletedDraft
} = mySubmissionSlice.actions;

export default mySubmissionSlice.reducer;
