import { gql } from 'apollo-boost';

export const VERIFY_RECAPTCHA = gql`
  mutation VerifyRecaptcha($input: RecaptchaToken!) {
    verifyRecaptcha(input: $input) {
      message
    }
  }
`;

export const CREATE_PROFILE = gql`
  mutation UpdateSellerProfile($sellerProfileInput: SellerProfileInput!) {
    updateSellerProfile(input: $sellerProfileInput)
  }
`;

export const GET_SIGNED_URL = gql`
  query SignedUrl($urlinput: SignedURLInput!) {
    signedURL(input: $urlinput) {
      rootFolder
      fileName
      signedURL
    }
  }
`;

export const GET_PROFILE_IMAGE_FILE_NAME = gql`
  mutation UploadSellerProfileImage {
    uploadSellerProfileImage {
      filepath
      fileName
      extensions
    }
  }
`;

export const PAYMENT = gql`
  mutation GeneratePaymentLink($amount: Int, $tickets: Int, $reason: PaymentMetaData, $notes: String!) {
    generatePaymentLink(input: { amount: $amount, tickets: $tickets, reason: $reason, notes: $notes }) {
      url
      status
      referenceId
    }
  }
`;

export const SellerAccountStatus = gql`
  query SellerAccountStatus {
    sellerAccountStatus {
      id
      isAccountBlocked
      subscription {
        isActive
        daysLeft
        dateOfRenewal
        planSelected
      }
      isRegistrationComplete
      hasSignUpPromo
      unreadNotifications
      tickets
    }
  }
`;
