import HomeIcon from '@images/icons/navbar/HomeIcon.svg';
import SubmissionsIcon from '@images/icons/navbar/SubmissionsIcon.svg';
import SpotTicketsIcon from '@images/icons/navbar/SpotTicketsIcon.svg';
import OffersIcon from '@images/icons/navbar/OffersIcon.svg';
// import CommunityIcon from '@images/icons/navbar/CommunityIcon.svg';
import NotifsIcon from '@images/icons/navbar/NotifsIcon.svg';
import ProfileIcon from '@images/icons/navbar/ProfileIcon.svg';

import ActiveHomeIcon from '@images/icons/navbar/HomeActiveIcon.svg';
// import ActiveCommunityIcon from '@images/icons/navbar/ActiveCommunityIcon.svg';
import ActiveNotifsIcon from '@images/icons/navbar/ActiveNotifsIcon.svg';
import ActiveProfileIcon from '@images/icons/navbar/ActiveProfileIcon.svg';
import ActiveSpotTicketsIcon from '@images/icons/navbar/ActiveSpotTicketsIcon.svg';
import ActiveSubmissionsIcon from '@images/icons/navbar/ActiveSubmissionsIcon.svg';
import ActiveOffersIcon from '@images/icons/navbar/ActiveOffersIcon.svg';

export type sidebarConstants = {
  icon: any;
  activeIcon?: any;
  key: string;
};

const sidebarConstants: sidebarConstants[] = [
  {
    icon: HomeIcon,
    activeIcon: ActiveHomeIcon,
    key: 'dashboard'
  },
  {
    icon: SubmissionsIcon,
    activeIcon: ActiveSubmissionsIcon,
    key: 'submissions'
  },
  {
    icon: SpotTicketsIcon,
    activeIcon: ActiveSpotTicketsIcon,
    key: 'spot_tickets'
  },
  {
    icon: OffersIcon,
    activeIcon: ActiveOffersIcon,
    key: 'offers_recieved_nav'
  },
  // {
  //   icon: CommunityIcon,
  //   activeIcon: ActiveCommunityIcon,
  //   key: 'community'
  // },
  {
    icon: NotifsIcon,
    activeIcon: ActiveNotifsIcon,
    key: 'notifications'
  },
  {
    icon: ProfileIcon,
    activeIcon: ActiveProfileIcon,
    key: 'profile'
  }
];

export default sidebarConstants;
