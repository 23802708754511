import React from 'react';
import { PageAnchorTypes } from '../containers/DashboardContainerPage/MySubmissions/types';

export const sectionAnchorData: PageAnchorTypes[] = [
  {
    title: 'my_draft',
    icon: (
      <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 0V23.2422H20.3369V11.6211H8.71582V0H0ZM11.6211 0V8.71582H20.3369L11.6211 0ZM2.90527 5.81055H5.81055V8.71582H2.90527V5.81055ZM2.90527 11.6211H5.81055V14.5264H2.90527V11.6211ZM2.90527 17.4316H14.5264V20.3369H2.90527V17.4316Z"
          fill="#7C7B7B"
        />
      </svg>
    )
  },
  {
    title: 'my_submission',
    icon: (
      <svg width="23" height="24" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.124023 4.47436C0.124023 2.14023 1.78896 0.248047 3.84277 0.248047H26.1539C28.2077 0.248047 29.8727 2.14023 29.8727 4.47436V5.89497C29.8727 7.94939 28.5828 9.66144 26.8733 10.0423C26.9051 10.2645 26.9216 10.4926 26.9216 10.7251V21.5217C26.9216 23.8559 25.2566 25.748 23.2028 25.748H6.79418C4.74037 25.748 3.07543 23.8559 3.07543 21.5217V10.7251C3.07543 10.4926 3.09194 10.2646 3.12371 10.0423C1.41401 9.66166 0.124023 7.94952 0.124023 5.89497V4.47436ZM3.84277 3.8706C3.54937 3.8706 3.31152 4.14091 3.31152 4.47436V5.89497C3.31152 6.22842 3.54937 6.49873 3.84277 6.49873H26.1539C26.4473 6.49873 26.6852 6.22842 26.6852 5.89497V4.47436C26.6852 4.14091 26.4473 3.8706 26.1539 3.8706H3.84277ZM6.79419 10.1213C6.50078 10.1213 6.26293 10.3917 6.26293 10.7251V21.5217C6.26293 21.8552 6.50078 22.1255 6.79418 22.1255H23.2028C23.4962 22.1255 23.7341 21.8552 23.7341 21.5217V10.7251C23.7341 10.3916 23.4962 10.1213 23.2028 10.1213H6.79419ZM12.0471 14.3121C11.1669 14.3121 10.4533 15.1231 10.4533 16.1234C10.4533 17.1237 11.1669 17.9347 12.0471 17.9347H18.6873C19.5675 17.9347 20.2811 17.1237 20.2811 16.1234C20.2811 15.1231 19.5675 14.3121 18.6873 14.3121H12.0471Z"
          fill="#7C7B7B"
        />
      </svg>
    )
  },
  {
    title: 'unpublished_story',
    icon: (
      <svg width="22" height="22" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.545568 0C0.229713 0 0 0.229713 0 0.545568V22.4544C0 22.7703 0.229713 23 0.545568 23H22.4544C22.7703 23 23 22.7703 23 22.4544V0.545568C23 0.229713 22.7703 0 22.4544 0L0.545568 0ZM2.87141 5.74282H20.0999V14.3571H17.2285L14.3571 17.2285H8.61423L5.74282 14.3571H2.87141V5.74282Z"
          fill="#7C7B7B"
        />
      </svg>
    )
  },
  {
    title: 'deleted_drafts',
    icon: (
      <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.41071 0C6.86875 0 5.60714 1.26161 5.60714 2.80357H2.80357C1.26161 2.80357 0 4.06518 0 5.60714H19.625C19.625 4.06518 18.3634 2.80357 16.8214 2.80357H14.0179C14.0179 1.26161 12.7563 0 11.2143 0H8.41071ZM2.80357 8.41072V21.8959C2.80357 22.2043 3.02786 22.4286 3.33625 22.4286H16.3168C16.6252 22.4286 16.8495 22.2043 16.8495 21.8959V8.41072H14.0459V18.2232C14.0459 19.0082 13.4291 19.625 12.6441 19.625C11.8591 19.625 11.2423 19.0082 11.2423 18.2232V8.41072H8.43875V18.2232C8.43875 19.0082 7.82196 19.625 7.03696 19.625C6.25196 19.625 5.63518 19.0082 5.63518 18.2232V8.41072H2.83161H2.80357Z"
          fill="#7C7B7B"
        />
      </svg>
    )
  }
];
