import React from 'react';
import NotFound from '@containers/NotFoundPage/Loadable';
import HomeContainer from '@containers/HomeContainer/Loadable';
import SignUpContainer from '@containers/SignUpContainer/Loadable';
import LoginContainer from '@containers/LoginContainer/Loadable';
import ProfileContainer from '@containers/ProfileContainer/Loadable';
import routeConstants, { RouteConstant } from '@utils/routeConstants';
import PackageDetailScreen from './containers/PackageDetailScreen/Loadable';
import DashBoardContainerPage from './containers/DashboardContainerPage';
import CopyrightContainer from './containers/CopyrightContainer';
import EditProfileContainer from './containers/EditProfileContainer';
import Auth from './containers/Auth';
import PaymentComplete from './containers/PaymentComplete';

type RouteConfig = Record<string, { component: React.FC<any> } & Partial<RouteConstant>>;

export const routeConfig: RouteConfig = {
  home: {
    component: HomeContainer,
    ...routeConstants.home
  },
  signUp: {
    component: SignUpContainer,
    ...routeConstants.signUp
  },
  login: {
    component: LoginContainer,
    ...routeConstants.login
  },
  authPage: {
    component: Auth,
    ...routeConstants.authPage
  },
  profile: {
    component: ProfileContainer,
    ...routeConstants.profile
  },
  packageDetailPage: {
    component: PackageDetailScreen,
    ...routeConstants.packageDetail
  },
  dashboardPage: {
    component: DashBoardContainerPage,
    ...routeConstants.dashboard
  },
  copyrightPage: {
    component: CopyrightContainer,
    ...routeConstants.copyrightContainer
  },
  editProfilePage: {
    component: EditProfileContainer,
    ...routeConstants.editProfileContainer
  },
  paymentCompletePage: {
    component: PaymentComplete,
    ...routeConstants.paymentComplete
  },
  notFoundPage: {
    component: NotFound,
    route: '/'
  }
};
