import { prepare } from '@app/utils';
import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';

export const initialState = {
  spotTicketsLinkLoading: false,
  spotTicketsLinkError: null
};

const addSpotTickets = createSlice({
  name: 'addSpotTickets',
  initialState,
  reducers: {
    requestGenerateSpotTicketsLink: {
      reducer: (state) => {
        state.spotTicketsLinkLoading = true;
      },
      prepare
    },
    successGenerateSpotTicketsLink(state) {
      state.spotTicketsLinkLoading = false;
    },
    failureGenerateSpotTicketsLink(state, action) {
      state.spotTicketsLinkLoading = false;
      state.spotTicketsLinkError = get(action.payload, 'message', 'failure');
    }
  }
});

export const { requestGenerateSpotTicketsLink, successGenerateSpotTicketsLink, failureGenerateSpotTicketsLink } =
  addSpotTickets.actions;

export default addSpotTickets.reducer;
