import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import downloadIcon from '@images/icons/download-icon-gray.svg';
import { PendingApprovalItemProps } from './types';
import moment from 'moment';
import { translate } from '@app/components/IntlGlobalProvider';
import { If } from 'tsw-sdk';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const PendingApprovalItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2.56rem;
`;

const PendingApprovalItemHeaderContainer = styled.p`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PendingApprovalDate = styled.p`
  ${fonts.weights.fw700()};
  ${fonts.size.xsmall()};
  line-height: 1rem;
  color: ${colors.pendingApprovalColors.dateColor};
`;

const StoryTitle = styled.p`
  ${fonts.family.SpoofFamily()}
  ${fonts.weights.fw900()};
  ${fonts.size.xBig()};
  line-height: 1.875rem;
  margin-top: 0.75rem;
  color: ${colors.pendingApprovalColors.storyColor};
`;

const StoryDocsContainer = styled.div`
  width: 100%;
  margin-top: 1.125rem;
  display: flex;
  align-items: center;
  gap: 3.75rem;
  flex-wrap: wrap;
`;

const StoryDocsCard = styled.div`
  width: 14.56rem;
  height: 7.43rem;
  background: ${colors.white};
  box-shadow: 0px 0px 1.25rem ${colors.pendingApprovalColors.cardShadow};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StoryDocsCardTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StoryDocName = styled.p`
  max-width: 9.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${fonts.weights.fw600()};
  ${fonts.size.small()};
  line-height: 1rem;
  color: ${colors.pendingApprovalColors.storyColor};
  margin-right: 0.75rem;
`;

const StyledImg = styled.img`
  cursor: pointer;
`;

const DocDate = styled.p`
  margin-top: 1rem;
  text-align: center;
  ${fonts.weights.fw700()};
  ${fonts.size.xsmall()};
  line-height: 1rem;
  color: ${colors.pendingApprovalColors.dateColor};
`;

const SpinIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

const PendingApprovalItem = ({
  storyData,
  getFileUrlLoading,
  generateStoryPdfUrlLoading,
  getSignedUrl,
  getStoryPdfUrl
}: PendingApprovalItemProps) => {
  // const getFileName = (filePath: string) => {
  //   const filePathArr = filePath.split('/');

  //   return filePathArr[filePathArr.length - 1];
  // };

  const handleFileDownload = (filePathString: string) => {
    const filePathArr = filePathString.split('/');
    const fileName = filePathArr[filePathArr.length - 1];

    filePathArr.pop();
    const filePath = filePathArr.join('/');

    getSignedUrl(filePath, fileName.replace('/', ''));
  };

  return (
    <PendingApprovalItemContainer>
      <PendingApprovalItemHeaderContainer>
        {storyData?.submittedAt && (
          <PendingApprovalDate>{moment(storyData?.createdAt).format('DD.MM.YYYY, HH:mm')}</PendingApprovalDate>
        )}
        <StoryTitle>{storyData?.title}</StoryTitle>
      </PendingApprovalItemHeaderContainer>
      <StoryDocsContainer>
        {storyData?.signedNoc && (
          <StoryDocsCard>
            <StoryDocsCardTitle>
              <StoryDocName>{translate('noc')}</StoryDocName>
              <If condition={!getFileUrlLoading} otherwise={<Spin indicator={SpinIcon} />}>
                <StyledImg
                  src={downloadIcon}
                  onClick={() => handleFileDownload(storyData?.signedNoc?.filepath)}
                  data-testid="download-btn-1"
                />
              </If>
            </StoryDocsCardTitle>
            <DocDate>{moment(storyData?.signedNoc?.updatedAt).format('DD.MM.YYYY | HH:mm')}</DocDate>
          </StoryDocsCard>
        )}
        {storyData?.signedPoa && (
          <StoryDocsCard>
            <StoryDocsCardTitle>
              <StoryDocName>{translate('poa')}</StoryDocName>
              <If condition={!getFileUrlLoading} otherwise={<Spin indicator={SpinIcon} />}>
                <StyledImg
                  src={downloadIcon}
                  onClick={() => handleFileDownload(storyData?.signedPoa?.filepath)}
                  data-testid="download-btn-2"
                />
              </If>
            </StoryDocsCardTitle>
            <DocDate>{moment(storyData?.signedPoa?.updatedAt).format('DD.MM.YYYY | HH:mm')}</DocDate>
          </StoryDocsCard>
        )}
        {storyData?.signedStory ? (
          <StoryDocsCard>
            <StoryDocsCardTitle>
              <StoryDocName>{translate('signed_story')}</StoryDocName>
              <If condition={!getFileUrlLoading} otherwise={<Spin indicator={SpinIcon} />}>
                <StyledImg
                  src={downloadIcon}
                  onClick={() => handleFileDownload(storyData?.signedStory?.filepath)}
                  data-testid="download-btn-3"
                />
              </If>
            </StoryDocsCardTitle>
            <DocDate>{moment(storyData?.signedStory?.updatedAt).format('DD.MM.YYYY | HH:mm')}</DocDate>
          </StoryDocsCard>
        ) : storyData?.submittedAt ? (
          <StoryDocsCard>
            <StoryDocsCardTitle>
              <StoryDocName>{`${storyData?.title}.pdf`}</StoryDocName>
              <If condition={!generateStoryPdfUrlLoading} otherwise={<Spin indicator={SpinIcon} />}>
                <StyledImg src={downloadIcon} onClick={getStoryPdfUrl} data-testid="download-btn-story" />
              </If>
            </StoryDocsCardTitle>
            <DocDate>{moment(storyData?.submittedAt).zone('GMT+0530').format('DD.MM.YYYY | HH:mm')}</DocDate>
          </StoryDocsCard>
        ) : (
          <></>
        )}
        {storyData?.swaDetails && (
          <StoryDocsCard>
            <StoryDocsCardTitle>
              <StoryDocName>{translate('swa_details')}</StoryDocName>

              <If condition={!getFileUrlLoading} otherwise={<Spin indicator={SpinIcon} />}>
                <StyledImg
                  src={downloadIcon}
                  onClick={() => handleFileDownload(storyData?.swaDetails?.filepath)}
                  data-testid="download-btn-4"
                />
              </If>
            </StoryDocsCardTitle>
            <DocDate>{moment(storyData?.swaDetails?.updatedAt).format('DD.MM.YYYY | HH:mm')}</DocDate>
          </StoryDocsCard>
        )}
        {storyData?.copyrightDetails && (
          <StoryDocsCard>
            <StoryDocsCardTitle>
              <StoryDocName>{translate('copyright_details')}</StoryDocName>

              <If condition={!getFileUrlLoading} otherwise={<Spin indicator={SpinIcon} />}>
                <StyledImg
                  src={downloadIcon}
                  onClick={() => handleFileDownload(storyData?.copyrightDetails?.filepath)}
                  data-testid="download-btn-5"
                />
              </If>
            </StoryDocsCardTitle>
            <DocDate>{moment(storyData?.copyrightDetails?.updatedAt).format('DD.MM.YYYY | HH:mm')}</DocDate>
          </StoryDocsCard>
        )}
        {storyData?.selfAttestationDetails && (
          <StoryDocsCard>
            <StoryDocsCardTitle>
              <StoryDocName>{translate('self_attestation_details')}</StoryDocName>

              <If condition={!getFileUrlLoading} otherwise={<Spin indicator={SpinIcon} />}>
                <StyledImg
                  src={downloadIcon}
                  onClick={() => handleFileDownload(storyData?.selfAttestationDetails?.filepath)}
                  data-testid="download-btn-6"
                />
              </If>
            </StoryDocsCardTitle>
            <DocDate>{moment(storyData?.copyrightDetails?.updatedAt).format('DD.MM.YYYY | HH:mm')}</DocDate>
          </StoryDocsCard>
        )}

        {storyData?.govtDocDetails && (
          <StoryDocsCard>
            <StoryDocsCardTitle>
              <StoryDocName>{translate('gov_docs')}</StoryDocName>
              <If condition={!getFileUrlLoading} otherwise={<Spin indicator={SpinIcon} />}>
                <StyledImg
                  src={downloadIcon}
                  onClick={() => handleFileDownload(storyData?.govtDocDetails?.filepath)}
                  data-testid="download-btn-7"
                />
              </If>
            </StoryDocsCardTitle>
            <DocDate>{moment(storyData?.govtDocDetails?.updatedAt).format('DD.MM.YYYY | HH:mm')}</DocDate>
          </StoryDocsCard>
        )}
      </StoryDocsContainer>
    </PendingApprovalItemContainer>
  );
};

export default PendingApprovalItem;
