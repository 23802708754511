import { prepare } from '@app/utils';
import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';

export const initialState = {
  loading: false,
  profileData: {},
  profileError: null,
  otpVerifySuccess: false,
  otpVerifyError: null,
  otpVerificationCodeSent: false,
  userProfile: { profilePic: '', profileBanner: '' },
  getProfileError: null,
  fileName: '',
  bannerFileName: '',
  uploadProfileImageLoading: false,
  uploadProfileImageSuccess: false,
  uploadProfileImageError: null,
  uploadBannerImageLoading: false,
  uploadBannerImageSuccess: false,
  uploadBannerImageError: null
};

const profileCreateSlice = createSlice({
  name: 'profileCreate',
  initialState,
  reducers: {
    requestPhoneVerification: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestVerifyOtp: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestCreateProfile: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    requestGetProfile: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },

    requestUploadProfileImage: {
      reducer: (state) => {
        state.uploadProfileImageLoading = true;
      },
      prepare
    },

    requestUploadBannerImage: {
      reducer: (state) => {
        state.uploadBannerImageLoading = true;
      },
      prepare
    },
    successCreateProfile(state, action) {
      state.loading = false;
      state.profileData = action.payload;
      state.profileError = null;
    },
    failureCreateProfile(state, action) {
      state.loading = false;
      state.profileError = get(action.payload, 'message', 'failure');
    },
    successUploadProfileImage(state) {
      state.uploadProfileImageError = null;
      state.uploadProfileImageLoading = false;
    },
    failureUploadProfileImage(state, action) {
      state.uploadProfileImageError = get(action.payload, 'message', 'something went wrong');
      state.uploadProfileImageLoading = false;
    },
    successUploadBannerImage(state) {
      state.uploadBannerImageError = null;
      state.uploadBannerImageLoading = false;
    },
    failureUploadBannerImage(state, action) {
      state.uploadBannerImageError = get(action.payload, 'message', 'something went wrong');
      state.uploadBannerImageLoading = false;
    },
    successGetFileName(state, action) {
      state.fileName = action.payload;
      state.userProfile.profilePic = action.payload;
    },
    successGetBannerFileName(state, action) {
      state.bannerFileName = action.payload;
      state.userProfile.profileBanner = action.payload;
    },
    successGetUserProfile(state, action) {
      state.userProfile = action.payload;
      state.getProfileError = null;
      state.loading = false;
    },
    failureGetUserProfile(state, action) {
      state.userProfile = {
        profileBanner: '',
        profilePic: ''
      };
      state.getProfileError = get(action.payload, 'message', 'something went wrong');
      state.loading = false;
    },
    successVerifyOtp(state) {
      state.loading = false;
      state.otpVerifySuccess = true;
      state.otpVerifyError = null;
    },
    successPhoneVerification(state) {
      state.otpVerificationCodeSent = true;
      state.otpVerifyError = null;
      state.loading = false;
    },
    failurePhoneVerification(state, action) {
      state.otpVerifyError = get(action.payload, 'message', 'failure');
      state.loading = false;
    },
    resetState(state) {
      state.loading = false;
      state.profileData = {};
      state.profileError = null;
      state.otpVerifySuccess = false;
      state.otpVerifyError = null;
      state.otpVerificationCodeSent = false;
      state.userProfile = {
        profilePic: '',
        profileBanner: ''
      };
      state.getProfileError = null;
      state.fileName = '';
      state.uploadProfileImageLoading = false;
      state.uploadProfileImageSuccess = false;
      state.uploadProfileImageError = null;
      state.uploadBannerImageLoading = false;
      state.uploadBannerImageSuccess = false;
      state.uploadBannerImageError = null;
    }
  }
});

export const {
  requestPhoneVerification,
  failurePhoneVerification,
  successPhoneVerification,
  successVerifyOtp,
  requestVerifyOtp,
  requestCreateProfile,
  successCreateProfile,
  failureCreateProfile,
  requestGetProfile,
  successGetUserProfile,
  failureGetUserProfile,
  resetState,
  requestUploadProfileImage,
  successUploadProfileImage,
  failureUploadProfileImage,
  successGetFileName,
  successGetBannerFileName,
  requestUploadBannerImage,
  successUploadBannerImage,
  failureUploadBannerImage
} = profileCreateSlice.actions;

export default profileCreateSlice.reducer;
