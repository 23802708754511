import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  renewSubscriptionLoading: false,
  renewSubscriptionError: null
};

const dashboardContainerSlice = createSlice({
  name: 'dashboardContainer',
  initialState,
  reducers: {
    requestRenewSubscription: {
      reducer: (state) => {
        state.renewSubscriptionLoading = true;
      },
      prepare
    },
    successRenewSubscription(state) {
      state.renewSubscriptionLoading = false;
    },
    failureRenewSubscription(state, action) {
      state.renewSubscriptionLoading = false;
      state.renewSubscriptionError = get(action.payload, 'message', 'something went wrong');
    },
    resetState(state) {
      state.renewSubscriptionError = null;
      state.renewSubscriptionLoading = false;
    }
  }
});

export const { requestRenewSubscription, successRenewSubscription, failureRenewSubscription, resetState } =
  dashboardContainerSlice.actions;

export default dashboardContainerSlice.reducer;
