import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Step4Props } from './types';
import { CustomButton } from 'tsw-sdk';
import downloadIcon from '@images/icons/download-icon.svg';
import { ArrowRightOutlined } from '@ant-design/icons';
import { COPYRIGHT_PROTECTION_STEPS } from '@app/utils/constants';

const StepContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const StepTitle = styled.h2`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
`;

const StepDescription = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;
  margin-top: 1.5rem;
`;

const DownloadStoryBtn = styled(CustomButton)`
  margin-top: 1.5rem;
`;

const DownloadIcon = styled.img`
  margin-left: 0.5rem;
`;

const ProceedBtn = styled(CustomButton)`
  margin-top: 3rem;
  margin-bottom: 2rem;

  && {
    width: 100%;
    border: 1px solid ${colors.primary};
    justify-content: center;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const Step4 = ({
  storyId,
  copyrightProtectionDetails,
  sellerId,
  poaSignedDocumentPath,
  dispatchUpdateCopyrightSelfAttestationDetails,
  dispatchGetPoaDocument,
  dispatchSetSignedPoaDocumentPath
}: Step4Props) => {
  useEffect(() => {
    if (sellerId && storyId) {
      dispatchSetSignedPoaDocumentPath('');
      dispatchGetPoaDocument(sellerId.toString(), storyId.toString());
    }
  }, [sellerId, storyId]);

  const handleProceed = () => {
    dispatchUpdateCopyrightSelfAttestationDetails({
      data: {
        storyId: storyId,
        copyrightProtectionID: copyrightProtectionDetails?.copyrightProtectionId,
        mode: 'PROCEED',
        currentStep: COPYRIGHT_PROTECTION_STEPS.STEP4
      },
      nextStep: COPYRIGHT_PROTECTION_STEPS.STEP5
    });
  };

  const handleFileDownload = () => {
    window.open(poaSignedDocumentPath, '_blank');
  };

  return (
    <StepContainer>
      <StepTitle>{translate('step4_title')}</StepTitle>
      <StepDescription>{translate('step4_description')}</StepDescription>

      <DownloadStoryBtn onClick={handleFileDownload} data-testid="download-file-step4">
        {translate('download_document')} <DownloadIcon src={downloadIcon} />
      </DownloadStoryBtn>

      <ProceedBtn
        onClick={handleProceed}
        text_color={`${colors.darkBorder}`}
        data-testid="step4-proceed"
        disabled={poaSignedDocumentPath === ''}
      >
        {translate('proceed')} <ArrowRightOutlined />
      </ProceedBtn>
    </StepContainer>
  );
};

export default Step4;
