import { translate } from '@app/components/IntlGlobalProvider';
import { SubscriptionRenewedPageProps } from '@app/containers/DashboardContainerPage/types';
import { colors, fonts } from '@app/themes';
import routeConstants from '@app/utils/routeConstants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CustomButton } from 'tsw-sdk';

const PaymentCompleteModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
`;

const PageTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.extraLarge()};
  line-height: 3.93rem;
  color: ${colors.primary};
  margin-bottom: 2rem;
`;

const SpotTicketBalanceContainer = styled.div`
  width: 31.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :nth-child(3) {
    margin-top: 1rem;
  }
`;

const ContainerText = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.big()};
  line-height: 1.62rem;
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
`;

const Divider = styled.div`
  height: 0.12rem;
  width: 31rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin-top: 1.75rem;
  margin-bottom: 0.75rem;
`;

const BackButton = styled(CustomButton)`
  width: 8.5rem;
  margin-top: 2.37rem;
  margin-left: auto;
  margin-right: auto;
`;

const SubscriptionRenewedPage = ({ spotTicketsBalance }: SubscriptionRenewedPageProps) => {
  const history = useHistory();
  return (
    <PaymentCompleteModalContainer>
      <PageTitle>{translate('paid_spot_tickets')}</PageTitle>

      <SpotTicketBalanceContainer>
        <ContainerText>{translate('spot_tickets_added')}</ContainerText>
        <ContainerText>{translate('tickets_amount', { amount: spotTicketsBalance + 500 })}</ContainerText>
      </SpotTicketBalanceContainer>
      <SpotTicketBalanceContainer>
        <ContainerText>{translate('paid_subscription')}</ContainerText>
        <ContainerText>{translate('tickets_amount', { amount: 500 })}</ContainerText>
      </SpotTicketBalanceContainer>
      <Divider />
      <SpotTicketBalanceContainer>
        <ContainerText>{translate('balance')}</ContainerText>
        <ContainerText>{translate('tickets_amount', { amount: spotTicketsBalance })}</ContainerText>
      </SpotTicketBalanceContainer>

      <BackButton onClick={() => history.push(routeConstants.dashboard.route)} data-testid="back-btn">
        {translate('back')}
      </BackButton>
    </PaymentCompleteModalContainer>
  );
};

export default SubscriptionRenewedPage;
