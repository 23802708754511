import { getQueryResponse } from '@app/utils/graphqlUtils';

import { AnyAction } from '@reduxjs/toolkit';
import { get } from 'lodash-es';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { requestSendMailToLegalTeam, failureSendMailToLegalTeam, successSendMailToLegalTeam } from './reducer';
import { SEND_MAIL_TO_LEGAL_TEAM } from './queries';

export function* sendMailToLegalTeam(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      supportNeeded: action.payload.supportNeeded,
      isBuyer: false,
      offerId: action.payload.offerId
    };
    const { ok, error } = yield call(getQueryResponse, SEND_MAIL_TO_LEGAL_TEAM, {
      input: payload
    });

    if (ok) {
      yield put(successSendMailToLegalTeam());
    } else {
      yield put(failureSendMailToLegalTeam(get(error, 'message', 'something_went_wrong')));
    }
  } catch (e: any) {
    yield put(failureSendMailToLegalTeam(get(e, 'message', 'something_went_wrong')));
  }
}

export default function* acceptedOfferContainerSaga() {
  yield all([takeLatest(requestSendMailToLegalTeam.toString(), sendMailToLegalTeam)]);
}

export const acceptedOfferSagaArr = [takeLatest(requestSendMailToLegalTeam.toString(), sendMailToLegalTeam)];
