import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Step1Props } from './types';
import _ from 'lodash-es';
import { CustomButton, If, PageSpinner } from 'tsw-sdk';
import downloadIcon from '@images/icons/download-icon.svg';
import { ArrowRightOutlined } from '@ant-design/icons';
import { COPYRIGHT_PROTECTION_STEPS } from '@app/utils/constants';

const StepContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const StepTitle = styled.h2`
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
`;

const StepDescription = styled.p`
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  line-height: 1.31rem;

  &:first-of-type {
    margin-top: 1.5rem;
  }
`;

const DownloadStoryBtn = styled(CustomButton)`
  margin-top: 1.5rem;
`;

const DownloadIcon = styled.img`
  margin-left: 0.5rem;
`;

const ProceedBtn = styled(CustomButton)`
  margin-top: 3rem;
  margin-bottom: 2rem;

  && {
    width: 100%;
    border: 1px solid ${colors.primary};
    justify-content: center;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const FileDownloadLink = styled.a`
  display: none;
`;

const Step1 = ({
  storyId,
  copyrightProtectionDetails,
  sellerId,
  storyPdfUrl,
  dispatchUpdateCopyrightSelfAttestationDetails,
  dispatchGenerateStoryPdf,
  dispatchResetStoryPdfUrl
}: Step1Props) => {
  const fileDownloadRef = useRef<any>(null);

  useEffect(() => {
    dispatchGenerateStoryPdf(sellerId, storyId);

    return () => {
      dispatchResetStoryPdfUrl();
    };
  }, []);

  const handleProceed = () => {
    dispatchUpdateCopyrightSelfAttestationDetails({
      data: {
        storyId: storyId,
        copyrightProtectionID: copyrightProtectionDetails?.copyrightProtectionId,
        mode: 'PROCEED',
        currentStep: COPYRIGHT_PROTECTION_STEPS.STEP1
      },
      nextStep: COPYRIGHT_PROTECTION_STEPS.STEP2
    });
  };

  const handleFileDownload = () => {
    fileDownloadRef?.current.click();
  };

  return (
    <StepContainer>
      <If condition={storyPdfUrl === ''}>
        <PageSpinner loading={true} />
      </If>
      <FileDownloadLink href={storyPdfUrl} ref={fileDownloadRef} target="_blank" download={true} />
      <StepTitle>{translate('step1_title')}</StepTitle>
      {_.range(3).map((number) => (
        <StepDescription key={number}>{translate(`step1_description_${number + 1}`)}</StepDescription>
      ))}
      <DownloadStoryBtn onClick={handleFileDownload} disabled={storyPdfUrl === ''} data-testid="file-download-step1">
        {translate('download_story')} <DownloadIcon src={downloadIcon} />
      </DownloadStoryBtn>

      <ProceedBtn
        onClick={handleProceed}
        text_color={`${colors.darkBorder}`}
        data-testid="step1-proceed"
        disabled={storyPdfUrl === ''}
      >
        {translate('proceed')} <ArrowRightOutlined />
      </ProceedBtn>
    </StepContainer>
  );
};

export default Step1;
