import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectOffersReceivedDomain = (state: any) => state.offerStoryReducer || initialState;

export const selectOpenOffers = () =>
  createSelector(selectOffersReceivedDomain, (substate) => get(substate, 'openOffers'));

export const selectClosedOffers = () =>
  createSelector(selectOffersReceivedDomain, (substate) => get(substate, 'closedOffers'));

export const selectDeclinedOffers = () =>
  createSelector(selectOffersReceivedDomain, (substate) => get(substate, 'declinedOffers'));

export const selectOpenOffersTotal = () =>
  createSelector(selectOffersReceivedDomain, (substate) => get(substate, 'openOffersTotal'));

export const selectClosedOffersTotal = () =>
  createSelector(selectOffersReceivedDomain, (substate) => get(substate, 'closedOffersTotal'));

export const selectDeclinedOffersTotal = () =>
  createSelector(selectOffersReceivedDomain, (substate) => get(substate, 'declinedOffersTotal'));

export const selectScreenplayRequests = () =>
  createSelector(selectOffersReceivedDomain, (substate) => get(substate, 'screenplayRequests'));

export const selectScreenplayRequestsTotal = () =>
  createSelector(selectOffersReceivedDomain, (substate) => get(substate, 'screenplayRequestsTotal'));

export const selectOpenOffersLoading = () =>
  createSelector(selectOffersReceivedDomain, (substate) => get(substate, 'getOpenOffersLoading'));

export const selectClosedOffersLoading = () =>
  createSelector(selectOffersReceivedDomain, (substate) => get(substate, 'getClosedOffersLoading'));
