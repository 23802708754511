import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '@app/themes';
import ArrowRight from '@images/icons/ArrowRightIcon.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { If } from 'tsw-sdk';

export interface OffersReceivedProps {
  offers: any[];
}

const OffersContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Heading = styled.h1`
  ${fonts.style.mulishHeading}
  color:${colors.myDashboardColors.subHeading};
  margin-bottom: 0;
  line-height: 1.75rem;
`;

const OffersDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 1.2rem;
`;

const DetailTitle = styled.p`
  ${fonts.style.mulishSubHeading}
  color:${colors.darkBorder};
  line-height: 1.25rem;
`;

const CustomTable = styled.table`
  width: 100%;
`;

const CustomTableHead = styled.th`
  && {
    ${fonts.weights.fw700()}
    font-size: 0.5rem;
    color: ${colors.myDashboardColors.tableText};
    text-align: left;
  }
`;

const CustomTableData = styled.td`
  font-size: 0.6rem;
  font-weight: 600;
  color: #333333;
`;

const ViewDiv = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.37rem 0;
  gap: 0.5rem;
  margin-top: auto;
  margin-bottom: 0px;
  color: ${colors.myDashboardColors.viewText};
`;

const ViewText = styled.div`
  ${fonts.size.small()}
  ${fonts.weights.fw800()}
colors:${colors.myDashboardColors.viewText}
`;

const SubHeading = styled.p`
  ${fonts.size.regular()}
  ${fonts.weights.fw700()}
  color: ${colors.darkBorder};
  margin-top: 0.6rem;
  line-height: 1.25rem;
`;

const OffersReceived = ({ offers }: OffersReceivedProps) => {
  return (
    <OffersContainer>
      <Heading>{translate('offers_recieved')}</Heading>
      <If condition={offers.length === 0}>
        <SubHeading>{translate('no_offer')}</SubHeading>
      </If>
      {offers.length > 0 &&
        offers.map((offerItem) => (
          <OffersDetailContainer key={offerItem?.id}>
            <DetailTitle>{offerItem?.storyTitle}</DetailTitle>

            <CustomTable>
              <tbody>
                <tr>
                  <CustomTableHead>{translate('offerer_name')}</CustomTableHead>
                  <CustomTableHead>{translate('offer_amt')}</CustomTableHead>
                  <CustomTableHead>{translate('offer_exp')}</CustomTableHead>
                  <CustomTableHead>{translate('days_left')}</CustomTableHead>
                </tr>
                <tr>
                  <CustomTableData>{`${offerItem?.offerEntity?.firstName} ${offerItem?.offerEntity?.lastName}`}</CustomTableData>
                  <CustomTableData>
                    {translate('amount', { amount: offerItem?.amount?.toLocaleString('en-IN') })}
                  </CustomTableData>
                  <CustomTableData>
                    {moment(offerItem?.expiresAt).zone('GMT+0530').format('MMM DD,YYYY')}
                  </CustomTableData>
                  <CustomTableData>{moment(offerItem?.expiresAt).fromNow()}</CustomTableData>
                </tr>
              </tbody>
            </CustomTable>
          </OffersDetailContainer>
        ))}

      <If condition={offers.length > 0}>
        <ViewDiv to="/dashboard?activeTab=offers_recieved_nav">
          <ViewText>{translate('view_all')}</ViewText>
          <img src={ArrowRight} />
        </ViewDiv>
      </If>
    </OffersContainer>
  );
};

export default OffersReceived;
