import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { CopyrightStepProgressProps } from './types';
import circleCheckedIcon from '@images/icons/circle-checked-icon.svg';
import _ from 'lodash-es';

const CopyrightStepProgressContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
`;

const CopyrightStepItem = styled.div`
  width: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CopyrightStepItemCircle = styled.div`
  width: 2rem;
  height: 2rem;
  border: 2px solid ${colors.copyrightColors.timelineItemBoxActive};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CopyrightStepCircleActive = styled.div`
  width: 0.62rem;
  height: 0.62rem;
  border-radius: 100%;
  background-color: ${colors.copyrightColors.timelineTrackColorActive};
`;

const CopyrightCircleStepNumber = styled.p`
  ${fonts.weights.fw400()};
  ${fonts.size.small()};
  line-height: 1.25rem;
  color: ${colors.primary};
`;

const CopyrightStepCheckmark = styled.img``;

const CopyrightStepLine = styled.div`
  width: 7rem;
  height: 2px;
  background-color: ${colors.copyrightColors.timelineItemBoxActive};
  position: absolute;
  left: 2rem;
  top: 1rem;
`;

const CopyrightStepProgressBar = ({ currentStep }: CopyrightStepProgressProps) => {
  return (
    <CopyrightStepProgressContainer>
      {_.range(5).map((number) => (
        <CopyrightStepItem key={number}>
          {currentStep > number + 1 ? (
            <CopyrightStepCheckmark src={circleCheckedIcon} />
          ) : (
            <CopyrightStepItemCircle>
              {currentStep === number + 1 && <CopyrightStepCircleActive />}
            </CopyrightStepItemCircle>
          )}
          {number !== 4 && <CopyrightStepLine />}
          <CopyrightCircleStepNumber>{number + 1}</CopyrightCircleStepNumber>
        </CopyrightStepItem>
      ))}
    </CopyrightStepProgressContainer>
  );
};

export default CopyrightStepProgressBar;

export const CopyrightStepProgressBarTest = CopyrightStepProgressBar;
