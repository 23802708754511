import { getQueryResponse } from '@app/utils/graphqlUtils';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get } from 'lodash-es';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_OFFER_DETAILS } from './queries';
import { requestGetOfferDetails, successGetOfferDetails, failureGetOfferDetails } from './reducer';

export function* getOfferDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const payload = {
      input: {
        offerId: action.payload.offerId
      }
    };
    const { ok, data, error } = yield call(getQueryResponse, GET_OFFER_DETAILS, payload);

    if (ok) {
      const buyerOfferDetails = get(data, 'buyerOfferDetails', {});

      yield put(successGetOfferDetails(buyerOfferDetails));
    } else {
      yield put(failureGetOfferDetails(get(error, 'message', 'something_went_wrong')));
      message.error(get(error, 'message', 'something_went_wrong'));
    }
  } catch (e: any) {
    yield put(failureGetOfferDetails(get(e, 'message', 'something_went_wrong')));
    const errorMessage = get(e, 'message', 'something_went_wrong');

    message.error(errorMessage.substring(errorMessage.indexOf(':') + 1));
  }
}

export default function* offerDetailsContainerSaga() {
  yield all([takeLatest(requestGetOfferDetails.toString(), getOfferDetails)]);
}

export const offerDetailsContainerSagaArr = [takeLatest(requestGetOfferDetails.toString(), getOfferDetails)];
