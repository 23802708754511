/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

const primary = '#333333';
const text = '#212529';
const secondaryText = '#ffffff';
const white = '#FFFFFF';
const secondary = '#b0b0b0';
const success = '#28a745';
const error = '#dc3545';
const gotoStories = '#1890ff';
const cardBg = '#d8d8d8';
const lightBorder = '#a0a0a0';
const darkBorder = '#333333';
const headerColor = '#bdbdbd';
const dropDownColor = '#eff1f1';
const tagBg = '#efefef';
const tagBorder = '#383838';
const disabledBg = '#efefef';
const cardHeader = '#d9d9d9';
const cardListItem = '#5c5c5c';
const checkboxborder = '#d7d7d7';
const cardHoverBg = '#e8e8e8';
const cardActiveBg = '#f0f0f0';
const btnDisabled = '#525252';
const navbarText = '#8A8A8A';
const dashboardBg = '#f5f5f5';
const transparentLoadingBg = 'rgba(255,255,255,0.8)';
const redClr = '#FF0000';
const formTextColor = '#121212';
const notifMessage = '#4F4F4F';

const myDashboardColors = {
  heading: '#A1A1A1',
  subHeading: '#181818',
  tableText: '#A9A9A9',
  viewText: '#4F4F4F',
  trendingStoryDate: '#747474',
  cardShadow: 'rgba(132, 132, 132, 0.2)',
  dividerColor: '#ededed',
  pendingClr: '#DB5D5D',
  subscriptionInActiveColor: 'rgba(0, 0, 0, 0.76)',
  buttonTextColor: '#3D3D3D',
  accordionBorder: '#CACACA'
};
const mySubmissions = {
  cardHeaderBg: '#c4c4c4',
  headingClr: '#333333',
  lightText: '#A9A9A9',
  tagBg: '#EEEEEE',
  backgroundfTextClr: '#DEDEDE',
  textClr: '#383838',
  progressText: '#4F4F4F',
  disableClr: '#DDDDDD',
  buttonClr: '#3D3D3D',
  tipShadow: 'rgba(132, 132, 132, 0.2)',
  anchorHover: '#3F3F3F',
  deletedDraftDate: '#070707'
};

const copyrightColors = {
  backgroundColor: '#f5f5f5',
  timelineItemText: '#8A8A8A',
  timelineItemBox: '#EFEFEF',
  timelineItemBoxActive: '#696767',
  timelineItemTextActive: '#333333',
  timelineTrackColor: '#EFEFEF',
  timelineTrackColorActive: '#696767',
  copyrightDeclarationTitle: '#3C3C3C',
  copyrightDeclarationQuesion: '#A1A1A1',
  storyUploadedInfo: '#DB5D5D',
  btnBorder: '#FF7F37',
  btnDisabled: '#A9A9A9',
  goBackBtnColor: '#A0A0A0',
  totalDueTextColor: '#BABABA'
};

const offersReceivedColors = {
  titleColor: '#A1A1A1',
  offerCardShadowColor: 'rgba(132, 132, 132, 0.2)',
  offerCardHeaderBg: '#C4C4C4',
  storyName: '#333333',
  offerDateColor: '#A9A9A9',
  scriptIncludedPillBg: '#EEEEEE',
  scriptIncludedPillColor: '#A9A9A9',
  cardHeaderBgTextColor: '#DEDEDE',
  closedPillBg: '#D8D8D8',
  offerCardPillText: '#757575',
  grayBtnBg: '#E0E0E0',
  grayBtnColor: '#4F4F4F',
  counterOfferNoteColor: '#A0A0A0',
  counterOfferFormLabel: '#383838',
  collapseBtnBg: '#7C7B7B',
  tagBg: '#EFEFEF',
  disableBg: '#DDDDDD',
  tableHeadingBorder: '#D9D9D9',
  checkboxLabelColor: '#121212',
  cancelBtnText: '#3D3D3D',
  offerAcceptMessageClr: '#767676',
  disableBackground: '#848484',
  storyTitleColor: '#4A4A4A',
  rejectedStateColor: '#B70000',
  supportButtonBg: '#262626',
  supportText: '#DCDCDC'
};

const profilePageColors = {
  titleColor: '#A1A1A1',
  companyLogoBg: '#EFEFEF',
  companyName: '#333333',
  companyRole: '#181818',
  roleDuration: '#5C5C5C',
  filmCardBg: '#D5D5D5',
  cardText: '#181818'
};

const pendingApprovalColors = {
  titleColor: '#A1A1A1',
  dateColor: '#A9A9A9',
  storyColor: '#333333',
  cardShadow: 'rgba(132, 132, 132, 0.2)'
};

const myTicketsColors = {
  ticketAmount: '#333333',
  cardShadow: 'rgba(132, 132, 132, 0.2)',
  myTicketTitle: '#181818',
  transactionHistoryColor: '#5C5C5C',
  currentBalanceText: '#787878',
  pageTitle: '#A1A1A1'
};

const notificationColors = {
  pageTitle: '#A1A1A1',
  notificationPillColor: '#FF0000',
  disabledOverlay: 'rgba(255,255,255,0.7)',
  notificationTitle: '#181818'
};

const neutral = {
  neutral90: '#C7CDD6',
  neutral80: '#F2F4F7',
  neutral70: '#F9FAFB'
};

const colors = {
  transparent: 'rgba(0,0,0,0)',
  // Example colors:
  text,
  primary,
  secondary,
  success,
  cardHeader,
  error,
  secondaryText,
  gotoStories,
  cardBg,
  lightBorder,
  darkBorder,
  headerColor,
  dropDownColor,
  tagBg,
  tagBorder,
  disabledBg,
  cardListItem,
  checkboxborder,
  cardHoverBg,
  cardActiveBg,
  btnDisabled,
  navbarText,
  myDashboardColors,
  redClr,
  white,
  dashboardBg,
  copyrightColors,
  mySubmissions,
  offersReceivedColors,
  profilePageColors,
  pendingApprovalColors,
  myTicketsColors,
  notificationColors,
  transparentLoadingBg,
  formTextColor,
  neutral,
  notifMessage,
  theme: {
    lightMode: {
      primary,
      secondary
    },
    darkMode: {
      primary: secondary,
      secondary: primary
    }
  }
};
module.exports = colors;
