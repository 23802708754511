import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { Progress } from 'antd';
import ArrowRight from '@images/icons/ArrowRightIcon.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import { Link } from 'react-router-dom';
import { If } from 'tsw-sdk';

export interface SubmissionDraftItemProps {
  date: string;
  title: string;
  percent: number;
  progress: string;
  id: string;
  isCopyrightPending: boolean;
}

const DraftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.205rem;
`;

const DraftItemContainer = styled.div`
  width: 11rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DateText = styled.p`
  ${fonts.size.xsmall()}
  ${fonts.weights.fw700()}
  color:${colors.myDashboardColors.tableText};
  line-height: 1rem;
`;

const DraftItemHeading = styled.p`
  ${fonts.size.regular()}
  ${fonts.weights.fw700()}
  color: ${colors.darkBorder};
  margin-top: 0.6rem;
  line-height: 1.25rem;
`;

const ProgressText = styled.p`
  ${fonts.size.xsmall()}
  ${fonts.weights.fw700()}
  color: ${colors.darkBorder};
  white-space: nowrap;
`;

const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  margin-top: 1rem;
`;

const CustomProgressBar = styled(Progress)`
  && {
    .ant-progress-success-bg,
    .ant-progress-bg {
      background-color: #4f4f4f;
    }

    .ant-progress-inner {
      background-color: #d8d8d8;
    }
  }
`;
const ContinueContainer = styled(Link)`
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
`;

const ContinueText = styled.p`
  ${fonts.size.small()}
  ${fonts.weights.fw800}
  color:${colors.myDashboardColors.viewText}
`;

const CopyrightText = styled.p`
  ${fonts.weights.fw700()};
  font-size: 0.4rem;
  line-height: 0.8rem;
  color: ${colors.myDashboardColors.pendingClr};
`;

const SubmissionDraftItem = ({ date, title, percent, progress, id, isCopyrightPending }: SubmissionDraftItemProps) => {
  return (
    <DraftContainer>
      <DraftItemContainer>
        <DateText>{date}</DateText>
        <DraftItemHeading>{title}</DraftItemHeading>
        <If condition={isCopyrightPending}>
          <CopyrightText>{translate('pending_approval')}</CopyrightText>
        </If>
        <ProgressContainer>
          <ProgressText>{`${percent}%`}</ProgressText>
          <CustomProgressBar percent={percent} showInfo={false} />
          <ProgressText>{progress}</ProgressText>
        </ProgressContainer>
      </DraftItemContainer>
      <ContinueContainer
        to={{
          pathname: '/dashboard',
          search: isCopyrightPending
            ? `?activeTab=pending-approval&storyId=${id}`
            : `?activeTab=new_submission&draftId=${id}`
        }}
      >
        <ContinueText>{translate('continue')}</ContinueText>
        <img src={ArrowRight} />
      </ContinueContainer>
    </DraftContainer>
  );
};

export default SubmissionDraftItem;
