import React, { useEffect } from 'react';
import { setClient } from '@app/utils/graphqlUtils';
import routeConstants from '@app/utils/routeConstants';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { successSetJWTToken } from '../TokenProvider/reducer';
import { AuthProps } from './types';
import { Auth } from 'aws-amplify';
import { Spin } from 'antd';
import styled from 'styled-components';
import { colors } from '@app/themes';

const PageSpinnerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;
`;

const AuthContainer = ({ dispatchSetToken }: AuthProps) => {
  const history = useHistory();

  useEffect(() => {
    const hasAuthCode = (url: string) => {
      const regex = /\/auth\?code=[^&\s]+/;
      return regex.test(url);
    };
    if (hasAuthCode(window.location.href)) {
      history.push(routeConstants.dashboard.route);
    }
  }, []);

  useEffect(() => {
    handleAuth();
  }, []);

  const handleAuth = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

      const userSession = await currentUser.getSignInUserSession();

      const accessToken = userSession.getAccessToken().getJwtToken();
      const refreshToken = userSession.getRefreshToken().getToken();
      const idToken = userSession.getIdToken().getJwtToken();

      setClient(accessToken);
      dispatchSetToken({
        accessToken: accessToken,
        idToken: idToken,
        refreshToken: refreshToken,
        credential: accessToken
      });

      history.push(routeConstants.dashboard.route);
    } catch (err: any) {
      // throw
      if (err) {
        history.push(routeConstants.signUp.route);
      }
    }
  };

  return (
    <PageSpinnerContainer>
      <Spin />
    </PageSpinnerContainer>
  );
};

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchSetToken: (data: any) => dispatch(successSetJWTToken(data))
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(AuthContainer);

export const AuthContainerTest = AuthContainer;
