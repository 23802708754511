import React from 'react';
import downloadIcon from '@images/icons/download-icon-gray.svg';
import { colors, fonts } from '@app/themes';
import styled from 'styled-components';
import { translate } from '@app/components/IntlGlobalProvider';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '@app/utils/constants';

const LegalUiContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2.62rem;
  padding-left: 4.93rem;
`;

const LegalContainerTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  line-height: 3.93rem;
  color: ${colors.profilePageColors.titleColor};
`;

const LegalContainerItem = styled.div`
  margin-top: 1.56rem;
  display: flex;
  align-items: center;
`;

const LegalContainerItemText = styled.p`
  ${fonts.size.big()};
  ${fonts.weights.fw700()};
  line-height: 1.5rem;
  color: ${colors.profilePageColors.companyName};
`;

const DownloadIcon = styled.img`
  margin-left: 1.5rem;
  cursor: pointer;
`;

const DownloadLink = styled.a``;

const LegalContainer = () => {
  return (
    <LegalUiContainer>
      <LegalContainerTitle>{translate('legal')}</LegalContainerTitle>
      <LegalContainerItem>
        <LegalContainerItemText>{translate('terms_and_conditions')} </LegalContainerItemText>
        <DownloadLink target={'_blank'} href={TERMS_AND_CONDITIONS_URL} rel="noreferrer">
          <DownloadIcon src={downloadIcon} />
        </DownloadLink>
      </LegalContainerItem>
      <LegalContainerItem>
        <LegalContainerItemText>{translate('privacy_policy')}</LegalContainerItemText>
        <DownloadLink target={'_blank'} href={PRIVACY_POLICY_URL} rel="noreferrer">
          <DownloadIcon src={downloadIcon} />
        </DownloadLink>
      </LegalContainerItem>
    </LegalUiContainer>
  );
};

export default LegalContainer;
