import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { Progress } from 'antd';
import ArrowRight from '@images/icons/ArrowRightIcon.svg';
import { translate } from '@app/components/IntlGlobalProvider';
import { Link } from 'react-router-dom';
import { If } from 'tsw-sdk';

export interface SubmissionDraftItemProps {
  date: string;
  title: string;
  percent: number;
  progress: string;
  id: string;
  isCopyrightPending: boolean;
  type?: string;
  handleContinue: any;
}

const DraftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DraftItemContainer = styled.div`
  width: 84.6%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DateText = styled.p<any>`
  ${fonts.size.xsmall()}
  ${fonts.weights.fw700()}
  color:${(props) => (props.type ? colors.mySubmissions.deletedDraftDate : colors.myDashboardColors.tableText)};
  line-height: 1rem;
`;

const Subcontainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 7rem;
  margin-top: 0.75rem;
`;

const DraftItemHeading = styled.h1`
  width: 18.18rem;
  ${fonts.family.SpoofFamily()}
  ${fonts.size.xBig()}
  ${fonts.weights.fw900()}
  color: ${colors.darkBorder};
  line-height: 1.87rem;
  margin-bottom: 0;
`;

const ProgressText = styled.p`
  ${fonts.size.xsmall()}
  ${fonts.weights.fw700()}
  color: ${colors.darkBorder};
  line-height: 1rem;
  white-space: nowrap;
`;

const ProgressContainer = styled.div`
  width: 15rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const CustomProgressBar = styled(Progress)`
  && {
    .ant-progress-success-bg,
    .ant-progress-bg {
      background-color: ${colors.myDashboardColors.viewText};
    }

    .ant-progress-inner {
      background-color: ${colors.cardBg};
    }
  }
`;

const ContinueContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  padding: 0.37rem 1.25rem;
`;

const ContinueText = styled.p`
  ${fonts.size.small()}
  ${fonts.weights.fw800}
  color:${colors.myDashboardColors.viewText};
  line-height: 1rem;
`;

const CopyrightText = styled.p`
  ${fonts.weights.fw700()};
  font-size: 0.4rem;
  line-height: 0.8rem;
  color: ${colors.myDashboardColors.pendingClr};
`;
const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubmissionDraftItem = ({
  date,
  title,
  percent,
  progress,
  id,
  isCopyrightPending,
  type,
  handleContinue
}: SubmissionDraftItemProps) => {
  const isDeletedDraft = type === 'deleted';
  return (
    <DraftContainer>
      <DraftItemContainer>
        <DateText type={isDeletedDraft}>{isDeletedDraft ? `${translate('deleted_on')} ${date}` : date}</DateText>
        <Subcontainer>
          <HeadingContainer>
            <DraftItemHeading>{title}</DraftItemHeading>
            <If condition={isCopyrightPending}>
              <CopyrightText>{translate('copyright_pending')}</CopyrightText>
            </If>
          </HeadingContainer>

          <ProgressContainer>
            <ProgressText>{`${percent}%`}</ProgressText>
            <CustomProgressBar percent={percent} showInfo={false} />
            <ProgressText>{progress}</ProgressText>
          </ProgressContainer>
        </Subcontainer>
      </DraftItemContainer>
      <ContinueContainer
        to={{
          pathname: '/dashboard',
          search: `?activeTab=new_submission&draftId=${id}`
        }}
      >
        <ContinueText onClick={() => handleContinue(id)} data-testid="continue-text">
          {translate('continue')}
        </ContinueText>
        <img src={ArrowRight} />
      </ContinueContainer>
    </DraftContainer>
  );
};

export default SubmissionDraftItem;
