import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CustomButton } from 'tsw-sdk';
import downloadIcon from '@images/icons/download-icon.svg';
import uploadIcon from '@images/icons/upload-icon.svg';
import { NOC_TEMPLATE_URL } from '@app/utils/constants';
import { NocPageProps } from './types';
import { validateFileSize } from '@app/utils/uiUtils';
import { message } from 'antd';

const NocContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NocContentContainer = styled.div`
  width: 100%;
  max-width: 38rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoChoiceDescriptionText = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  line-height: 1.31rem;
  margin-top: 3rem;
`;

const ProTipTextMessage = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  line-height: 1.31rem;

  & > span {
    ${fonts.weights.fw700()};
  }
`;

const ProTipText = styled.span``;

const NocDownloadTitle = styled.p`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-top: 3rem;
  line-height: 1.96rem;
`;

const DownloadNocBtn = styled(CustomButton)`
  margin-right: auto;
  margin-top: 1rem;
`;

const DownloadIcon = styled.img`
  margin-left: 0.5rem;
`;

const UploadNocTitle = styled.p`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-top: 3rem;
  line-height: 1.96rem;
`;

const UploadNocBtn = styled(CustomButton)`
  margin-right: auto;
  margin-top: 1rem;

  && {
    border: 1px solid ${colors.primary};
    background-color: transparent;
    justify-content: center;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const UploadIcon = styled.img`
  margin-left: 0.5rem;
`;

const IncaseText = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  margin-top: 3rem;
  line-height: 1.31rem;
`;

const NocFormatDownloadLink = styled.a`
  display: none;
`;

const NocInputRef = styled.input`
  display: none;
`;

const FileUploadedText = styled.p`
  margin-top: 0.5rem;
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-right: auto;
  line-height: 1.31rem;
`;

const PreviewFileBtn = styled.span`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-left: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
`;

const DeleteFileBtn = styled.span`
  ${fonts.family.SpoofRegular()};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-left: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
`;

const FileLimitText = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  margin-top: 0.4rem;
  line-height: 1.31rem;
`;

const NocPage = ({
  nocFilePath,
  nocFileName,
  nocSignedDocumentPath,
  dispatchSetNocFilePath,
  handleNocFileUpload,
  dispatchPreviewFile
}: NocPageProps) => {
  const nocFileRef = useRef<any>(null);
  const uploadedNocDownloadRef = useRef<any>(null);
  const [fileName, setFileName] = useState<string>('');

  useEffect(() => {
    if (fileName === '') {
      let fileNameString = '';
      if (nocFileName) {
        const fileNameArr = nocFileName.split('/');
        fileNameString = fileNameArr[fileNameArr.length - 1];
      }

      if (fileNameString.trim() === '') {
        if (nocFilePath) {
          const fileNameArr = nocFilePath.split('/');
          fileNameString = fileNameArr[fileNameArr.length - 1];
        }
      }

      setFileName(fileNameString);
    }
  }, [nocFileName, nocFilePath]);

  const downloadNocTemplate = () => {
    window.open(nocSignedDocumentPath, '_blank');
  };

  const handleNocFileChange = (e: any) => {
    const file = e.target.files[0];
    if (validateFileSize(file, 5)) {
      handleNocFileUpload(file);
    } else {
      message.error(translate('file_size_exceed', { fileSize: '5mb' }));
    }

    e.target.value = null;
  };

  const handleUploadNocClick = () => {
    nocFileRef.current?.click();
  };

  const handleUploadedNocFileDownload = () => {
    if (nocFilePath.includes('https')) {
      window.open(nocFilePath, '_blank');
    } else {
      let rootFolder: any = nocFilePath.split('.')[0];
      rootFolder = rootFolder.split('/');
      rootFolder.pop();

      rootFolder = rootFolder.join('/');

      let fileName: any = nocFilePath.split('/');

      fileName = fileName[fileName.length - 1];

      dispatchPreviewFile({
        rootFolder,
        fileName
      });
    }
  };

  return (
    <NocContainer data-testid="noc-page-container">
      <NocInputRef
        type={'file'}
        accept="application/pdf"
        ref={nocFileRef}
        onChange={handleNocFileChange}
        data-testid="noc-file-input"
      />
      <NocFormatDownloadLink href={nocFilePath} ref={uploadedNocDownloadRef} target="_blank" download={true} />
      <NocContentContainer>
        <NoChoiceDescriptionText>{translate('no_description_text')}</NoChoiceDescriptionText>
        <ProTipTextMessage>
          <ProTipText>{translate('pro_tip_text')}</ProTipText>
          {translate('pro_tip')}
        </ProTipTextMessage>
        <NocDownloadTitle>{translate('download_format_title')}</NocDownloadTitle>
        <DownloadNocBtn onClick={downloadNocTemplate} data-testid="download-noc-btn">
          {translate('download_noc_btn_text')} <DownloadIcon src={downloadIcon} />
        </DownloadNocBtn>
        <UploadNocTitle>{translate('upload_noc_title')}</UploadNocTitle>
        <UploadNocBtn
          text_color={`${colors.darkBorder}`}
          onClick={handleUploadNocClick}
          disabled={nocFilePath !== ''}
          data-testid="upload-noc-btn"
        >
          {translate('upload_noc_btn')} <UploadIcon src={uploadIcon} />
        </UploadNocBtn>
        <FileLimitText>{translate('5mb_size_limit')}</FileLimitText>
        {nocFilePath !== '' && (
          <FileUploadedText>
            {translate('noc_uploaded_text', { fileName: fileName })}
            <PreviewFileBtn onClick={handleUploadedNocFileDownload} data-testid="noc-preview-btn">
              {translate('preview')}
            </PreviewFileBtn>
            <DeleteFileBtn onClick={() => dispatchSetNocFilePath('')} data-testid="noc-delete-file-btn">
              {translate('delete')}
            </DeleteFileBtn>
          </FileUploadedText>
        )}
        {/* <IncaseText>{translate('incase_text')}</IncaseText> */}
      </NocContentContainer>
    </NocContainer>
  );
};

export default NocPage;
