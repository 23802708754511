import { translate } from '@app/components/IntlGlobalProvider';
import { colors, fonts } from '@app/themes';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CustomButton } from 'tsw-sdk';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CopyrightDeclarationProps } from './types';
import NocPage from './NocPage';
import { AUTHOR_STATES } from '@app/utils/constants';

type ButtonProps = {
  selected: boolean;
};

const CopyrightDeclarationContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CopyrightContentContainer = styled.div`
  width: 100%;
  max-width: 38rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
`;

const CopyrightDeclarationTitle = styled.h2`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xlarge()};
  color: ${colors.copyrightColors.copyrightDeclarationTitle};
  margin-bottom: 0;
`;

const AuthorQuestion = styled.p`
  width: 100%;
  ${fonts.family.SpoofFamily()};
  ${fonts.weights.fw700()};
  ${fonts.size.xBig()};
  color: ${colors.copyrightColors.copyrightDeclarationQuesion};
  margin-top: 3rem;
  line-height: 1.96rem;
  margin-bottom: 1.43rem;
`;

const NotAuthorHelp = styled.p`
  width: 100%;
  ${fonts.family.SpoofRegular()};
  ${fonts.weights.fw400()};
  ${fonts.size.regular()};
  color: ${colors.copyrightColors.timelineItemTextActive};
  line-height: 1.31rem;
`;

const AuthorQuestionBtnContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
`;

const AuthorQuestionBtn = styled(CustomButton)<ButtonProps>`
  border-width: 2px !important;
  border-style: solid !important;
  border-color: ${(props) => (props.selected ? colors.copyrightColors.btnBorder : 'transparent')} !important;
`;

const AuthorQuestionBtnYes = styled(CustomButton)<ButtonProps>`
  margin-left: 1.5rem;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: ${(props) => (props.selected ? colors.copyrightColors.btnBorder : 'transparent')} !important;
`;

const ProceedBtn = styled(CustomButton)`
  margin-top: 3rem;
  margin-bottom: 2rem;

  && {
    width: 100%;
    border: 1px solid ${colors.primary};
    background-color: transparent;
    justify-content: center;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`;

const CopyrightDeclaration = ({
  isAuthor,
  copyrightAuthorVerificationDetails,
  sellerId,
  storyId,
  nocActive,
  nocFilePath,
  nocFileName,
  nocSignedDocumentPath,
  dispatchGetCopyrightAuthorVerificationDetails,
  dispatchUpdateAuthorVerificationDetails,
  dispatchUploadNocFile,
  dispatchSetNocFilePath,
  dispatchPreviewFile,
  setIsAuthor,
  setNocActive,
  dispatchGetNocDocument
}: CopyrightDeclarationProps) => {
  useEffect(() => {
    if (sellerId && storyId) {
      dispatchGetCopyrightAuthorVerificationDetails(sellerId, storyId);
      dispatchGetNocDocument(sellerId.toString());
    }
  }, [sellerId, storyId]);

  useEffect(() => {
    if (isAuthor === 'no') {
      setNocActive(true);
    } else {
      setNocActive(false);
    }
  }, [isAuthor]);

  useEffect(() => {
    if (copyrightAuthorVerificationDetails) {
      if (copyrightAuthorVerificationDetails.declaration === AUTHOR_STATES.CLAIMED_AUTHOR) {
        setIsAuthor('yes');
      } else if (copyrightAuthorVerificationDetails.declaration === AUTHOR_STATES.DISCLAIMED_AUTHOR) {
        setIsAuthor('no');
      }
      if (copyrightAuthorVerificationDetails.noc) {
        if (copyrightAuthorVerificationDetails.noc.uploaded) {
          const filePath = copyrightAuthorVerificationDetails.noc.filepath;
          dispatchSetNocFilePath(filePath);
        }
      }
    }
  }, [copyrightAuthorVerificationDetails]);

  const handleNocFileUpload = (file: any) => {
    dispatchUploadNocFile({ storyId, file });
  };

  const handleProceed = () => {
    let payload = {};
    if (isAuthor === 'yes') {
      payload = {
        storyId: storyId,
        copyrightId: copyrightAuthorVerificationDetails?.copyrightId,
        hasClaimedAuthor: AUTHOR_STATES.CLAIMED_AUTHOR,
        proceedToNextStep: true
      };
    } else if (isAuthor === 'no') {
      payload = {
        storyId: storyId,
        copyrightId: copyrightAuthorVerificationDetails?.copyrightId,
        hasClaimedAuthor: AUTHOR_STATES.DISCLAIMED_AUTHOR,
        noc: {
          isFileUploaded: true,
          filepath: nocFileName !== '' ? nocFileName : nocFilePath
        },
        proceedToNextStep: true
      };
    }

    dispatchUpdateAuthorVerificationDetails(payload);
  };

  const getIsDisabled = () => {
    let isDisabled = false;

    if (isAuthor === 'yes') {
      isDisabled = false;
    } else if (isAuthor === 'no') {
      if (nocFileName === '' && nocFilePath === '') {
        isDisabled = true;
      }
      if (nocSignedDocumentPath === '') {
        isDisabled = true;
      }
      if (!copyrightAuthorVerificationDetails?.copyrightId) {
        isDisabled = true;
      }
    } else {
      isDisabled = true;
    }

    return isDisabled;
  };

  return (
    <CopyrightDeclarationContainer data-testid="copyright-declaration-container">
      <CopyrightContentContainer>
        <CopyrightDeclarationTitle>
          {nocActive ? translate('no_objection_certificate') : translate('copyright_declaration_title')}
        </CopyrightDeclarationTitle>
        <AuthorQuestion>{translate('author_question')}</AuthorQuestion>
        <NotAuthorHelp>{translate('not_author_help')}</NotAuthorHelp>
        <AuthorQuestionBtnContainer>
          <AuthorQuestionBtn
            onClick={() => setIsAuthor('no')}
            data-testid="author-question-no"
            selected={isAuthor === 'no'}
          >
            {translate('no')}
          </AuthorQuestionBtn>
          <AuthorQuestionBtnYes
            onClick={() => setIsAuthor('yes')}
            data-testid="author-question-yes"
            selected={isAuthor === 'yes'}
          >
            {translate('yes')}
          </AuthorQuestionBtnYes>
        </AuthorQuestionBtnContainer>

        {isAuthor === 'no' ? (
          <NocPage
            nocFilePath={nocFilePath}
            nocFileName={nocFileName}
            nocSignedDocumentPath={nocSignedDocumentPath}
            handleNocFileUpload={handleNocFileUpload}
            dispatchSetNocFilePath={dispatchSetNocFilePath}
            dispatchPreviewFile={dispatchPreviewFile}
          />
        ) : (
          <></>
        )}

        <ProceedBtn
          text_color={`${colors.darkBorder}`}
          onClick={handleProceed}
          disabled={getIsDisabled()}
          data-testid="proceed-btn"
        >
          {translate('proceed')} <ArrowRightOutlined />
        </ProceedBtn>
      </CopyrightContentContainer>
    </CopyrightDeclarationContainer>
  );
};

export default CopyrightDeclaration;

export const CopyrightDeclarationTest = CopyrightDeclaration;
