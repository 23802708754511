import NavBar from '@app/components/NavBar';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import MyDashboard from './MyDashboard';

import { useHistory, useLocation } from 'react-router-dom';
import MySubmissions from './MySubmissions';
import OffersRecieved from './OffersRecieved';
import CommunityPage from './MyCommunity';
import NotificationPage from './MyNotifications';
import ProfilePage from './MyProfile';
import NewSubmissionPage from './NewSubmissionPage';
import SubmittedStoryPage from './SubmittedStoryPage';
import { selectAccessToken, selectIdToken, selectRefreshToken } from '../TokenProvider/selectors';
import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { requestRefreshToken } from '../LoginContainer/reducer';
import { get, isEmpty } from 'lodash-es';
import jwtDecode from 'jwt-decode';
import { DashboardProps, PageContainerProps } from './types';
import OfferView from './OfferView';
import ManageOffer from './ManageOffer';
import OfferHistory from './OfferHistory';
import AcceptedOffer from './AcceptedOffer';
import CounteringLoader from './ManageOffer/components/CounteringOffer';
import CelebrationTime from './ManageOffer/components/CelebrationTime';
import PendingApproval from './PendingApproval';
import { requestGetProfile } from '../ProfileContainer/reducer';
import { selectUserProfile } from '../ProfileContainer/selectors';
import { resetState } from '../CopyrightContainer/reducer';
import AddSpotTickets from './AddSpotTickets';
import MySpotTickets from './MySpotTickets';
import TicketTransactionDetails from './TicketTransactionDetails';
import { requestAccountStatus } from '../HomeContainer/reducer';
import { selectAccountStatusData } from '../HomeContainer/selectors';
import routeConstants from '@app/utils/routeConstants';
import { selectGlobalLoading } from '../App/selectors';
import { If, PageSpinner } from 'tsw-sdk';
import SubscriptionExpiredPage from './SubscriptionExpiredPage';
import { requestRenewSubscription } from './reducer';
import { requestGetSpotTickets } from './MySpotTickets/reducer';
import { selectSpotTicketsBalance } from './MySpotTickets/selectors';
import { requestGenerateSpotTicketsLink } from './AddSpotTickets/reducer';
import SubscriptionRenewedPage from './SubscriptionExpiredPage/components/SubscriptionRenewedPage';
import RequestView from './RequestView';

const DashboardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: auto;
`;
const PageContainer = styled.div<PageContainerProps>`
  width: 100%;
  min-width: 70rem;
  height: 100%;
  overflow-y: ${(props) => (props.isActive ? `auto` : 'hidden')};
  position: relative;
`;

type IdToken = {
  email: string;
};

const DashboardContainerPage = ({
  accessToken,
  idToken,
  refreshToken,
  userProfile,
  accountStatusData,
  globalLoading,
  spotTicketsBalance,
  dispatchRefreshToken,
  dispatchGetUserProfile,
  dispatchResetCopyrightState,
  dispatchAccountStatus,
  dispatchRenewSubscription,
  dispatchGetMySpotTickets,
  dispatchGenerateAddSpotTicketsLink
}: DashboardProps) => {
  const location = useLocation();
  const history = useHistory();

  const cognitoGroup: any = get(accessToken, 'cognito:groups', []);

  useEffect(() => {
    if (refreshToken && idToken) {
      if (!cognitoGroup.length) {
        const decoded: IdToken = jwtDecode(idToken);
        dispatchRefreshToken(decoded.email, refreshToken);
      } else {
        if (!cognitoGroup.includes('seller')) {
          const decoded: IdToken = jwtDecode(idToken);
          dispatchRefreshToken(decoded.email, refreshToken);
        }
      }
    }
    if (!userProfile?.id) {
      dispatchGetUserProfile();
    } else {
      dispatchGetMySpotTickets(userProfile?.id);
    }
    dispatchResetCopyrightState();
    dispatchAccountStatus();
  }, []);

  useEffect(() => {
    if (userProfile?.id) {
      dispatchGetMySpotTickets(userProfile?.id);
    }
  }, [userProfile]);

  useEffect(() => {
    if (accountStatusData) {
      if (!isEmpty(accountStatusData)) {
        if (!accountStatusData?.isRegistrationComplete) {
          history.push(routeConstants.profile.route);
        }
      }
    }
  }, [accountStatusData]);

  const getPage = () => {
    const params = new URLSearchParams(location?.search);

    const activeTab = params.get('activeTab');

    switch (activeTab) {
      case 'dashboard':
        return <MyDashboard />;
      case 'spot_tickets':
        return <AddSpotTickets />;
      case 'submissions':
        return <MySubmissions />;
      case 'offers_recieved_nav':
        return <OffersRecieved />;
      case 'community':
        return <CommunityPage />;
      case 'notifications':
        return <NotificationPage />;
      case 'profile':
        return <ProfilePage />;
      case 'new_submission':
        return <NewSubmissionPage />;
      case 'submitted_story':
        return <SubmittedStoryPage />;
      case 'offers':
        return <OfferView />;
      case 'offer-detail':
        return <ManageOffer />;
      case 'offer-history':
        return <OfferHistory />;
      case 'accepted-offer':
        return <AcceptedOffer />;
      case 'counter-offer':
        return <CounteringLoader />;
      case 'celebration-time':
        return <CelebrationTime />;
      case 'pending-approval':
        return <PendingApproval />;
      case 'my-tickets':
        return <MySpotTickets />;
      case 'transaction-details':
        return <TicketTransactionDetails />;
      case 'subscription-renewed':
        return <SubscriptionRenewedPage spotTicketsBalance={spotTicketsBalance} />;
      case 'request-view':
        return <RequestView />;
      default:
        return <MyDashboard />;
    }
  };

  return (
    <DashboardContainer>
      <PageSpinner loading={globalLoading} />
      <NavBar />
      <PageContainer isActive={accountStatusData?.subscription?.isActive}>
        {getPage()}
        <If condition={!isEmpty(accountStatusData) ? !accountStatusData?.subscription?.isActive : false}>
          <SubscriptionExpiredPage
            userProfile={userProfile}
            dispatchRenewSubscription={dispatchRenewSubscription}
            handleCardClick={() => dispatchGetMySpotTickets(userProfile?.id)}
            spotTicketsBalance={spotTicketsBalance}
            handleAddingTickets={dispatchGenerateAddSpotTicketsLink}
          />
        </If>
      </PageContainer>
    </DashboardContainer>
  );
};

export const mapStateToProps = createStructuredSelector({
  accessToken: selectAccessToken(),
  idToken: selectIdToken(),
  refreshToken: selectRefreshToken(),
  userProfile: selectUserProfile(),
  accountStatusData: selectAccountStatusData(),
  globalLoading: selectGlobalLoading(),
  spotTicketsBalance: selectSpotTicketsBalance()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchRefreshToken: (email: string, refreshToken: string) =>
      dispatch(requestRefreshToken({ email, refreshToken })),
    dispatchGetUserProfile: () => dispatch(requestGetProfile({})),
    dispatchAccountStatus: () => dispatch(requestAccountStatus()),
    dispatchResetCopyrightState: () => dispatch(resetState()),
    dispatchRenewSubscription: () => dispatch(requestRenewSubscription({})),
    dispatchGetMySpotTickets: (sellerId: string) =>
      dispatch(
        requestGetSpotTickets({
          sellerId
        })
      ),
    dispatchGenerateAddSpotTicketsLink: (tickets: number) =>
      dispatch(
        requestGenerateSpotTicketsLink({
          tickets
        })
      )
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DashboardContainerPage);

export const DashboardContainerPageTest = DashboardContainerPage;
