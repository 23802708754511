import { colors, fonts } from '@app/themes';
import React from 'react';
import styled from 'styled-components';
import { StoryItemProps } from './types';

const StoryItemContainer = styled.div`
  width: 18rem;
  height: 14.5rem;
  background-color: ${colors.profilePageColors.filmCardBg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  border-radius: 1rem;

  &:not(:first-of-type) {
    margin-left: 2rem;
  }
`;

const StoryName = styled.p`
  ${fonts.family.SpotwriterFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw400()};
  color: ${colors.profilePageColors.cardText};
`;

const StoryYear = styled.p`
  margin-top: 3rem;
  ${fonts.family.SpotwriterFamily()};
  ${fonts.size.xBig()};
  ${fonts.weights.fw400()};
  color: ${colors.profilePageColors.cardText};
`;

const StoryItem = ({ story }: StoryItemProps) => {
  return (
    <StoryItemContainer>
      <StoryName>{story.name}</StoryName>
      <StoryYear>{new Date(story.publishDate * 1000).getFullYear()}</StoryYear>
    </StoryItemContainer>
  );
};

export default StoryItem;
