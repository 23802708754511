import React from 'react';
import { colors, fonts } from '@app/themes';
import { Modal } from 'antd';
import styled from 'styled-components';
import { CustomButton } from 'tsw-sdk';
import ArrowRight from '@images/icons/MySubmissionIcons/arrow-right.svg';
import { ModalProps } from './types';
import { translate } from '../IntlGlobalProvider';

const StyledModal = styled(Modal)`
  margin: 2rem;

  &&& {
    & .ant-modal-content {
      border-radius: 1.25rem;
    }

    & .ant-modal-footer {
      display: none;
    }

    & .ant-modal-close {
      display: none;
    }
  }
`;

const ContentContainer = styled.div<any>`
  width: 100%;
  height: ${(props) => props.height ?? '20rem'};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ModalTitle = styled.p`
  ${fonts.family.SpoofFamily()};
  ${fonts.size.extraLarge()};
  ${fonts.weights.fw700()};
  color: ${colors.primary};
  width: 70%;
  line-height: 3.93rem;
  text-align: center;
`;

const ModalDesc = styled.p`
  ${fonts.size.size15px()};
  color: ${colors.primary};
  width: 40%;
  margin-top: 2.313rem;
  line-height: 1rem;
  text-align: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 3.7rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

const CancelButton = styled(CustomButton)`
  &&& {
    background-color: ${colors.tagBg};
    color: ${colors.mySubmissions.buttonClr};
  }
`;

const Icon = styled.img`
  margin-left: 10px;
`;

const CustomModal = ({ title, desc, height, width, visible, handleCancel, handleConfirm }: ModalProps) => {
  return (
    <StyledModal
      centered={true}
      visible={visible}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={<></>}
      width={width ?? 700}
      data-testid="modal"
    >
      <ContentContainer height={height} data-testid="content-container">
        <ModalTitle>{translate(title)}</ModalTitle>
        <ModalDesc>{translate(desc)}</ModalDesc>
        <ButtonContainer>
          <CancelButton onClick={handleCancel} data-testid="modal-cancel-btn">
            {translate('cancel')}
          </CancelButton>
          <CustomButton onClick={handleConfirm} data-testid="modal-confirm-btn">
            {translate('delete')}
            <Icon src={ArrowRight} alt="right arrow" />
          </CustomButton>
        </ButtonContainer>
      </ContentContainer>
    </StyledModal>
  );
};

export default CustomModal;
